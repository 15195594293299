import { Grid, Paper } from '@mui/material';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { UploadFileButton } from '../UploadFileButton';
import { FC } from 'react';
import { useIsDesktop } from 'Hooks';
export interface IFileCard {
  clickHandler: () => void;
  fieldTitle?: string;
}
export const FileCard: FC<IFileCard> = (props) => {
  const dictionary = Dictionary[DICTIONARY.GENERAL];
  const isDesktop = useIsDesktop();

  return (
    <Paper
      sx={{
        width: '100%',
        boxShadow: 'none',
      }}
    >
      <Grid container spacing={1} alignItems='center'>
        <Grid item sx={isDesktop ? { ml: 'auto' } : { m: '0 auto' }}>
          <UploadFileButton clickHandler={props.clickHandler}>
            {dictionary.addAdditionalFile}
          </UploadFileButton>
        </Grid>
      </Grid>
      {props.children}
    </Paper>
  );
};
