import { api } from 'API';
import { FileCategories } from 'Enum';

const baseUrl = '/file';
type base64 = string;

interface IFileBase64 {
  contentType: string;
  fileContentBase64: base64;
}
export const getFile = async (id: number, fileName: string) => {
  const fileData = await api.get<IFileBase64>(baseUrl + '/' + id);
  if (fileData.data) {
    const byteCharacters = atob(fileData.data.fileContentBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new File([byteArray], fileName, {
      type: fileData.data.contentType,
    });
  }
};

export interface IFile {
  caseDetailsRelationId?: number;
  fileCategoryRelationId: number;
  id: number;
  userGivenName: string;
  hospitalizationDetailsRelationId?: number;
}

export type fileNames = Partial<Record<FileCategories, IFile[]>>;

export const getFileNames = async () => {
  const fileNamesData = await api.get<fileNames>(baseUrl + '/' + 'fileNames');
  if (fileNamesData.data) {
    return fileNamesData.data;
  }
};

export const postFile = async (
  fileCategoryId: number,
  file: FormData,
  caseDetailsId?: number,
  hospitalizationDetailsId?: number
) => {
  const caseDetailsKeyName = 'caseDetailsId';
  const hospitalizationDetailsKeyName = 'hospitalizationDetailsId';

  let url = baseUrl + '/' + fileCategoryId;
  if (caseDetailsId || hospitalizationDetailsId) {
    url += '?';
  }
  if (caseDetailsId) {
    url += `${caseDetailsKeyName}=${caseDetailsId}`;
  }
  if (hospitalizationDetailsId) {
    url += `&${hospitalizationDetailsKeyName}=${hospitalizationDetailsId}`;
  }
  const fileData = await api.post<IFile>(url, file);
  if (fileData.data) {
    return fileData.data;
  }
};

export const deleteFile = async (id: number) => {
  const fileData = await api.delete(baseUrl + '/' + id);
  if (fileData.status === 200) {
    return true;
  }
  return false;
};
