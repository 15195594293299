import { IPersonalDetails } from 'API';
import {
  textTooLong,
  onlyNumbers,
  onlyNumbersMessage,
  emailWithEmpty,
  emailMessage,
  requiredMessage,
  lettersOrNumbersNoSpecialChars,
  lettersOrNumbersNoSpecialCharsMessage,
  englishLettersAndNumbers,
  englishLettersAndNumbersMessage,
} from 'Regex';
import { store } from 'Store';
import * as yup from 'yup';

// Not in use yet not enough time to implement schema
export const personalDetailsSchema: yup.SchemaOf<IPersonalDetails> = yup
  .object()
  .shape({
    contactInfoSection: yup.object({
      phoneNumber: yup
        .string()
        .matches(onlyNumbers, onlyNumbersMessage)
        .max(7, textTooLong(7))
        .required(requiredMessage)
        .nullable(),
      phonePrefixRelationId: yup.string().required(requiredMessage).nullable(),
      extraPhoneNumber: yup
        .string()
        .matches(onlyNumbers, onlyNumbersMessage)
        .max(7, textTooLong(7))
        .notRequired()
        .nullable(),
      mail: yup.string().when('isRecognized', {
        is: () => store.getState().generalContent.formInfo.isRecognized,
        then: yup.string().notRequired().nullable(),
        otherwise: yup
          .string()
          .matches(emailWithEmpty, emailMessage)
          .test({
            name: 'isRequired',
            message: requiredMessage,
            test: (value, context) => {
              if (context.parent.hasNoMail === false && value == '') {
                return false;
              }
              return true;
            },
          })
          .max(50, textTooLong(50))
          .nullable()
          .notRequired(),
      }),

      hasNoMail: yup.boolean().when('isRecognized', {
        is: () => store.getState().generalContent.formInfo.isRecognized,
        then: yup.boolean().notRequired().nullable(),
        otherwise: yup
          .boolean()
          .test({
            name: 'isRequired',
            message: requiredMessage,
            test: (value, context) => {
              if (context.parent.mail == '' && value === false) {
                return false;
              }
              return true;
            },
          })
          .nullable()
          .notRequired(),
      }),
    }),
    hmoSection: yup.object({
      hmoRelationId: yup.number().required(requiredMessage).nullable(),
      doctorsNameAndSpecialization: yup
        .string()
        .notRequired()
        .nullable()
        .matches(
          lettersOrNumbersNoSpecialChars,
          lettersOrNumbersNoSpecialCharsMessage
        ),
    }),
    mailBoxSection: yup
      .object({
        townId: yup
          .string()
          .nullable()
          .test({
            name: 'isRequired',
            message: requiredMessage,
            test: (value, context) => {
              if (
                store.getState().personalDetails.generalObj
                  .isDifferentMailAddress === false
              ) {
                return true;
              } else {
                if (!value) {
                  return false;
                }
                return true;
              }
            },
          }),
        apartmentNumber: yup
          .string()
          .notRequired()
          .nullable()
          .matches(onlyNumbers, onlyNumbersMessage),
        street: yup
          .string()
          .test({
            name: 'isRequired',
            message: requiredMessage,
            test: (value, context) => {
              if (
                store.getState().personalDetails.generalObj
                  .isDifferentMailAddress === false ||
                store.getState().personalDetails.generalObj.isAddressTab ===
                  false
              ) {
                return true;
              } else {
                if (!value) {
                  return false;
                }
                return true;
              }
            },
          })
          .nullable()
          .matches(
            lettersOrNumbersNoSpecialChars,
            lettersOrNumbersNoSpecialCharsMessage
          ),
        mailHouseNumber: yup
          .string()
          .test({
            name: 'isRequired',
            message: requiredMessage,
            test: (value, context) => {
              if (
                store.getState().personalDetails.generalObj
                  .isDifferentMailAddress === false ||
                store.getState().personalDetails.generalObj.isAddressTab ===
                  false
              ) {
                return true;
              } else {
                if (!value) {
                  return false;
                }
                return true;
              }
            },
          })
          .nullable()
          .matches(
            lettersOrNumbersNoSpecialChars,
            lettersOrNumbersNoSpecialCharsMessage
          ),
        zipCode: yup
          .string()
          .test({
            name: 'isRequired',
            message: requiredMessage,
            test: (value, context) => {
              if (
                store.getState().generalContent.formInfo.isRecognized ||
                store.getState().personalDetails.generalObj
                  .isDifferentMailAddress === false
              ) {
                return true;
              } else {
                if (!value) {
                  return false;
                }
                return true;
              }
            },
          })
          .test({
            name: 'length',
            message: 'חייב להכיל 7 ספרות',
            test: (value, context) => {
              if (
                store.getState().personalDetails.generalObj
                  .isDifferentMailAddress === false
              ) {
                return true;
              }
              if (value?.length === 7) {
                return true;
              }
              return false;
            },
          })
          .nullable()
          .matches(onlyNumbers, onlyNumbersMessage),
        po: yup
          .string()
          .notRequired()
          .nullable()
          .matches(onlyNumbers, onlyNumbersMessage),
      })
      .notRequired(),
    personalInfoSection: yup.object({
      birthDate: yup.string().required(requiredMessage).nullable(),
      birthCountryId: yup
        .number()
        .test({
          name: 'isRequired',
          message: requiredMessage,
          test: (val) => {
            if (store.getState().generalContent.formInfo.isRecognized || val) {
              return true;
            }
            return false;
          },
        })
        .nullable(),
      immigrationYear: yup.string().notRequired().nullable(),
      genderId: yup.number().required(requiredMessage).nullable(),
      maritalStatusId: yup
        .number()
        .when('isRecognized', {
          is: () => store.getState().generalContent.formInfo.isRecognized,
          then: yup.number().nullable(),
          otherwise: yup.number().required(requiredMessage).nullable(),
        })
        .nullable(),

      firstName: yup
        .string()
        .required(requiredMessage)
        .max(20, textTooLong(20))
        .matches(
          lettersOrNumbersNoSpecialChars,
          lettersOrNumbersNoSpecialCharsMessage
        )
        .nullable(),
      lastName: yup
        .string()
        .required(requiredMessage)
        .max(30, textTooLong(30))
        .matches(
          lettersOrNumbersNoSpecialChars,
          lettersOrNumbersNoSpecialCharsMessage
        )
        .nullable(),
      israeliId: yup.string().required(requiredMessage).nullable(),
    }),
    previousHMOSection: yup.array().of(
      yup.object({
        hmoRelationId: yup.number().required(requiredMessage).nullable(),
        hospitalizationEndDate: yup
          .string()
          .required(requiredMessage)
          .nullable(),
      })
    ),
    residentialAddressSection: yup.object({
      israeliAddressRelation: yup.object({
        israeliTownId: yup
          .string()
          .test({
            name: 'isRequired',
            message: requiredMessage,
            test: (val) => {
              if (val) {
                return true;
              }
              return false;
            },
          })
          .nullable(),
        apartmentNumber: yup
          .string()
          .notRequired()
          .nullable()
          .matches(onlyNumbers, onlyNumbersMessage),
        street: yup
          .string()
          .test({
            name: 'isRequired',
            message: requiredMessage,
            test: (val) => {
              if (val !== '') {
                return true;
              }
              return false;
            },
          })
          .nullable()
          .matches(
            lettersOrNumbersNoSpecialChars,
            lettersOrNumbersNoSpecialCharsMessage
          ),
        homeNumHouse: yup
          .string()
          .test({
            name: 'isRequired',
            message: requiredMessage,
            test: (val) => {
              if (val !== '') {
                return true;
              }
              return false;
            },
          })
          .nullable()
          .matches(
            lettersOrNumbersNoSpecialChars,
            lettersOrNumbersNoSpecialCharsMessage
          ),
        israeliZipCode: yup
          .string()
          .when('isRecognized', {
            is: () => store.getState().generalContent.formInfo.isRecognized,
            then: yup.string().nullable(),
            otherwise: yup
              .string()
              .test({
                name: 'isRequired',
                message: requiredMessage,
                test: (val) => {
                  if (val !== '') {
                    return true;
                  }
                  return false;
                },
              })
              .nullable()
              .min(7, 'חייב להכיל 7 ספרות')
              .matches(onlyNumbers, onlyNumbersMessage),
          })
          .nullable(),
      }),
      abroadAddressRelation: yup.object({
        countryId: yup.string().notRequired().nullable(),
        street: yup
          .string()
          .notRequired()
          .nullable()
          .matches(
            lettersOrNumbersNoSpecialChars,
            lettersOrNumbersNoSpecialCharsMessage
          ),
        abroadTown: yup
          .string()
          .notRequired()
          .nullable()
          .matches(
            lettersOrNumbersNoSpecialChars,
            lettersOrNumbersNoSpecialCharsMessage
          ),
        abroadZipCode: yup
          .string()
          .nullable()
          .matches(englishLettersAndNumbers, englishLettersAndNumbersMessage)
          .notRequired(),
        po: yup
          .string()
          .nullable()
          .notRequired()
          .matches(onlyNumbers, onlyNumbersMessage),
      }),
    }),
  });
