import { Stack } from '@mui/material';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { background } from 'Images';
import { useDispatchApp } from 'Hooks';
import { Loader } from 'Components';
import { Helmet } from 'react-helmet-async';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  fetchBanks,
  fetchBranches,
  fetchCities,
  fetchGeneralContent,
} from 'API';
import { setBanks, setBranches, setCities, setGeneralContent } from 'Store';
import {
  IEmergencyForm,
  IPostEmergencyFormResponse,
  createRequest,
  updateFormRequest,
} from 'API/EmergencyForm';
import { Header } from './Header';
import { Login } from './Login';
import { Success } from './Success';
import { useForm } from 'react-hook-form';
import { personalDetailsSchema } from './Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { OTPLoginContext } from 'Context/OTPLoginContext';
import { HTTP_STATUS_CODES } from 'Enum/HttpStatusCodes';

export const EmergencyFormWrap = () => {
  const methods = useForm<IEmergencyForm>({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(personalDetailsSchema),
  });
  const { isFormValidated, loginDetails } = useContext(OTPLoginContext);

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<IEmergencyForm>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState<boolean>(false);

  const [response, setResponse] = useState<IPostEmergencyFormResponse>();
  const [errorMsg, setErrorMsg] = useState('');
  const [errorTraceId, setErrorTraceId] = useState('');
  const [initFlag, setInitFlag] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatchApp();
  const dictionary = Dictionary[DICTIONARY.EMERGENCY_FORM];

  const { watch } = methods;
  const values = watch();

  useEffect(() => {
    const submitForm = async () => {
      if (loginDetails.accessToken) {
        const formattedValues = {
          ...values,
          isHospitalization: (values.isHospitalization as any) === '1',
        };
        await updateFormRequest(
          formattedValues,
          loginDetails.emergencyFormId,
          loginDetails.accessToken
        );
      }
    };

    if (initFlag || isSubmit) return;
    else submitForm();
  }, [
    initFlag,
    isSubmit,
    loginDetails.accessToken,
    loginDetails.emergencyFormId,
    values,
  ]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (step === 1) setStep(step + 1);
    else if (step === 2) handleSubmit();
  };

  const handleBack = () => {
    setStep(1);
  };

  const handleSubmit = async () => {
    if (loginDetails.accessToken) {
      setIsSubmit(true);
      const values = methods.getValues();
      const formattedValues = {
        ...values,
        isHospitalization: (values.isHospitalization as any) === '1',
      };
      const res = await createRequest(
        formattedValues,
        loginDetails.emergencyFormId,
        loginDetails.accessToken
      );

      if (res?.status === HTTP_STATUS_CODES.Created) {
        setResponse(res.data);
        setStep(3);
      } else if (res?.status === HTTP_STATUS_CODES.Unauthorized) {
        setErrorMsg(dictionary.error401);
        setStep(1);
      } else {
        setErrorMsg(dictionary.error400);
        res.data.TraceId && setErrorTraceId(res.data.TraceId);
        setStep(1);
      }
    } else return;
  };

  const initGeneralContent = useCallback(async () => {
    const generalContentData = await fetchGeneralContent();
    if (generalContentData) dispatch(setGeneralContent(generalContentData));
  }, [dispatch]);

  const initCities = useCallback(async () => {
    const citiesData = await fetchCities();
    if (citiesData) dispatch(setCities(citiesData));
  }, [dispatch]);

  const initBanks = useCallback(async () => {
    const banksData = await fetchBanks();
    if (banksData) dispatch(setBanks(banksData));
  }, [dispatch]);

  const initBranches = useCallback(async () => {
    const branchesData = await fetchBranches();
    if (branchesData) dispatch(setBranches(branchesData));
  }, [dispatch]);

  const initEmergencyForm = useCallback(async () => {
    const generalContentPromise = initGeneralContent();
    const citiesPromise = initCities();
    const banksPromise = initBanks();
    const branchesPromise = initBranches();

    await Promise.all([
      generalContentPromise,
      citiesPromise,
      banksPromise,
      branchesPromise,
    ]);

    setIsLoading(false);
  }, [initBanks, initBranches, initCities, initGeneralContent]);

  useEffect(() => {
    initEmergencyForm();
  }, [initEmergencyForm]);

  return (
    <>
      <Helmet>
        <title>
          {Dictionary[DICTIONARY.HEADER].title +
            ' - ' +
            Dictionary[DICTIONARY.EMERGENCY_FORM].title}
        </title>
      </Helmet>
      <Header />
      <Stack
        alignItems='center'
        component={'main'}
        role={'main'}
        sx={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundOrigin: 'border-box',
          height: '80vh',
          p: '24px',
        }}
      >
        {isFormValidated ? (
          <>
            {step === 1 &&
              (isLoading ? (
                <Loader />
              ) : (
                <Step1
                  methods={methods}
                  setFormData={setFormData}
                  formData={formData}
                  handleChange={handleChange}
                  errorMsg={errorMsg}
                  errorTraceId={errorTraceId}
                  setInitFlag={setInitFlag}
                />
              ))}
            {step === 2 && (
              <Step2
                formData={formData}
                setFormData={setFormData}
                handleChange={handleChange}
                handleBack={handleBack}
              />
            )}
            {step === 3 && response && (
              <Success
                response={response}
                email={formData.email || undefined}
              />
            )}
          </>
        ) : (
          <Login
            isLoginLoading={isLoginLoading}
            token={loginDetails.accessToken || ''}
            setIsLoggedIn={setIsLoggedIn}
          />
        )}
      </Stack>
    </>
  );
};
