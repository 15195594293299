import { Grid, Link, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { getForm } from 'API';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { useEffect, useState } from 'react';
import { StatusRequestCard } from './StatusRequestCard';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet-async';
export const StatusRequestPage = () => {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const statusRequestPageDictionary =
    Dictionary[DICTIONARY.STATUS_REQUEST_PAGE];

  const [requestData, setRequestData] = useState<{
    numOfRequest?: number;
    date?: Date;
  }>({ date: undefined, numOfRequest: undefined });

  const Statuses: { title: string; isFinished: boolean }[] = [
    { title: statusRequestPageDictionary.firstCard, isFinished: true },
    { title: statusRequestPageDictionary.secondCard, isFinished: false },
    { title: statusRequestPageDictionary.thirdCard, isFinished: false },
  ];

  const init = async () => {
    const form = await getForm();
    if (form) {
      setRequestData({
        numOfRequest: form.formId || 0,
        date: form.submittedDate ? new Date(form.submittedDate) : new Date(),
      });
    }
  };

  const dateFormat = (date: Date) => {
    if (date.toString() !== 'Invalid date')
      return format(date, "dd/MM/yy', 'HH:mm");
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Helmet>
        <title>{
          Dictionary[DICTIONARY.HEADER].title +
          ' - ' +
          Dictionary[DICTIONARY.STATUS_REQUEST_PAGE].title
        }</title>
      </Helmet>
      <Stack
        component={'main'}
        role={'main'}
        sx={{ height: '100%' }}
        alignItems='center'
        justifyContent='center'
      >
        <Typography
          variant='h1'
          fontWeight='bold'
          mt={4.25}
          mb={isDesktop ? 5.25 : 5}
        >
          {statusRequestPageDictionary.title}
        </Typography>
        <Grid container justifyContent='center' gap={1}>
          <Typography variant='h2'>
            {statusRequestPageDictionary.numOfRequest}:
          </Typography>
          <Typography variant='h2' fontWeight={500}>
            {requestData.numOfRequest}
          </Typography>
        </Grid>
        <Grid container justifyContent='center' gap={1}>
          <Typography variant='h2'>
            {statusRequestPageDictionary.requestDate}
          </Typography>
          <Typography variant='h2' fontWeight={500}>
            {dateFormat(requestData.date || new Date())}
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent='center'
          gap={3}
          mt={isDesktop ? 10.5 : 5.875}
        >
          {Statuses.map((status) => (
            <StatusRequestCard key={status.title} {...status} />
          ))}
        </Grid>
        <Typography textAlign='left' mb={2} mt={7}>
          {statusRequestPageDictionary.info1}
          <Link href='#' ml={1}>
            {statusRequestPageDictionary.info1Link}
          </Link>
        </Typography>
        <Typography mb={10} textAlign='left' sx={{ width: '100%' }}>
          {statusRequestPageDictionary.info2}
        </Typography>
      </Stack>
    </>
  );
};
