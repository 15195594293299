import {
  Button,
  Checkbox,
  Divider,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  FileType,
  IEmergencyForm,
  IEmergencyFormDTO,
  getFormDataById,
} from 'API/EmergencyForm';
import {
  ControllerAutoComplete,
  ControllerDatePicker,
  ControllerSelect,
  PhoneNumber,
  RadioButtons,
  Loader,
} from 'Components';
import { FormSection } from 'Components/FormSection';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { useAppSelector, useIsDesktop } from 'Hooks';
import {
  IDF_RANKS,
  IDF_TYPES,
  POLICE_RANKS,
  POLICE_TYPES,
  SHABAS_RANKS,
  SHABAS_TYPES,
} from 'Modules/CaseDetails/FirstSection';
import { textFieldShrink } from 'Utils';
import { validateLettersAndNumbersOnly } from 'Utils/validateLettersAndNumbersOnly';
import { validateLettersNumbersAndSpacialChars } from 'Utils/validateLettersNumbersAndSpacialChars';
import { validateNumbersOnly } from 'Utils/validateNumbersOnly';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { FileUpload } from '../FileUpload';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { OTPLoginContext } from 'Context/OTPLoginContext';

interface IStep1 {
  formData: IEmergencyForm;
  handleChange: Function;
  setFormData: Function;
  methods: UseFormReturn<IEmergencyForm, any>;
  errorMsg: string;
  errorTraceId: string;
  setInitFlag: Function;
}

export const Step1 = ({
  formData,
  handleChange,
  setFormData,
  methods,
  errorMsg,
  errorTraceId,

  setInitFlag,
}: IStep1) => {
  const dictionary = Dictionary[DICTIONARY.EMERGENCY_FORM];
  const {
    formState: { errors },
    getValues,
    watch,
  } = methods;

  const { loginDetails, setUploadedFiles } = useContext(OTPLoginContext);

  const initFlag = useRef<boolean>(true);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const initForm = useCallback(async () => {
    if (!loginDetails.emergencyFormId) return;

    initFlag.current = true;

    setInitFlag(true);

    const response = await getFormDataById(
      loginDetails.emergencyFormId,

      loginDetails.accessToken
    );

    setIsLoading(false);

    if (response) {
      methods.reset({ ...response });

      setFormData({ ...response });

      setUploadedFiles(response.uploadedFiles);

      initFlag.current = false;

      setInitFlag(false);
    }
  }, [
    loginDetails.emergencyFormId,

    loginDetails.accessToken,

    setInitFlag,

    methods,

    setFormData,

    setUploadedFiles,
  ]);

  useEffect(() => {
    initForm();
  }, [initForm, loginDetails.emergencyFormId]);

  const [
    maritalStatus,
    genders,
    serviceTypes,
    militaryRanks,
    injuryLevelOptions,
  ] = useAppSelector(
    ({
      generalContent: {
        generalContent: {
          maritalStatuses,
          genders,
          serviceTypes,
          militaryRanks,
          injuryLevelOptions,
        },
      },
    }) => [
      maritalStatuses,
      genders,
      serviceTypes,
      militaryRanks,
      injuryLevelOptions,
    ]
  );

  const isDesktop = useIsDesktop();

  const [banks] = useAppSelector(({ generalContent: { banks } }) => [banks]);
  const [branches] = useAppSelector(({ generalContent: { branches } }) => [
    branches,
  ]);

  const [cities] = useAppSelector(({ generalContent: { cities } }) => [cities]);

  const getMilitaryRanks = () => {
    const serviceTypeId = parseInt(watch('serviceTypeId') || '0');

    if (serviceTypeId && serviceTypeId == SHABAS_TYPES) {
      return militaryRanks.filter((e) => SHABAS_RANKS.includes(e.id));
    } else if (serviceTypeId && IDF_TYPES.includes(serviceTypeId)) {
      return militaryRanks.filter((e) => IDF_RANKS.includes(e.id));
    } else if (serviceTypeId && POLICE_TYPES.includes(serviceTypeId)) {
      return militaryRanks.filter((e) => POLICE_RANKS.includes(e.id));
    } else {
      return militaryRanks;
    }
  };

  const getBranchOptions = () => {
    const bankId = watch('bankRelationId');
    return bankId && branches[bankId]
      ? branches[bankId].map(({ id, value, branchIdNumber }) => ({
          id: id,
          value: `${value}, ${branchIdNumber}`,
        }))
      : [];
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    methods.trigger();
    methods.handleSubmit((data) => {
      setFormData((prevState: IEmergencyFormDTO) => ({
        ...prevState,
        ...data,
      }));
      handleChange();
    })();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <Stack alignItems={'center'}>
      <Typography
        variant='h1'
        fontWeight='bold'
        tabIndex={0}
        mb={2}
        textAlign={'center'}
        fontSize={'22px'}
        maxWidth={isDesktop ? undefined : '280px'}
      >
        {dictionary.subtitle}
      </Typography>
      <Typography
        component={'h2'}
        fontSize={'16px'}
        maxWidth={'630px'}
        textAlign={'center'}
        mb={4}
      >
        {dictionary.info}
      </Typography>
      {errorMsg && (
        <Stack
          sx={{
            mb: 2,
            bgcolor: '#ffff5d',
            p: '12px 20px',
            borderRadius: '16px',
            width: 'auto',
            textAlign: 'center',
            gap: '5px',
          }}
        >
          <Typography
            variant='h2'
            sx={{
              color: '#e10000',
              fontWeight: '500',
            }}
          >
            {errorMsg}
          </Typography>
          {errorTraceId && (
            <Typography variant='subtitle1'>{errorTraceId}</Typography>
          )}
        </Stack>
      )}
      <Stack
        maxWidth={'600px'}
        gap={'24px'}
        sx={{
          backgroundColor: '#fff',
          p: '24px',
          borderRadius: '15px',
          boxShadow: '0px 3px 21px 0px rgba(45, 112, 217, 0.15)',
        }}
      >
        <FormSection grouped id={dictionary.subtitle}>
          <FormProvider {...methods}>
            <form
              onSubmit={onSubmit}
              style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
            >
              <Typography variant='h3' fontWeight='bold' lineHeight={'normal'}>
                {dictionary.personalDetails}
              </Typography>
              <TextField
                {...methods.register('firstName')}
                label={`${dictionary.firstName} ${dictionary.isRequired}`}
                variant={'outlined'}
                id={'firstName'}
                error={Boolean(errors.firstName)}
                {...textFieldShrink(getValues('firstName'))}
                helperText={errors.firstName?.message}
                role={Boolean(errors.firstName) ? 'alert' : undefined}
                onChange={(event: any) => {
                  validateLettersAndNumbersOnly(event);
                }}
                inputProps={{
                  maxLength: '20',
                }}
              />
              <TextField
                {...methods.register('lastName')}
                label={`${dictionary.lastName} ${dictionary.isRequired}`}
                variant={'outlined'}
                id={'lastName'}
                error={Boolean(errors.lastName)}
                {...textFieldShrink(getValues('lastName'))}
                helperText={errors.lastName?.message}
                role={Boolean(errors.lastName) ? 'alert' : undefined}
                onChange={(event: any) => {
                  validateLettersAndNumbersOnly(event);
                }}
                inputProps={{
                  maxLength: '30',
                }}
              />
              <TextField
                {...methods.register('israeliId')}
                label={dictionary.idNumber}
                variant={'outlined'}
                id={'israeliId'}
                error={Boolean(errors.israeliId)}
                {...textFieldShrink(getValues('israeliId'))}
                helperText={errors.israeliId?.message}
                role={Boolean(errors.israeliId) ? 'alert' : undefined}
                onKeyDown={(event: any) => {
                  validateNumbersOnly(event);
                }}
                sx={{ mb: 4 }}
                inputProps={{
                  maxLength: '9',
                }}
                disabled={formData.israeliId ? true : false}
              />
              <RadioButtons
                id={dictionary.gender}
                name='genderId'
                options={genders.map(({ value, id }) => ({
                  value: id.toString(),
                  label: value,
                }))}
              />
              <TextField
                inputProps={{
                  maxLength: '15',
                }}
                {...methods.register('fathersName')}
                label={dictionary.fathersName}
                variant={'outlined'}
                id={'fathersName'}
                error={Boolean(errors.fathersName)}
                {...textFieldShrink(getValues('fathersName'))}
                helperText={errors.fathersName?.message}
                role={Boolean(errors.fathersName) ? 'alert' : undefined}
                onChange={(event: any) => {
                  validateLettersAndNumbersOnly(event);
                }}
              />
              <ControllerSelect
                name='maritalStatusId'
                label={dictionary.maritalStatusId}
              >
                {maritalStatus.map(({ id, value }) => (
                  <MenuItem key={id} value={id}>
                    {value}
                  </MenuItem>
                ))}
              </ControllerSelect>

              <ControllerDatePicker
                label={dictionary.birthDate}
                name='birthDate'
              />
              <Typography variant='h3' fontWeight='bold' mt={4}>
                {dictionary.addressDetails}
              </Typography>
              <ControllerAutoComplete
                name={'israeliTownId'}
                label={dictionary.city}
                options={cities}
              />
              <Stack flexDirection={'row'} gap={2}>
                <TextField
                  {...methods.register('street')}
                  label={dictionary.street}
                  variant={'outlined'}
                  role={Boolean(errors.street) ? 'alert' : undefined}
                  id={'street'}
                  inputProps={{
                    maxLength: '50',
                  }}
                  error={Boolean(errors.street)}
                  helperText={errors.street?.message}
                  {...textFieldShrink(getValues('street'))}
                  onChange={(event: any) => {
                    validateLettersAndNumbersOnly(event);
                  }}
                  style={{ width: '65%' }}
                />
                <TextField
                  {...methods.register('homeNumHouse')}
                  label={dictionary.houseNumber}
                  variant={'outlined'}
                  role={Boolean(errors.homeNumHouse) ? 'alert' : undefined}
                  id={'homeNumHouse'}
                  inputProps={{
                    maxLength: '6',
                  }}
                  error={Boolean(errors.homeNumHouse)}
                  helperText={errors.homeNumHouse?.message}
                  {...textFieldShrink(getValues('homeNumHouse'))}
                  onKeyDown={(event: any) => {
                    validateNumbersOnly(event);
                  }}
                  style={{ width: '35%' }}
                />
              </Stack>

              <TextField
                {...methods.register('apartmentNumber')}
                label={dictionary.apartmentNumber}
                variant={'outlined'}
                role={Boolean(errors.apartmentNumber) ? 'alert' : undefined}
                id={'po'}
                inputProps={{
                  maxLength: '5',
                }}
                error={Boolean(errors.apartmentNumber)}
                helperText={errors.apartmentNumber?.message}
                onKeyDown={(event: any) => {
                  validateNumbersOnly(event);
                }}
              />
              <TextField
                {...methods.register('israeliZipCode')}
                label={dictionary.zipCode}
                variant={'outlined'}
                role={Boolean(errors.israeliZipCode) ? 'alert' : undefined}
                id={'israeliZipCode'}
                inputProps={{
                  maxLength: '7',
                }}
                error={Boolean(errors.israeliZipCode)}
                helperText={errors.israeliZipCode?.message}
                {...textFieldShrink(getValues('israeliZipCode'))}
                onKeyDown={(event: any) => {
                  validateNumbersOnly(event);
                }}
              />
              <Typography variant='h3' fontWeight='bold' mt={4}>
                {dictionary.contactDetails}
              </Typography>
              <PhoneNumber
                phoneNumberFormName='phoneNumber'
                phoneNumberPrefixFormName='phonePrefixRelationId'
                phoneNumberError={Boolean(errors.phoneNumber)}
                phoneNumberErrorMsg={errors.phoneNumber?.message}
                phoneNumberLabel={dictionary.phoneNumber}
              />
              <PhoneNumber
                phoneNumberFormName='extraPhoneNumber'
                phoneNumberPrefixFormName='extraPhonePrefixRelationId'
                phoneNumberError={Boolean(errors.extraPhonePrefixRelationId)}
                phoneNumberErrorMsg={errors.extraPhonePrefixRelationId?.message}
                phoneNumberLabel={dictionary.extraPhone}
              />
              <TextField
                {...methods.register('email')}
                label={dictionary.email}
                variant={'outlined'}
                role={Boolean(errors.email) ? 'alert' : undefined}
                id={'email'}
                type='email'
                inputProps={{
                  maxLength: '50',
                }}
                error={Boolean(errors.email)}
                {...textFieldShrink(getValues('email'))}
                helperText={errors.email?.message}
              />
              <Typography
                variant='h3'
                fontWeight='bold'
                mt={4}
                lineHeight={'normal'}
              >
                {dictionary.militaryDetails}
              </Typography>
              <ControllerAutoComplete
                options={serviceTypes}
                name={'serviceTypeId'}
                label={dictionary.serviceType}
              />

              <TextField
                {...methods.register('role')}
                label={dictionary.role}
                variant={'outlined'}
                role={Boolean(errors.role) ? 'alert' : undefined}
                inputProps={{
                  maxLength: '50',
                }}
                id={'role'}
                {...textFieldShrink(getValues('role'))}
                error={Boolean(errors.role)}
                helperText={errors.role?.message}
                onChange={(event: any) => {
                  validateLettersAndNumbersOnly(event);
                }}
              />

              <ControllerDatePicker
                name={'injuryDate'}
                label={dictionary.injuryDate}
                maxDate={new Date()}
              />
              <Typography
                variant='h3'
                fontWeight='bold'
                mt={4}
                lineHeight={'normal'}
              >
                {dictionary.injuryDetails}
              </Typography>
              <RadioButtons
                id={dictionary.isHospitalization}
                name='isHospitalization'
                options={[
                  {
                    value: '1',
                    label: dictionary.yes,
                  },
                  {
                    value: '0',
                    label: dictionary.no,
                  },
                ]}
              />
              <Typography variant='subtitle1' mt={2}>
                {dictionary.injuryDetailsInfo}
              </Typography>
              <TextField
                {...methods.register('injuryDescription')}
                placeholder={dictionary.injuryDetailsPlaceholder}
                variant={'outlined'}
                role={Boolean(errors.injuryDescription) ? 'alert' : undefined}
                inputProps={{
                  maxLength: '500',
                }}
                id={'InjuryDescription'}
                error={Boolean(errors.injuryDescription)}
                helperText={errors.injuryDescription?.message}
                {...textFieldShrink(getValues('injuryDescription'))}
                multiline
                rows={6}
                onChange={(event: any) =>
                  validateLettersNumbersAndSpacialChars(event)
                }
              />
              <Typography variant='subtitle1'>
                {dictionary.attachedMedicalDocs}
              </Typography>
              <FileUpload
                fileCategoryId={FileType.HospitalizationDocument}
                formId={loginDetails.emergencyFormId}
              />

              <Typography variant='h3' fontWeight='bold' mt={4}>
                {dictionary.injuryLevel}
              </Typography>
              <ControllerAutoComplete
                options={injuryLevelOptions}
                name={'injuryLevelOptionId'}
                label={dictionary.injuryLevelLabel}
              />

              <Typography variant='h3' fontWeight='bold' mt={4}>
                {dictionary.bankDetails}
              </Typography>
              <Typography variant='subtitle1'>
                {dictionary.bankDetailsInfo}
              </Typography>
              <ControllerAutoComplete
                options={banks}
                name={'bankRelationId'}
                label={dictionary.bank}
              />
              <ControllerAutoComplete
                name='branchRelationId'
                label={dictionary.branch}
                options={getBranchOptions()}
              />
              <TextField
                {...methods.register('accountNumber')}
                label={dictionary.accountNumber}
                variant={'outlined'}
                role={Boolean(errors.accountNumber) ? 'alert' : undefined}
                inputProps={{
                  maxLength: '11',
                }}
                id={'accountNumber'}
                {...textFieldShrink(getValues('accountNumber'))}
                error={Boolean(errors.accountNumber)}
                helperText={errors.accountNumber?.message}
                onKeyDown={(event: any) => {
                  validateNumbersOnly(event);
                }}
              />
              <Typography variant='h3' fontWeight='bold' mt={4}>
                {dictionary.familyContactDetails}
              </Typography>
              <TextField
                {...methods.register('familyContactFirstName')}
                label={dictionary.firstName}
                variant={'outlined'}
                id={'familyContactFirstName'}
                error={Boolean(errors.familyContactFirstName)}
                {...textFieldShrink(getValues('familyContactFirstName'))}
                helperText={errors.familyContactFirstName?.message}
                role={
                  Boolean(errors.familyContactFirstName) ? 'alert' : undefined
                }
                onChange={(event: any) => {
                  validateLettersAndNumbersOnly(event);
                }}
              />
              <TextField
                {...methods.register('familyContactLastName')}
                label={dictionary.lastName}
                variant={'outlined'}
                id={'familyContactLastName'}
                error={Boolean(errors.familyContactLastName)}
                {...textFieldShrink(getValues('familyContactLastName'))}
                helperText={errors.familyContactLastName?.message}
                role={
                  Boolean(errors.familyContactLastName) ? 'alert' : undefined
                }
                onChange={(event: any) => {
                  validateLettersAndNumbersOnly(event);
                }}
              />
              <PhoneNumber
                phoneNumberFormName='familyContactPhoneNumber'
                phoneNumberPrefixFormName='familyContactPhonePrefixRelationId'
                phoneNumberError={Boolean(errors.familyContactPhoneNumber)}
                phoneNumberErrorMsg={errors.familyContactPhoneNumber?.message}
                phoneNumberLabel={dictionary.phoneNumber}
              />
              <Typography variant='h3' fontWeight='bold' mt={4}>
                {dictionary.fillerContactDetails}
              </Typography>
              <TextField
                {...methods.register('formFillerFirstName')}
                label={dictionary.firstName}
                variant={'outlined'}
                id={'formFillerFirstName'}
                error={Boolean(errors.formFillerFirstName)}
                {...textFieldShrink(getValues('formFillerFirstName'))}
                helperText={errors.formFillerFirstName?.message}
                role={Boolean(errors.formFillerFirstName) ? 'alert' : undefined}
                onChange={(event: any) => validateLettersAndNumbersOnly(event)}
              />
              <TextField
                {...methods.register('formFillerLastName')}
                label={dictionary.lastName}
                variant={'outlined'}
                id={'formFillerLastName'}
                error={Boolean(errors.formFillerLastName)}
                {...textFieldShrink(getValues('formFillerLastName'))}
                helperText={errors.formFillerLastName?.message}
                role={Boolean(errors.formFillerLastName) ? 'alert' : undefined}
                onChange={(event: any) => validateLettersAndNumbersOnly(event)}
              />
              <PhoneNumber
                phoneNumberFormName='formFillerPhoneNumber'
                phoneNumberPrefixFormName='formFillerPhonePrefixRelationId'
                phoneNumberError={Boolean(errors.formFillerPhoneNumber)}
                phoneNumberErrorMsg={errors.formFillerPhoneNumber?.message}
                phoneNumberLabel={dictionary.phoneNumber}
              />

              <Divider sx={{ mt: 2, mb: 3 }} />

              <Typography variant='h3' fontWeight='bold'>
                {dictionary.signerLabel}
              </Typography>
              <Stack flexDirection={'row'} alignItems={'flex-start'}>
                <Checkbox
                  {...methods.register('isCanNotSign', {
                    onChange: (e) =>
                      setFormData((prevData: IEmergencyForm) => ({
                        ...prevData,
                        isCanNotSign: e.target.checked,
                      })),
                  })}
                  defaultChecked={formData.isCanNotSign || false}
                  id={'isCanNotSign'}
                  sx={{ p: '4px' }}
                />
                <Typography>{dictionary.canNotSign}</Typography>
              </Stack>
              <Stack
                overflow={'hidden'}
                height={getValues('isCanNotSign') ? undefined : '0px'}
                gap={2}
              >
                <Typography variant='subtitle1' mt={2}>
                  {dictionary.checkboxNote}
                </Typography>
                <TextField
                  {...methods.register('signerFullName')}
                  label={dictionary.fullName}
                  variant={'outlined'}
                  id={'signerFullName'}
                  error={Boolean(errors.signerFullName)}
                  {...textFieldShrink(getValues('signerFullName'))}
                  helperText={errors.signerFullName?.message}
                  role={Boolean(errors.signerFullName) ? 'alert' : undefined}
                  onChange={(event: any) => {
                    validateLettersAndNumbersOnly(event);
                  }}
                />
                <TextField
                  {...methods.register('signerId')}
                  label={dictionary.idNumber}
                  variant={'outlined'}
                  id={'signerId'}
                  error={Boolean(errors.signerId)}
                  {...textFieldShrink(getValues('signerId'))}
                  helperText={errors.signerId?.message}
                  role={Boolean(errors.signerId) ? 'alert' : undefined}
                  onKeyDown={(event: any) => {
                    validateNumbersOnly(event);
                  }}
                />
                <TextField
                  {...methods.register('signerPhone')}
                  label={dictionary.phoneNumber}
                  variant={'outlined'}
                  id={'signerPhone'}
                  error={Boolean(errors.signerPhone)}
                  {...textFieldShrink(getValues('signerPhone'))}
                  helperText={errors.signerPhone?.message}
                  role={Boolean(errors.signerPhone) ? 'alert' : undefined}
                  onKeyDown={(event: any) => {
                    validateNumbersOnly(event);
                  }}
                />
                <TextField
                  {...methods.register('signerRelation')}
                  label={dictionary.relation}
                  variant={'outlined'}
                  id={'signerRelation'}
                  {...textFieldShrink(getValues('signerRelation'))}
                  onChange={(event: any) => {
                    validateLettersAndNumbersOnly(event);
                  }}
                  sx={{ mb: 2 }}
                />
              </Stack>

              <Divider />

              <Typography fontSize={'16px'} fontWeight='bold' mt={4}>
                {dictionary.notes}
              </Typography>
              <TextField
                {...methods.register('note')}
                variant={'outlined'}
                role={Boolean(errors.note) ? 'alert' : undefined}
                inputProps={{
                  maxLength: '500',
                }}
                placeholder={dictionary.limitNote}
                id={'note'}
                error={Boolean(errors.note)}
                helperText={errors.note?.message}
                {...textFieldShrink(getValues('note'))}
                multiline
                rows={6}
                onChange={(event: any) =>
                  validateLettersNumbersAndSpacialChars(event)
                }
              />
              <FileUpload
                fileCategoryId={1}
                formId={loginDetails.emergencyFormId}
              />
              <Button
                variant='contained'
                color='primary'
                type='submit'
                sx={{ margin: '0 auto', mt: 4, width: '225px' }}
              >
                {dictionary.continue}
              </Button>
            </form>
          </FormProvider>
        </FormSection>
      </Stack>
    </Stack>
  );
};
