import { Typography, MenuItem } from '@mui/material';
import {
  CustomToggleGroup,
  FormSection,
  FileUpload,
  RadioButtons,
} from 'Components';
import { ControllerSelect } from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY, FileCategories } from 'Enum';
import { useAppSelector, useDispatchApp } from 'Hooks';
import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { setIsRequestAllowanceWasSubmitted } from 'Store/requestAndEarlyAcquaintanceSlice';

export const FirstSection = () => {
  const isRequestAllowanceWasSubmittedName = 'isRequestAllowanceWasSubmitted';
  const isRequestProcessingEndedName =
    'socialSecurityRequestSection.isRequestProcessingEnded';

  const isRequestAllowanceWasSubmitted = useWatch({
    name: isRequestAllowanceWasSubmittedName,
    defaultValue: false,
  }) as boolean;

  const dispatch = useDispatchApp();
  useEffect(() => {
    dispatch(setIsRequestAllowanceWasSubmitted(isRequestAllowanceWasSubmitted));
  }, [isRequestAllowanceWasSubmitted]);

  const isRequestProcessingEnded = useWatch({
    name: isRequestProcessingEndedName,
    defaultValue: false,
  }) as boolean;

  const requestTypes = useAppSelector(
    ({
      generalContent: {
        generalContent: { requestReasonTypes },
      },
    }) => requestReasonTypes
  );

  return (
    <FormSection>
      <Typography
        sx={{ fontWeight: 'bold', fontSize: '16px!important' }}
        variant={'h2'}
      >
        {
          Dictionary[DICTIONARY.REQUEST_AND_EARLY_ACQUAINTANCE]
            .firstSectionTitle
        }
      </Typography>
      <RadioButtons
        name={isRequestAllowanceWasSubmittedName}
        options={[
          {
            value: 'true',
            label:
              Dictionary[DICTIONARY.REQUEST_AND_EARLY_ACQUAINTANCE]
                .firstSectionToggleButtonRightLabel,
          },
          {
            value: 'false',
            label:
              Dictionary[DICTIONARY.REQUEST_AND_EARLY_ACQUAINTANCE]
                .firstSectionToggleButtonLeftLabel,
          },
        ]}
        id={
          Dictionary[DICTIONARY.REQUEST_AND_EARLY_ACQUAINTANCE]
            .firstSectionFirstInputTitle
        }
      />
      {isRequestAllowanceWasSubmitted && (
        <>
          <ControllerSelect
            label={
              Dictionary[DICTIONARY.REQUEST_AND_EARLY_ACQUAINTANCE]
                .firstSectionCollapseSecondInput
            }
            name='socialSecurityRequestSection.requestReasonId'
          >
            {requestTypes.map(({ id, value }) => (
              <MenuItem key={id} value={id}>
                {value}
              </MenuItem>
            ))}
          </ControllerSelect>

          <RadioButtons
            name={isRequestProcessingEndedName}
            options={[
              {
                value: 'true',
                label:
                  Dictionary[DICTIONARY.REQUEST_AND_EARLY_ACQUAINTANCE]
                    .firstSectionToggleButtonRightLabel,
              },
              {
                value: 'false',
                label:
                  Dictionary[DICTIONARY.REQUEST_AND_EARLY_ACQUAINTANCE]
                    .firstSectionToggleButtonLeftLabel,
              },
            ]}
            id={
              Dictionary[DICTIONARY.REQUEST_AND_EARLY_ACQUAINTANCE]
                .firstSectionSecondInputTitle
            }
          />

          {isRequestProcessingEnded && (
            <>
              <Typography sx={{ fontWeight: 'bold', fontSize: '14px', mt: 2 }}>
                {
                  Dictionary[DICTIONARY.REQUEST_AND_EARLY_ACQUAINTANCE]
                    .firstSectionUploadFilesTitle
                }
              </Typography>
              <Typography sx={{ fontSize: '14px' }}>
                {
                  Dictionary[DICTIONARY.REQUEST_AND_EARLY_ACQUAINTANCE]
                    .firstSectionUploadFilesInfo1
                }
              </Typography>
              <Typography sx={{ fontSize: '14px' }}>
                {
                  Dictionary[DICTIONARY.REQUEST_AND_EARLY_ACQUAINTANCE]
                    .firstSectionUploadFilesInfo2
                }
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: '14px', mt: 2 }}>
                {
                  Dictionary[DICTIONARY.REQUEST_AND_EARLY_ACQUAINTANCE]
                    .firstSectionCollapseFirstFileUploadLabel
                }
              </Typography>
              <FileUpload
                fileLabel={
                  Dictionary[DICTIONARY.REQUEST_AND_EARLY_ACQUAINTANCE]
                    .firstSectionCollapseFirstFileUploadLabel
                }
                fileCategoryId={FileCategories.SocialSecurityApprovalDecision}
                ariaLabel={
                  Dictionary[DICTIONARY.REQUEST_AND_EARLY_ACQUAINTANCE]
                    .firstSectionCollapseFirstFileUploadTitle
                }
              />
              <Typography sx={{ fontWeight: 'bold', fontSize: '14px', mt: 2 }}>
                {
                  Dictionary[DICTIONARY.REQUEST_AND_EARLY_ACQUAINTANCE]
                    .firstSectionCollapseSecondFileUploadLabel
                }
              </Typography>

              <FileUpload
                fileLabel={
                  Dictionary[DICTIONARY.REQUEST_AND_EARLY_ACQUAINTANCE]
                    .firstSectionCollapseSecondFileUploadLabel
                }
                fileCategoryId={FileCategories.SocialSecurityProtocol}
                ariaLabel={
                  Dictionary[DICTIONARY.REQUEST_AND_EARLY_ACQUAINTANCE]
                    .firstSectionCollapseSecondFileUploadTitle
                }
              />
            </>
          )}
        </>
      )}
    </FormSection>
  );
};
