import { Avatar, IconButton, Typography } from '@mui/material';
import { QuestionMark } from 'Images';
import { CustomToolTip } from 'Components';
interface IQuestionMarkToolTip {
  title: string;
  inRadio?: boolean;
}
export const QuestionMarkToolTip = (props: IQuestionMarkToolTip) => {
  return (
    <CustomToolTip
      title={<Typography color='primary'>{props.title}</Typography>}
    >
      <IconButton
        sx={
          props.inRadio
            ? {
                position: 'relative',
              }
            : {
                position: 'absolute',
                top: '50%',
                right: 0,
                transform: 'translate(100%, -50%)',
              }
        }
      >
        <Avatar
          src={QuestionMark}
          alt='מידע'
          sx={{
            width: '24px',
            height: '24px',
          }}
        />
      </IconButton>
    </CustomToolTip>
  );
};
