import { MenuItem, TextField, Typography } from '@mui/material';
import {
  FileUpload,
  FormSection,
  ControllerDatePicker,
  ControllerSelect,
  ControllerAutoComplete,
  RadioButtons,
  InfoText,
} from 'Components';
import { DICTIONARY, FileCategories } from 'Enum';
import { Dictionary } from 'Dictionary';
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from 'Hooks';
import { generateError } from 'Regex';
import { textFieldShrink } from 'Utils';
import { IPersonalDetails } from 'API';
import { validateLettersAndNumbersOnly } from 'Utils/validateLettersAndNumbersOnly';
import { validateNumbersOnly } from 'Utils/validateNumbersOnly';
import { store } from 'Store';

export const FirstSection = () => {
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext<IPersonalDetails>();
  const [maritalStatus, countries, genders] = useAppSelector(
    ({
      generalContent: {
        generalContent: { maritalStatuses, genders },
        countries,
      },
    }) => [maritalStatuses, countries, genders]
  );

  const formInfo = store.getState().generalContent.formInfo;
  const firstNameFormName = 'personalInfoSection.firstName';
  const lastNameFormName = 'personalInfoSection.lastName';
  const [firstName, lastName] = getValues([
    firstNameFormName,
    lastNameFormName,
  ]);

  const personalInfoSectionErrors = errors.personalInfoSection;
  const dictionary = Dictionary[DICTIONARY.PERSONAL_DETAILS];
  const generalDictionary = Dictionary[DICTIONARY.GENERAL];

  return (
    <FormSection>
      <TextField
        id={dictionary.firstSectionFirstInput}
        inputProps={{
          maxLength: '20',
        }}
        {...register(firstNameFormName)}
        error={Boolean(personalInfoSectionErrors?.firstName)}
        label={dictionary.firstSectionFirstInput}
        {...textFieldShrink(firstName)}
        helperText={
          Boolean(personalInfoSectionErrors?.firstName) &&
          generateError(
            dictionary.firstSectionFirstInput,
            personalInfoSectionErrors?.firstName?.message!
          )
        }
        role={
          Boolean(personalInfoSectionErrors?.firstName) ? 'alert' : undefined
        }
        onChange={(event: any) => validateLettersAndNumbersOnly(event)}
      />
      <TextField
        role={
          Boolean(personalInfoSectionErrors?.lastName) ? 'alert' : undefined
        }
        id={dictionary.firstSectionSecondInput}
        inputProps={{
          maxLength: '40',
        }}
        {...register(lastNameFormName)}
        label={dictionary.firstSectionSecondInput}
        {...textFieldShrink(lastName)}
        error={Boolean(personalInfoSectionErrors?.lastName)}
        helperText={
          Boolean(personalInfoSectionErrors?.lastName) &&
          generateError(
            dictionary.firstSectionSecondInput,
            personalInfoSectionErrors?.lastName?.message!
          )
        }
        onChange={(event: any) => validateLettersAndNumbersOnly(event)}
      />
      <TextField
        id={dictionary.firstSectionThirdInput}
        {...register('personalInfoSection.israeliId')}
        InputLabelProps={{ shrink: true }}
        disabled
        label={dictionary.firstSectionThirdInput}
        onKeyDown={(event) => validateNumbersOnly(event)}
      />

      {!formInfo.isRecognized && (
        <>
          <Typography>
            {dictionary.firstSectionFileUploadLabel}
            <Typography component='span' color='error'>
              {generalDictionary.required}
            </Typography>
          </Typography>
          <InfoText text={dictionary.firstSectionFileUploadLabelNote} />
          <FileUpload
            fileLabel={dictionary.firstSectionFileUploadText}
            fileCategoryId={FileCategories.IdNumberScan}
            ariaLabel={dictionary.firstSectionFileUploadLabel}
            dataRequired
          />
        </>
      )}

      <RadioButtons
        id={dictionary.firstSectionToggleButtonLabel}
        name='personalInfoSection.genderId'
        options={genders.map(({ value, id }) => ({
          value: id.toString(),
          label: value,
        }))}
      />
      <ControllerSelect
        name='personalInfoSection.maritalStatusId'
        label={dictionary.firstSectionFourthInput}
        disabled={formInfo.isRecognized}
      >
        {maritalStatus.map(({ id, value }) => (
          <MenuItem key={id} value={id}>
            {value}
          </MenuItem>
        ))}
      </ControllerSelect>
      <ControllerDatePicker
        label={dictionary.firstSectionFifthInput}
        name='personalInfoSection.birthDate'
        required
      />
      <ControllerAutoComplete
        options={countries}
        name='personalInfoSection.birthCountryId'
        label={dictionary.firstSectionSixthInput}
        disabled={formInfo.isRecognized}
      />
      <ControllerDatePicker
        viewYearOnly
        label={dictionary.firstSectionSeventhInput}
        name='personalInfoSection.immigrationYear'
        disabled={formInfo.isRecognized}
      />
    </FormSection>
  );
};
