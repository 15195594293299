import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

interface IFormTitle {
  title: string;
  subTitle?: string;
}
export const FormTitle = (props: IFormTitle) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Stack
      alignItems='center'
      sx={{
        mt: isDesktop ? '90px' : '40px',
        mb: '28px',
        maxWidth: '720px',
        textAlign: 'center',
      }}
    >
      <Typography variant='h1' fontWeight='bold' tabIndex={0}>
        {props.title}
      </Typography>
      {props.subTitle && (
        <Typography variant='subtitle1'>{props.subTitle}</Typography>
      )}
    </Stack>
  );
};
