import { Avatar, Button } from '@mui/material';
import { UploadFile } from 'Images';
import { forwardRef } from 'react';
interface IUploadFileButton {
  clickHandler: () => void;
  children?: any;
}
export const UploadFileButton = forwardRef<
  HTMLButtonElement,
  IUploadFileButton
>((props, ref) => {
  return (
    <Button
      ref={ref}
      variant='outlined'
      sx={{ minWidth: '130px', width: '160px', height: '40px' }}
      onClick={props.clickHandler}
      endIcon={
        <Avatar
          alt=''
          src={UploadFile}
          sx={{ width: '18px', height: '18px' }}
        />
      }
    >
      {props.children}
    </Button>
  );
});
