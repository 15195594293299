import { Button, Checkbox, Stack, Typography } from '@mui/material';
import { FormSection } from 'Components/FormSection';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { useEffect, useState } from 'react';
import { IEmergencyForm, IEmergencyFormDTO } from 'API/EmergencyForm';
import { Loader } from 'Components';

interface IStep2 {
  formData: IEmergencyForm;
  setFormData: (data: IEmergencyForm) => void;
  handleChange: any;
  handleBack: any;
}

export const Step2 = ({
  formData,
  setFormData,
  handleChange,
  handleBack,
}: IStep2) => {
  const [isLoading, setIsLoading] = useState(false);
  const [checkbox, setCheckbox] = useState<boolean>(false);
  const dictionary = Dictionary[DICTIONARY.EMERGENCY_FORM];

  const onSubmit = () => {
    setIsLoading(true);
    handleChange();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <FormSection id='pdf'>
      <Stack alignItems={'center'}>
        <Typography variant='h1' fontWeight='bold' tabIndex={0} mb={2}>
          {dictionary.medicalConfidentiality}
        </Typography>
        <Typography
          component={'h2'}
          fontSize={'16px'}
          fontWeight={'bold'}
          maxWidth={'630px'}
          textAlign={'center'}
          mb={4}
        >
          {dictionary.medicalConfidentialitySubtitle}
        </Typography>
        <Stack
          maxWidth={'570px'}
          gap={'24px'}
          sx={{
            backgroundColor: '#fff',
            p: '24px',
            borderRadius: '15px',
            boxShadow: '0px 3px 21px 0px rgba(45, 112, 217, 0.15)',
          }}
        >
          <Typography>{dictionary.medicalConfidentialityPart1}</Typography>
          <Typography>{dictionary.medicalConfidentialityPart2}</Typography>
          <Typography>{dictionary.medicalConfidentialityPart3}</Typography>
          <Typography>{dictionary.medicalConfidentialityPart4}</Typography>
          <Typography>{dictionary.medicalConfidentialityPart5}</Typography>
          <Typography>{dictionary.medicalConfidentialityPart6}</Typography>
          <Typography>{dictionary.medicalConfidentialityPart7}</Typography>
          <Stack flexDirection={'row'}>
            <Checkbox
              value={checkbox}
              onChange={(_, checked) => setCheckbox(checked)}
            />
            <Typography>
              {formData.isCanNotSign
                ? dictionary.isCanNotSignLabel(
                    formData.firstName || '',
                    formData.lastName || '',
                    formData.israeliId || '',
                    formData.signerFullName || '',
                    formData.signerId || ''
                  )
                : dictionary.medicalConfidentialitySign(
                    formData.firstName || '',
                    formData.lastName || '',
                    formData.israeliId || ''
                  )}
            </Typography>
          </Stack>
          <Stack flexDirection={'row'} justifyContent={'space-between'}>
            <Button variant='outlined' color='primary' onClick={handleBack}>
              {dictionary.back}
            </Button>
            <Button
              disabled={!checkbox}
              variant='contained'
              color='primary'
              onClick={onSubmit}
            >
              {dictionary.submit}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </FormSection>
  );
};
