import { LobbyLayout, PrivateLayout } from 'Components';
import {
  EmploymentAndBankDetails,
  StatusLobby,
  CaseDetails,
  Representation,
  WaiverOfMedicationConfidentiality,
  PersonalDetails,
  RequestAndEarlyAcquaintance,
  VerifyDetails,
  SuccessPage,
  StatusRequestPage,
  Lobby,
  NotFoundPage,
  DoubleDoc,
  EmergencyForm,
} from 'Modules';
import { AuthIssue } from 'Modules/AuthIssue';

export const PRIVATE_LAYOUT_PATH = '/form';

export const LOBBY_LAYOUT_PATH = '/';

export const DOUBLE_DOC_PATH = '/doubleDoc';
export const AUTH_ISSUE = '/auth-issue';
export const EMERGENCY_FORM_PATH = '/EmergencyForm';

export const RoutesConfig = {
  LOBBY: {
    MODULE: <Lobby />,
    PATH: LOBBY_LAYOUT_PATH + '',
  },
  PRIVATE_LAYOUT: {
    MODULE: <PrivateLayout />,
    PATH: PRIVATE_LAYOUT_PATH,
  },
  LOBBY_LAYOUT: {
    MODULE: <LobbyLayout />,
    PATH: LOBBY_LAYOUT_PATH,
  },
  STATUS_LOBBY: {
    MODULE: <StatusLobby />,
    PATH: PRIVATE_LAYOUT_PATH + '',
  },
  CASE_DETAILS: {
    MODULE: <CaseDetails />,
    PATH: PRIVATE_LAYOUT_PATH + '/caseDetails',
  },
  EMPLOYMENT_AND_BANK_DETAILS: {
    MODULE: <EmploymentAndBankDetails />,
    PATH: PRIVATE_LAYOUT_PATH + '/employmentAndBankDetails',
  },
  REPRESENTATION: {
    MODULE: <Representation />,
    PATH: PRIVATE_LAYOUT_PATH + '/representation',
  },
  WAIVER_OF_MEDICAL_CONFIDENTIALITY: {
    MODULE: <WaiverOfMedicationConfidentiality />,
    PATH: PRIVATE_LAYOUT_PATH + '/waiverOfMedicationConfidentiality',
  },
  PERSONAL_DETAILS: {
    MODULE: <PersonalDetails />,
    PATH: PRIVATE_LAYOUT_PATH + '/personalDetails',
  },
  REQUEST_AND_EARLY_ACQUAINTANCE: {
    MODULE: <RequestAndEarlyAcquaintance />,
    PATH: PRIVATE_LAYOUT_PATH + '/requestAndEarlyAcquaintance',
  },
  VERIFY_DETAILS: {
    MODULE: <VerifyDetails />,
    PATH: PRIVATE_LAYOUT_PATH + '/verifyDetails',
  },
  SUCCESS_PAGE: {
    MODULE: <SuccessPage />,
    PATH: PRIVATE_LAYOUT_PATH + '/success/:id',
  },
  STATUS_REQUEST_PAGE: {
    MODULE: <StatusRequestPage />,
    PATH: PRIVATE_LAYOUT_PATH + '/statusRequestPage',
  },
  NOT_FOUND: {
    PATH: '*',
    MODULE: <NotFoundPage />,
  },
  DOUBLE_DOC: {
    MODULE: <DoubleDoc />,
    PATH: DOUBLE_DOC_PATH,
  },
  AUTH_ISSUE: {
    MODULE: <AuthIssue />,
    PATH: AUTH_ISSUE,
  },
  EMERGENCY_FORM: {
    MODULE: <EmergencyForm />,
    PATH: EMERGENCY_FORM_PATH,
  },
};

export type RoutesConfigType = keyof typeof RoutesConfig;
