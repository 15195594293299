import { TextField, Typography } from '@mui/material';
import { IPersonalDetails } from 'API';
import { FormSection, PhoneNumber, CustomCheckbox } from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { useFormContext } from 'react-hook-form';
import { generateError } from 'Regex';
import { store } from 'Store';
import { textFieldShrink } from 'Utils';

export const SecondSection = () => {
  const {
    register,
    formState: { errors },
    getValues,
  } = useFormContext<IPersonalDetails>();

  const mailFormName = 'contactInfoSection.mail';
  const formInfo = store.getState().generalContent.formInfo;

  const [mail] = getValues([mailFormName]);

  return (
    <FormSection>
      <Typography sx={{ fontWeight: 'bold' }}>
        {Dictionary[DICTIONARY.PERSONAL_DETAILS].secondSectionTitle}
      </Typography>
      <PhoneNumber
        phoneNumberFormName='contactInfoSection.phoneNumber'
        phoneNumberPrefixFormName='contactInfoSection.phonePrefixRelationId'
        phoneNumberError={Boolean(errors.contactInfoSection?.phoneNumber)}
        phoneNumberErrorMsg={errors.contactInfoSection?.phoneNumber?.message}
        phoneNumberLabel={
          Dictionary[DICTIONARY.PERSONAL_DETAILS].secondSectionFirstInput
        }
        disabled={formInfo.isRecognized}
      />
      <PhoneNumber
        phoneNumberFormName='contactInfoSection.extraPhoneNumber'
        phoneNumberPrefixFormName='contactInfoSection.extraPhonePrefixRelationId'
        phoneNumberErrorMsg={
          errors.contactInfoSection?.extraPhoneNumber?.message
        }
        phoneNumberError={Boolean(errors.contactInfoSection?.extraPhoneNumber)}
        phoneNumberLabel={
          Dictionary[DICTIONARY.PERSONAL_DETAILS].secondSectionSecondInput
        }
        disabled={formInfo.isRecognized}
      />
      <TextField
        role={Boolean(errors.contactInfoSection?.mail) ? 'alert' : undefined}
        id={Dictionary[DICTIONARY.PERSONAL_DETAILS].secondSectionThirdInput}
        type='email'
        inputProps={{
          maxLength: '50',
        }}
        {...register(mailFormName)}
        error={Boolean(errors.contactInfoSection?.mail)}
        label={Dictionary[DICTIONARY.PERSONAL_DETAILS].secondSectionThirdInput}
        {...textFieldShrink(mail)}
        helperText={
          Boolean(errors.contactInfoSection?.mail) &&
          generateError(
            Dictionary[DICTIONARY.PERSONAL_DETAILS].secondSectionThirdInput,
            errors.contactInfoSection?.mail?.message!
          )
        }
        disabled={formInfo.isRecognized}
      />
      {!mail && (
        <CustomCheckbox
          name={'contactInfoSection.hasNoMail'}
          label={Dictionary[DICTIONARY.PERSONAL_DETAILS].secondSectionNoEmail}
          disabled={formInfo.isRecognized}
        />
      )}
    </FormSection>
  );
};
