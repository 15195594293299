interface IDynamicAccordionArrow {
  color: string;
  size?: number;
  rotate?: number;
}

export const DynamicArrow = ({
  color,
  size,
  rotate = 0,
}: IDynamicAccordionArrow) => {
  return (
    <svg
      width={size || 20}
      height={size || 20}
      viewBox='0 0 20 20'
      style={{
        transform: `rotate(${rotate}deg)`,
        WebkitTransform: `rotate(${rotate}deg)`,
      }}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.4611 12.9026L16.3454 7.11461C16.4775 6.98469 16.4775 6.77463 16.3454 6.64472L15.789 6.09744C15.6569 5.96752 15.4434 5.96752 15.3113 6.09744L10.2222 11.0921L5.13317 6.09744C5.00109 5.96752 4.78751 5.96752 4.65546 6.09744L4.09904 6.64472C3.96699 6.77463 3.96699 6.98469 4.09904 7.11461L9.98337 12.9026C10.1154 13.0325 10.329 13.0325 10.4611 12.9026Z'
        fill={color}
      />
    </svg>
  );
};
