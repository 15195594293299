import {
  Avatar,
  Button,
  Card,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { ModuleCardInfoText } from './ModuleCardInfoText';
import { IProgressBar, ProgressBar } from './ProgressBar';

interface IModuleCard {
  icon?: string;
  title?: string;
  showProgressBar: boolean;
  progressBar?: IProgressBar;
  buttonClick: () => void;

  showComment?: boolean;
}
export const ModuleCard = (props: IModuleCard) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const cardStyle = isDesktop
    ? { padding: '22px 40px', width: '384px', height: '337px' }
    : {
        padding: '8px 10px 24px 10px',
        width: '145px',
        flexGrow: 1,
        height: '250px',
      };

  const avatarStyle = isDesktop
    ? { height: '104px', width: '104px', mb: 1.75 }
    : { height: '60px', width: '60px', mb: 1 };

  const split2WordInto2LinesInMobile = (title: string) => {
    // This function is here because there wasn't a way of making the lines the same in each card
    //and since the cards title are hard coded then this is the solution we came up with sorry in advanced

    const typographyStyle = isDesktop ? 2 : 1.75;

    const numOfWords = title.split(' ');
    if (isDesktop) {
      return (
        <Typography
          textAlign='center'
          variant='h2'
          sx={{
            width: '100%',
            mb: typographyStyle,
            fontWeight: 'bold',
          }}
        >
          {title}
        </Typography>
      );
    }
    if (numOfWords.length === 2) {
      return (
        <>
          <Typography
            textAlign='center'
            variant='h2'
            sx={{
              width: '100%',
            }}
          >
            {numOfWords[0]}
          </Typography>
          <Typography
            textAlign='center'
            variant='h2'
            sx={{
              width: '100%',
              mb: typographyStyle,
            }}
          >
            {numOfWords[1]}
          </Typography>
        </>
      );
    }
    return (
      <Typography
        textAlign='center'
        variant='h2'
        sx={{
          width: '100%',
          mb: typographyStyle,
        }}
      >
        {title}
      </Typography>
    );
  };

  const buttonLabel = () => {
    switch (props.progressBar?.value) {
      case 0:
        return Dictionary[DICTIONARY.STATUS_LOBBY].buttonLabel;
      case 100:
        return Dictionary[DICTIONARY.STATUS_LOBBY].buttonLabelWhenFinished;
      default:
        return Dictionary[DICTIONARY.STATUS_LOBBY].buttonLabelWhenModified;
    }
  };

  const accessibilityTitle = () => {
    switch (props.progressBar?.value) {
      case 0:
        return props.title
          ? `${Dictionary[DICTIONARY.STATUS_LOBBY].buttonLabel} ${props.title}`
          : '';
      case 100:
        return props.title
          ? `${Dictionary[DICTIONARY.STATUS_LOBBY].buttonLabelWhenFinished} ${
              props.title
            }`
          : '';
      default:
        return props.title
          ? `${Dictionary[DICTIONARY.STATUS_LOBBY].buttonLabelWhenModified} ${
              Dictionary[DICTIONARY.STATUS_LOBBY].buttonLabel
            } ${props.title}`
          : '';
    }
  };

  return (
    <Card
      sx={{
        ...cardStyle,
        boxShadow: '0px 4px 28px rgba(45, 112, 217, 0.15)',
        // TODO boxShadow add to theme when things will be clear
      }}
    >
      <Stack alignItems='center' sx={{ height: '100%', position: 'relative' }}>
        <Avatar
          src={props.icon}
          alt=''
          sx={{
            ...avatarStyle,
            margin: 0,
          }}
        />
        {split2WordInto2LinesInMobile(props.title || '')}
        {props.showProgressBar && (
          <ProgressBar
            {...props.progressBar}
            showComment={props.showComment || false}
          />
        )}
        {Boolean(props.showComment) && <ModuleCardInfoText />}
        <Button
          onClick={props.buttonClick}
          variant='outlined'
          sx={{
            position: 'absolute',
            bottom: '0',
            right: '50%',
            transform: 'translateX(50%)',
          }}
          aria-label={accessibilityTitle()}
        >
          {buttonLabel()}
        </Button>
      </Stack>
    </Card>
  );
};
