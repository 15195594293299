import {
  Avatar,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { Info } from 'Images';

export const ModuleCardInfoText = () => {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  return isDesktop ? (
    <Paper
      sx={{
        boxShadow: '0px 3px 15px rgba(45, 112, 217, 0.15)',
        borderRadius: '14px',
        padding: '8px',
      }}
    >
      <Grid
        container
        alignItems='center'
        columnGap={1}
        direction={isDesktop ? 'row' : undefined}
        flexWrap={isDesktop ? 'nowrap' : undefined}
      >
        <Avatar src={Info} alt='' sx={{ width: '18px', height: '18px' }} />
        <Typography
          variant='subtitle2'
          color='#60727E'
          sx={{ flexBasis: '90%', flexGrow: 1, opacity: 0.72 }}
        >
          {Dictionary[DICTIONARY.STATUS_LOBBY].infoText}
        </Typography>
      </Grid>
    </Paper>
  ) : (
    <Grid container alignItems='center' spacing={0.5}>
      <Grid item xs={1}>
        <Avatar src={Info} alt='' sx={{ width: '10px', height: '10px' }} />
      </Grid>
      <Grid item xs={11}>
        <Typography
          display='flex'
          variant='subtitle2'
          color='common.black'
          sx={{ flexBasis: '90%', flexGrow: 1, opacity: 0.72 }}
        >
          {Dictionary[DICTIONARY.STATUS_LOBBY].infoTextMobile}
        </Typography>
      </Grid>
    </Grid>
  );
};
