import { Component, ErrorInfo } from 'react';

interface IErrorBoundary {}

interface IErrorBoundaryState {
  hasError: boolean;
  errorMessage: Error | string;
}

class ErrorBoundary extends Component<IErrorBoundary, IErrorBoundaryState> {
  constructor(props: IErrorBoundary) {
    super(props);
    this.state = { hasError: false, errorMessage: '' };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true, errorMessage: error });
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <h1
          style={{
            position: 'absolute',
            right: '50%',
            top: '50%',
            transform: 'translateX(50%)',
          }}
        >
          Something went wrong
        </h1>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
