import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersonalInfoSection } from 'API';

export type CASE_TYPE = {
  id: number;
  type: number;
};
export type IS_MENTAL = {
  id: number;
  type: boolean;
};
interface InitState {
  generalObj: Partial<PersonalInfoSection>;
  caseDetailsObj: {
    caseType: {
      [key: string]: CASE_TYPE;
    };
    isMentalIllnessCausedBySpecificEvent: {
      [key: string]: IS_MENTAL;
    };
  };
}

const initialState: InitState = {
  generalObj: {
    birthDate: '',
  },
  caseDetailsObj: {
    caseType: {},
    isMentalIllnessCausedBySpecificEvent: {},
  },
};

export const CaseDetailsSlice = createSlice({
  name: 'caseDetails',
  initialState,
  reducers: {
    setGeneralObj: (state, action: PayloadAction<PersonalInfoSection>) => {
      state.generalObj.birthDate = action.payload.birthDate;
    },
    setCaseType: (state, action: PayloadAction<CASE_TYPE>) => {
      state.caseDetailsObj.caseType[action.payload.id] = action.payload;
    },
    setIsMentalIllnessCausedBySpecificEvent: (
      state,
      action: PayloadAction<IS_MENTAL>
    ) => {
      state.caseDetailsObj.isMentalIllnessCausedBySpecificEvent[
        action.payload.id
      ] = action.payload;
    },
  },
});

export const {
  setGeneralObj,
  setCaseType,
  setIsMentalIllnessCausedBySpecificEvent,
} = CaseDetailsSlice.actions;

export default CaseDetailsSlice.reducer;
