import { Grid, Typography, useTheme } from '@mui/material';
interface IBullet {
  title: string;
  description: string;
}
export const Bullet = (props: IBullet) => {
  const theme = useTheme();
  return (
    <Grid container alignItems='start'>
      <Grid
        item
        sx={{
          p: 1,
        }}
      >
        <div
          style={{
            borderRadius: '50%',
            maxWidth: '6px',
            width: '6px',
            height: '6px',
            backgroundColor: theme.palette.primary.main,
          }}
        ></div>
      </Grid>
      <Grid
        item
        sx={{
          width: 'calc(100% - 6px - 16px)',
        }}
      >
        <Typography paragraph>
          <Typography fontWeight={700} component='span'>
            {`${props.title} - `}
          </Typography>
          {`${props.description}`}
        </Typography>
      </Grid>
    </Grid>
  );
};
