import { api } from 'API';

const baseUrl = '/RequestsAndEarlyAcquaintance';

export interface SocialSecurityRequestSection {
  submittedRequestDate?: string | null;
  requestReasonId?: string | null;
  isRequestProcessingEnded?: boolean | null;
}

export interface MinistryOfDefenceSection {
  isMinistryOfDefenceEmployee?: boolean | null;
  workplace?: string | null;
  isThereRelativeInMinistryOfDefense?: boolean | null;
  relativeDetail?: string | null;
}

export interface IRequestAndEarlyAcquaintance {
  isRequestAllowanceWasSubmitted?: boolean | null;
  socialSecurityRequestSection?: SocialSecurityRequestSection | null;
  ministryOfDefenceSection?: MinistryOfDefenceSection;
}

export const getRequestAndEarlyAcquaintance = async () => {
  const requestAndEarlyAcquaintance =
    await api.get<IRequestAndEarlyAcquaintance>(baseUrl);
  if (requestAndEarlyAcquaintance.data) {
    return requestAndEarlyAcquaintance.data;
  }
};

export const putRequestAndEarlyAcquaintance = async (
  data: Partial<IRequestAndEarlyAcquaintance>
) => {
  const representation = await api.put(baseUrl, data);
  if (representation.status === 200) {
    return representation.data;
  }
};
