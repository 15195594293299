import { Avatar, Grid, Link, Stack, Typography } from '@mui/material';
import { GridButton } from '../GridButtons';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { InfoPaper } from 'Components';
import { StepPaper } from './StepPaper';
import {
  FifthStepIcon,
  FirstStepIcon,
  FourthStepIcon,
  SecondStepIcon,
  ThirdStepIcon,
} from 'Images';
import { useIsDesktop } from 'Hooks';
export const ThirdTab = () => {
  const dictionary = Dictionary[DICTIONARY.LOBBY];
  const isDesktop = useIsDesktop();

  const steps = [
    {
      Icon: FirstStepIcon,
      title: dictionary.thirdTabFirstStepTitle,
      paragraphs: [
        dictionary.thirdTabFirstStepFirstParagraph,
        dictionary.thirdTabFirstStepSecondParagraph,
      ],
      url: 'https://shikum.mod.gov.il/MedicalServices/Pages/zakaut.aspx',
      urlTitle: dictionary.thirdTabSecondStepSecondParagraphUrlTitle1,
    },
    {
      Icon: SecondStepIcon,
      title: dictionary.thirdTabSecondStepTitle,
      paragraphs: [
        dictionary.thirdTabSecondStepFirstParagraph,
        dictionary.thirdTabSecondStepSecondParagraph,
      ],
      url: 'https://shikum.mod.gov.il/BasicPrivileges/hacara/Pages/medical-opinion.aspx',
      urlTitle: dictionary.thirdTabSecondStepSecondParagraphUrlTitle2,
    },
    {
      Icon: ThirdStepIcon,
      title: dictionary.thirdTabThirdStepTitle,
      paragraphs: [
        dictionary.thirdTabThirdStepFirstParagraph,
        dictionary.thirdTabThirdStepSecondParagraph,
        dictionary.thirdTabThirdStepThirdParagraph,
      ],
    },
    {
      Icon: FourthStepIcon,
      title: dictionary.thirdTabFourthStepTitle,
      paragraphs: [
        dictionary.thirdTabFourthStepFirstParagraph,
        dictionary.thirdTabFourthStepSecondParagraph,
      ],
    },
    {
      Icon: FifthStepIcon,
      title: dictionary.thirdTabFifthStepTitle,
      paragraphs: [dictionary.thirdTabFifthStepFirstParagraph],
    },
  ];

  return (
    <Stack alignItems='center'>
      <InfoPaper>
        <Stack spacing={3}>
          {steps.map((step, index) => (
            <StepPaper key={index}>
              <Grid
                container
                spacing={{
                  md: 1,
                }}
              >
                <Grid
                  container
                  alignItems='center'
                  justifyContent={{
                    xs: 'center',
                  }}
                  item
                  xs={12}
                  md={1}
                >
                  <Avatar
                    src={step.Icon}
                    alt={`-שלב${index}`}
                    sx={{ width: '64px', height: '64px' }}
                  />
                </Grid>
                <Grid container item xs={12} md={11}>
                  <Grid
                    container
                    justifyContent={{
                      xs: 'center',
                      md: 'flex-start',
                    }}
                    item
                    xs={12}
                  >
                    <Typography
                      sx={{
                        alignSelf: 'center',
                      }}
                      variant='subtitle1'
                    >{`${dictionary.step} ${index + 1} ${dictionary.of} ${
                      steps.length
                    }`}</Typography>
                  </Grid>
                  <Grid
                    container
                    justifyContent={{
                      xs: 'center',
                      md: 'flex-start',
                    }}
                    item
                    xs={12}
                  >
                    <Typography sx={{ fontSize: '18px' }} fontWeight={700}>
                      {step.title}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {step.paragraphs.map((paragraph, index) => (
                <Typography
                  alignSelf={isDesktop ? 'start' : undefined}
                  textAlign={isDesktop ? 'start' : 'center'}
                  paragraph
                  key={index}
                >
                  {paragraph}
                  {step.urlTitle && index === step.paragraphs.length - 1 && (
                    <Link href={step.url} target='_blank' sx={{ pl: '5px' }}>
                      {step.urlTitle}
                    </Link>
                  )}
                </Typography>
              ))}
            </StepPaper>
          ))}
        </Stack>
      </InfoPaper>
      <GridButton lastStep rightButton={dictionary.enterTheForm} />
    </Stack>
  );
};
