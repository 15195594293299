import { Typography } from '@mui/material';
import { useIsDesktop } from 'Hooks';

interface IPhoneNumberText {
  phoneNumber: string;
}
export const PhoneNumberText = (props: IPhoneNumberText) => {
  const isDesktop = useIsDesktop();

  return isDesktop ? (
    <Typography
      component='span'
      sx={{
        color: 'inherit',
        fontWeight: 'inherit',
      }}
    >{` ${props.phoneNumber} `}</Typography>
  ) : (
    <Typography
      sx={(theme) => ({
        textDecoration: 'underline',
        fontWeight: 'inherit',
        color: theme.palette.primary.main,
      })}
      component={'a'}
      href={`tel:${props.phoneNumber}`}
    >
      {props.phoneNumber}
    </Typography>
  );
};
