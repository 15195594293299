import { Paper } from '@mui/material';
import { OTPLoginContext } from 'Context/OTPLoginContext';
import { useIsDesktop } from 'Hooks';
import { useCallback, useContext, useState } from 'react';
import { CodeValidation } from './CodeValidation';
import { VerifyOtpRequest, otpCodeValidation } from 'API/EmergencyForm';

interface IOTPCodeValidationWrapper {
  setValidation: Function;
  sendCodeAgain: Function;
  setIsLoggedIn: Function;
}

export const OTPCodeValidationWrapper = ({
  setValidation: setValidation,
  sendCodeAgain,
  setIsLoggedIn,
}: IOTPCodeValidationWrapper) => {
  const {
    userDetails,
    setUserDetails,
    setIsFormValidation,
    loginDetails,
    setLoginDetails,
  } = useContext(OTPLoginContext);
  const [isValidated, setIsValidated] = useState<boolean | null>(null);
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [isWrongCode, setIsWrongCode] = useState<boolean>(false);

  const isDesktop = useIsDesktop();

  const validationHandler = useCallback(
    async (code: string) => {
      const requestBody: VerifyOtpRequest = {
        handicappedIsrId: userDetails.handicappedIsrId,
        formFillerIsrId: userDetails.formFillerIsrId,
        phone: userDetails.phone,
        otpCode: code,
      };

      const result = await otpCodeValidation(requestBody);

      if (result.status === 410) {
        setIsBlocked(true);
        setIsValidated(false);
        return;
      } else if (result.status === 400) {
        setIsWrongCode(true);
        setIsBlocked(false);
      }
      if (result.data) {
        const resultData = result.data;
        setIsValidated(true);

        setIsFormValidation(true);
        setLoginDetails({
          ...loginDetails,
          accessToken: resultData.accessToken,
          emergencyFormId: resultData.emergencyFormId,
        });
        window.sessionStorage.setItem('token', resultData.accessToken);
      } else setIsValidated(false);
    },
    [setUserDetails, userDetails]
  );

  return (
    <>
      <Paper
        sx={{
          maxWidth: '770px',
          width: '100%',
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
          padding: isDesktop ? '25px 20px 24px 20px' : '16px 16px 525px 16px',
          boxShadow: 'unset',
        }}
      >
        <CodeValidation
          isValidated={isValidated}
          setIsValidated={setIsLoggedIn}
          validationHandler={validationHandler}
          setValidation={setValidation}
          sendCodeAgain={sendCodeAgain}
          isBlocked={isBlocked}
          setIsBlocked={setIsBlocked}
          isWrongCode={isWrongCode}
          setIsWrongCode={setIsWrongCode}
        />
      </Paper>
    </>
  );
};
