import { LinearProgress, Stack, Typography } from '@mui/material';
import { DICTIONARY } from 'Enum';
import { Dictionary } from 'Dictionary';
export interface IProgressBar {
  value?: number;
}

export const ProgressBar = (props: IProgressBar & { showComment: boolean }) => {
  const filledValue = 100;
  const notFilledValue = 0;

  const isUndefined = props.value === undefined;

  const progressBarMsg = () => {
    if (props.showComment)
      return Dictionary[DICTIONARY.STATUS_LOBBY].progressBarAllMandatoryFilled;
    if (props.value === filledValue)
      return Dictionary[DICTIONARY.STATUS_LOBBY].progressBarAllFilled;
    if (props.value === notFilledValue || isUndefined)
      return Dictionary[DICTIONARY.STATUS_LOBBY].progressBarNotFilled;
    return Dictionary[DICTIONARY.STATUS_LOBBY].progressBarPartiallyFilled;
  };
  return (
    <Stack gap={1} mb={1} alignItems='center'>
      <LinearProgress
        variant='determinate'
        sx={{ width: '96%', borderRadius: '3px', height: '6px' }}
        value={isUndefined ? notFilledValue : props.value}
        aria-hidden='true'
      />
      <Typography variant='subtitle1' color='#60727E' sx={{ fontWeight: 700 }}>
        {progressBarMsg()}
      </Typography>
    </Stack>
  );
};
