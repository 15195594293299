import {
  FormControl,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroupProps,
  FormHelperText,
  Typography,
  Box,
} from '@mui/material';
import { useController } from 'react-hook-form';
import { generateError } from 'Regex';
import { InfoText } from 'Components';

type Options = {
  value: string | boolean;
  label: string;
};

interface ICustomRadioGroup extends RadioGroupProps {
  id?: string;
  options: Options[];
  name: string;
  description?: string;
  infoText?: string;
}
export const CustomRadioGroup: React.FC<ICustomRadioGroup> = (props) => {
  const { id, options, name, ...RadioProps } = props;

  let {
    field: { onChange, value, ref, ...fields },
    fieldState: { error },
  } = useController({
    name: name,
    defaultValue: null,
  });
  return (
    <FormControl error={Boolean(error)}>
      {id ? (
        <FormLabel htmlFor={id}>
          <Typography color={error ? 'error' : 'text.primary'} fontWeight={700}>
            {id}
          </Typography>
          {props.infoText && (
            <Box sx={{ mt: 1, mb: 1 }}>
              <InfoText text={props.infoText} />
            </Box>
          )}
          {props.description && (
            <Typography color='text.primary'>{props.description}</Typography>
          )}
        </FormLabel>
      ) : null}
      <RadioGroup
        aria-labelledby={id}
        aria-label={id}
        {...RadioProps}
        {...fields}
        name={name}
        value={value}
        onChange={(e, value) => {
          onChange(value);
        }}
        sx={{
          marginBottom: 0,
        }}
      >
        {options.map(({ value, label }) => (
          <FormControlLabel
            control={<Radio inputRef={ref} />}
            key={label}
            value={value}
            label={label}
          />
        ))}
      </RadioGroup>
      {error?.message && (
        <FormHelperText>{generateError(id!, error.message)}</FormHelperText>
      )}
    </FormControl>
  );
};
