import { useEffect, useRef, useState } from 'react';
import { Box, Divider, TextField } from '@mui/material';
import { useIsDesktop } from 'Hooks';
import { onlyNumbers } from 'Regex';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';

interface IOTPCode {
  isCodeSentAgain: boolean;
  isValidated: boolean | null;
  isBlocked: boolean;
  setCode: Function;
  isWrongCode: boolean;
  validationHandler: Function;
}

const maxCodeLength = 4;

export const OTPCode = ({
  isCodeSentAgain,
  isValidated,
  isBlocked,
  setCode,
  validationHandler,
  isWrongCode,
}: IOTPCode) => {
  const [otpCode, setOtpCode] = useState<string>('');
  const [underlines, setUnderlines] = useState<any>([]);
  const otpTextFieldRef = useRef<any>();
  const isDesktop = useIsDesktop();

  useEffect(() => {
    if (isDesktop) return; //TODO Replace with userAgent logics in the future for certain mobile detections.
    (async () => {
      if ('OTPCredential' in window) {
        const ac = new AbortController();
        navigator.credentials
          .get({
            otp: { transport: ['sms'] },
            signal: ac.signal,
          })
          .then((otp: any) => {
            otpTextFieldRef.current.value = otp.code;
            setOtpCode(otp.code);
            validationHandler(otp.code);
          });
      }
    })();
  }, [isCodeSentAgain, isDesktop]);

  useEffect(() => {
    if (isCodeSentAgain) {
      setOtpCode('');
      otpTextFieldRef.current.focus();
    }
  }, [isCodeSentAgain]);

  useEffect(() => {
    setUnderlines(
      Array.from({ length: maxCodeLength }, (_, index: number) => {
        return (
          <Divider
            key={index}
            orientation='horizontal'
            sx={{
              height: '2px',
              width: '45px',
              borderColor: index < otpCode.length ? 'transparent' : '#1552E8',
            }}
          />
        );
      })
    );
  }, [otpCode]);

  const otpCodeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const codeInput = event.target.value;
    if (!onlyNumbers.test(codeInput)) return;
    if (codeInput.length <= maxCodeLength) {
      setOtpCode(codeInput);
      const updatedCode: (string | null)[] = codeInput.split('');
      while (updatedCode.length < maxCodeLength) updatedCode.push(null);
      setCode(updatedCode);
    }
    if (codeInput.length === maxCodeLength)
      event.target.setSelectionRange(3, 4);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '246px',
      }}
    >
      <TextField
        value={otpCode || ''}
        autoComplete='one-time-code'
        aria-required={true}
        aria-label={Dictionary[DICTIONARY.LOGIN].phoneEmailValidation.otpCode}
        disabled={isBlocked}
        autoFocus
        ref={otpTextFieldRef}
        type='text'
        inputMode='numeric'
        sx={{
          '& fieldset': {
            border: 'none',
          },
          'input::selection': {
            background: 'transparent',
          },
        }}
        inputProps={{
          style: {
            border: 'none',
            padding: isDesktop ? '0 20px 0 20px' : '0 22px 0 22px',
            direction: 'ltr',
            fontSize: isDesktop ? '40px' : '32px',
            fontWeight: 700,
            color: isValidated === false || isWrongCode ? '#A30F28' : '#1552E8',
            width: '100%',
            letterSpacing: isDesktop ? '38px' : '43px',
          },
        }}
        onChange={otpCodeChangeHandler}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          gap: '15px',
          width: '100%',
        }}
      >
        {underlines}
      </Box>
    </Box>
  );
};
