import { DICTIONARY } from 'Enum';

export const Dictionary = {
  [DICTIONARY.LOBBY]: {
    title: 'שלום לך!',
    subTitle: 'הגעת לטופס בקשה להכרה בנכות במשרד הבטחון',
    firstTab: 'לפני שנתחיל',
    secondTab: 'חשוב לדעת',
    thirdTab: 'על התהליך',
    firstTabInfo:
      'הטופס הדיגיטלי מאפשר לך להגיש לאגף השיקום בקשה להכרה בנכות וגם לצרף מסמכים ומידע רפואי באופן מהיר ונוח.',
    firstTabTitle: '',
    firstTabFirstParagraph:
      'התהליך הוא אישי ומורכב. הטופס שלפניך מכיל שאלות רבות וחלקן מתייחסות לאירועים אישיים ומורכבים שעברת, אבל ככל שהמידע שיועבר אלינו יהיה מפורט יותר, כך נוכל לבדוק את הבקשה שלך ביעילות ובמהירות.',
    firstTabSecondParagraph:
      'חשוב להשקיע מאמץ במילוי הבקשה, לקרוא את כל הפרטים ולצרף כל מסמך או תיעוד שיסייע לנו להכיר אותך ולעזור לך.',
    enterTheForm: 'כניסה לטופס',
    secondTabWeAreHereForYouTitle: 'אנחנו פה בשבילך',
    secondTabWeAreHereForYouFirstParagraph:
      'אם יש לך צורך בעזרה במהלך מילוי הטופס, נשמח לעזור',
    secondTabWeAreHereForYouSecondParagraph:
      'כמו כן, ניתן לפנות לגורמי הסיוע בהתאם לסוג השירות שלך',
    emotionalSupportTitle: 'קו תמיכה נפשית לשירותך בטלפון',
    emotionalSupportPhoneNumber: '8944*',
    emotionalSupportFirstParagraph:
      'מעניק תמיכה רגשית למתמודדים עם פוסט טראומה, 24 שעות ביממה, 7 ימים בשבוע מאפשר גם פנייה באופן אנונימי',
    secondTabWeAreHereForYouFirstBulletTitle: 'צה"ל',
    secondTabWeAreHereForYouFirstBulletFirstParagraph: `מוקד 'קשר עד' של מערך הנפגעים בצה"ל שמספרו 1111 (שלוחה 6)`,
    secondTabWeAreHereForYouSecondBulletTitle: 'משטרת ישראל',
    secondTabWeAreHereForYouSecondBulletFirstParagraph: `מדור נפגעים שמספרו 02-5428584 או 02-5428764`,
    secondTabWeAreHereForYouThirdBulletTitle: `שירות בתי הסוהר`,
    secondTabWeAreHereForYouThirdBulletFirstParagraph: `מערך הנפגעים שמספרו 074-7830194`,
    secondTabInjuryReportTitle: 'דיווח אירוע חריג או דוח פציעה',
    secondTabInjuryReportFirstParagraph: `בהמשך נבקש ממך להעלות את דיווח אירוע חריג או דוח פציעה, אך גם אם הוא לא נמצא ברשותך, אנחנו מתאמצים לאתר עבורך את המידע ולאשר את הבקשה אפילו בלעדיו.
    רצוי להשיג כל מסמך או תיעוד שיסייע לנו לבדוק את הבקשה ולעזור לך.`,
    secondTabMultipleVulnerabilitiesTitle: 'מספר פגיעות במקביל',
    secondTabMultipleVulnerabilitiesParagraph: `אם חלית או נפצעת מספר פעמים ובאירועים שונים, אין צורך להגיש מספר בקשות. בטופס הדיגיטלי ניתן להוסיף פרטים של פגיעות או מחלות נוספות.`,
    secondTabAttachedFilesTitle: 'מסמכים מצורפים',
    secondTabAttachedFilesFirstParagraphBoldSection: `נא לא להעלות לטופס הדיגיטלי מסמכים מסווגים. `,
    secondTabAttachedFilesFirstParagraph: `אפשר לצרף רק מסמכים ברשימת סיווג בלמ"ס (בלתי מסווג).`,
    secondTabAttachedFilesSecondParagraph: `אם יש צורך בהעברת מסמכים או מידע מסווג לצורך הטיפול בבקשתך, ניתן להעביר אותם באופן אישי לאחר מילוי הבקשה ישירות אלינו - אגף השיקום, היחידה לתביעות וקביעת זכאות, רחוב ז'בוטינסקי 94, פתח תקווה ובהתאם להנחיות ביחידתך.`,
    secondTabYourDataIsSavedTitle: 'המידע נשמר עבורך',
    secondTabYourDataIsSavedFirstParagraph: `אפשר לעצור באמצע מילוי הטופס ולחזור בהמשך לנקודה שבה הפסקת, באמצעות סיסמה שתישלח לטלפון הנייד שלך - כל הפרטים שמילאת יישמרו עבורך.
    `,
    thirdTabTitle: 'על התהליך',
    thirdTabFirstStepTitle: 'מילוי הטופס ושליחתו',
    thirdTabFirstStepFirstParagraph:
      'השלב הראשון בתהליך הוא מילוי טופס הבקשה להכרה בנכות ושליחתו אלינו.',
    thirdTabFirstStepSecondParagraph:
      'חשוב לדעת, כבר בשלב זה מי שהשתחררו משירות חובה, מילואים או ממסגרת ביטחונית - יקבלו אישור לקבלת טיפול רפואי עד לסיום בירור הבקשה וקבלת ההחלטה.',
    thirdTabSecondStepTitle: 'בדיקת הבקשה וחוות דעת רפואית',
    thirdTabSecondStepFirstParagraph:
      'לאחר שנקבל את הטופס נבדוק את הפרטים שהעברת, ואם חסר מידע כלשהו נודיע לך על כך.',
    thirdTabSecondStepSecondParagraph:
      'בשלב זה אנחנו בוחנים את הקשר הסיבתי בין הפגיעה או המחלה, לבין השירות הצבאי/הביטחוני שלך. אם יהיה בכך צורך, נבקש ממך להגיע לבדיקה אצל רופא/רופאה מומחים שיעבירו לנו חוות דעת רפואית. המומחה לא קובע את דרגת הנכות, אלא בודק רק את הקשר הסיבתי בין הפגיעה או המחלה, לבין השירות שלך.',
    thirdTabSecondStepSecondParagraphUrlTitle1:
      'למידע נוסף על קבלת טיפול רפואי',
    thirdTabSecondStepSecondParagraphUrlTitle2: 'למידע נוסף על חוות דעת רפואית',

    thirdTabThirdStepTitle: 'החלטה ואישור עקרוני',
    thirdTabThirdStepFirstParagraph:
      'בשלב הזה, כשיש לנו את כל הפרטים על הפגיעה שלך ומצבך הרפואי, נקבל החלטה אם לאשר את הבקשה שלך או לדחות אותה. את ההודעה על ההחלטה נשלח אליך בדואר רשום.',
    thirdTabThirdStepSecondParagraph:
      'אם הבקשה שלך תאושר, אנחנו נודיע לך גם אילו פגיעות או מחלות הוכרו על ידינו, ואם היא תידחה, באפשרותך להגיש ערעור על ההחלטה.',
    thirdTabThirdStepThirdParagraph:
      'חשוב לדעת, האישור בשלב זה הוא עקרוני בלבד ומאשר כי מצאנו קשר סיבתי בין השירות שלך לפגיעה או למחלה. ההחלטה לגבי הזכאות שלך לזכויות, טיפולים ותגמולים תינתן בהתאם לדרגת הנכות, שאותה תקבע הוועדה הרפואית.',
    thirdTabFourthStepTitle: 'ועדה רפואית',
    thirdTabFourthStepFirstParagraph:
      'לאחר האישור העקרוני של בקשתך, נתאם עבורך ועדה רפואית עם רופאים מומחים בתחום הפגיעה או המחלה שלך. הוועדה הרפואית היא זו שקובעת את דרגת הנכות שלך.',
    thirdTabFourthStepSecondParagraph:
      'כל המסמכים הרפואיים שלך יועברו לרופאי הוועדה. חשוב לדעת, אם יש לך מסמכים נוספים שלא הוגשו או בדיקות עדכניות חשוב להביא אותם לוועדה.',
    thirdTabFifthStepTitle: 'אישור סופי ועדכון על זכויות',
    thirdTabFifthStepFirstParagraph:
      'לאחר החלטת הוועדה הרפואית, נשלח לך הודעה שמפרטת את דרגת הנכות שנקבעה לך והתאריך שבו מתחילה הזכאותך שלך, ונעדכן אותך על הזכויות, הטיפולים והתגמולים שמגיעים לך בהתאם לדרגת הנכות שנקבעה.',
    of: 'מתוך',
    step: 'שלב',
    instructions:
      'ניתן לצרף לבקשה קבצים בגודל עד 20MB בעלי הסיומות הבאות: png, jpg, doc, docx, pdf, tif. ',
  },
  [DICTIONARY.HEADER]: {
    title: 'בקשה להכרה בנכות',
    emergencyFormTitle: 'טופס בקשה להכרה בנכות',
  },
  [DICTIONARY.STATUS_LOBBY]: {
    title: 'בקשה להכרה',
    mobileTitle: 'בקשה להכרה',
    subTitle:
      'לפניך כל השלבים בתהליך. אפשר למלא אותם בכל סדר שנוח לך, אבל חשוב להשלים את כולם לפני הגשת הבקשה',
    buttonLabel: 'מילוי',
    buttonLabelWhenModified: 'המשך',
    buttonLabelWhenFinished: 'עריכה',
    progressBarNotFilled: 'עדיין לא מילאת',
    progressBarPartiallyFilled: 'מילאת חלקית',
    progressBarAllMandatoryFilled: 'מילאת את שדות החובה',
    progressBarAllFilled: '✓ מילאת הכל',
    submitFileButtonLabel: 'מילאתי הכל, אפשר להגיש',
    submitDialogTitle: 'רק רגע...',
    submitDialogSubTitle: 'לא סיימת למלא את הבקשה',
    submitDialogText:
      'אנחנו יודעים שהתהליך ארוך ומורכב, אבל ניתן להגיש את הבקשה רק לאחר מילוי כל הפרטים והשלבים.',
    submitDialogButton: 'חזרה להגשת הבקשה',
    infoText:
      'יש שדות שלא מולאו. כדי להגדיל את סיכויי קבלת הבקשה מומלץ למלא את כל השדות שניתן',
    infoTextMobile: 'כדאי למלא גם שדות רשות',
  },
  [DICTIONARY.CASE_DETAILS]: {
    title: 'פרטי הפגיעה',
    topFirstDescription:
      'הגעת לחלק יותר מורכב: החלק שבו נבקש ממך לתאר את הפגיעה שחווית ואיך היא משפיעה עליך.',
    topSecondDescription:
      'אנחנו יודעים שזה לא פשוט, אבל כדי שנוכל לטפל בבקשה שלך בצורה הטובה ביותר, חשוב לנו לקבל ממך מידע מפורט ומדויק על החבלה או המחלה שעברת ועל התפקוד שלך כיום.',
    topThirdTitle: 'כדי לקצר את התהליך',
    topThirdDescription:
      'נבקש ממך לצרף מסמכים שמתעדים את הפגיעה: מומלץ לשלוח כמה שיותר מידע רלוונטי שיסייע לנו לטפל בך.',
    topThirdDescriptionLine2:
      'נא לא להעלות מסמכים מסווגים. אפשר לצרף רק מסמכים בלתי מסווגים לטופס הדיגיטלי.',
    topThirdDescriptionLine3:
      "אם יש צורך להעביר מידע מסווג, נא להעביר אותו לאחר מילוי הבקשה, בהתאם להנחיות ביחידה שלך, ישירות אלינו - אגף השיקום, היחידה לתביעות וקביעת זכאות, רחוב ז'בוטינסקי 94, פתח תקווה.",
    injury: 'חבלה',
    disease: 'מחלה',
    mental: 'פגיעה נפשית',
    firstSectionTitle: 'פרטי השירות והתפקיד שלך בזמן הפגיעה',
    firstSectionSubTitle: 'התשובות צריכות להתייחס לשירות שלך בזמן הפגיעה',
    firstSectionFirstInput: 'סוג השירות (שדה חובה)',
    serviceTypeDetail: 'פירוט סוג שירות',
    firstSectionDateInput: 'תאריך שחרור',
    firstSectionSecondInput: 'תפקיד בזמן הפגיעה (שדה חובה)',
    firstSectionFourthInput: 'חיל',
    firstSectionSixthInput: 'פרופיל רפואי נוכחי',

    secondSectionTitle: 'מה סוג הפגיעה שלך? (חובה)',
    secondSectionDescription:
      'אם נפגעת במספר אירועים שונים, באפשרותך להוסיף בתחתית המסך אירוע נוסף ולפרט את הפגיעות שנגרמו בו.',
    secondSectionFirstToggleButtonLabel:
      'חבלה היא פגיעה שקרתה במיקום ספציפי ובתאריך מסוים. מחלה או מצב רפואי, היא פגיעה שהתפתחה לאורך זמן',
    secondSectionFirstToggleButtonRight: 'חבלה',
    secondSectionFirstToggleButtonLeft: 'מחלה או מצב רפואי',
    secondSectionMentalBoxTitle: 'קו תמיכה נפשי לשירותך בטלפון 8944*',
    secondSectionMentalBoxInfo:
      'מעניק תמיכה רגשית למתמודדים עם פוסט טראומה, 24 שעות ביממה, 7 ימים בשבוע. מאפשר גם פנייה באופן אנונימי',
    secondSectionMentalLabel:
      'האם הפגיעה הנפשית נגרמה לך בעקבות אירוע מסוים או התפתחה לאורך זמן בשל תנאי השירות שלך? (שדה חובה)',
    secondSectionMentalTooltip:
      'אם הפגיעה נגרמה בעקבות מספר מקרים שקרו באירוע מתמשך (כמו פעילות מבצעית או לחימה), ניתן למלא את התאריך שבו החל האירוע ולפרט בשדה תיאור הפגיעה את כל המקרים',
    secondSectionMentalLong: 'לאורך זמן',
    tooltipMental:
      'אם הפגיעה נגרמה בעקבות מספר מקרים שקרו באירוע מתמשך (כמו פעילות מבצאית לחימה), ניתן למלא את התאריך שבו החל האירוע ולפרט בשדה תיאור הפגיעה את כל המקרים',
    secondSectionMentalShort: 'אירוע מסוים',
    secondSectionDiseaseIsDiagnosisExist: 'האם יש אבחון? (שדה חובה)',
    secondSectionDiseaseDiagnosisDate: 'מתי אובחנה המחלה? (שדה חובה)',
    secondSectionMentalDiseaseDiagnosisDate: 'מתי אובחנה הפגיעה? (שדה חובה)',
    secondSectionFirstInputLabel:
      'האם יש מסמך של דיווח אירוע חריג או דוח פציעה?',
    secondSectionFirstInputTooltip:
      'דוח פציעה הוא מסמך שמתעד את הפגיעה לאחר חבלה, חתום בידי גורם רשמי, פיקודי או רפואי. הדוח מכיל תיאור של השתלשלות האירועים, כולל מקום, מועד ונסיבות הפציעה, מפרט את אופי הפציעה וכן התייחסות לתיעוד רפואי, לעדים ולחקירה אם בוצעה כזו',
    secondSectionFirstInputPlaceholder: 'יש לבחור את האפשרות המתאימה לך',
    secondSectionFirstFileUploadLabel: 'ניתן לצרף דוח פציעה או דוח אירוע חריג ',
    secondSectionFirstFileUploadText: 'דוח פציעה או דוח אירוע חריג',
    secondSectionSecondToggleButtonLabel:
      'האם התנהלה חקירה צבאית או שבוצע תחקיר לפגיעה? (שדה חובה)',
    secondSectionSecondToggleButtonRight: 'כן',
    secondSectionSecondToggleButtonLeft: 'לא',
    secondSectionSecondInputPlaceholder: 'מי הגוף שניהל את החקירה?',
    secondSectionSecondFileUploadLabel: 'ניתן לצרף דוח תחקיר',
    secondSectionSecondFileUploadText: 'דוח תחקיר',
    secondSectionThirdFileUploadLabel: 'צירוף מסמך נוסף שרלוונטי לנושא',
    secondSectionThirdFileUploadText: 'מסמך נוסף שרלוונטי לנושא',
    secondSectionIsThereAnyOtherFamilyMemberThatSuffersFromThatDisease:
      'האם מישהו מבני המשפחה שלך סובל ממחלה זו?',
    secondSectionIsThereAnyOtherFamilyMemberThatSuffersFromThatMentalDisease:
      'האם מישהו נוסף מבני המשפחה שלך מתמודד עם אותו מצב נפשי כמוך?',
    secondSectionThereIsAnotherPerson: 'יש אדם נוסף',
    secondSectionNoItsOnlyMe: 'לא, רק אני',
    secondSectionDiseaseDescriptionNoJustMe:
      'תיאור המחלה של בן או בת המשפחה, והקשר ביניכם',
    secondSectionMentalDiseaseDescriptionNoJustMe:
      'תיאור המצב הנפשי של בן או בת המשפחה, והקשר ביניכם',
    secondSectionHowDidYourServiceCausedYourMedicalCondition:
      'כיצד גרמו תנאי השירות למצבך הרפואי? (עד 500 תווים)',
    secondSectionHowDidYourServiceCausedYourMentalMedicalCondition:
      'כיצד גרמו תנאי השירות למצבך הרפואי? (עד 500 תווים)',

    thirdSectionTitle: 'תאונת דרכים',
    thirdSectionFirstToggleButtonLabel:
      'האם הפגיעה קרתה בתאונת דרכים? (שדה חובה)',
    thirdSectionFirstToggleButtonRight: 'כן',
    thirdSectionFirstToggleButtonLeft: 'לא',
    thirdSectionSecondToggleButtonLabel: 'האם היית בכלי רכב ממונע בזמן התאונה?',
    thirdSectionSecondToggleButtonRight: 'כן, נסעתי ברכב',
    thirdSectionSecondToggleButtonLeft: 'לא, הלכתי ברגל',
    thirdSectionThirdToggleButtonLabel: 'האם נהגת ברכב?',
    thirdSectionThirdToggleButtonRight: 'כן',
    thirdSectionThirdToggleButtonLeft: 'לא',
    thirdSectionFirstInputValue: 'סוג הרכב שנסעתי בו',
    thirdSectionMilitaryCardInfoText:
      'חשוב: במקרה של תאונה עם רכב צבאי, מוקד "קשר עד" שמספרו 1111 (שלוחה 6) יכול לסייע לך בשחזור הנתונים',
    thirdSectionSecondInputValue: 'מספר הרכב',
    thirdSectionFourthToggleButtonLabel: 'האם רכב נוסף היה מעורב בתאונה?',
    thirdSectionFourthToggleButtonRight: 'כן',
    thirdSectionFourthToggleButtonLeft: 'לא',
    thirdSectionFifthToggleButtonLabel: 'האם הוגשה תביעה לחברת הביטוח?',
    thirdSectionFifthToggleButtonRight: 'כן',
    thirdSectionFifthToggleButtonLeft: 'לא',
    thirdSectionThirdInputValue: 'שם חברת הביטוח',
    thirdSectionFirstFileUploadLabel: 'צירוף רישיון נהיגה',
    thirdSectionFirstFileUploadText: 'רישיון נהיגה',
    thirdSectionSecondFileUploadLabel: 'צירוף תעודת ביטוח חובה',
    thirdSectionSecondFileUploadText: 'תעודת ביטוח חובה',
    thirdSectionThirdFileUploadLabel: 'צירוף דו"ח משטרה (אם יש)',
    thirdSectionThirdFileUploadLabel2:
      'מומלץ לפנות למוקד מידע משטרתי במספר טלפון 110 ולבקש אותו',
    thirdSectionThirdFileUploadText: 'דו"ח משטרה',

    diseaseSectionTitle: 'תיאור הפגיעה במילים שלך (שדה חובה)',
    diseaseSectionLabel1: 'תיאור המחלה במילים שלך (שדה חובה - עד 500 תווים)',
    diseaseMentalSectionLabel1:
      'תיאור הפגיעה במילים שלך (שדה חובה - עד 500 תווים)',
    diseaseSectionPlaceholder1:
      'זה המקום לפרט את כל מה שחשוב לך שנדע בקשר לפגיעה שלך ולפרט בצורה יסודית ומלאה את האירוע.',
    diseaseSectionLabel3: 'תיאור המחלה של בן או בת המשפחה, והקשר ביניכם',

    fourthSectionTitle: 'תיאור הפגיעה במילים שלך (שדה חובה - עד 500 תווים)',
    fourthSectionInfo1:
      'זה המקום לספר לנו על האירוע והפגיעה שעברת. ניתן לכתוב פה עד 500 תווים (רק אותיות ומספרים) ו/או לצרף קובץ ולפרט כל מה שחשוב לך שנדע.',
    fourthSectionInfo2:
      'אם יש לך פגיעה נוספת מאירוע אחר יש להוסיף "בקשה להכרה בפגיעה נוספת" בתחתית עמוד זה',
    fourthSectionFirstInput: 'מתי נפגעת? (שדה חובה)',
    fourthSectionSecondInput:
      'זה המקום לפרט את כל מה שחשוב לך שנדע בקשר לפגיעה שלך ולפרט בצורה יסודית ומלאה את האירוע.',
    fourthSectionFirstQuestionMark:
      'זה המקום לפרט את כל מה שחשוב לך שנדע בקשר לפגיעה שלך ולפרט בצורה יסודית ומלאה את האירוע.',

    fifthSectionTitle: 'תיעוד של הפגיעה מהשירות הצבאי או הביטחוני',
    fifthSectionFirstFileUploadLabel:
      'צירוף מסמך המעיד על הפגיעה, מהיחידה או מהצוות הפיקודי (אם יש)',
    fifthSectionFirstFileUploadText: 'מסמך המעיד על הפגיעה',
    fifthSectionSecondFileUploadLabel:
      'צירוף מסמך נוסף מהשירות המעיד על הפגיעה (אם יש)',
    fifthSectionSecondFileUploadText: 'מסמך נוסף שרלוונטי לנושא',
    fifthSectionFirstInputPlaceholder: 'תיאור הקובץ',

    sixthSectionTitle: 'עדים',
    sixthSectionSubTitle: 'האם יש עדים (ראייה או שמיעה) לפגיעה שחווית?',
    sixthSectionFirstInputLabel:
      'אנחנו שואלים על עדים, כדי שנוכל לקבל תמונה מלאה ומפורטת על נסיבות הפגיעה שעברת.​ עדים יכולים להיות מי שראו את הפגיעה, או מי ששמעו ממך את תיאור המקרה בסמוך לה.​',
    sixthSectionFirstInputLabel2:
      'נציגים מיומנים שמטפלים בבקשה שלך ידאגו ליצור קשר עם העדים על פי הפירוט שנתת, ולבצע תשאול ברגישות הנדרשת. זה יעזור לנו לתת לך את השירות המתאים ביותר',
    sixthSectionFirstInputPlaceHolder: 'יש לסמן את האפשרות המתאימה מבחינתך',
    sixthSectionAddNewWitness: 'הוספת עד',
    sixthSectionWitnessTitle: 'פרטי העד',
    sixthSectionWitnessFirstInput: 'שם פרטי',
    sixthSectionWitnessSecondInput: 'שם משפחה',
    sixthSectionWitnessThirdInput: 'סוג הקשר עם העד',
    sixthSectionWitnessFourthInput: 'טלפון',
    sixthSectionWitnessFifthInput: 'תפקיד',
    sixthSectionWitnessSixthInput: 'אדם זה בתפקיד מסווג',
    sixthSectionWitnessCivilAddress: 'כתובת אזרחית',
    sixthSectionWitnessMilitaryMail: 'דואר צבאי',
    sixthSectionWitnessId: 'ת.ז',

    seventhSectionTitle: 'בדיקות, אבחונים וטיפולים רפואיים',
    seventhSectionFirstToggleButtonLabel:
      'האם עברת בדיקה, אבחון או טיפול רפואי כלשהו בעקבות הפגיעה? כולל מרפאות מקצועיות כגון מרפאת כאב, פיזיותרפיה ועוד (שדה חובה)',
    seventhSectionFirstToggleButtonRight: 'עברתי',
    seventhSectionFirstToggleButtonLeft: 'לא עברתי',
    seventhSectionAddFacilityTitle: 'הוספת מוסד רפואי',
    seventhSectionFacilityTitle: 'פרטי מוסד רפואי',
    seventhSectionFacilityFirstInput: 'שם המוסד הרפואי',
    seventhSectionFacilitySecondInput: 'תאריך הביקור',
    seventhSectionFacilityToggleButtonLabel: 'האם אושפזת?',
    seventhSectionFacilityToggleButtonRight: 'כן',
    seventhSectionFacilityToggleButtonLeft: 'לא',
    seventhSectionFacilitySummaryYes: 'כולל אשפוז',
    seventhSectionFacilitySummaryNo: 'ללא אשפוז',
    seventhSectionFacilityThirdInput: 'באיזו מחלקה?',
    seventhSectionFacilityFourthInput: 'תאריך תחילת האשפוז',
    seventhSectionFacilityFifthInput: 'תאריך סיום האשפוז',
    seventhSectionFacilitySixthInput: 'רופאה או רופא מטפלים',
    seventhSectionFacilityFileLabel: 'צירוף מסמך שחרור / סיכום רפואי (אם יש)',
    secondaryPaperFormTitle: 'בקשת הכרה בפגיעה נוספת',
    secondaryPaperFormSubTitle:
      'אם חווית פגיעה נוספת באירוע אחר: חבלה, מחלה או פגיעה נפשית, באפשרותך למלא את פרטי האירוע והפגיעה הנוספים.',
  },
  [DICTIONARY.EMPLOYMENT_AND_BANK_DETAILS]: {
    title: 'מצב תעסוקתי ופרטי חשבון',
    employmentStatusesTitle: 'מה המצב התעסוקתי שלך?',
    firstSectionTitle: 'מקום עבודה נוכחי',
    firstSectionTitle2: 'מקום עבודה קודם',
    firstSectionFirstInput: 'שם מקום העבודה',
    firstSectionSecondInput: 'כתובת מקום העבודה',
    firstSectionThirdInput: 'שם המעסיק או המעסיקה שלך',
    firstSectionFourthInput: 'מספר הטלפון שלו או שלה',
    firstSectionTelephoneNumber: 'מספר טלפון נייח',
    firstSectionFifthInput: 'התפקיד והמשימות שלך',
    firstSectionFifthInputNoJob: 'התפקיד והמשימות שביצעת',
    firstSectionFifthInputPlaceholder:
      'פה כדאי לתאר את סוג העבודה והמשימות שלך במקום זה. למשל: עריכת דין, נהיגה, הנהלת חשבונות, טיפול במחסן וכדומה',
    secondSectionTitle: 'פרטי חשבון בנק',
    secondSectionSubTitle:
      'אנחנו מבקשים את הפרטים האלו כדי שנוכל לבצע העברות אל חשבונך במידת הצורך.',
    secondSectionFirstInput: 'שם הבנק (שדה חובה)',
    secondSectionSecondInput: 'קוד הסניף (שדה חובה)',
    secondSectionThirdInput: 'מספר חשבון בנק (שדה חובה)',
    secondSectionExtraInfo: "יש לצרף צילום צ'ק או אישור ניהול חשבון ",
    secondSectionExtraInfoFileLabel: 'אישור ניהול חשבון',
    secondSectionFileTopic: 'הקלד כאן את הנושא הקובץ',
    accountNumberErrorMsg: 'מספר חשבון בנק לא תקין',
    notRecognized:
      'המידע שמוצג פה לגבי חשבון הבנק שלך, הוא המידע שמופיע אצלנו. אם מצאת טעות אפשר לעדכן דרך ',
    notRecognizedLink: 'טופס עדכון פרטי חשבון בנק',
  },
  [DICTIONARY.PERSONAL_DETAILS]: {
    title: 'פרטים אישיים',
    firstSectionFirstInput: 'שם פרטי (שדה חובה)',
    firstSectionSecondInput: 'שם משפחה (שדה חובה)',
    firstSectionThirdInput: 'מספר תעודת זהות (שדה חובה)',
    firstSectionFileUploadLabel: 'יש לצרף צילום תעודת זהות כולל ספח ',
    firstSectionFileUploadLabelNote:
      'חשוב: בתעודת זהות ביומטרית יש לצרף צילום של שני הצדדים.',
    firstSectionFileUploadText: 'צילום תעודת זהות כולל ספח',
    firstSectionToggleButtonLabel: 'מין (שדה חובה)',
    firstSectionFourthInput: 'מצב משפחתי (שדה חובה)',
    firstSectionFifthInput: 'תאריך לידה (שדה חובה)',
    firstSectionSixthInput: 'ארץ לידה (שדה חובה)',
    firstSectionSeventhInput: 'שנת עליה',
    secondSectionTitle: 'איך נוכל ליצור איתך קשר?',
    secondSectionFirstInput: 'מספר הטלפון שלך (שדה חובה)',
    secondSectionFirstInputPrefix: 'קידומת',
    secondSectionSecondInput: 'מספר טלפון נוסף',
    secondSectionThirdInput: 'דואר אלקטרוני (שדה חובה)',
    secondSectionNoEmail: 'אין לי כתובת דואר אלקטרוני',
    secondSectionThirdInputTextHelper:
      'לא חובה אבל מומלץ, כך נוכל לעדכן אותך בקלות בסטטוס הבקשה',
    thirdSectionTitle: 'כתובת מגורים כפי שמופיעה בתעודת הזהות (שדה חובה)',
    thirdSectionInfoText:
      'חשוב: אם יהיה שינוי בכתובת שלך, כדאי לעדכן אותנו, כך נוכל לשלוח אליך דואר רשום',
    thirdSectionToggleButtonLabel: 'מדינה',
    thirdSectionRightTabValue: 'כתובת',
    thirdSectionLeftTabValue: 'תא דואר',
    thirdSectionRightTabFirstInput: 'יישוב (שדה חובה)',
    thirdSectionRightTabSecondInput: 'רחוב (שדה חובה)',
    thirdSectionRightTabThirdInput: 'מספר (שדה חובה)',
    thirdSectionRightTabFourthInput: 'מספר דירה',
    thirdSectionRightTabFifthInput: 'מיקוד (שדה חובה)',
    thirdSectionRightTabFifthInfo: 'איתור מיקוד באתר דואר ישראל',
    thirdSectionLeftTabFirstInput: 'יישוב',
    thirdSectionLeftTabSecondInput: 'מספר תיבת דואר',
    thirdSectionLeftTabThirdInput: 'מיקוד (שדה חובה)',
    thirdSectionCheckboxLabel: 'הכתובת למשלוח דואר שונה מכתובת המגורים',
    thirdSectionMyResidentialLocationIsOutOfSeas: 'כתובת המגורים שלי בחו"ל',
    thirdSectionAbroadCountry: 'באיזו מדינה?',
    thirdSectionAbroadPOBox: 'מספר תיבת דואר',
    thirdSectionAbroadStreet: 'רחוב',
    thirdSectionAbroadNumHouse: 'מספר בית',
    thirdSectionAbroadCity: 'יישוב',
    thirdSectionAbroadZipCode: 'מיקוד',
    thirdSectionAddressForMailTitle: 'כתובת למשלוח דואר',

    fourthSectionTitle: 'קופת חולים נוכחית',
    fourthSectionSubTitle:
      'אנחנו פונים לקופות החולים כדי לקבל פירוט על המצב הרפואי שלך ואופן הטיפול בך',
    fourthSectionFirstInput: 'שם קופת חולים (שדה חובה)',
    fourthSectionSecondInput: 'שם רופא או רופאת משפחה',
    fifthSectionTitle: 'קופת חולים קודמת (אם קיימת)',
    fifthSectionSubTitle:
      'יש למלא פרטים של כל הקופות החולים אליהן השתייכת בעבר',
    fifthSectionFirdtInput: 'שם קופת חולים',
    fifthSectionSecondInput: 'שנת סיום החברות בקופה',
    fifthSectionAddNewHospital: 'הוספת קופת חולים קודמת',
    addressForMailDeliveryTitle: 'כתובת למשלוח דואר',
    addressForMailDeliveryInfo:
      'אם יהיה שינוי בכתובת, כדאי לעדכן אותנו דרך האזור האישי',
    addressForMailDeliveryToggleButton: 'מדינה',
    addressForMailDeliveryRightToggleButtonValue: 'ישראל',
    addressForMailDeliveryLeftToggleButtonValue: 'מדינה אחרת',

    deleteModalFirstParagraph: 'רק שניה בבקשה...',
    deleteModalSecondaryParagraph: 'בחרת למחוק את קופת חולים',
    deleteModalThirdParagraph:
      'אם השתייכת בעבר לקופה זו, חשוב לנו לדעת מזה כדי לספק לך טיפול מלא ויסודי',
    deleteModalConfirmTitle: 'האם לזה התכוונת?',
    deleteModalConfirmTitleGeneral: 'האם ברצונך למחוק פריט זה?',
    deleteModalCancelButton: 'לא למחוק',
    deleteModalConfirmButton: 'כן, אפשר למחוק',
    deleteModalCaseDetailsSecondLine: 'בחרת למחוק את פרטי הפגיעה',
    deleteModalCaseDetailsLine2: 'האם התכוונת למחוק',
    deleteModalCaseDetailsLine3: 'כל מידע על הפגיעה שחווית חשוב לנו.',
    deleteModalCaseDetailsLine4: 'ניתן לחזור לכאן בהמשך ולערוך את המידע שמסרת.',
    deleteModalCaseDetailsLastLine: 'האם לכך התכוונת?',

    deleteModalWitnessFirstLine: 'בחרת למחוק את פרטי העד',
    deleteModalWitnessSecondLine:
      'לצורך קבלת תמונה מלאה על מצבך ועל הפגיעה שחווית, כל עדות חשובה לנו.',
    deleteModalWitnessThirdLine:
      'את התשאול מבצע הצוות שלנו ברגישות ובמקצועיות המלאות.',
    deleteModalWitnessLastLine: 'האם לכך התכוונת?',
    notRecognized:
      'המידע שמוצג פה לגביך, הוא המידע שמופיע אצלנו. אם מצאת טעות אפשר לעדכן דרך ',
    notRecognizedLink: 'טופס עדכון פרטים אישיים',
  },
  [DICTIONARY.REPRESENTATION]: {
    title: 'סיוע וייצוג משפטי',
    firstSectionInputTextHelper:
      'הגשת הבקשה אינה מחייבת ייצוג משפטי, ובחירה בייצוג כזה לא משפיעה על הטיפול בבקשה שלך',
    firstSectionInputTextHelperTitle: 'סיוע של מרכז "בידיים טובות"',
    firstSectionInputTextHelperInfo:
      'חשוב: אם יש לך צורך בעזרה במילוי הטופס, מרכז "בידיים טובות" יעניק לך סיוע והכוונה ללא עלות, בהגשת הבקשה ובהכנה לוועדה הרפואית, בטלפון 3757*',
    firstSectionCheckboxLabel: 'נעזרתי במרכז "בידיים טובות" במילוי בקשה זו',
    firstSectionTitle: 'האם יש לך ייצוג משפטי? (שדה חובה)',
    firstSectionToggleButtonLabel: 'האם בחרת לקבל ייצוג משפטי? (שדה חובה)',
    firstSectionToggleButtonRightValue: 'כן',
    firstSectionToggleButtonLeftValue: 'לא',
    firstSectionThirdInputTextHelper: 'פרטי עורך או עורכת הדין',
    firstSectionFirstInput: 'שם פרטי',
    firstSectionSecondInput: 'שם משפחה',
    firstSectionThirdInput: 'כתובת',
    firstSectionFileUploadLabel: 'יש לצרף ייפוי כוח מעורך דין ',
    firstSectionFileUploadText: 'ייפוי כוח מעורך דין',
    firstSectionSecondFileUploadLabel: 'יש לצרף מכתב ייצוג מעורך דין',
    firstSectionSecondFileUploadText: 'ייצוג מעורך דין',
    firstSectionAssociationTitle: 'פרטי העמותה/הפקולטה',
    firstSectionAssociationName: 'שם העמותה/הפקולטה',
    firstSectionAssociationAddress: 'כתובת',
    firstSectionAssociationPhone: 'טלפון',
    firstSectionAssociationAgentName: 'שם נציג/נציגת העמותה',
    firstSectionAssociationAgentPhone: 'מספר טלפון נייד',
    firstSectionAssociationEmail: 'דואר אלקטרוני',

    secondSectionTitle: 'מינוי אפוטרופוס',
    secondSectionInfoLabel:
      'אפוטרופוס הוא אדם שממונה בצו בית משפט עבור מי שאינו מסוגל לדאוג לענייניו באופן עצמאי.',
    secondSectionToggleButtonLabel: 'האם מונה לך אפוטרופוס? (שדה חובה)',
    secondSectionToggleButtonRightValue: 'כן',
    secondSectionToggleButtonLeftValue: 'לא',
    secondSectionFirstInput: 'שם פרטי',
    secondSectionSecondInput: 'שם משפחה',
    secondSectionThirdInput: 'כתובת',
    secondSectionFifthInput: 'דואר אלקטרוני',
    secondSectionFourthInput: 'מספר טלפון נייד',
    secondSectionGuardianId: 'תעודת זהות',
    secondSectionFileUploadLabel: 'יש לצרף כתב מינוי אפוטרופסות ',
    secondSectionFileUploadText: 'כתב מינוי אפוטרופסות',

    thirdSectionTitle: 'ייצוג דרך עמותה או פקולטה למשפטים',
    thirdSectionInfoLabel: 'האם עמותה או פקולטה למשפטים מייצגת אותך?',
    thirdSectionToggleButtonLabel:
      'האם עמותה או פקולטה למשפטים מייצגת אותך? (שדה חובה)',
    thirdSectionToggleButtonRightValue: 'כן',
    thirdSectionToggleButtonLeftValue: 'לא',
    thirdSectionFileUploadLabel: 'יש לצרף ייפוי כח ',
    thirdSectionFileUploadText: 'ייפוי כח',
  },
  [DICTIONARY.WAIVER_OF_MEDICAL_CONFIDENTIALITY]: {
    title: 'סודיות רפואית',
    pageTitle: 'ויתור על סודיות רפואית',
    subTitle:
      'טופס הסכמה למסירת מידע רפואי או מידע אחר נוסף הדרוש לצורך בירור וטיפול בבקשתי להכרה כנכה צה"ל ומתן טיפול וויתור סודיות רפואית',
    subTitle2: 'יש לסמן שקראת והסכמת למה שכתוב מטה',
    firstSectionFirstParagraph: [
      'הנני, ',
      ' נותנ/ת בזאת רשות לכל העובדים בצוות רפואי ו/או מוסד רפואי לרבות קופות החולים, רופאיהן, עובדיהן, ו/או מי מטעמן ו/או לכל העובדים של המוסד לביטוח לאומי ו/או לצבא הגנה לישראל ו/או למשרד הביטחון ו/או למשטרת ישראל ו/או לשירות בתי הסוהר ו/או לחברות ביטוח ו/או למשרדי ממשלה ו/או לעיריות ולמוסדות מוניציפליים ו/או לכל העובדים בתחום הסוציאלי ו/או הסיעודי ו/או לגורמים מטפלים לרבות פסיכולוגים ו/או למוסדות שיקומיים לרבות בתים מאזנים או קהילות שיקום  (להלן: "המוסדות") למסור לאגף שיקום נכים במשרד הביטחון ולכל גורם החייב בהוצאותיי לרבות חברות הביטוח (להלן "המבקשים") או מי שמציג כתב הרשאה לפעול מטעמם של המבקשים לאסוף מידע כאמור במסמך זה, את כל הפרטים המצויים בידי המוסדות ללא יוצא מן הכלל ובאופן שידרשו המבקשים על מצבי הבריאותי ו/או הסוציאלי ו/או מצבי בתחום הסיעודי ו/או השיקומי ו/או כל מחלה שחליתי בה בעבר או שאני חולה בה כעת ו/או כל מידע אחר/נוסף שמצוי בידי מי מהגורמים אשר דרוש למשרד הביטחון על מנת לברר את בקשתי להכרה כנכה צה"ל. ',
    ],
    firstSectionSecondParagraph:
      'הנני נותנ/ת בזאת רשות לנציב קבילות החיילים או מי מטעמו, למסור לאגף שיקום נכים במשרד הביטחון את העתק הקבילה וכן מסמכים שצורפו אליה וכן כל מכתב נוסף או מסמכים נוספים שנמסרו לנציב קבילות החיילים, על-ידי או מטעמי במהלך הטיפול בקבילה וכן העתק תשובת הנציב לקבילתי. ',
    firstSectionThirdParagraph:
      'הנני משחרר/ת בזאת את כל המוסדות ו/או כל עובד מעובדיהם, לרבות קופות החולים ו/או כל רופא מרופאיהם ו/או כל עובד מעובדיהם ו/או כל מוסד ממוסדותיהם כולל בתי חולים כלליים ו/או פסיכיאטריים ו/או שיקומיים וכל סניף מסניפי מוסדותיהם - מחובת שמירה על סודיות בכל הנוגע למצבי הבריאותי ו/או הסוציאלי ו/או מצבי בתחום הסיעוד ו/או השיקומי ומתיר להם בזאת מתן כל מידע מכל תיק שנפתח על שמי במוסד, לרבות במוסד לביטוח לאומי ולרבות מידע על תשלומים ששילם לי המוסד לביטוח לאומי.',
    firstSectionFourthParagraph:
      'הנני נותנ/ת בזאת רשות לאגף שיקום נכים במשרד הביטחון או מי שהוסמך לכך מטעמו להעביר מידע על מצבי הבריאותי ו/או הסוציאלי ו/או מצבי בתחום הסיעודי ו/או השיקומי למוסדות רפואיים בקהילה לרבות קופות החולים ו/או לשירותי הרווחה ו/או למוסד לביטוח הלאומי, וזאת על מנת לאפשר רצף טיפולי בענייני. ',
    firstSectionFivthP:
      'הנני נותנ/ת בזאת רשות לאגף שיקום נכים במשרד הביטחון או מי שהוסמך לכך מטעמו להעביר מידע על מצבי הבריאותי ו/או הסוציאלי ו/או מצבי בתחום הסיעודי ו/או השיקומי למוסדות רפואיים בקהילה לרבות קופות החולים ו/או לשירותי הרווחה ו/או למוסד לביטוח הלאומי ולספקי טיפול של משרד הביטחון וזאת על מנת לאפשר רצף טיפולי בענייני.',
    firstSectionSixthP:
      'הנני נותנ/ת בזאת רשות לאגף שיקום נכים במשרד הביטחון או מי שהוסמך לכך מטעמו לברר את נכונות פרטי חשבון הבנק שלי (שם הבנק, מספר סניף, מספר חשבון) אותם ציינתי בבקשה להכרה כנכה צה"ל, על מנת שניתן יהיה להעביר לחשבון הבנק אותו ציינתי תשלומים להם אהיה זכאי, ככל שאהיה זכאי.',
    firstSectionSeventhP:
      'הנני מוותר/ת על סודיות כלפי המבקשים ולא תהא לי כל טענה או תביעה מסוג כלשהו בקשר למסירת המידע כאמור על ידי המוסדות המפורטים לעיל. בקשתי זו יפה גם לפי חוק הגנת הפרטיות התשמ"א-1981 והיא חלה על כל מידע רפואי או אחר המצוי במאגרי המידע של כל המוסדות לרבות קופות חולים ו/או רופאיהן ו/או עובדיהן ו/או מי מטעמם.',

    firstSectionCheckboxLabel: (
      firstName?: string,
      lastName?: string,
      identifier?: string
    ) =>
      `הנני ${firstName || ''} ${lastName || ''}, ת"ז ${
        identifier || ''
      }, הבנתי את הכתוב ומקובל עלי לוותר על הסודיות הרפואית מול גופים אלו, במסגרת הטיפול שלכם בתיק `,
    firstSectionCheckboxLabelPart2: '(שדה חובה)',
    firstSectionFifthParagraph:
      'מי ששירתו בצה"ל (בחובה, בקבע או במילואים) וחווים קושי בזמן המילוי - נשמח לעזור במוקד "קשר עד" בטלפון 1111 (שלוחה 6)',
    firstSectionFirstListItem: 'קופות חולים אליהן השתייכת',
    firstSectionSecondListItem:
      'מוסדות רפואיים שבהם טופלת (בתי חולים כללים, פסיכיאטריים או שיקומיים)',
    firstSectionThirdListItem: 'ביטוח לאומי',
    firstSectionFourthListItem:
      'צבא ההגנה לישראל / משטרת ישראל / שירות בתי הסוהר',
    firstSectionFifthListItem: 'הרשות הלאומית לבטיחות בדרכים',
  },
  [DICTIONARY.REQUEST_AND_EARLY_ACQUAINTANCE]: {
    title: 'רקע מול גורמים ממשלתיים',
    firstSectionTitle: 'בקשות מול ביטוח לאומי',
    firstSectionFirstInputTitle:
      'האם הגשת בקשה לביטוח לאומי באחד מהנושאים: נכות כללית, פגיעת עבודה ופגיעה בפעולת איבה? (שדה חובה)',
    firstSectionSecondInputTitle: 'האם הטיפול בבקשה שלך הסתיים? (שדה חובה)',
    firstSectionToggleButtonRightLabel: 'כן',
    firstSectionToggleButtonLeftLabel: 'לא',
    firstSectionCollapseInfo:
      'אם ביטוח לאומי קיבל את בקשתך והעביר לך קצבה במשך 3 שנים ומעלה, לא ניתן להגיש בקשה להכרה בנכות. אם עדיין לא עברו 3 שנים, נוכל לקבל את הבקשה שלך באותו עניין רפואי במקביל',
    firstSectionCollapseFirstInput: 'תאריך הגשת הבקשה',
    firstSectionCollapseSecondInput: 'סיבת הבקשה',
    firstSectionUploadFilesTitle: 'יש לצרף את המסמכים הבאים, אם הם ברשותך',
    firstSectionUploadFilesInfo1:
      'אם המסמכים לא ברשותך, כדאי לפנות באופן ישיר לביטוח לאומי: באזור האישי באתר, או בעזרת המוקד הטלפוני 6050*, זה יסייע לטיפול מהיר בבקשה שלך.',
    firstSectionUploadFilesInfo2:
      'אם אין באפשרותך לפנות לביטוח לאומי בעצמך, אנחנו נאתר עבורך את המסמכים אך רצוי להביא בחשבון שזה עלול לעכב את הטיפול בבקשה',
    firstSectionCollapseFirstFileUploadTitle: 'החלטת ביטוח לאומי (אם יש)',
    firstSectionCollapseFirstFileUploadLabel: 'החלטת ביטוח לאומי',
    firstSectionCollapseSecondFileUploadTitle:
      'פרוטוקול הוועדה הרפואית (אם יש)',
    firstSectionCollapseSecondFileUploadLabel: 'פרוטוקול הוועדה הרפואית',
    secondSectionTitle: 'קשר למשרד הבטחון',
    secondSectionSubTitle:
      'אנחנו שואלים זאת, כדי להבטיח טיפול אובייקטיבי, למנוע כפילות בהטבות ולקדם שקיפות',
    secondSectionInfo: 'חשוב: שירות בצה"ל אינו נחשב כעבודה במשרד הבטחון',
    secondSectionFirstInputTitle:
      'האם מקום עבודתך הוא משרד הביטחון? (שדה חובה)',
    secondSectionFirstInputRightLabel: 'כן',
    secondSectionFirstInputLeftLabel: 'לא',
    secondSectionFirstInputCollapseFirstInput: 'באיזה אגף? (שדה חובה)',
    secondSectionSecondInputTitle:
      'האם יש במשפחתך אדם שעובד במשרד הביטחון? (שדה חובה)',
    secondSectionSecondInputRightLabel: 'כן',
    secondSectionSecondInputLeftLabel: 'לא',
    secondSectionSecondInputCollapseFirstInput:
      'מה הקרבה המשפחתית ביניכם? (שדה חובה)',
  },
  [DICTIONARY.VERIFY_DETAILS]: {
    title: 'אימות ואישור הפרטים',
    firstSectionFirstParagraph: 'כמעט סיימת להגיש את הבקשה',
    firstSectionFirstTitle: 'חשוב לדעת',
    firstSectionSecondParagraph:
      'כל הפרטים שמילאת בטופס יילקחו בחשבון בתהליך קביעת הזכאות שלך, לכן חשוב שהמידע יהיה מדויק ומפורט',
    firstSectionCheckBoxLabel:
      'לפני הלחיצה על כפתור האישור והשליחה של הטופס אלינו, חשוב לנו לבקש ממך לוודא שכל הפרטים נכונים ושלא חסר מידע משמעותי',
    editRequest: 'חזרה לעריכה',
    buttonLabel: 'אישור ושליחה',
  },
  [DICTIONARY.SUCCESS_PAGE]: {
    title: 'הבקשה שלך הוגשה בהצלחה',
    formNumber: 'מספר הבקשה: ',
    formSubmissionDate: 'תאריך הגשת הבקשה: ',
    secondaryTitle: 'מה השלבים הבאים?',
    firstParagraph:
      'אנו נעבור על הפרטים שמסרת, ובמידת הצורך נבקש ממך להשלים מידע ולהגיע לבדיקה אצל רופא או רופאה מומחים.',
    secondParagraph:
      'אם השתחררת משירות חובה, מילואים או ממסגרת ביטחונית, אנו נשלח לך בתוך 14 יום אישור לקבלת טיפול רפואי עד לסיום הטיפול בבקשה שלך - ',
    secondParagraphTitle: 'למידע נוסף.',
    secondParagraphLink:
      'https://shikum.mod.gov.il/MedicalServices/Pages/zakaut.aspx',
    thirdParagraph: 'את כל הפרטים על תהליך הבדיקה והזכויות באגף השיקום, ',
    thirdParagraphTitle: 'ניתן למצוא באתר שלנו.',
    thirdParagraphLink: 'https://shikum.mod.gov.il/Pages/default.aspx',
    buttonLabel: 'צפייה בסטטוס הבקשה',
    additionalRequest: 'רוצה להגיש בקשה נוספת?',
    additionalRequestLabel: 'הגשת בקשה חדשה',
  },
  [DICTIONARY.GENERAL]: {
    required: '(שדה חובה)',
    filePreviewLabel: 'צפייה בקובץ המסכם',
    fileEditLabel: 'עריכת הטופס המקוון המסכם',
    fileUploadLabel: 'העלאת קובץ',
    formAutoSaveText:
      'הפרטים נשמרים אוטומטית. ניתן לעזוב ולחזור לכאן כשנוח לך, באמצעות הזדהות',
    goBackToTheMainScreen: 'שמירה ויציאה',
    finishStep: 'סיימתי למלא את השלב',
    delete: 'מחיקה',
    watch: 'צפייה',
    returnToTheMainPage: 'שמירה וחזרה למסך הראשי',
    noOptions: 'לא קיימת אופציה כזאת',
    addAdditionalFile: 'צירוף קובץ נוסף',
    fileError: 'קובץ לא תקין',
    fileErrorNew:
      'הקובץ לא תקין. אפשר לצרף קובץ עד משקל 20Mb עם אחת מהסיומות: doc, docx, jpg, png, pdf, tif',
    fileIsTooBig: 'קובץ גדול מידי',
    errorDeleteMsg: 'שגיאה במחיקת הקובץ',
    close: 'סגירה',
    open: 'פתיחה',
    select: 'בחירה',
    errors: {
      requiredField: 'שדה חובה',
      invalidName: 'שם לא תקין',
      invalidID: 'תעודת זהות לא תקינה',
      invalidPhone: 'מספר לא תקין',
      invalidEmail: 'אימייל לא תקין',
      invalidDate: 'תאריך לא חוקי',
    },
  },
  [DICTIONARY.STATUS_REQUEST_PAGE]: {
    title: 'בקשה להכרה',
    numOfRequest: 'מספר הבקשה',
    requestDate: 'תאריך הגשת הבקשה',
    firstCard: 'הבקשה הוגשה',
    secondCard: 'בבדיקה ביחידה להכרה',
    thirdCard: 'פתיחת תיק',
    info1: ' לאחר פתיחת התיק המשך המעקב לגבי הסטטוס יתבצע',
    info1Link: 'באזור האישי',
    info2: 'טקסט הסבר',
  },
  [DICTIONARY.CONTACT_US]: {
    alt: 'פתיחת דף מידע עם דרכי יצירת קשר ועזרה במילוי הטופס',
    title: 'אנחנו פה בשבילך',
    firstSectionText: 'אם יש לך צורך בעזרה במהלך מילוי הטופס, נשמח לעזור​',
    itemGoodHandsTitle: 'מרכז בידיים טובות - בטלפון 3757*',
    itemGoodHands:
      'מעניק סיוע והכוונה ללא עלות, בהגשת הבקשה ובהכנה לוועדה הרפואית בזכות צוות רפואי מומחה​',
    itemShikumTitle: 'אגף השיקום - במספר הטלפון​',
    itemShikumHelper: '(שלוחה 7, לפניות בנושא תביעות)',
    itemShikumPhoneNumber: '8150*',
    itemShikum: 'מוקד טלפוני ארצי שמטפל בכל שלבי ההכרה בנכות במשרד הביטחון​',
    secondSectionText: 'כמו כן, ניתן לפנות לגורמי הסיוע בהתאם לסוג השירות שלך:',
    itemIDF:
      'צה"ל - מוקד "קשר עד" של מערך הנפגעים בצה"ל, שמספרו 1111 (שלוחה 6)',
    itemPolice: 'משטרת ישראל - מדור נפגעים שמספרו 02-5428584 או 02-5428764',
    itemShabas: 'שירות בתי הסוהר - מערך הנפגעים שמספרו 074-7830194',
  },
  [DICTIONARY.NOT_FOUND]: {
    title: 'סליחה, העמוד שחיפשת לא נמצא',
    subTitle: 'כנראה שיש בעיה טכנית להציג אותו, או שהוא הוסר מהאתר',
    buttonLabel: 'חזרה למקום בו היית',
    info1: 'הבעיה הזו מונעת ממך להתקדם בתהליך?',
    info2: 'נשמח לעזור במוקד אגף שיקום נכים 8150*',
  },
  [DICTIONARY.DOUBLE_DOC]: {
    title: 'הטופס הדיגיטלי מאפשר להגיש בקשות חדשות בלבד​',
    subTitle1: '​ זיהינו שהגשת בעבר בקשה להכרה בנכות.',
    subTitle2:
      'אם ברצונך להגיש בקשה נוספת, ניתן לעשות זאת כרגע באמצעות טופס ידני בלבד.',
    helpTitle: 'נשמח לעזור',
    helpInfo1: 'ניתן להגיש בקשה חדשה ביחידת התביעות, בטלפון 8150* (שלוחה 7)',
    link: 'למידע נוסף בנושא בקשה להכרה',
    enterPersonalService: 'כניסה לאיזור האישי​',
  },
  [DICTIONARY.AUTH_ISSUE]: {
    title: 'אחד מפרטי ההזדהות לא נכונים',
    subTitle1:
      '​ לא ניתן להמשיך את התהליך. לצורך שמירה על פרטיות המידע שלך כדאי לפנות למוקד בטלפון 8150*',
    back: 'חזרה',
  },
  [DICTIONARY.EMERGENCY_FORM]: {
    title: 'טופס הכרה מהיר',
    subtitle: 'מסלול מהיר - הכרה בנכות מלחמת חרבות ברזל',
    info: 'אגף השיקום במשרד הביטחון ערוך לתת מענה לכל אנשי צה"ל וכוחות הביטחון, שנפגעו במלחמת חרבות ברזל - באמצעות פתיחת מסלול מהיר להגשת בקשה להכרה בנכות',
    medicalConfidentiality: 'ויתור על סודיות רפואית',
    medicalConfidentialitySubtitle:
      'טופס הסכמה למסירת מידע רפואי או מידע אחר נוסף הדרוש לצורך בירור וטיפול בבקשתי להכרה כנכה צה"ל ומתן טיפול וויתור סודיות רפואית - מלחמת "חרבות ברזל"',
    medicalConfidentialityPart1:
      'הנני נותנ/ת בזאת רשות לכל העובדים בצוות רפואי ו/או מוסד רפואי לרבות קופות החולים, רופאיהן, עובדיהן, ו/או מי מטעמן ו/או לכל העובדים של המוסד לביטוח לאומי ו/או לצבא הגנה לישראל ו/או למשרד הביטחון ו/או למשטרת ישראל ו/או לשירות בתי הסוהר ו/או לחברות ביטוח ו/או למשרדי ממשלה ו/או לעיריות ולמוסדות מוניציפליים ו/או לכל העובדים בתחום הסוציאלי ו/או הסיעודי ו/או לגורמים מטפלים לרבות פסיכולוגים ו/או למוסדות שיקומיים לרבות בתים מאזנים או קהילות שיקום  (להלן: "המוסדות") למסור לאגף שיקום נכים במשרד הביטחון ולכל גורם החייב בהוצאותיי לרבות חברות הביטוח (להלן "המבקשים") או מי שמציג כתב הרשאה לפעול מטעמם של המבקשים לאסוף מידע כאמור במסמך זה, את כל הפרטים המצויים בידי המוסדות ללא יוצא מן הכלל ובאופן שידרשו המבקשים על מצבי הבריאותי ו/או הסוציאלי ו/או מצבי בתחום הסיעודי ו/או השיקומי ו/או כל מחלה שחליתי בה בעבר או שאני חולה בה כעת ו/או כל מידע אחר/נוסף שמצוי בידי מי מהגורמים אשר דרוש למשרד הביטחון על מנת לברר את בקשתי להכרה כנכה צה"ל.',
    medicalConfidentialityPart2:
      'הנני נותנ/ת בזאת רשות לנציב קבילות החיילים או מי מטעמו, למסור לאגף שיקום נכים במשרד הביטחון את העתק הקבילה וכן מסמכים שצורפו אליה וכן כל מכתב נוסף או מסמכים נוספים שנמסרו לנציב קבילות החיילים, על-ידי או מטעמי במהלך הטיפול בקבילה וכן העתק תשובת הנציב לקבילתי. ',
    medicalConfidentialityPart3:
      'הנני משחרר/ת בזאת את כל המוסדות ו/או כל עובד מעובדיהם, לרבות קופות החולים ו/או כל רופא מרופאיהם ו/או כל עובד מעובדיהם ו/או כל מוסד ממוסדותיהם כולל בתי חולים כלליים ו/או פסיכיאטריים ו/או שיקומיים וכל סניף מסניפי מוסדותיהם - מחובת שמירה על סודיות בכל הנוגע למצבי הבריאותי ו/או הסוציאלי ו/או מצבי בתחום הסיעוד ו/או השיקומי ומתיר להם בזאת מתן כל מידע מכל תיק שנפתח על שמי במוסד, לרבות במוסד לביטוח לאומי ולרבות מידע על תשלומים ששילם לי המוסד לביטוח לאומי.',
    medicalConfidentialityPart4:
      'הנני נותנ/ת בזאת רשות לאגף שיקום נכים במשרד הביטחון או מי שהוסמך לכך מטעמו להעביר מידע על מצבי הבריאותי ו/או הסוציאלי ו/או מצבי בתחום הסיעודי ו/או השיקומי למוסדות רפואיים בקהילה לרבות קופות החולים ו/או לשירותי הרווחה ו/או למוסד לביטוח הלאומי, וזאת על מנת לאפשר רצף טיפולי בענייני. ',
    medicalConfidentialityPart5:
      'הנני נותנ/ת בזאת רשות לאגף שיקום נכים במשרד הביטחון או מי שהוסמך לכך מטעמו להעביר מידע על מצבי הבריאותי ו/או הסוציאלי ו/או מצבי בתחום הסיעודי ו/או השיקומי למוסדות רפואיים בקהילה לרבות קופות החולים ו/או לשירותי הרווחה ו/או למוסד לביטוח הלאומי ולספקי טיפול של משרד הביטחון וזאת על מנת לאפשר רצף טיפולי בענייני.',
    medicalConfidentialityPart6:
      'הנני נותנ/ת בזאת רשות לאגף שיקום נכים במשרד הביטחון או מי שהוסמך לכך מטעמו לברר את נכונות פרטי חשבון הבנק שלי (שם הבנק, מספר סניף, מספר חשבון) אותם ציינתי בבקשה להכרה כנכה צה"ל, על מנת שניתן יהיה להעביר לחשבון הבנק אותו ציינתי תשלומים להם אהיה זכאי, ככל שאהיה זכאי.',
    medicalConfidentialityPart7:
      'הנני מוותר/ת על סודיות כלפי המבקשים ולא תהא לי כל טענה או תביעה מסוג כלשהו בקשר למסירת המידע כאמור על ידי המוסדות המפורטים לעיל. בקשתי זו יפה גם לפי חוק הגנת הפרטיות התשמ"א-1981 והיא חלה על כל מידע רפואי או אחר המצוי במאגרי המידע של כל המוסדות לרבות קופות חולים ו/או רופאיהן ו/או עובדיהן ו/או מי מטעמם.',

    medicalConfidentialitySign: (
      firstName?: string,
      lastName?: string,
      identifier?: string
    ) =>
      `הנני ${firstName || ''} ${lastName || ''}, תעודת זהות ${
        identifier || ''
      }, הבנתי את הכתוב ומקובל עלי לוותר על הסודיות הרפואית מול גופים אלו, במסגרת הטיפול שלכם בתיק `,
    isCanNotSignLabel: (
      firstName?: string,
      lastName?: string,
      identifier?: string,
      signerFullName?: string,
      signerId?: string
    ) =>
      `אני ${signerFullName || ''}, תעודת זהות ${
        signerId || ''
      }, אחתום עבור הפצוע/ה ${firstName || ''} ${
        lastName || ''
      }, תעודת זהות ${identifier}  ומקובל עלינו לוותר על הסודיות הרפואית מול גופים אלו, במסגרת הטיפול שלכם בתיק `,
    successForm: 'הטופס נשלח בהצלחה',
    personalDetails: 'פרטים אישיים - מתוך רישומי הגוף הביטחוני',
    addressDetails: 'כתובת מגורים',
    contactDetails: 'פרטי התקשרות',
    militaryDetails: 'פרטי השירות הצבאי/הביטחוני - מתוך רישומי הגוף הביטחוני',
    injuryDetails: 'תיאור הפציעה (מתוך דוח סיכום רפואי)',
    injuryDetailsInfo: 'תאור נסיבות הפציעה והאיברים שנפצעו',
    injuryDetailsPlaceholder:
      'זה המקום לפרט את כל מה שחשוב לך שנדע בקשר לפציעה והאיברים שנפגעו',
    attachedMedicalDocs: '*יש לצרף דו"ח אשפוז/ מסמכים רפואיים',
    bankDetailsInfo:
      'אנחנו מבקשים את הפרטים האלו כדי שנוכל לבצע העברות שונות (כמו החזרי נסיעות) אל חשבונך, במידת הצורך',
    attchedBankDocs:
      '*מומלץ לצרף צילום המחאה ריקה או צילום כרטיס אשראי או אישור ניהול חשבון (ניתן להוציא מהאפליקציה)',
    familyContactDetails: 'איש קשר מקרבה ראשונה לעדכונים',
    fillerContactDetails: 'פרטי המקשר/קצין הנפגעים/רם 2 (הממלא את הטופס)',
    checkboxNote: 'יש לציין את פרטי החותם בשם מבקש ההכרה:',
    limitNote: 'ניתן להקליד עד 500 תווים',
    isRequired: '(חובה)',
    firstName: 'שם פרטי',
    lastName: 'שם משפחה',
    fullName: 'שם מלא (חובה)',
    idNumber: 'תעודת זהות (חובה)',
    gender: 'מין (חובה)',
    isHospitalization: 'האם הפצוע/ה מאושפז/ת בבית חולים?',
    yes: 'כן',
    no: 'לא',
    signerLabel: 'חתימה במקום הפצוע/ה',
    militaryId: 'מספר אישי בצה"ל',
    birthDate: 'תאריך לידה (חובה)',
    unitName: 'שם היחידה',
    role: 'תפקיד',
    city: 'יישוב (חובה)',
    relation: 'קרבה למבקש ההכרה',
    phoneNumber: 'מספר טלפון נייד',
    extraPhone: 'מספר טלפון נוסף (בית)',
    serviceType: 'סוג השירות',
    street: 'רחוב (חובה)',
    injuryDate: 'תאריך קרות הפציעה',
    fathersName: 'שם האב',
    address: 'כתובת מגורים',
    maritalStatusId: 'מצב משפחתי (חובה)',
    addressInfo: 'עיר, רחוב, מספר בית',
    description: 'פירוט הפציעה',
    descriptionInfo: 'האברים שנפגעו. חובה לצרף דו"ח אשפוז/מסמכים רפואיים',
    email: 'דואר אלקטרוני',
    notes:
      'מידע נוסף שחשוב שאגף השיקום ידע על מבקש/ת ההכרה ועל מצב/ה האישי/רפואי',
    injuryLevel: 'רמת הפציעה לאחר קביעת גורם רפואי מוסמך',
    injuryLevelLabel: 'רמת הפציעה (חובה)',
    bankDetails: 'פרטי חשבון בנק',
    bank: 'שם הבנק (חובה)',
    branch: 'קוד הסניף (חובה)',
    houseNumber: 'מספר בית',
    apartmentNumber: 'מספר דירה',
    accountNumber: 'מספר חשבון בנק (חובה) ',
    familyContact: 'איש קשר מקרבה ראשונה לעדכונים',
    familyContactName: 'שם',
    familyContactLastName: 'משפחה',
    familyContactPhone: 'טלפון/נייד',
    fillerDetails: 'פרטי המקשר/קצין הנפגעים/רם 2',
    fillerInfo: 'הממלא את הטופס',
    fillerName: 'שם',
    fillerLastName: 'משפחה',
    fillerPhone: 'טלפון/נייד',
    zipCode: 'מיקוד',
    submit: 'שליחה',
    continue: 'המשך',
    back: 'חזרה',
    canNotSign:
      'אני אחתום עבור הפצוע/ה, ויש לי קרבה משפחתית אליו/ה, או שטיפלתי בו/ה (קציני רפואה/רופאים), בשל מצב רפואי שלא מאפשר חתימה',
    loginTitle: 'הנחיות למקשר, רם 2, קצין נפגעים',
    loginInfo:
      'אגף השיקום במשרד הביטחון ערוך לתת מענה לכל אנשי צה"ל וכוחות הביטחון, שנפגעו במלחמת חרבות ברזל - באמצעות פתיחת מסלול מהיר להגשת בקשה להכרה בנכות',
    info1Label: 'לפני ההגעה לבית החולים',
    info1:
      'חשוב למלא את כל המידע בטופס זה עבור הפצוע, בהתאם למידע המצוי ברישומי צה"ל / הגופים הביטחוניים.',
    info2Label: 'בית החולים',
    info2:
      'יש למלא את טופס הבקשה עבור הפצוע ולהקפיד לצרף את כל המסמכים הנדרשים',
    li1: 'חובה לצרף דוח אשפוז ומסמכים רפואיים.',
    li2: 'חשוב לרשום פרטי חשבון בנק.',
    li3: 'חובה לצרף טופס ויתור סודיות רפואית.',
    enterPassword: 'הקלדת סיסמא',
    successTitle: 'הבקשה נקלטה בהצלחה',
    successSubtitle: 'הבקשה להכרה בגין פציעה במלחמת “חרבות ברזל" נקלטה בהצלחה',
    successSendToEmail: 'בשעות הקרובות יישלח סיכום לכתובת הדוא”ל:',
    andNumber: ' ומספרה ',
    requestDate: 'תאריך הבקשה:',
    returnToStart: 'הגשת בקשה נוספת',
    requestNumber: 'מספר הבקשה:',
    finish: 'סיום',
    error400: 'שגיאת בקשה - הטופס לא נשמר',
    error401: 'פג תוקף ההזדהות - הטופס לא נשמר',
  },

  [DICTIONARY.LOGIN]: {
    title: 'שלחו לי קוד אימות',
    handicappedIsrId: 'מספר הזהות של הפצוע/פצועה (שדה חובה)',
    formFillerIsrId: 'מספר הזהות שלך (שדה חובה)',
    phone: 'מספר הטלפון שלך (שדה חובה)',
    phonePrefix: 'קידומת',
    password: 'סיסמא',
    phoneEmailValidation: {
      title: 'אימות פרטי ההתקשרות שלך',
      subTitle: {
        lineOne: 'כדי לוודא שמדובר בך,',
        lineTwo: 'נצטרך לאמת את פרטי ההתקשרות, ולשלוח קוד חד פעמי',
      },
      sendCodeTo: 'שלחו לי קוד ל:',
      missingMail: [
        'נראה שלא הזנת דואר אלקטרוני.',
        'כדי לקבל את הקוד בדואר אלקטרוני, יש לחזור לשלב הקודם ולהקליד את הפרטים',
      ],
      willBeSentTo: 'הקוד ישלח ל   ',
      phone: 'נייד',
      email: 'דואר אלקטרוני',
      backwards: 'חזרה',
      forward: 'שליחה',
      whatCode: 'הקלדת קוד אימות',
      codeTimer: 'הקוד תקף ל5 דקות',
      didntGetCode: 'לא קיבלתי קוד, ',
      sendAgain: 'שלחו שוב',
      wrongCode: 'הקוד שהוקלד לא נכון',
      blocked: 'יותר מדי ניסיונות כושלים, אנא נסה שוב עם קוד חדש',
      codeSentAgain: 'הקוד נשלח מחדש',
      autoCompleteFail: 'השלמה אוטומטית נכשלה, אנא הקלד את הקוד באופן ידני',
      otpCode: 'קוד חד פעמי 4 ספרות',
    },
  },
};
