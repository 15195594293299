import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { getRepresentation, IRepresentation, putRepresentation } from 'API';
import {
  FormAutoSaveTypography,
  FormPaper,
  FormTitle,
  HomePageFormLink,
} from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { RoutesConfig } from 'Routes';
import { debounce, files, initFocus } from 'Utils';
import { FirstSection } from './FirstSection';
import { representationSchema } from './Schema';
import { SecondSection } from './SecondSection';

type Inputs = IRepresentation;
export const Representation = () => {
  const methods = useForm<Inputs>({
    mode: 'onTouched',
    resolver: yupResolver(representationSchema),
    defaultValues: {
      associationSection: {
        associationName: undefined,
        representativeName: undefined,
        address: undefined,
        mobilePhonePrefixRelationId: undefined,
        mobilePhone: undefined,
        phonePrefixRelationId: undefined,
        phone: undefined,
        mail: undefined,
      },
      guardianSection: {
        address: undefined,
        firstName: undefined,
        idNumber: undefined,
        lastName: undefined,
        mail: undefined,
        phone: undefined,
        phonePrefixRelationId: undefined,
      },
      isRepresentedByGuardian: undefined,
      isAssistedWithGoodHandsCenter: undefined,
      representationOptionRelationId: undefined,
      lawyerSection: {
        address: undefined,
        firstName: undefined,
        lastName: undefined,
      },
    },
  });

  const navigate = useNavigate();

  const forceSubmit = async () => {
    const values = methods.getValues();
    await putRepresentation(values);
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await forceSubmit();
    const result = await files();

    if (result) {
      navigate(RoutesConfig.STATUS_LOBBY.PATH);
    }
  };

  const initFlag = useRef<boolean>(true);

  const initForm = async () => {
    initFlag.current = true;
    const representation = await getRepresentation();
    if (representation) {
      methods.reset({ ...representation });
      initFlag.current = false;
    }
  };

  useEffect(() => {
    initFocus();
    initForm();
  }, []);

  /* Creating a ref object that will be used to store the id of the timeout. */
  const id = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    const subscription = methods.watch(async (data) => {
      if (initFlag.current) return;
      if (!methods.formState.errors) return;
      debounce(id, putRepresentation, data);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [methods.watch]);

  return (
    <>
      <Helmet>
        <title>
          {Dictionary[DICTIONARY.HEADER].title +
            ' - ' +
            Dictionary[DICTIONARY.REPRESENTATION].title}
        </title>
      </Helmet>
      <HomePageFormLink save={forceSubmit} />
      <Stack alignItems='center' component={'main'} role={'main'}>
        <FormTitle title={Dictionary[DICTIONARY.REPRESENTATION].title} />
        <FormPaper>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <FirstSection />
              <SecondSection />
              <FormAutoSaveTypography save={forceSubmit} />
            </form>
          </FormProvider>
        </FormPaper>
      </Stack>
    </>
  );
};
