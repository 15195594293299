import {
  AppBar,
  Avatar,
  Link,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ContactUs } from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { Logo, MobileLogo, ContactUsIcon } from 'Images';
import { useState } from 'react';

export const Header = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const [showContactUsModal, setShowContactUsModal] = useState<boolean>(false);

  const openModalHandler = () => {
    setShowContactUsModal(true);
  };

  const closeModalHandler = () => {
    setShowContactUsModal(false);
  };

  return (
    <>
      <AppBar
        sx={{
          borderRadius: (theme) => theme.spacing(0, 0, 0, 3),
          [theme.breakpoints.up('sm')]: {
            borderRadius: (theme) => theme.spacing(0, 0, 3, 3),
          },
          position: isDesktop ? 'sticky' : 'relative',
        }}
      >
        <Toolbar>
          <Link
            href={'https://shikum.mod.gov.il/'}
            aria-label={'כניסה לאתר השיקום'}
          >
            <Avatar
              src={Logo}
              sx={
                isDesktop
                  ? { width: '65px', height: '71px' }
                  : { width: '54px', height: '49px' }
              }
              alt='אגף שיקום נכים - מקום לגאווה'
              imgProps={{
                style: {
                  objectFit: 'contain',
                },
              }}
            />
          </Link>
          <Typography
            textAlign='center'
            sx={{
              fontWeight: 'bold',
              fontSize: isDesktop ? '35px' : '21px',
              width: isDesktop ? '30ch' : '12ch',
            }}
            variant='h1'
          >
            {Dictionary[DICTIONARY.HEADER].title}
          </Typography>
          <Avatar
            onClick={openModalHandler}
            src={ContactUsIcon}
            sx={
              isDesktop
                ? { cursor: 'pointer', width: '32px', height: '32px' }
                : {
                    cursor: 'pointer',
                    width: '25px',
                    height: '25px',
                    marginLeft: 'auto',
                  }
            }
            alt={Dictionary[DICTIONARY.CONTACT_US].alt}
          />
        </Toolbar>
      </AppBar>
      {showContactUsModal && (
        <ContactUs
          isVisible={showContactUsModal}
          closeModal={closeModalHandler}
        />
      )}
    </>
  );
};
