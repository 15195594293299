const numberCodes = {
  zero: 48,
  nine: 57,
  numZero: 96,
  numNine: 105,
};

export const validateNumbersOnly = (event: React.KeyboardEvent) => {
  const key = window.event ? event.keyCode : event.which;
  const ctrOrCmdKey = event.ctrlKey || event.metaKey;
  if (
    event.key === 'Backspace' ||
    event.key === 'Delete' ||
    event.key === 'ArrowLeft' ||
    event.key === 'ArrowRight' ||
    event.key === 'Tab' ||
    // Allow cut, copy, paste
    (ctrOrCmdKey && event.key === 'x') ||
    (ctrOrCmdKey && event.key === 'c') ||
    (ctrOrCmdKey && event.key === 'v')
  ) {
    return true;
  } else if (
    event.shiftKey ||
    key < numberCodes.zero ||
    (key > numberCodes.nine && key < numberCodes.numZero) ||
    key > numberCodes.numNine
  ) {
    // Avoid typing if shift is held
    // or if key is not a number between 0 to 9
    // or a num pad number
    event.preventDefault();
  } else return true;
};
