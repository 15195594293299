import { Stack, TextField, Typography } from '@mui/material';
import { IRequestAndEarlyAcquaintance } from 'API';
import {
  CustomToggleGroup,
  FormSection,
  InfoText,
  RadioButtons,
} from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { useFormContext, useWatch } from 'react-hook-form';
import { generateError } from 'Regex';
import { validateLettersAndNumbersOnly } from 'Utils/validateLettersAndNumbersOnly';
export const SecondSection = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<IRequestAndEarlyAcquaintance>();

  const isMinistryOfDefenceEmployee = useWatch({
    name: 'ministryOfDefenceSection.isMinistryOfDefenceEmployee',
    defaultValue: false,
  }) as boolean;

  const isThereRelativeInMinistryOfDefense = useWatch({
    name: 'ministryOfDefenceSection.isThereRelativeInMinistryOfDefense',
    defaultValue: false,
  }) as boolean;

  const dictionary = Dictionary[DICTIONARY.REQUEST_AND_EARLY_ACQUAINTANCE];
  return (
    <FormSection>
      <Stack gap={0.5}>
        <Typography
          sx={{ fontWeight: 'bold', fontSize: '16px!important' }}
          variant={'h2'}
        >
          {dictionary.secondSectionTitle}
        </Typography>
        <Typography variant='subtitle1' component={'p'}>
          {dictionary.secondSectionSubTitle}
        </Typography>
      </Stack>
      <RadioButtons
        name='ministryOfDefenceSection.isMinistryOfDefenceEmployee'
        options={[
          {
            value: 'true',
            label: dictionary.secondSectionFirstInputRightLabel,
          },
          {
            value: 'false',
            label: dictionary.secondSectionFirstInputLeftLabel,
          },
        ]}
        id={dictionary.secondSectionFirstInputTitle}
        infoText={dictionary.secondSectionInfo}
      />
      {isMinistryOfDefenceEmployee && (
        <TextField
          role={
            Boolean(errors.ministryOfDefenceSection?.workplace)
              ? 'alert'
              : undefined
          }
          inputProps={{
            maxLength: '50',
          }}
          id={dictionary.secondSectionFirstInputCollapseFirstInput}
          {...register('ministryOfDefenceSection.workplace')}
          error={Boolean(errors.ministryOfDefenceSection?.workplace)}
          helperText={
            Boolean(errors.ministryOfDefenceSection?.workplace?.message) &&
            generateError(
              dictionary.secondSectionFirstInputCollapseFirstInput,
              errors.ministryOfDefenceSection?.workplace?.message!
            )
          }
          label={dictionary.secondSectionFirstInputCollapseFirstInput}
          onChange={(event: any) => validateLettersAndNumbersOnly(event)}
        />
      )}
      <RadioButtons
        name='ministryOfDefenceSection.isThereRelativeInMinistryOfDefense'
        options={[
          {
            value: 'true',
            label: dictionary.secondSectionFirstInputRightLabel,
          },
          {
            value: 'false',
            label: dictionary.secondSectionFirstInputLeftLabel,
          },
        ]}
        id={dictionary.secondSectionSecondInputTitle}
      />
      {isThereRelativeInMinistryOfDefense && (
        <TextField
          role={
            Boolean(errors.ministryOfDefenceSection?.relativeDetail)
              ? 'alert'
              : undefined
          }
          inputProps={{
            maxLength: '50',
          }}
          id={dictionary.secondSectionSecondInputCollapseFirstInput}
          {...register('ministryOfDefenceSection.relativeDetail')}
          error={Boolean(errors.ministryOfDefenceSection?.relativeDetail)}
          helperText={
            Boolean(errors.ministryOfDefenceSection?.relativeDetail?.message) &&
            generateError(
              dictionary.secondSectionSecondInputCollapseFirstInput,
              errors.ministryOfDefenceSection?.relativeDetail?.message!
            )
          }
          label={dictionary.secondSectionSecondInputCollapseFirstInput}
          onChange={(event: any) => validateLettersAndNumbersOnly(event)}
        />
      )}
    </FormSection>
  );
};
