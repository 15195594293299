import { Avatar, Button, Divider, Stack, Typography } from '@mui/material';
import { FormSection } from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { Vi } from 'Images';
import { format } from 'date-fns';
import { useIsDesktop } from 'Hooks';
import { IPostEmergencyFormResponse } from 'API/EmergencyForm';
import { useContext } from 'react';
import { OTPLoginContext } from 'Context/OTPLoginContext';

interface ISuccess {
  response: IPostEmergencyFormResponse;
  email?: string;
}

export const Success = ({ response, email }: ISuccess) => {
  const isDesktop = useIsDesktop();
  const dictionary = Dictionary[DICTIONARY.EMERGENCY_FORM];
  const requestDate = format(new Date(), 'dd.MM.yy');
  const { loginDetails } = useContext(OTPLoginContext);

  return (
    <FormSection>
      <Avatar
        alt=''
        src={Vi}
        sx={{
          height: isDesktop ? '120px' : '100px',
          width: isDesktop ? '120px' : '100px',
          border: '5px solid white',
          borderRadius: '50%',
          m: '0 auto',
        }}
      />
      <Stack
        maxWidth={'600px'}
        sx={{
          backgroundColor: '#fff',
          p: '24px 14px',
          borderRadius: '15px',
          boxShadow: '0px 3px 21px 0px rgba(45, 112, 217, 0.15)',
          m: '0 auto',
        }}
      >
        <Typography
          variant='h1'
          sx={{
            padding: 0,
            textAlign: 'center',
            mb: 2,
            fontWeight: 'bold',
          }}
        >
          {dictionary.successTitle}
        </Typography>

        <Typography
          component={'h2'}
          sx={{
            fontSize: '18px',
            maxWidth: '360px',
            textAlign: 'center',
            m: '0 auto',
          }}
        >
          {dictionary.successSubtitle}
          {loginDetails.emergencyFormId && !email && (
            <>
              {dictionary.andNumber}{' '}
              <strong>{loginDetails.emergencyFormId}</strong>
            </>
          )}
        </Typography>

        {email && (
          <Stack sx={{ textAlign: 'center', m: '24px 0' }}>
            <Typography component={'h3'} sx={{ fontSize: '18px' }}>
              {`${dictionary.successSendToEmail} `}
            </Typography>
            <Typography component={'h3'} sx={{ fontSize: '18px' }}>
              {email}
            </Typography>
          </Stack>
        )}

        {email && loginDetails.emergencyFormId && (
          <Typography
            textAlign='center'
            component={'h3'}
            sx={{ fontSize: '18px', mt: 1 }}
          >
            {`${dictionary.requestNumber} `}
            <span style={{ fontWeight: 'bold' }}>
              {loginDetails.emergencyFormId}
            </span>
          </Typography>
        )}
        <Typography
          textAlign='center'
          component={'h3'}
          sx={{ fontSize: '18px', mt: email ? undefined : 2 }}
        >
          {`${dictionary.requestDate} `}
          <span style={{ fontWeight: 'bold' }}>{requestDate}</span>
        </Typography>

        <Divider sx={{ m: '24px 0' }} />
        <Stack flexDirection={'row'} justifyContent={'space-around'} mt={2}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => window.location.reload()}
            sx={{ fontSize: '16px', width: isDesktop ? '250px' : undefined }}
          >
            {dictionary.finish}
          </Button>
        </Stack>
      </Stack>
    </FormSection>
  );
};
