import { DICTIONARY } from 'Enum';
import { Dictionary } from 'Dictionary';
import { Stack, Typography } from '@mui/material';
import { InfoPaper } from 'Components';
import { GridButton } from '../GridButtons';
interface IFirstTab {
  nextTab: () => void;
}
export const FirstTab = (props: IFirstTab) => {
  const dictionary = Dictionary[DICTIONARY.LOBBY];

  return (
    <Stack alignItems='center'>
      <InfoPaper>
        <Typography textAlign='center' fontWeight={700}>
          {dictionary.firstTabInfo}
        </Typography>
        <Typography textAlign='center' mt={4} paragraph>
          {dictionary.firstTabTitle + ' ' + dictionary.firstTabFirstParagraph}
        </Typography>
        <Typography textAlign='center'>
          {dictionary.firstTabSecondParagraph}
        </Typography>
      </InfoPaper>
      <GridButton
        nextStep={props.nextTab}
        rightButton={dictionary.enterTheForm}
        leftButton={dictionary.secondTab}
      />
    </Stack>
  );
};
