import { ValidationRule } from 'react-hook-form';

export const textTooLong = (textLength: number) =>
  `הטקסט שהקלדת ארוך מידי. מספר התווים המירבי הוא ${textLength}`;

export const generateError = (fieldName: string, errorMsg: string) => {
  switch (errorMsg) {
    case 'תאריך סיום האשפוז חייב להיות אחרי תאריך תחילת האשפוז':
      return errorMsg;
    case 'תאריך שחרור לא יכול להיות עתידי':
      return errorMsg;
    case 'תאריך פגיעה לא יכול להיות עתידי':
      return errorMsg;
    case 'מספר אישי לא חוקי':
      return errorMsg;
    case 'תאריך פגיעה חייב להיות מאוחר מתאריך לידה':
      return errorMsg;
    case 'מספר חשבון בנק לא תקין':
      return errorMsg;
    default:
      return `${errorMsg} ${fieldName}`;
  }
};

export const noNumbersNSymbols = /[0-9!@#$%^&*()]/;

/// Validations
// Only letters
// export const onlyLetters = /^[a-zA-Z\u0590-\u05fe-|"|' ]*$/gi;
// export const onlyLettersMessage = 'יש להקליד אותיות בלבד, ללא ספרות';
// export const onlyHebrewAndEnglishLetterValidation: ValidationRule<RegExp> = {
//   value: onlyLetters,
//   message: onlyLettersMessage,
// };
// Only numbers
export const onlyNumbers = /^[0-9]*$/;
export const onlyNumbersMessage = 'יש להקליד ספרות בלבד';
export const onlyNumbersValidation: ValidationRule<RegExp> = {
  value: onlyNumbers,
  message: onlyNumbersMessage,
};
// Email
export const email = /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/gi;
export const emailWithEmpty = /^$|\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/gi;
export const emailMessage =
  'יש להקליד אותיות באנגלית ואת הסימן @ בלבד, ללא רווחים';
export const emailValidation: ValidationRule<RegExp> = {
  value: email,
  message: emailMessage,
};
// LettersAndNumbersNoSpecialChars
export const lettersOrNumbersWithExtraChars =
  /^[a-zA-Z0-9\u0590-\u05fe-|.|,|(|)|?|!|:|'|"|\n ]*$/gi;
export const lettersOrNumbersNoSpecialChars =
  /^[a-zA-Z0-9\u0590-\u05fe-|'|" ]*$/gi;
export const lettersOrNumbersWithExtraCharsMessage =
  'יש להקליד אותיות או ספרות בלבד, ללא תווים מיוחדים';
export const lettersOrNumbersNoSpecialCharsMessage =
  'יש להקליד אותיות או ספרות בלבד, ללא תווים מיוחדים';
export const letterAndNumbersNoSpecialCharValidation: ValidationRule<RegExp> = {
  value: lettersOrNumbersNoSpecialChars,
  message: lettersOrNumbersNoSpecialCharsMessage,
};
// Only english letters and numbers

export const englishLettersAndNumbers = /^[0-9a-zA-Z-|'|" ]*$/gi;
export const englishLettersAndNumbersMessage =
  'יש להקליד ספרות או אותיות באנגלית בלבד';

// Date
export const dateMessage =
  'יש להקליד תאריך במבנה dd/mm/yyyy או לבחור בעזרת התאריכון';

export const requiredMessage = 'יש למלא';

export const endBeforeStart =
  'תאריך סיום האשפוז חייב להיות אחרי תאריך תחילת האשפוז';
export const endService = 'תאריך שחרור לא יכול להיות עתידי';
export const injuryDate = 'תאריך פגיעה לא יכול להיות עתידי';
export const endServiceAndInjury = 'תאריך פגיעה חייב להיות לפני תאריך השחרור';
export const bornDateAndInjury = 'תאריך פגיעה חייב להיות מאוחר מתאריך לידה';
export const invalidServiceId = 'מספר אישי לא חוקי';

export const exactlyNumOfChars = (numOfChars: number) =>
  `יש להזין ${numOfChars} תווים במדויק`;

export const phoneNumberNotRequired = /^$|(\S{7})/;
export const phoneNumberMessage = 'חייב להכיל 7 ספרות';

// File name validation
export const fileName = /[^a-zA-Z0-9\u05D0-\u05EA\- _]/g;

export const isValidIsraeliID = (idString: string) => {
  var id = idString.trim();
  if (id.length > 9 || id.length < 4 || isNaN(parseInt(id))) return false;
  id = id.length < 9 ? ('00000000' + id).slice(-9) : id;
  return (
    Array.from(id, Number).reduce((counter, digit, i) => {
      const step = digit * ((i % 2) + 1);
      return counter + (step > 9 ? step - 9 : step);
    }) %
      10 ===
    0
  );
};

export const noNumbersAndSymbols = (str: string) => {
  return noNumbersNSymbols.test(str);
};

export const isPhoneNumber = (str: string) => {
  const phoneRegex = /^\d{7,8}$/;
  return phoneRegex.test(str);
};

export const isFullPhoneNumber = (str: string) => {
  const phoneRegex = /^\d{9,10}$/;
  return phoneRegex.test(str);
};

export const isValidEmail = (str: string) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(str);
};

export const validateIsraeliIdMessage = 'מספר תעודת זהות לא תקין';
