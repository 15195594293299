import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Typography,
} from '@mui/material';
import {
  deleteFile,
  getPersonalDetail,
  IWaiverOfMedicalConfidentiality,
  postFile,
} from 'API';
import { FormSection } from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY, FileCategories } from 'Enum';
import { Controller, useFormContext } from 'react-hook-form';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useEffect, useState } from 'react';
import { useAppSelector, useIsDesktop } from 'Hooks';
import { requiredMessage } from 'Regex';
export const FirstSection = () => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<IWaiverOfMedicalConfidentiality>();

  const [personalDetails, setPersonalDetails] = useState<{
    firstName?: string;
    lastName?: string;
    identifier?: string;
  } | null>(null);

  const isDesktop = useIsDesktop();
  const { isConfirm } = watch();
  const { files, status } = useAppSelector(({ file: { files, status } }) => ({
    files: files[FileCategories.MedicalConfidentialityWaiver],
    status,
  }));
  const generatePdf = async () => {
    if (status === 'FINISHED_SETTING') {
      if (isConfirm) {
        if (files) return;
        const pdfElement = document.getElementById('pdf');
        if (pdfElement) {
          const a = await html2canvas(pdfElement);
          var pdf = new jsPDF();
          const dataUrl = await a.toDataURL('image/png');
          pdf.addImage(dataUrl, 'PNG', 0, 0, 200, 250);
          const arrayBuffer = pdf.output('arraybuffer');
          const file = new File([arrayBuffer], 'waiver.pdf', {
            type: 'application/pdf',
          });
          const form = new FormData();
          form.append('file', file);
          await postFile(FileCategories.MedicalConfidentialityWaiver, form);
        }
      } else {
        if (files) {
          await deleteFile(files[0].id);
        }
      }
    }
  };

  // If uncomment this lines, it create and send to server pdf
  // useEffect(() => {
  //   generatePdf();
  // }, [isConfirm, files]);

  const setPersonalFiles = async () => {
    const personalDetails = await getPersonalDetail();
    if (personalDetails) {
      setPersonalDetails({
        firstName: personalDetails.personalInfoSection?.firstName || '',
        lastName: personalDetails.personalInfoSection?.lastName || '',
        identifier: personalDetails.personalInfoSection?.israeliId || '',
      });
    }
  };
  useEffect(() => {
    setPersonalFiles();
  }, []);

  return (
    <FormSection id='pdf'>
      <Typography sx={{ mt: isDesktop ? 4 : undefined }}>
        {
          Dictionary[DICTIONARY.WAIVER_OF_MEDICAL_CONFIDENTIALITY]
            .firstSectionFirstParagraph[0]
        }
        <strong>{`${personalDetails?.firstName} ${personalDetails?.lastName}`}</strong>
        {
          Dictionary[DICTIONARY.WAIVER_OF_MEDICAL_CONFIDENTIALITY]
            .firstSectionFirstParagraph[1]
        }
      </Typography>
      <Typography sx={{ mt: 2 }}>
        {
          Dictionary[DICTIONARY.WAIVER_OF_MEDICAL_CONFIDENTIALITY]
            .firstSectionSecondParagraph
        }
      </Typography>
      <Typography sx={{ mt: 2 }}>
        {
          Dictionary[DICTIONARY.WAIVER_OF_MEDICAL_CONFIDENTIALITY]
            .firstSectionThirdParagraph
        }
      </Typography>
      <Typography sx={{ mt: 2 }}>
        {
          Dictionary[DICTIONARY.WAIVER_OF_MEDICAL_CONFIDENTIALITY]
            .firstSectionFourthParagraph
        }
      </Typography>

      <Typography sx={{ mt: 2 }}>
        {
          Dictionary[DICTIONARY.WAIVER_OF_MEDICAL_CONFIDENTIALITY]
            .firstSectionFivthP
        }
      </Typography>
      <Typography sx={{ mt: 2 }}>
        {
          Dictionary[DICTIONARY.WAIVER_OF_MEDICAL_CONFIDENTIALITY]
            .firstSectionSixthP
        }
      </Typography>
      <Typography sx={{ mt: 2 }}>
        {
          Dictionary[DICTIONARY.WAIVER_OF_MEDICAL_CONFIDENTIALITY]
            .firstSectionSeventhP
        }
      </Typography>

      <Controller
        render={({ field: { value, ...rest } }) => (
          <FormControlLabel
            sx={{ alignItems: 'flex-start', mt: 5 }}
            control={<Checkbox checked={value} {...rest} />}
            label={
              <Typography>
                {Dictionary[
                  DICTIONARY.WAIVER_OF_MEDICAL_CONFIDENTIALITY
                ].firstSectionCheckboxLabel(
                  personalDetails?.firstName,
                  personalDetails?.lastName,
                  personalDetails?.identifier
                )}
                ,
                <strong>
                  {
                    Dictionary[DICTIONARY.WAIVER_OF_MEDICAL_CONFIDENTIALITY]
                      .firstSectionCheckboxLabelPart2
                  }
                </strong>
              </Typography>
            }
          />
        )}
        rules={{
          required: {
            message: requiredMessage,
            value: true,
          },
        }}
        name='isConfirm'
        control={control}
      />
      {errors.isConfirm && (
        <FormHelperText color='error'>
          <Typography color='error'>{requiredMessage}</Typography>
        </FormHelperText>
      )}
    </FormSection>
  );
};
