export enum CASE_TYPE_RELATION_ID{
    INJURY_ID = 1,
    DISEASE_ID = 2,
    MENTAL_ID =3
}

export enum CAR_ACCIDENT_TYPE {
    LISING_OR_IDF = '1',
    CIVIL_CAR_ID = '2',
    MILITARY_CAR_ID = '3',
    POLICE_CAR_ID = '4',
    BICYCLE_OR_SCOOTER_ID = '5',
}
