import { Box } from '@mui/material';
import { AddPaperCard, FormAutoSaveTypography, FormPaper } from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';

interface IAddCaseDetails {
  append: () => void;
  save: () => Promise<void>;
}

export const AddCaseDetail = (props: IAddCaseDetails) => {
  return (
    <FormPaper>
      <AddPaperCard
        add={props.append}
        label={Dictionary[DICTIONARY.CASE_DETAILS].secondaryPaperFormTitle}
        secondaryLabel={
          Dictionary[DICTIONARY.CASE_DETAILS].secondaryPaperFormSubTitle
        }
      />
      <Box mt={3}>
        <FormAutoSaveTypography save={props.save} />
      </Box>
    </FormPaper>
  );
};
