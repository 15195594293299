import { Avatar, Button, Grid, Modal, Paper, Typography } from '@mui/material';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { background, DoubleDocIcon } from 'Images';
import { useIsDesktop } from 'Hooks';
import { Header } from 'Components';
import { Helmet } from 'react-helmet-async';

export const AuthIssue = () => {
  const isDesktop = useIsDesktop();

  const onClickHandler = () => {
    sessionStorage.clear();
    window.location.href = '/form';
  };

  return (
    <>
      <Helmet>
        <title>
          {Dictionary[DICTIONARY.HEADER].title +
            ' - ' +
            Dictionary[DICTIONARY.AUTH_ISSUE].title}
        </title>
      </Helmet>
      <Header />
      <Grid
        container
        sx={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundOrigin: 'border-box',
          height: '100vh',
        }}
      ></Grid>
      <Modal open={true}>
        <Paper
          sx={
            isDesktop
              ? {
                  boxShadow: '0px 3px 21px 0px #2D70D926',
                  padding: '65px 100px 70px 100px',
                  maxWidth: '850px',
                  mb: '64px',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  textAlign: 'center',
                  width: '80%',
                  maxHeight: '80vh',
                  overflow: 'visible',
                }
              : {
                  boxShadow: '0px 3px 21px 0px #2D70D926',
                  padding: '38px 16px 48px 16px',
                  maxWidth: '750px',
                  mb: '64px',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  textAlign: 'center',
                  width: '80%',
                  maxHeight: '80vh',
                  overflow: 'auto',
                }
          }
        >
          <span
            style={{
              position: 'absolute',
              top: isDesktop ? '24px' : '18px',
              left: isDesktop ? '30px' : '24px',
              fontSize: isDesktop ? '32px' : '24px',
              cursor: 'pointer',
            }}
            tabIndex={0}
            onClick={onClickHandler}
          >
            x
          </span>
          <Avatar
            src={DoubleDocIcon}
            sx={
              isDesktop
                ? {
                    width: '104px',
                    height: '104px',
                    margin: '0 auto',
                    mb: '30px',
                  }
                : {
                    width: '64px',
                    height: '64px',
                    margin: '0 auto',
                    mb: '30px',
                  }
            }
            alt=''
          />

          <Typography
            component='h3'
            variant={'h1'}
            sx={{
              fontWeight: 'bold',
              maxWidth: isDesktop ? '285px' : '205px',
              margin: '0 auto',
            }}
          >
            {Dictionary[DICTIONARY.AUTH_ISSUE].title}
          </Typography>

          <Typography
            sx={
              isDesktop
                ? {
                    fontSize: '20px',
                    m: '20px auto 50px',
                    maxWidth: '350px',
                    lineHeight: '26px',
                  }
                : {
                    fontSize: '16px',
                    mt: '20px',
                    padding: '0 15px',
                    mb: '50px',
                  }
            }
          >
            {Dictionary[DICTIONARY.AUTH_ISSUE].subTitle1}
          </Typography>

          <Button
            onClick={onClickHandler}
            sx={{
              mt: 2,
              fontSize: '18px',
              fontWeight: 400,
              width: '170px',
            }}
            variant='outlined'
          >
            {Dictionary[DICTIONARY.AUTH_ISSUE].back}
          </Button>
        </Paper>
      </Modal>
    </>
  );
};
