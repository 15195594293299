import { EventType } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useScrollToTop } from 'Hooks';
import { useEffect } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import { RoutesConfig } from 'Routes';
export const Router = () => {
  const { instance } = useMsal();
  const isAuth = useIsAuthenticated();
  useEffect(() => {
    const callbackId = instance.addEventCallback((event: any) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        console.log('Event', event);
        if (
          event.error &&
          event.error.errorMessage.indexOf('AAD_Custom_AADB2C_102') > -1
        ) {
          // TODO when the user phone is not valid
          alert('Phone is not valid for this user');
          console.log('Phone is not valid for this user');
        }
      }

      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ) {
        if (event?.payload) {
          /**
           * We need to reject id tokens that were not issued with the default sign-in policy.
           * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
           * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
           */
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  useScrollToTop();

  const privateRoutesConfig: RouteObject[] = [
    {
      path: RoutesConfig.PRIVATE_LAYOUT.PATH,
      element: RoutesConfig.PRIVATE_LAYOUT.MODULE,
      children: [
        {
          index: true,
          element: RoutesConfig.STATUS_LOBBY.MODULE,
        },
        {
          path: RoutesConfig.EMPLOYMENT_AND_BANK_DETAILS.PATH,
          element: RoutesConfig.EMPLOYMENT_AND_BANK_DETAILS.MODULE,
        },
        {
          path: RoutesConfig.PERSONAL_DETAILS.PATH,
          element: RoutesConfig.PERSONAL_DETAILS.MODULE,
        },
        {
          path: RoutesConfig.REPRESENTATION.PATH,
          element: RoutesConfig.REPRESENTATION.MODULE,
        },
        {
          path: RoutesConfig.WAIVER_OF_MEDICAL_CONFIDENTIALITY.PATH,
          element: RoutesConfig.WAIVER_OF_MEDICAL_CONFIDENTIALITY.MODULE,
        },
        {
          path: RoutesConfig.REQUEST_AND_EARLY_ACQUAINTANCE.PATH,
          element: RoutesConfig.REQUEST_AND_EARLY_ACQUAINTANCE.MODULE,
        },
        {
          path: RoutesConfig.CASE_DETAILS.PATH,
          element: RoutesConfig.CASE_DETAILS.MODULE,
        },
        {
          path: RoutesConfig.VERIFY_DETAILS.PATH,
          element: RoutesConfig.VERIFY_DETAILS.MODULE,
        },
        {
          path: RoutesConfig.SUCCESS_PAGE.PATH,
          element: RoutesConfig.SUCCESS_PAGE.MODULE,
        },
        {
          path: RoutesConfig.STATUS_REQUEST_PAGE.PATH,
          element: RoutesConfig.STATUS_REQUEST_PAGE.MODULE,
        },
      ],
    },
  ];

  const loginRoutesConfig: RouteObject[] = [
    {
      path: RoutesConfig.LOBBY_LAYOUT.PATH,
      element: RoutesConfig.LOBBY_LAYOUT.MODULE,
      children: [
        {
          element: RoutesConfig.LOBBY.MODULE,
          index: true,
        },
      ],
    },
  ];

  const doubleDocPage: RouteObject[] = [
    {
      path: RoutesConfig.DOUBLE_DOC.PATH,
      element: RoutesConfig.DOUBLE_DOC.MODULE,
      children: [
        {
          element: RoutesConfig.DOUBLE_DOC.MODULE,
          index: true,
        },
      ],
    },
  ];

  const authIssuePage: RouteObject[] = [
    {
      path: RoutesConfig.AUTH_ISSUE.PATH,
      element: RoutesConfig.AUTH_ISSUE.MODULE,
      children: [
        {
          element: RoutesConfig.AUTH_ISSUE.MODULE,
          index: true,
        },
      ],
    },
  ];

  const emergencyFormPage: RouteObject[] = [
    {
      path: RoutesConfig.EMERGENCY_FORM.PATH,
      element: RoutesConfig.EMERGENCY_FORM.MODULE,
      children: [
        {
          element: RoutesConfig.EMERGENCY_FORM.MODULE,
          index: true,
        },
      ],
    },
  ];

  const routesConfig: RouteObject[] = [
    ...privateRoutesConfig,
    ...loginRoutesConfig,
    ...doubleDocPage,
    ...authIssuePage,
    ...emergencyFormPage,
    {
      path: '*',
      element: RoutesConfig.NOT_FOUND.MODULE,
    },
  ];

  const routes = useRoutes(routesConfig);

  return routes;
};
