import { Grid, Stack, Typography } from '@mui/material';
import { useIsDesktop } from 'Hooks';
import { FileActions } from '../FileActions';
interface IFileRow {
  fileName: string;
  deleteFile: () => void;
  watchFile: () => void;
}
export const FileRow = (props: IFileRow) => {
  const filename = props.fileName;
  const isDesktop = useIsDesktop();

  return (
    <Grid container>
      <Grid
        container
        alignItems='center'
        sx={{
          padding: '16px 0 0 0',
        }}
      >
        <Stack
          sx={{
            mt: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Typography
            noWrap
            title={filename}
            sx={{
              width: 'auto',
              // margin: '0 auto',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              maxWidth: isDesktop ? undefined : '100px',
            }}
          >
            {filename}
          </Typography>

          <FileActions
            filename={filename}
            deleteFile={props.deleteFile}
            watchFile={props.watchFile}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};
