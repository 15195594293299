import { Avatar, Divider, IconButton, Stack, Typography } from '@mui/material';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { TrashCan, WatchFile } from 'Images';

interface IFileActions {
  filename: string;
  watchFile: () => void;
  deleteFile: () => void;
}

export const FileActions = (props: IFileActions) => {
  const dictionary = Dictionary[DICTIONARY.GENERAL];
  return (
    <>
      <Stack
        onClick={props.watchFile}
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '100px',
        }}
      >
        <IconButton
          sx={{
            padding: '4px',
          }}
        >
          <Avatar
            src={WatchFile}
            alt={`${dictionary.watch} ${props.filename}`}
            sx={{ width: '24px', height: '24px' }}
          />
        </IconButton>
        <Typography color='primary' noWrap sx={{ cursor: 'pointer' }}>
          {dictionary.watch}
          <span className='sr-only'>{props.filename}</span>
        </Typography>
      </Stack>

      <Divider
        aria-hidden='true'
        orientation='vertical'
        flexItem
        variant='middle'
      />
      <Stack
        onClick={props.deleteFile}
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '100px',
        }}
      >
        <IconButton
          sx={{
            padding: '4px',
          }}
        >
          <Avatar
            src={TrashCan}
            sx={{ width: '24px', height: '24px' }}
            alt={`${dictionary.delete} ${props.filename}`}
          />
        </IconButton>
        <Typography color='primary' sx={{ cursor: 'pointer' }}>
          {dictionary.delete}
          <span className='sr-only'>{props.filename}</span>
        </Typography>
      </Stack>
    </>
  );
};
