import { Stack } from '@mui/material';
import {
  FormPaper,
  FormTitle,
  HomePageFormLink,
  TrashCanGrid,
} from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { FifthSection } from './FifthSection';
import { FirstSection } from './FirstSection';
import { AddCaseDetail } from './AddCaseDetail';
import { SecondSection } from './SecondSection';
import { SeventhSection } from './SeventhSection';
import { SixthSection } from './SixthSection';
import { ThirdSection } from './ThirdSection';
import { TopDescription } from './TopDescription';
import { DeleteModal, Text } from './DeleteModal';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFieldArray,
} from 'react-hook-form';
import {
  caseDetailsEmptyObject,
  deleteCaseDetails,
  getCaseDetails,
  ICaseDetails,
  ICaseDetailsInputs,
  postCaseDetails,
  putCaseDetails,
} from 'API';
import { useEffect, useRef, useState } from 'react';
import { diseaseId, IndexProvider } from './CaseContext';
import { debounce, files, initFocus } from 'Utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { caseDetailsSchema } from './Schema';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { RoutesConfig } from 'Routes';
import { useAppSelector } from 'Hooks';

export type Inputs = ICaseDetailsInputs;
export const CaseDetails = () => {
  const defaultValues = {
    caseDetails: [
      {
        ...caseDetailsEmptyObject,
        id: 0,
      },
    ],
  };

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [indexForm, setIndexForm] = useState<number>(0);
  const [fieldIdForm, setFieldIdForm] = useState(0);

  const { caseType, isMentalIllnessCausedBySpecificEvent } = useAppSelector(
    ({
      caseDetails: {
        caseDetailsObj: { caseType, isMentalIllnessCausedBySpecificEvent },
      },
    }) => ({ caseType, isMentalIllnessCausedBySpecificEvent })
  );

  const methods = useForm<Inputs>({
    mode: 'onBlur',
    resolver: yupResolver(caseDetailsSchema),
    defaultValues,
    context: {},
  });
  const { append, remove, fields } = useFieldArray({
    name: 'caseDetails',
    control: methods.control,
    keyName: 'rid',
  });
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await forceSubmit();
    const result = await files();
    if (result) {
      navigate(RoutesConfig.STATUS_LOBBY.PATH);
    }
  };

  const removeHandler = async (index: number, id: number) => {
    initFlag.current = true;
    const caseDetails = await deleteCaseDetails(id);
    if (caseDetails) {
      remove(index);
      setShowDeleteModal(false);
    }
    initFlag.current = false;
  };

  const appendHandler = async () => {
    initFlag.current = true;
    const caseDetails = await postCaseDetails({});
    if (caseDetails) {
      append({
        ...caseDetailsEmptyObject,
        hospitalizationDetailsSection: [],
        id: caseDetails.id,
      });
    }
    initFlag.current = false;
  };

  const initFlag = useRef<boolean>(true);

  const initForm = async () => {
    initFlag.current = true;
    const caseDetails = await getCaseDetails();
    if (caseDetails && caseDetails?.length) {
      methods.reset({ caseDetails: [...caseDetails] });
    } else {
      const caseDetails = await postCaseDetails({});
      if (caseDetails) {
        methods.reset({ caseDetails: [caseDetails] });
      }
    }
    initFlag.current = false;
  };

  const onDeleteHandler = (index: number, fieldId: any) => {
    setShowDeleteModal(true);
    setIndexForm(index);
    setFieldIdForm(fieldId);
  };
  const getModalText = (): Text => {
    const res = { date: '', injuryType: '' };
    const values = methods.getValues().caseDetails[indexForm];

    const isDisease =
      values?.caseTypeRelationId === diseaseId.toString() || false;

    const diseaseDiagnosisDate =
      values?.diseaseKindSection?.diseaseDiagnosisDate;
    const diseaseDiagnosisDateFormat =
      diseaseDiagnosisDate &&
      format(new Date(diseaseDiagnosisDate), 'dd/MM/yyyy');
    const injuryKindSection = values?.injuryKindSection?.date;
    const injuryKindSectionFormat =
      injuryKindSection && format(new Date(injuryKindSection), 'dd/MM/yyyy');

    if (isDisease) {
      if (diseaseDiagnosisDateFormat) {
        res.date = diseaseDiagnosisDateFormat;
      }
      res.injuryType = Dictionary[DICTIONARY.CASE_DETAILS].disease;
    } else {
      if (injuryKindSectionFormat) {
        res.date = injuryKindSectionFormat;
      }
      res.injuryType = Dictionary[DICTIONARY.CASE_DETAILS].injury;
    }
    return res;
  };

  const closeDeleteModalHandler = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    initFocus();
    initForm();
  }, []);

  /* Creating a ref object that will be used to store the id of the timeout. */
  const id = useRef<NodeJS.Timeout | undefined>(undefined);

  // normalize data
  const normalizeData = (): Partial<ICaseDetails>[] => {
    const data = methods.getValues();
    return data.caseDetails.map((caseDetail) => {
      if (!caseDetail) return {};
      return {
        ...caseDetail,
        areThereWitnessesOptionId: caseDetail.areThereWitnessesOptionId
          ? caseDetail.areThereWitnessesOptionId
          : null,
        militaryServiceSection: {
          ...caseDetail.militaryServiceSection,
          troopId: caseDetail.militaryServiceSection?.troopId
            ? caseDetail.militaryServiceSection?.troopId
            : null,
          medicalProfile: caseDetail.militaryServiceSection?.medicalProfile
            ? caseDetail.militaryServiceSection?.medicalProfile
            : null,
          serviceTypeId: caseDetail.militaryServiceSection?.serviceTypeId
            ? caseDetail.militaryServiceSection?.serviceTypeId
            : null,
          rankId: caseDetail.militaryServiceSection?.rankId
            ? caseDetail.militaryServiceSection?.rankId
            : null,
        },
        trafficAccidentSection: {
          ...caseDetail.trafficAccidentSection,
          vehicleDetailsSection: {
            ...caseDetail.trafficAccidentSection?.vehicleDetailsSection,
            ownershipVehicleId: caseDetail.trafficAccidentSection
              ?.vehicleDetailsSection?.ownershipVehicleId
              ? caseDetail.trafficAccidentSection?.vehicleDetailsSection
                  ?.ownershipVehicleId
              : null,
          },
        },
        injuryKindSection: {
          ...caseDetail.injuryKindSection,
          investigatorOptionId: caseDetail.injuryKindSection
            ?.investigatorOptionId
            ? caseDetail.injuryKindSection?.investigatorOptionId
            : null,
          reportAttachedSelectedOptionId: caseDetail.injuryKindSection
            ?.reportAttachedSelectedOptionId
            ? caseDetail.injuryKindSection?.reportAttachedSelectedOptionId
            : null,
        },
        hospitalizationDetailsSection: caseDetail.hospitalizationDetailsSection,
        witnesses: caseDetail.witnesses?.map((witness) => ({
          ...witness,
          phonePrefixRelationId: witness?.phonePrefixRelationId
            ? witness?.phonePrefixRelationId
            : null,
          typeId: witness?.typeId ? witness?.typeId : null,
        })),
      } as Partial<ICaseDetails>;
    });
  };
  useEffect(() => {
    const subscription = methods.watch(async (data) => {
      if (initFlag.current) return;
      // if (data.caseDetails && !Object.keys(methods.formState.errors).length) {
      const normalizedData = normalizeData();

      debounce(id, putCaseDetails, normalizedData);
      // }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [methods.watch]);

  const forceSubmit = async () => {
    await putCaseDetails(normalizeData());
  };
  return (
    <>
      <Helmet>
        <title>
          {Dictionary[DICTIONARY.HEADER].title +
            ' - ' +
            Dictionary[DICTIONARY.CASE_DETAILS].title}
        </title>
      </Helmet>
      <HomePageFormLink save={forceSubmit} />
      <Stack alignItems='center' component={'main'} role={'main'}>
        <FormTitle title={Dictionary[DICTIONARY.CASE_DETAILS].title} />
        <TopDescription />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {fields.map((field, index) => (
              <FormPaper key={field.rid}>
                <IndexProvider index={index} fieldId={field.id}>
                  <FirstSection />
                  <SecondSection />
                  <ThirdSection />
                  <FifthSection />
                  <SixthSection />
                  <SeventhSection />
                </IndexProvider>
                <TrashCanGrid remove={() => onDeleteHandler(index, field.id)} />
              </FormPaper>
            ))}
            <AddCaseDetail append={appendHandler} save={forceSubmit} />
          </form>
          {showDeleteModal && (
            <DeleteModal
              isVisible={showDeleteModal}
              closeModal={closeDeleteModalHandler}
              deleteModal={() => removeHandler(indexForm, fieldIdForm)}
              texts={getModalText()}
            />
          )}
        </FormProvider>
      </Stack>
    </>
  );
};
