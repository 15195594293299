import { FileCategories } from 'Enum';
import { store } from 'Store';
export const getFileIds = (
  fileCategoryId: FileCategories,
  hospitalizationId?: number | null,
  caseDetailsId?: number | null
) => {
  const files = store.getState().file.files;
  const categoryFiles = files[fileCategoryId];
  if (caseDetailsId && hospitalizationId) {
    return categoryFiles?.filter(
      (file) =>
        file.caseDetailsRelationId === caseDetailsId &&
        file.hospitalizationDetailsRelationId === hospitalizationId
    );
  } else if (caseDetailsId) {
    return categoryFiles?.filter(
      (file) => file.caseDetailsRelationId === caseDetailsId
    );
  } else if (hospitalizationId) {
    return categoryFiles?.filter(
      (file) => file.hospitalizationDetailsRelationId === hospitalizationId
    );
  } else {
    return files[fileCategoryId];
  }
};
