import { Avatar, Box, Grid, Typography } from '@mui/material';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { Arrow } from 'Images';
import { useNavigate } from 'react-router-dom';
import { RoutesConfig } from 'Routes';

interface IHomePageFormLink {
  save: () => Promise<void>;
}

export const HomePageFormLink = (props: IHomePageFormLink) => {
  const navigate = useNavigate();

  const onClickHandler = async () => {
    await props.save();
    navigate(RoutesConfig.STATUS_LOBBY.PATH);
  };

  return (
    <Grid container justifyContent='left' mt={3}>
      <Box
        display='flex'
        alignItems='center'
        gap={1}
        onClick={onClickHandler}
        sx={{ cursor: 'pointer' }}
        tabIndex={0}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onClickHandler();
          }
        }}
        role={'link'}
      >
        <Avatar src={Arrow} alt='' sx={{ width: '32px', height: '32px' }} />
        <Typography sx={{ fontWeight: 'bold' }} color='primary'>
          {Dictionary[DICTIONARY.GENERAL].returnToTheMainPage}
        </Typography>
      </Box>
    </Grid>
  );
};
