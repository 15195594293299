import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface InitState {
  bankNumber: number;
  branchNumber: number;
}

const initialState: InitState = {
  bankNumber: 0,
  branchNumber: 0,
};

export const EmploymentAndBankDetailsSlice = createSlice({
  name: 'employmentAndBankDetails',
  initialState,
  reducers: {
    setBankNumber: (state, action: PayloadAction<number>) => {
      state.bankNumber = action.payload;
    },
    setBranchNumber: (state, action: PayloadAction<number>) => {
      state.branchNumber = action.payload;
    },
  },
});

export const { setBankNumber, setBranchNumber } =
  EmploymentAndBankDetailsSlice.actions;

export default EmploymentAndBankDetailsSlice.reducer;
