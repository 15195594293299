import { TextField, Typography } from '@mui/material';
import { ICaseDetailsInputs } from 'API';
import {
  ControllerAutoComplete,
  ControllerDatePicker,
  FormSection,
} from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { useAppSelector } from 'Hooks';
import { useContext } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { generateError } from 'Regex';
import { textFieldShrink } from 'Utils';
import { validateLettersAndNumbersOnly } from 'Utils/validateLettersAndNumbersOnly';
import { IndexContext } from '../CaseContext';
import { validateNumbersOnly } from 'Utils/validateNumbersOnly';

export const IDF_TYPES = [1, 2, 3];
export const IDF_RANKS = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 23,
  24, 44,
];

export const POLICE_TYPES = [4, 5, 6];
export const POLICE_RANKS = [
  23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
];

export const SHABAS_TYPES = 7;
export const SHABAS_RANKS = [
  14, 15, 16, 17, 21, 23, 24, 41, 42, 43, 50, 72, 73, 74,
];

export const FirstSection = () => {
  const [serviceTypes, militaryRanks, militaryTroops] = useAppSelector(
    ({
      generalContent: {
        generalContent: { serviceTypes, militaryRanks, militaryTroops },
      },
    }) => [serviceTypes, militaryRanks, militaryTroops]
  );

  const {
    register,
    formState: { errors },
    getValues,
  } = useFormContext<ICaseDetailsInputs>();

  const { index } = useContext(IndexContext);

  const dictionary = Dictionary[DICTIONARY.CASE_DETAILS];

  const baseName = `caseDetails.${index}.militaryServiceSection`;

  const otherServiceTypeId = 12;

  const serviceTypeId = useWatch({
    name: `${baseName}.serviceTypeId` as const,
    defaultValue: 0,
  });

  const showTroopId = [1, 2, 3];

  const militaryServiceSectionValues = getValues(
    `caseDetails.${index}.militaryServiceSection`
  );

  const militaryServiceError = errors.caseDetails
    ? errors.caseDetails[index]?.militaryServiceSection
    : undefined;

  return (
    <FormSection grouped id={dictionary.firstSectionTitle}>
      <Typography sx={{ fontWeight: 'bold' }} id={dictionary.firstSectionTitle}>
        {dictionary.firstSectionTitle}
      </Typography>
      <Typography>{dictionary.firstSectionSubTitle}</Typography>
      <ControllerAutoComplete
        options={serviceTypes}
        name={`${baseName}.serviceTypeId` as const}
        label={dictionary.firstSectionFirstInput}
      />
      {serviceTypeId == otherServiceTypeId && (
        <TextField
          role={
            Boolean(militaryServiceError?.otherServiceType)
              ? 'alert'
              : undefined
          }
          inputProps={{
            maxLength: '50',
          }}
          id={`${dictionary.serviceTypeDetail}-${index}`}
          {...register(
            `${baseName}.otherServiceType` as `caseDetails.0.militaryServiceSection.otherServiceType`
          )}
          {...textFieldShrink(militaryServiceSectionValues?.otherServiceType)}
          error={Boolean(militaryServiceError?.otherServiceType)}
          helperText={
            Boolean(militaryServiceError?.otherServiceType?.message) &&
            generateError(
              dictionary.serviceTypeDetail,
              militaryServiceError?.otherServiceType?.message!
            )
          }
          label={dictionary.serviceTypeDetail}
          onChange={(event: any) => validateLettersAndNumbersOnly(event)}
        />
      )}

      <ControllerDatePicker
        name={
          `${baseName}.releaseDate` as `caseDetails.0.militaryServiceSection.releaseDate`
        }
        label={dictionary.firstSectionDateInput}
      />
      <TextField
        role={Boolean(militaryServiceError?.role) ? 'alert' : undefined}
        inputProps={{
          maxLength: '50',
        }}
        id={`${dictionary.firstSectionSecondInput}-${index}`}
        {...textFieldShrink(militaryServiceSectionValues?.role)}
        {...register(
          `${baseName}.role` as `caseDetails.0.militaryServiceSection.role`
        )}
        error={Boolean(militaryServiceError?.role)}
        helperText={
          Boolean(militaryServiceError?.role?.message) &&
          generateError(
            dictionary.firstSectionSecondInput,
            militaryServiceError?.role?.message!
          )
        }
        label={dictionary.firstSectionSecondInput}
        onChange={(event: any) => validateLettersAndNumbersOnly(event)}
      />
      {showTroopId.includes(serviceTypeId) && (
        <ControllerAutoComplete
          name={
            `${baseName}.troopId` as `caseDetails.0.militaryServiceSection.troopId`
          }
          label={dictionary.firstSectionFourthInput}
          options={militaryTroops}
        />
      )}

      <TextField
        role={
          Boolean(militaryServiceError?.medicalProfile) ? 'alert' : undefined
        }
        inputProps={{
          maxLength: '2',
        }}
        id={`${dictionary.firstSectionSixthInput}-${index}`}
        {...textFieldShrink(militaryServiceSectionValues?.medicalProfile)}
        error={Boolean(militaryServiceError?.medicalProfile)}
        helperText={
          Boolean(militaryServiceError?.medicalProfile?.message) &&
          generateError(
            dictionary.firstSectionSixthInput,
            militaryServiceError?.medicalProfile?.message!
          )
        }
        {...register(
          `${baseName}.medicalProfile` as `caseDetails.0.militaryServiceSection.medicalProfile`
        )}
        label={dictionary.firstSectionSixthInput}
        onKeyDown={(event) => validateNumbersOnly(event)}
      />
    </FormSection>
  );
};
