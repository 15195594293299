import { configureStore } from '@reduxjs/toolkit';
import fileSlice from './filleSlice';
import generalContentSlice from './generalContentSlice';
import caseDetailsSlice from './caseDetailsSlice';
import personalDetailsSlice from './personalDetailsSlice';
import requestAndEarlyAcquaintanceSlice from './requestAndEarlyAcquaintanceSlice';
import employmentAndBankDetailsSlice from './employmentAndBankDetailsSlice';

export const store = configureStore({
  reducer: {
    file: fileSlice,
    generalContent: generalContentSlice,
    caseDetails: caseDetailsSlice,
    personalDetails: personalDetailsSlice,
    requestAndEarlyAcquaintance: requestAndEarlyAcquaintanceSlice,
    employmentAndBankDetails: employmentAndBankDetailsSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
