import {useContext} from "react";
import {Grid, Typography} from '@mui/material';
import {useFormContext} from 'react-hook-form';
import {IndexContext} from "../../CaseContext";
import {ICaseDetailsInputs} from "API";
import {format} from "date-fns";
import {Dictionary} from "Dictionary";
import {DICTIONARY} from "Enum";
import {useIsDesktop} from "Hooks";

export const SummaryCollapseBox = () => {

    const isDesktop = useIsDesktop();

    const {getValues} = useFormContext<ICaseDetailsInputs>();
    const {index, baseName = ''} = useContext(IndexContext);
    const {caseDetails = []} = getValues();
    const caseIndex = parseInt(baseName.split('.')[1]);


    const getHospital = () => {
        if (caseDetails && caseDetails[caseIndex]) {
            if (caseDetails[caseIndex].hospitalizationDetailsSection) {
                if (caseDetails[caseIndex].hospitalizationDetailsSection![index]) {
                    return caseDetails[caseIndex].hospitalizationDetailsSection![index]
                }
            }
        }
    };

    const hospital = getHospital();

    const hospitalName = hospital?.medicalInstitutionName;
    const visitDate = hospital?.hospitalizationStartDate;
    const visitDateFormat = visitDate && format(new Date(visitDate), "dd/MM/yyyy");

    const wasHospitalized = hospital?.wasHospitalized ? Dictionary[DICTIONARY.CASE_DETAILS].seventhSectionFacilitySummaryYes : Dictionary[DICTIONARY.CASE_DETAILS].seventhSectionFacilitySummaryNo;

    return (
        <Grid container spacing={1} alignItems="center" sx={{ pl: isDesktop ? '0' : '20px', pr: '5px' }}>
            <Grid item sx={{pt:'0 !important'}}>
                <Typography>{hospitalName}</Typography>
            </Grid>
            <Grid item sx={{pt:'0 !important'}}>
                {visitDateFormat &&
                <Typography> | {visitDateFormat}</Typography>
                }
            </Grid>
            <Grid item sx={{pt:'0 !important'}}>
                <Typography sx={{fontSize: '13px'}}>{hospital?.wasHospitalized !== null ? wasHospitalized : ''}</Typography>
            </Grid>
        </Grid>
    )
};
