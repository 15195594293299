import { Container, Grid } from '@mui/material';
import { background } from 'Images';
import { Outlet, useNavigate } from 'react-router-dom';
import { Header, Footer, Loader } from 'Components';
import {
  getFileNames,
  getForm,
  getGeneralContent,
  getLobbyStatuses,
} from 'API';
import { setFiles, setFormInfo, setGeneralContent } from 'Store';
import { useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useDispatchApp } from 'Hooks';
import { InteractionStatus } from '@azure/msal-browser';
export const PrivateLayout = () => {
  const navigate = useNavigate();

  const dispatch = useDispatchApp();

  const [isFinished, setIsFinished] = useState<boolean>(false);

  const { inProgress } = useMsal();

  const isAuthenticated = useIsAuthenticated();

  const initGeneralContent = async () => {
    const generalContentData = await getGeneralContent();
    if (generalContentData) dispatch(setGeneralContent(generalContentData));
  };
  const initFileNames = async () => {
    const fileNames = await getFileNames();
    if (fileNames) dispatch(setFiles(fileNames));
  };
  const formInfoInit = async () => {
    const form = await getForm();
    if (form) {
      dispatch(setFormInfo(form));
    }
  };
  async function init() {
    await getLobbyStatuses();
    formInfoInit();
    initGeneralContent();
    initFileNames();
    setIsFinished(true);
  }
  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      if (!isAuthenticated) {
        navigate('/');
      }
      init();
    }
  }, [inProgress]);

  return (
    <>
      <Header />
      <Grid
        container
        sx={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundOrigin: 'border-box',
        }}
      >
        <Container
          maxWidth='xl'
          sx={{
            overflowX: 'hidden',
            minHeight: 'calc(100vh - 72px - 92px)',
          }}
        >
          {isFinished ? <Outlet /> : <Loader />}
        </Container>
      </Grid>
      <Footer />
    </>
  );
};
