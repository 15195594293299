import { Grid, TextField, Typography } from '@mui/material';
import { ICaseDetailsInputs } from 'API';
import {
  ControllerDatePicker,
  CustomToggleGroup,
  FileUpload,
  FormSection,
  PaperCard,
  RadioButtons,
} from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY, FileCategories } from 'Enum';
import { useContext, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { generateError } from 'Regex';
import { extractCaseIndex } from 'Utils';
import { IndexContext } from '../CaseContext';
import { SummaryCollapseBox } from './SummaryCollapseBox';
import { useIsDesktop } from 'Hooks';
import { validateLettersAndNumbersOnly } from 'Utils/validateLettersAndNumbersOnly';
import { validateLettersNumbersAndSpacialChars } from 'Utils/validateLettersNumbersAndSpacialChars';

interface IMedicalFacilityCard {
  deleteFacility: () => void;
  hospitalizationId: number;
}
export const MedicalFacilityCard = (props: IMedicalFacilityCard) => {
  const { index, baseName = '' } = useContext(IndexContext);

  const {
    register,
    formState: { errors },
    getValues,
  } = useFormContext<ICaseDetailsInputs>();

  const hospitalBaseName = `${baseName}.${index}`;

  const hospitalizationName = `${hospitalBaseName}.wasHospitalized`;

  const caseId = getValues(`caseDetails.${extractCaseIndex(baseName)}.id`);

  const hospitalizationErrorsArray = (() => {
    const caseDetailsIndex = (baseName.split('.')[1] as unknown as number) || 0;
    if (
      errors.caseDetails &&
      errors.caseDetails[caseDetailsIndex] &&
      errors.caseDetails[caseDetailsIndex].hospitalizationDetailsSection
    ) {
      return errors.caseDetails[caseDetailsIndex].hospitalizationDetailsSection;
    } else {
      return undefined;
    }
  })();

  const hospitalizationStartDateName = `${hospitalBaseName}.hospitalizationStartDate`;

  const hospitalizationStartDate = useWatch({
    name: hospitalizationStartDateName,
    defaultValue: new Date(),
  });

  const hospitalizationError =
    hospitalizationErrorsArray !== undefined
      ? hospitalizationErrorsArray[index]
      : undefined;

  const hospitalization = useWatch({
    name: hospitalizationName,
    defaultValue: false,
  }) as boolean;

  const dictionary = Dictionary[DICTIONARY.CASE_DETAILS];

  return (
    <PaperCard
      remove={props.deleteFacility}
      text={<SummaryCollapseBox />}
      isOpen={true}
    >
      <Grid
        container
        gap={4}
        sx={{
          '& .MuiFormHelperText-root': { backgroundColor: '#F8F8F8', m: 0 },
        }}
      >
        <div>
          <Typography
            color='primary'
            sx={
              useIsDesktop()
                ? { fontWeight: 'bold' }
                : { fontWeight: 'bold', pl: '40px', pt: '8px' }
            }
          >
            {dictionary.seventhSectionFacilityTitle}
          </Typography>
        </div>
        <TextField
          role={
            Boolean(hospitalizationError?.medicalInstitutionName)
              ? 'alert'
              : undefined
          }
          inputProps={{
            maxLength: '50',
          }}
          id={`${dictionary.seventhSectionFacilityFirstInput}-${index}`}
          error={Boolean(hospitalizationError?.medicalInstitutionName)}
          helperText={
            Boolean(hospitalizationError?.medicalInstitutionName?.message) &&
            generateError(
              dictionary.seventhSectionFacilityFirstInput,
              hospitalizationError?.medicalInstitutionName?.message!
            )
          }
          {...register(
            `${hospitalBaseName}.medicalInstitutionName` as `caseDetails.0.hospitalizationDetailsSection.0.medicalInstitutionName`
          )}
          label={dictionary.seventhSectionFacilityFirstInput}
          onChange={(event: any) => validateLettersAndNumbersOnly(event)}
        />
        <ControllerDatePicker
          name={`${hospitalBaseName}.hospitalizationStartDate` as const}
          label={dictionary.seventhSectionFacilitySecondInput}
        />
        <RadioButtons
          name={`${hospitalizationName}` as const}
          options={[
            {
              value: 'true',
              label: dictionary.seventhSectionFacilityToggleButtonRight,
            },
            {
              value: 'false',
              label: dictionary.seventhSectionFacilityToggleButtonLeft,
            },
          ]}
          id={dictionary.seventhSectionFacilityToggleButtonLabel}
        />
        {hospitalization && (
          <>
            <TextField
              role={
                Boolean(hospitalizationError?.clinicDepartment)
                  ? 'alert'
                  : undefined
              }
              inputProps={{
                maxLength: '50',
              }}
              id={`${dictionary.seventhSectionFacilityThirdInput}-${index}`}
              error={Boolean(hospitalizationError?.clinicDepartment)}
              helperText={
                Boolean(hospitalizationError?.clinicDepartment?.message) &&
                generateError(
                  dictionary.seventhSectionFacilityThirdInput,
                  hospitalizationError?.clinicDepartment?.message!
                )
              }
              {...register(
                `${hospitalBaseName}.clinicDepartment` as `caseDetails.0.hospitalizationDetailsSection.0.clinicDepartment`
              )}
              label={dictionary.seventhSectionFacilityThirdInput}
              onChange={(event: any) => validateLettersAndNumbersOnly(event)}
            />
            <ControllerDatePicker
              name={`${hospitalizationStartDateName}` as const}
              label={dictionary.seventhSectionFacilityFourthInput}
            />
            <ControllerDatePicker
              name={`${hospitalBaseName}.hospitalizationEndDate` as const}
              label={dictionary.seventhSectionFacilityFifthInput}
              minDate={hospitalizationStartDate}
            />
            <TextField
              role={
                Boolean(hospitalizationError?.doctorName) ? 'alert' : undefined
              }
              inputProps={{
                maxLength: '50',
              }}
              id={`${dictionary.seventhSectionFacilitySixthInput}-${index}`}
              error={Boolean(hospitalizationError?.doctorName)}
              helperText={
                Boolean(hospitalizationError?.doctorName?.message) &&
                generateError(
                  dictionary.seventhSectionFacilitySixthInput,
                  hospitalizationError?.doctorName?.message!
                )
              }
              {...register(
                `${hospitalBaseName}.doctorName` as `caseDetails.0.hospitalizationDetailsSection.0.doctorName`
              )}
              label={dictionary.seventhSectionFacilitySixthInput}
              onChange={(event: any) => validateLettersAndNumbersOnly(event)}
            />
          </>
        )}
        <FormSection disablePadding>
          <Typography sx={{ mt: 3 }} variant={'subtitle1'}>
            {dictionary.seventhSectionFacilityFileLabel}
          </Typography>
          <FileUpload
            caseDetailsId={caseId}
            hospitalizationId={props.hospitalizationId}
            fileLabel={dictionary.seventhSectionFacilitySixthInput}
            fileCategoryId={FileCategories.ReleaseLetter}
            ariaLabel={dictionary.seventhSectionFacilityFileLabel}
          />
        </FormSection>
      </Grid>
    </PaperCard>
  );
};
