import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface InitState {
  generalObj: {
    isDifferentMailAddress: boolean;
    isAddressTab: boolean;
  };
}

const initialState: InitState = {
  generalObj: {
    isDifferentMailAddress: false,
    isAddressTab: true,
  },
};

export const PersonalDetailsSlice = createSlice({
  name: 'personalDetails',
  initialState,
  reducers: {
    setIsDifferentMailAddress: (state, action: PayloadAction<boolean>) => {
      state.generalObj.isDifferentMailAddress = action.payload;
    },
    setIsAddressTab: (state, action: PayloadAction<boolean>) => {
      state.generalObj.isAddressTab = action.payload;
    },
  },
});

export const { setIsDifferentMailAddress, setIsAddressTab } =
  PersonalDetailsSlice.actions;

export default PersonalDetailsSlice.reducer;
