import { forwardRef, useContext, useRef, useState } from 'react';

import { Loader } from 'Components/Loader';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { FileCard } from './FileCard';
import { UploadFileButton } from './UploadFileButton';
import { FileRow } from './FileRow';
import { Typography } from '@mui/material';
import { DeleteModal } from 'Components';
import { deleteFile, postFile } from 'API/EmergencyForm';
import { OTPLoginContext } from 'Context/OTPLoginContext';
import { checkFileSize, isFileValid } from 'Utils';

export interface IFileUpload {
  loading?: boolean;
  fileCategoryId: number;
  formId: string;
  fileData?: File;
}

export const FileUpload = forwardRef<HTMLButtonElement, IFileUpload>(
  (props, ref) => {
    const dictionary = Dictionary[DICTIONARY.GENERAL];
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [showErrorDelete, setShowErrorDelete] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [currFile, setCurrFile] = useState({ id: 0, name: '' });
    const [errorMessage, setErrorMsg] = useState(dictionary.fileErrorNew);
    const [errorDeleteMessage, setErrorDeleteMsg] = useState(
      dictionary.errorDeleteMsg
    );
    const { uploadedFiles, setUploadedFiles } = useContext(OTPLoginContext);

    const checkExtension = (file: File) => {
      const allowsExtension = ['jpg', 'png', 'pdf', 'tif', 'tiff', 'jpeg'];
      const fileExt = file.name.split('.').pop();
      if (fileExt && allowsExtension.includes(fileExt.toLowerCase())) {
        return true;
      }
      return false;
    };

    const onChangeHandler = async (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const file = event.target.files;
      if (file !== null) {
        if (checkFileSize(file[0]) && checkExtension(file[0])) {
          if (!(await isFileValid(file[0]))) {
            setErrorMsg(dictionary.fileErrorNew);
            setShowError(true);
            return;
          }
          setIsLoading(true);
          const fileWithoutExtension = file[0].name.split('.');
          const fileNTimestamp =
            fileWithoutExtension[0].replace(
              /[^a-zA-Z0-9\u05D0-\u05EA\- _]/g,
              ''
            ) +
            '-' +
            Date.now();
          const fileExt = file[0].name.split('.').pop();
          const fileName = fileNTimestamp + '.' + fileExt;

          await postFileHandler(file[0], fileName);
          setShowError(false);
          return;
        }
        setShowError(true);
      }
    };

    const postFileHandler = async (file: File, fileName: string) => {
      const token = window.sessionStorage.getItem('token') || '';
      const form = new FormData();
      form.append('file', file, fileName);
      const fileData = await postFile(
        props.formId,
        props.fileCategoryId,
        form,
        token
      );
      setIsLoading(false);
      if (fileData) {
        const newFiles = [...uploadedFiles];
        newFiles.push(fileData);
        setUploadedFiles(newFiles);
        setShowError(false);
      } else {
        setShowError(true);
      }
    };

    const fileInput = useRef<HTMLInputElement | null>(null);

    const deleteFileHandler = async (fileName: string, fieldId: number) => {
      setCurrFile({ id: fieldId, name: fileName });
      setShowDeleteModal(true);
    };

    const deleteModal = async (currFile: any) => {
      const token = window.sessionStorage.getItem('token') || '';
      const fileData = await deleteFile(props.formId, currFile.id, token);

      if (fileData) {
        const indexToDelete = uploadedFiles.findIndex(
          (file) => file.id === currFile.id
        );
        if (indexToDelete !== -1) {
          const newFiles = [...uploadedFiles];
          newFiles.splice(indexToDelete, 1);
          setUploadedFiles(newFiles);
          setShowErrorDelete(false);
        } else {
          setShowErrorDelete(true);
        }
      } else {
        setShowErrorDelete(true);
      }

      if (fileInput.current) {
        fileInput.current.value = '';
      }

      setShowDeleteModal(false);
      (fileInput.current as any).value = null;
      closeDeleteModalHandler();
    };

    const closeDeleteModalHandler = () => {
      setCurrFile({ id: 0, name: '' });
      setShowDeleteModal(false);
    };

    const watchFile = async (fileData: File, fileName: string) => {
      if (fileData) window.open(URL.createObjectURL(fileData));
    };

    const clickFileInput = () => {
      if (fileInput.current) {
        fileInput.current.click();
      }
    };
    return (
      <>
        <input
          ref={(e) => {
            fileInput.current = e;
          }}
          type='file'
          accept='.jpg,.png,.pdf,.tif,tiff,jpeg'
          hidden
          onChange={onChangeHandler}
          id={`upload-file-${props.fileCategoryId}`}
        />
        <label
          htmlFor={`upload-file-${props.fileCategoryId}`}
          className='sr-only'
        >
          {`${dictionary.fileUploadLabel} ${props.fileCategoryId}`}
        </label>
        {showDeleteModal && currFile.name && (
          <DeleteModal
            isVisible={showDeleteModal}
            closeModal={closeDeleteModalHandler}
            deleteModal={() => deleteModal(currFile)}
            fileName={currFile.name}
          />
        )}

        {uploadedFiles &&
        uploadedFiles.some(
          (file) => file.fileCategoryRelationId === props.fileCategoryId
        ) ? (
          <>
            <FileCard clickHandler={clickFileInput}>
              {uploadedFiles
                .filter(
                  (file) => file.fileCategoryRelationId === props.fileCategoryId
                )
                .map((file) => (
                  <FileRow
                    key={file.userGivenName}
                    fileName={file.userGivenName}
                    watchFile={() =>
                      watchFile(props.fileData!, props.fileData!.name)
                    }
                    deleteFile={() =>
                      deleteFileHandler(file.userGivenName, file.id)
                    }
                  />
                ))}
            </FileCard>
          </>
        ) : (
          <UploadFileButton clickHandler={clickFileInput} ref={ref}>
            {dictionary.fileUploadLabel}
          </UploadFileButton>
        )}
        {showError ? (
          <Typography
            color='error'
            fontSize='14px'
            textAlign='left'
            maxWidth='310px'
            p='10px'
            role={Boolean(showError) ? 'alert' : undefined}
          >
            {errorMessage}
          </Typography>
        ) : null}
        {showErrorDelete ? (
          <Typography
            color='error'
            fontSize='14px'
            textAlign='left'
            maxWidth='310px'
            p='10px'
            role={Boolean(showErrorDelete) ? 'alert' : undefined}
          >
            {errorDeleteMessage}
          </Typography>
        ) : null}
        {isLoading && <Loader />}
      </>
    );
  }
);
