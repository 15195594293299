import { TextField, Typography } from '@mui/material';
import { IRepresentation } from 'API';
import {
  CustomToggleGroup,
  FileUpload,
  FormSection,
  PhoneNumber,
  RadioButtons,
} from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY, FileCategories } from 'Enum';
import { useFormContext, useWatch } from 'react-hook-form';
import { generateError } from 'Regex';
import { validateLettersAndNumbersOnly } from 'Utils/validateLettersAndNumbersOnly';
import { validateNumbersOnly } from 'Utils/validateNumbersOnly';

export const SecondSection = () => {
  const isRepresentedByGuardianName = 'isRepresentedByGuardian';

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const isRepresentedByGuardian = useWatch({
    name: isRepresentedByGuardianName,
    defaultValue: false,
  }) as boolean;

  const dictionary = Dictionary[DICTIONARY.REPRESENTATION];
  const generalDictionary = Dictionary[DICTIONARY.GENERAL];
  return (
    <FormSection grouped id={dictionary.secondSectionTitle}>
      <Typography
        sx={{ fontWeight: 'bold' }}
        id={dictionary.secondSectionTitle}
      >
        {dictionary.secondSectionTitle}
      </Typography>
      <Typography>{dictionary.secondSectionInfoLabel}</Typography>
      <RadioButtons
        name={isRepresentedByGuardianName}
        options={[
          {
            value: 'true',
            label: dictionary.secondSectionToggleButtonRightValue,
          },
          {
            value: 'false',
            label: dictionary.secondSectionToggleButtonLeftValue,
          },
        ]}
        id={dictionary.secondSectionToggleButtonLabel}
      />
      {isRepresentedByGuardian && (
        <>
          <TextField
            role={
              Boolean(errors.guardianSection?.firstName) ? 'alert' : undefined
            }
            inputProps={{
              maxLength: '20',
            }}
            error={Boolean(errors.guardianSection?.firstName)}
            helperText={
              Boolean(errors.guardianSection?.firstName?.message) &&
              generateError(
                dictionary.secondSectionFirstInput,
                errors.guardianSection?.firstName?.message!
              )
            }
            {...register('guardianSection.firstName')}
            label={dictionary.secondSectionFirstInput}
            id={dictionary.secondSectionFirstInput}
            onChange={(event: any) => validateLettersAndNumbersOnly(event)}
          />
          <TextField
            role={
              Boolean(errors.guardianSection?.lastName) ? 'alert' : undefined
            }
            inputProps={{
              maxLength: '30',
            }}
            error={Boolean(errors.guardianSection?.lastName)}
            helperText={
              Boolean(errors.guardianSection?.lastName?.message) &&
              generateError(
                dictionary.secondSectionSecondInput,
                errors.guardianSection?.lastName?.message!
              )
            }
            {...register('guardianSection.lastName')}
            label={dictionary.secondSectionSecondInput}
            id={dictionary.secondSectionSecondInput}
            onChange={(event: any) => validateLettersAndNumbersOnly(event)}
          />
          <TextField
            role={
              Boolean(errors.guardianSection?.idNumber) ? 'alert' : undefined
            }
            inputProps={{
              maxLength: '9',
            }}
            error={Boolean(errors.guardianSection?.idNumber)}
            helperText={
              Boolean(errors.guardianSection?.idNumber?.message) &&
              generateError(
                dictionary.secondSectionGuardianId,
                errors.guardianSection?.idNumber?.message!
              )
            }
            {...register('guardianSection.idNumber')}
            label={dictionary.secondSectionGuardianId}
            id={dictionary.secondSectionGuardianId}
            onKeyDown={(event) => validateNumbersOnly(event)}
          />
          <TextField
            role={
              Boolean(errors.guardianSection?.address) ? 'alert' : undefined
            }
            inputProps={{
              maxLength: '50',
            }}
            error={Boolean(errors.guardianSection?.address)}
            helperText={
              Boolean(errors.guardianSection?.address?.message) &&
              generateError(
                dictionary.secondSectionThirdInput,
                errors.guardianSection?.address?.message!
              )
            }
            {...register('guardianSection.address')}
            label={dictionary.secondSectionThirdInput}
            id={dictionary.secondSectionThirdInput}
            onChange={(event: any) => validateLettersAndNumbersOnly(event)}
          />
          <PhoneNumber
            phoneNumberErrorMsg={errors.guardianSection?.phone?.message}
            phoneNumberError={Boolean(errors.guardianSection?.phone?.message)}
            phoneNumberPrefixFormName='guardianSection.phonePrefixRelationId'
            phoneNumberFormName='guardianSection.phone'
            phoneNumberLabel={dictionary.secondSectionFourthInput}
          />
          <TextField
            role={Boolean(errors.guardianSection?.mail) ? 'alert' : undefined}
            inputProps={{
              maxLength: '50',
            }}
            error={Boolean(errors.guardianSection?.mail)}
            helperText={
              Boolean(errors.guardianSection?.mail?.message) &&
              generateError(
                dictionary.secondSectionFifthInput,
                errors.guardianSection?.mail?.message!
              )
            }
            {...register('guardianSection.mail')}
            label={dictionary.secondSectionFifthInput}
            id={dictionary.secondSectionFifthInput}
          />
          <Typography sx={{ marginTop: 3 }}>
            {dictionary.secondSectionFileUploadLabel}
            <Typography color='error' component='span'>
              {generalDictionary.required}
            </Typography>
          </Typography>
          <FileUpload
            dataRequired={isRepresentedByGuardian}
            fileLabel={dictionary.secondSectionFileUploadText}
            fileCategoryId={FileCategories.GuardianApprovalAttachment}
            ariaLabel={dictionary.secondSectionFileUploadLabel}
          />
        </>
      )}
    </FormSection>
  );
};
