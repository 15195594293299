import {
  Avatar,
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { ArrowLeft, background, NotFound } from 'Images';
import { useNavigate } from 'react-router-dom';
import { Footer, Header } from 'Components';
import { useIsDesktop } from 'Hooks';

export const NotFoundPage = () => {
  const navigate = useNavigate();
  const onClickHandler = () => {
    navigate(-1);
  };
  const dictionary = Dictionary[DICTIONARY.NOT_FOUND];

  const isDesktop = useIsDesktop();

  return (
    <>
      <Header />
      <Grid
        container
        sx={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundOrigin: 'border-box',
        }}
      >
        <Container maxWidth='xl'>
          <Stack alignItems='center' sx={{ textAlign: 'center' }}>
            <Avatar
              src={NotFound}
              sx={
                isDesktop
                  ? {
                      width: '614.82px',
                      height: '304.38px',
                      marginTop: '50px',
                    }
                  : {
                      width: '310px',
                      height: '150px',
                      marginTop: '50px',
                    }
              }
            />
            <Typography
              variant='h1'
              mt={10}
              fontWeight={700}
              sx={
                isDesktop
                  ? {}
                  : {
                      fontSize: '24px',
                      marginBottom: '20px',
                      padding: '0 30px',
                      mt: '50px',
                    }
              }
            >
              {dictionary.title}
            </Typography>
            <Typography
              variant='h2'
              sx={isDesktop ? {} : { fontSize: '18px', padding: '0 30px' }}
            >
              {dictionary.subTitle}
            </Typography>
            <Paper
              elevation={0}
              sx={{
                backgroundColor: '#F2F9FF',
                padding: isDesktop ? '8px 95px' : '12px 20px',
                mt: 4,
                borderRadius: '8px',
              }}
            >
              <Typography
                textAlign='center'
                sx={isDesktop ? {} : { padding: '0' }}
              >
                {dictionary.info1}
              </Typography>
              <Typography
                textAlign='center'
                sx={isDesktop ? {} : { padding: '0 30px' }}
              >
                {dictionary.info2}
              </Typography>
            </Paper>
            <Button
              onClick={onClickHandler}
              sx={{
                mt: 5,
                mb: 5,
              }}
              variant='contained'
              endIcon={
                <Avatar
                  src={ArrowLeft}
                  sx={{ width: '24px', height: '24px' }}
                />
              }
            >
              {dictionary.buttonLabel}
            </Button>
          </Stack>
        </Container>
      </Grid>
      <Footer />
    </>
  );
};
