import Lottie from 'lottie-react';
import animationData from './loader.json';
import { Grid } from '@mui/material';

export const Loader = () => {
  return (
    <Grid sx={{ maxWidth: '220px', margin: '0 auto' }}>
      <Lottie animationData={animationData} loop />
    </Grid>
  );
};
