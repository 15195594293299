import {Grid, MenuItem, Typography} from '@mui/material';
import { ControllerDatePicker, ControllerSelect, PaperCard } from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import {useAppSelector, useIsDesktop} from 'Hooks';
import { IndexContext } from 'Modules/CaseDetails/CaseContext';
import { useContext } from 'react';
import { SummaryCollapseBox } from './SummaryCollapseBox';

interface IPreviousHospitalCard {
  deleteHospital: () => void;
}
export const PreviousHospitalCard = (props: IPreviousHospitalCard) => {
  const hmOs = useAppSelector(
    ({
      generalContent: {
        generalContent: { hmOs },
      },
    }) => hmOs
  );

  const { index } = useContext(IndexContext);

  const baseName = `previousHMOSection.${index}`;
  const isDesktop = useIsDesktop();

  return (
    <PaperCard
      remove={props.deleteHospital}
      text={<SummaryCollapseBox />}
      isOpen={true}
    >
      <Grid container gap={4}>
        <div>
          <Typography color='primary' sx={{ fontWeight: 'bold', pl: isDesktop ? '0' : '40px' }}>
            {Dictionary[DICTIONARY.PERSONAL_DETAILS].fifthSectionTitle}
          </Typography>
          <Typography color='primary' sx={{ pl: isDesktop ? '0' : '40px' }}>
            {Dictionary[DICTIONARY.PERSONAL_DETAILS].fifthSectionSubTitle}
          </Typography>
        </div>
        <ControllerSelect
          name={`${baseName}.hmoRelationId` as const}
          label={Dictionary[DICTIONARY.PERSONAL_DETAILS].fifthSectionFirdtInput}
        >
          {hmOs.map(({ value, id }) => (
            <MenuItem key={id} value={id}>
              {value}
            </MenuItem>
          ))}
        </ControllerSelect>
        <ControllerDatePicker
          name={`${baseName}.hospitalizationEndDate`}
          label={
            Dictionary[DICTIONARY.PERSONAL_DETAILS].fifthSectionSecondInput
          }
          viewYearOnly={true}
        />
      </Grid>
    </PaperCard>
  );
};
