import { api } from 'API';

const baseUrl = '/PersonalDetail';

export interface PersonalInfoSection {
  birthDate?: string | null;
  birthCountryId?: number | null;
  immigrationYear?: string | null;
  genderId?: number | null;
  maritalStatusId?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  israeliId?: string | null;
}

export interface ContactInfoSection {
  phonePrefixRelationId?: string | null;
  phoneNumber?: string | null;
  extraPhonePrefixRelationId?: string | null;
  extraPhoneNumber?: string | null;
  mail?: string | null;
  hasNoMail?: boolean | null;
}

export interface IsraeliAddressRelation {
  israeliTownId?: string | null;
  townId?: string | null;
  street?: string | null;
  homeNumHouse?: string | null;
  apartmentNumber?: string | null;
  israeliZipCode?: string | null;
  po?: string | null;
}

export interface AbroadAddressRelation {
  abroadTown?: string | null;
  countryId?: string | null;
  townId?: string | null;
  street?: string | null;
  homeNumHouse?: string | null;
  apartmentNumber?: string | null;
  abroadZipCode?: string | null;
  po?: string | null;
}

export interface ResidentialAddressSection {
  isAbroadResidentialAddress?: boolean; // No more toggle for Israel and outOfSeas
  israeliAddressRelation?: IsraeliAddressRelation;
  abroadAddressRelation?: AbroadAddressRelation;
  isDifferentMailAddress?: boolean;
}

export interface MailBoxSection {
  townId?: string | null;
  street?: string | null;
  mailHouseNumber?: string | null;
  apartmentNumber?: string | null;
  zipCode?: string | null;
  po?: string | null;
}

export interface HmoSection {
  hmoRelationId?: number | null;
  doctorsNameAndSpecialization?: string | null;
}

export interface PreviousHMOSection {
  hospitalizationEndDate?: string | null;
  hmoRelationId?: number | null;
  doctorsNameAndSpecialization?: string | null;
}

export interface IPersonalDetails {
  personalInfoSection?: PersonalInfoSection;
  contactInfoSection?: ContactInfoSection;
  residentialAddressSection?: ResidentialAddressSection;
  mailBoxSection?: MailBoxSection;
  hmoSection?: HmoSection;
  previousHMOSection?: PreviousHMOSection[];
}

export const previousHMOSectionEmptyObject: PreviousHMOSection = {
  doctorsNameAndSpecialization: undefined,
  hmoRelationId: undefined,
  hospitalizationEndDate: undefined,
};

export const getPersonalDetail = async () => {
  const personalDetail = await api.get<IPersonalDetails>(baseUrl);
  if (personalDetail.data) {
    return personalDetail.data;
  }
};

export const putPersonalDetail = async (data: Partial<IPersonalDetails>) => {
  const personalDetail = await api.put<IPersonalDetails>(baseUrl, data);
  if (personalDetail.status === 200) {
    return personalDetail.data;
  }
};
