import { Box, InputProps, SxProps, TextField, Typography } from '@mui/material';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { onlyNumbers } from 'Regex';
import { regexFormController } from 'Utils/regexFormController';
import { useEffect, useState } from 'react';

interface IControlledTextField {
  label?: string;
  ariaLabel?: string;
  ariaDescribe?: string;
  ariaRequired?: boolean;
  defaultValue?: string;
  value: string;
  size?: 'small' | 'medium';
  autoFocus?: boolean;
  showError?: boolean;
  errorMsg?: string;
  errorMsgSx?: SxProps;
  sx?: SxProps;
  inputSx?: SxProps;
  labelSx?: SxProps;
  type?: string;
  setValue: Function;
  inputLimit?: number;
  inputRegexType?: 'NUMBER' | 'ID' | 'PHONE' | 'FULL_PHONE' | 'NAME' | 'EMAIL';
  error: string | null;
  setError: (error: string | null) => void;
  customInputProps?: any;
  autocomplete?: string;
  inputmode?:
    | 'text'
    | 'search'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | undefined;
}

export const ControlledTextField = ({
  label,
  ariaLabel,
  ariaDescribe,
  ariaRequired = false,
  defaultValue,
  value,
  setValue,
  size,
  autoFocus,
  showError,
  errorMsgSx,
  sx,
  inputSx,
  labelSx,
  type = 'text',
  inputLimit = Number.MAX_SAFE_INTEGER,
  inputRegexType,
  error,
  setError,
  customInputProps,
  autocomplete,
  inputmode,
}: IControlledTextField) => {
  const [inputValue, setInputValue] = useState<string>(value);

  useEffect(() => {
    if (inputValue.length === 0) return;
    regexFormController(inputRegexType, inputValue, setError);
  }, [inputRegexType]);

  const onChangeDefaultHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setError(null);
    const val: string = event.target.value;
    switch (inputRegexType) {
      case 'ID': {
        if (onlyNumbers.test(val) && val.length <= inputLimit) {
          setInputValue(val);
          setValue(val);
        }
        break;
      }
      case 'PHONE': {
        if (onlyNumbers.test(val) && val.length <= inputLimit) {
          setInputValue(val);
          setValue(val);
        }
        break;
      }
      default: {
        if (val.length <= inputLimit) {
          setInputValue(val);
          setValue(val);
        }
      }
    }
  };

  const onBlurDefaultHandler = (event: React.FocusEvent<HTMLInputElement>) =>
    regexFormController(inputRegexType, event.target.value, setError);

  return (
    <Box width='100%'>
      <TextField
        defaultValue={defaultValue}
        value={inputValue}
        label={label}
        aria-label={ariaLabel}
        aria-invalid='true'
        aria-describedby={ariaDescribe}
        aria-required={ariaRequired}
        autoFocus={autoFocus}
        autoComplete={autocomplete}
        error={error !== null && showError}
        size={size || 'small'}
        type={type}
        inputMode={inputmode}
        sx={{
          bgcolor: 'white',
          borderRadius: '8px',
          width: '100%',

          '& fieldset': {
            border: '1px solid',
            borderColor: 'primary.shade_200',
            borderRadius: '8px',
          },
          '& label.Mui-focused': {
            color: '#1525E8',
          },
          ...sx,
        }}
        inputProps={{
          ...customInputProps,
          sx: {
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px white inset',
            },
            ...inputSx,
          },
        }}
        InputLabelProps={{
          sx: {
            fontSize: '16px',
            fontFamily: 'inherit',
            ...labelSx,
          },
        }}
        onChange={onChangeDefaultHandler}
        onBlur={onBlurDefaultHandler}
      />
      {error && showError && (
        <Typography
          color={'error.main'}
          sx={{
            margin: '0 0 0 7px',
            ...errorMsgSx,
          }}
          aria-live='assertive'
          role='alert'
        >
          {error || Dictionary[DICTIONARY.GENERAL].required}
        </Typography>
      )}
    </Box>
  );
};
