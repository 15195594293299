import { styled, Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
export const CustomToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} enterTouchDelay={0}/>
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    '::before': {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '16px 24px',
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 4px 8px 0px #2D70D929',
    fontSize: 11,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '16px',
  },
}));
