import { IEmploymentAndBankDetails } from 'API';
import * as yup from 'yup';
import {
  textTooLong,
  onlyNumbers,
  lettersOrNumbersNoSpecialChars,
  lettersOrNumbersWithExtraChars,
  lettersOrNumbersNoSpecialCharsMessage,
  requiredMessage,
  onlyNumbersMessage,
  phoneNumberMessage,
  phoneNumberNotRequired,
} from 'Regex';
import { bankAccountValidation } from 'Utils/bankAccountValidation';
import { store } from 'Store';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';

export const employmentAndBankDetailSchema: yup.SchemaOf<IEmploymentAndBankDetails> =
  yup.object().shape({
    bankAccountDetailsSection: yup.object({
      accountNumber: yup
        .string()
        .required(requiredMessage)
        .nullable()
        .max(11, textTooLong(11))
        .matches(onlyNumbers, onlyNumbersMessage)
        .test(
          'bankAccountValidation',
          Dictionary[DICTIONARY.EMPLOYMENT_AND_BANK_DETAILS]
            .accountNumberErrorMsg,
          (val) =>
            bankAccountValidation(
              store.getState().employmentAndBankDetails.bankNumber,
              store.getState().employmentAndBankDetails.branchNumber,
              parseInt(val!)
            )
        ),
      bankRelationId: yup.string().required(requiredMessage).nullable(),
      branchRelationId: yup.string().nullable().required(requiredMessage),
    }),
    workPlaceDetailsSection: yup.object({
      employmentStatusId: yup.string().required(requiredMessage).nullable(),
      jobTitle: yup
        .string()
        .notRequired()
        .max(500, textTooLong(500))
        .nullable(),
      landlinePhoneNum: yup
        .string()
        .notRequired()
        .nullable()
        .matches(phoneNumberNotRequired, phoneNumberMessage),
      landlinePrefixRelationId: yup.string().notRequired().nullable(),
      managerName: yup
        .string()
        .notRequired()
        .nullable()
        .max(50, textTooLong(50))
        .matches(
          lettersOrNumbersNoSpecialChars,
          lettersOrNumbersNoSpecialCharsMessage
        ),
      managerPhoneNum: yup
        .string()
        .notRequired()
        .nullable()
        .matches(phoneNumberNotRequired, phoneNumberMessage),
      managerPhonePrefixRelationId: yup.string().notRequired().nullable(),
      workName: yup
        .string()
        .notRequired()
        .nullable()
        .max(50, textTooLong(50))
        .matches(
          lettersOrNumbersNoSpecialChars,
          lettersOrNumbersNoSpecialCharsMessage
        ),
      workAddress: yup
        .string()
        .notRequired()
        .nullable()
        .max(50, textTooLong(50))
        .matches(
          lettersOrNumbersWithExtraChars,
          lettersOrNumbersNoSpecialCharsMessage
        ),
    }),
  });
