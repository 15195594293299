import { api } from 'API';

const baseUrl = '/bank';

export interface IBank {
  id: number;
  value: string;
}

export const getBanks = async () => {
  const banksData = await api.get<IBank[]>(baseUrl);
  if (banksData.data) {
    return banksData.data.map((bank) => ({
      id: bank.id,
      value: `${bank.value}, ${bank.id}`,
    }));
  }
};

export const fetchBanks = async () => {
  const banksData = await fetch(`${process.env.REACT_APP_DOMAIN}/bank`);
  const result = await banksData.json();

  if (result) {
    return result;
  }
};
