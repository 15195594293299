import { Typography } from '@mui/material';
import { FileUpload, FormSection } from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY, FileCategories } from 'Enum';
import { useContext } from 'react';
import { IndexContext } from '../CaseContext';
export const FifthSection = () => {
  const { fieldId } = useContext(IndexContext);

  return (
    <FormSection
      grouped
      id={Dictionary[DICTIONARY.CASE_DETAILS].fifthSectionTitle}
    >
      <Typography
        sx={{ fontWeight: 'bold', mb: 3 }}
        id={Dictionary[DICTIONARY.CASE_DETAILS].fifthSectionTitle}
      >
        {Dictionary[DICTIONARY.CASE_DETAILS].fifthSectionTitle}
      </Typography>
      <Typography variant={'subtitle1'}>
        {Dictionary[DICTIONARY.CASE_DETAILS].fifthSectionFirstFileUploadLabel}
      </Typography>
      <FileUpload
        caseDetailsId={fieldId}
        fileLabel={
          Dictionary[DICTIONARY.CASE_DETAILS].fifthSectionFirstFileUploadText
        }
        fileCategoryId={FileCategories.InjuryFromTheUnitOrCommandStaff}
        ariaLabel={
          Dictionary[DICTIONARY.CASE_DETAILS].fifthSectionFirstFileUploadLabel
        }
      />
    </FormSection>
  );
};
