import { Button, Grid, Stack, Typography } from '@mui/material';
import { DICTIONARY } from 'Enum';
import { Dictionary } from 'Dictionary';
import { useNavigate } from 'react-router-dom';
import { RoutesConfig } from 'Routes';
import { useIsDesktop } from 'Hooks';

interface IFormAutoSaveTypography {
  save: () => Promise<void>;
}

export const FormAutoSaveTypography = (props: IFormAutoSaveTypography) => {
  const isDesktop = useIsDesktop();
  const buttonSx = isDesktop ? { minWidth: '225px !important' } : {};
  const navigate = useNavigate();
  const returnToTheMainLink = async () => {
    await props.save();
    navigate(RoutesConfig.STATUS_LOBBY.PATH);
  };
  return (
    <Stack>

      <Grid container justifyContent='center' spacing={1}>
        <Grid item>
          <Button
            sx={buttonSx}
            variant='outlined'
            onClick={returnToTheMainLink}
          >
            {Dictionary[DICTIONARY.GENERAL].goBackToTheMainScreen}
          </Button>
        </Grid>
        <Grid item>
          <Button sx={buttonSx} variant='contained' type='submit'>
            {Dictionary[DICTIONARY.GENERAL].finishStep}
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
};
