import { api } from 'API';

const baseUrl = '/generalContent';

export interface PhonePrefix {
  id: number;
  value: string;
}

export interface Gender {
  id: number;
  value: string;
}

export interface CaseType {
  id: number;
  value: string;
}

export interface MaritalStatus {
  id: number;
  value: string;
}

export interface HmO {
  id: number;
  value: string;
}

export interface OwnershipVehicleType {
  id: number;
  value: string;
}

export interface WitnessType {
  id: number;
  value: string;
}

export interface ServiceType {
  id: number;
  value: string;
}

export interface MilitaryRank {
  id: number;
  value: string;
}

export interface MilitaryTroop {
  id: number;
  value: string;
}

export interface LocationAddressCategory {
  id: number;
  value: string;
}

export interface RequestReasonTypes {
  id: number;
  value: string;
}

export interface ReportAttachedOptions {
  isReportFileAttached: boolean;
  id: number;
  value: string;
}

interface InvestigationDirectorOptions {
  id: number;
  value: string;
}

export interface AreThereWitnessesOptions {
  areThereWitnesses: boolean;
  id: number;
  value: string;
}

export interface EmploymentStatus {
  id: number;
  value: string;
}

export interface RepresentationOption {
  id: number;
  value: string;
}

export interface IGeneralContent {
  phonePrefixes: PhonePrefix[];
  genders: Gender[];
  caseTypes: CaseType[];
  maritalStatuses: MaritalStatus[];
  hmOs: HmO[];
  ownershipVehicleTypes: OwnershipVehicleType[];
  witnessTypes: WitnessType[];
  serviceTypes: ServiceType[];
  militaryRanks: MilitaryRank[];
  militaryTroops: MilitaryTroop[];
  locationAddressCategories: LocationAddressCategory[];
  requestReasonTypes: RequestReasonTypes[];
  reportAttachedOptions: ReportAttachedOptions[];
  investigationDirectorOptions: InvestigationDirectorOptions[];
  areThereWitnessesOptions: AreThereWitnessesOptions[];
  employmentStatuses: EmploymentStatus[];
  representationOptions: RepresentationOption[];
  injuryLevelOptions: RequestReasonTypes[];
}

export const getGeneralContent = async () => {
  const generalContentData = await api.get<IGeneralContent>(baseUrl);
  if (generalContentData.data) {
    return generalContentData.data;
  }
};

export const fetchGeneralContent = async () => {
  const generalContentData = await fetch(
    `${process.env.REACT_APP_DOMAIN}/generalContent`
  );
  const result = await generalContentData.json();

  if (result) {
    return result;
  }
};
