import { IEmergencyForm } from 'API/EmergencyForm';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import {
  textTooLong,
  onlyNumbers,
  onlyNumbersMessage,
  emailWithEmpty,
  emailMessage,
  requiredMessage,
  lettersOrNumbersNoSpecialChars,
  lettersOrNumbersNoSpecialCharsMessage,
  lettersOrNumbersWithExtraChars,
  lettersOrNumbersWithExtraCharsMessage,
  validateIsraeliIdMessage,
  isValidIsraeliID,
} from 'Regex';
import { bankAccountValidation } from 'Utils/bankAccountValidation';
import * as yup from 'yup';

// Not in use yet not enough time to implement schema
export const personalDetailsSchema: yup.SchemaOf<IEmergencyForm> = yup
  .object()
  .shape({
    firstName: yup
      .string()
      .test('isRequired', requiredMessage, (value) => value !== '')
      .max(20, textTooLong(20))
      .matches(
        lettersOrNumbersNoSpecialChars,
        lettersOrNumbersNoSpecialCharsMessage
      ),
    lastName: yup
      .string()
      .required(requiredMessage)
      .max(30, textTooLong(30))
      .matches(
        lettersOrNumbersNoSpecialChars,
        lettersOrNumbersNoSpecialCharsMessage
      ),
    israeliId: yup
      .string()
      .required(requiredMessage)
      .test('isValidIsraeliID', validateIsraeliIdMessage, (value: any) =>
        value === '' ? true : isValidIsraeliID(value)
      )
      .nullable(),
    genderId: yup.number().required(requiredMessage).nullable(),
    fathersName: yup
      .string()
      .notRequired()
      .max(15, textTooLong(15))
      .matches(
        lettersOrNumbersNoSpecialChars,
        lettersOrNumbersNoSpecialCharsMessage
      )
      .nullable(),
    maritalStatusId: yup.number().required(requiredMessage).nullable(),
    birthDate: yup.string().required(requiredMessage).nullable(),
    role: yup
      .string()
      .max(50, textTooLong(50))
      .matches(
        lettersOrNumbersWithExtraChars,
        lettersOrNumbersWithExtraCharsMessage
      )
      .notRequired()
      .nullable(),
    israeliTownId: yup.string().required(requiredMessage).nullable(),
    street: yup
      .string()
      .matches(
        lettersOrNumbersNoSpecialChars,
        lettersOrNumbersNoSpecialCharsMessage
      )
      .required(requiredMessage)
      .nullable(),
    homeNumHouse: yup
      .string()
      .notRequired()
      .matches(
        lettersOrNumbersNoSpecialChars,
        lettersOrNumbersNoSpecialCharsMessage
      )
      .nullable(),
    apartmentNumber: yup
      .string()
      .notRequired()
      .matches(onlyNumbers, onlyNumbersMessage)
      .nullable(),
    israeliZipCode: yup
      .string()
      .notRequired()
      .test('israeliZipCode', 'חייב להכיל 7 ספרות', (value) => {
        if (value && value.length > 0 && value.length < 7) {
          return false;
        }
        return true;
      })
      .matches(onlyNumbers, onlyNumbersMessage)
      .nullable(),
    phonePrefixRelationId: yup.string().notRequired().nullable(),
    phoneNumber: yup
      .string()
      .matches(onlyNumbers, onlyNumbersMessage)
      .max(7, textTooLong(7))
      .test('phoneNumber', 'חייב להכיל 7 ספרות', (value) => {
        if (value && value.length > 0 && value.length < 7) {
          return false;
        }
        return true;
      })
      .notRequired()
      .nullable(),
    extraPhonePrefixRelationId: yup.string().notRequired().nullable(),
    extraPhoneNumber: yup
      .string()
      .matches(onlyNumbers, onlyNumbersMessage)
      .max(10, textTooLong(10))
      .test('phoneNumber', 'חייב להכיל 9 - 10 ספרות', (value) => {
        if (value && value.length > 0 && value.length < 7) {
          return false;
        }
        return true;
      })
      .notRequired()
      .nullable(),
    email: yup
      .string()
      .matches(emailWithEmpty, emailMessage)
      .max(50, textTooLong(50))
      .notRequired()
      .nullable(),
    serviceTypeId: yup.string().notRequired().nullable(),
    injuryDate: yup.string().notRequired().nullable(),
    isHospitalization: yup.boolean().notRequired().nullable(),
    injuryDescription: yup
      .string()
      .max(500, textTooLong(500))
      .matches(
        lettersOrNumbersWithExtraChars,
        lettersOrNumbersWithExtraCharsMessage
      )
      .required(requiredMessage)
      .nullable(),
    injuryLevelOptionId: yup.string().required(requiredMessage).nullable(),
    bankRelationId: yup.string().required(requiredMessage).nullable(),
    branchRelationId: yup.string().nullable().required(requiredMessage),
    accountNumber: yup
      .string()
      .max(11, textTooLong(11))
      .matches(onlyNumbers, onlyNumbersMessage)
      .test({
        name: 'bankAccountValidation',
        params: {},
        message:
          Dictionary[DICTIONARY.EMPLOYMENT_AND_BANK_DETAILS]
            .accountNumberErrorMsg,
        test: function (value) {
          return (
            value === '' ||
            bankAccountValidation(
              this.parent.bankRelationId,
              this.parent.branchRelationId,
              parseInt(value!)
            )
          );
        },
      })
      .required(requiredMessage)
      .nullable(),
    familyContactFirstName: yup
      .string()
      .notRequired()
      .max(20, textTooLong(20))
      .matches(
        lettersOrNumbersNoSpecialChars,
        lettersOrNumbersNoSpecialCharsMessage
      )
      .nullable(),
    familyContactLastName: yup
      .string()
      .notRequired()
      .max(30, textTooLong(30))
      .matches(
        lettersOrNumbersNoSpecialChars,
        lettersOrNumbersNoSpecialCharsMessage
      )
      .nullable(),
    familyContactPhonePrefixRelationId: yup.string().notRequired().nullable(),
    familyContactPhoneNumber: yup
      .string()
      .matches(onlyNumbers, onlyNumbersMessage)
      .max(7, textTooLong(7))
      .test('phoneNumber', 'חייב להכיל 7 ספרות', (value) => {
        if (value && value.length > 0 && value.length < 7) {
          return false;
        }
        return true;
      })
      .notRequired()
      .nullable(),
    formFillerFirstName: yup
      .string()
      .notRequired()
      .max(20, textTooLong(20))
      .matches(
        lettersOrNumbersNoSpecialChars,
        lettersOrNumbersNoSpecialCharsMessage
      )
      .nullable(),
    formFillerLastName: yup
      .string()
      .notRequired()
      .max(30, textTooLong(30))
      .matches(
        lettersOrNumbersNoSpecialChars,
        lettersOrNumbersNoSpecialCharsMessage
      )
      .nullable(),
    formFillerPhonePrefixRelationId: yup.string().notRequired().nullable(),
    formFillerPhoneNumber: yup
      .string()
      .matches(onlyNumbers, onlyNumbersMessage)
      .max(7, textTooLong(7))
      .test('phoneNumber', 'חייב להכיל 7 ספרות', (value) => {
        if (value && value.length > 0 && value.length < 7) {
          return false;
        }
        return true;
      })
      .notRequired()
      .nullable(),
    isCanNotSign: yup.boolean().notRequired().nullable(),
    signerFullName: yup.string().when('isCanNotSign', {
      is: true,
      then: yup
        .string()
        .required(requiredMessage)
        .max(50, textTooLong(50))
        .nullable(),
      otherwise: yup.string().notRequired().max(50, textTooLong(50)).nullable(),
    }),
    signerId: yup.string().when('isCanNotSign', {
      is: true,
      then: yup
        .string()
        .required(requiredMessage)
        .test('isValidIsraeliID', validateIsraeliIdMessage, (value: any) =>
          value === '' ? true : isValidIsraeliID(value)
        )
        .nullable(),
      otherwise: yup.string().notRequired().nullable(),
    }),
    signerPhone: yup
      .string()
      .matches(onlyNumbers, onlyNumbersMessage)
      .max(10, textTooLong(10))
      .test('phoneNumber', 'חייב להכיל 9 - 10 ספרות', (value) => {
        if (value && value.length > 0 && value.length < 7) {
          return false;
        }
        return true;
      })
      .notRequired()
      .nullable(),
    signerRelation: yup
      .string()
      .max(30, textTooLong(30))
      .notRequired()
      .nullable(),
    note: yup
      .string()
      .max(500, textTooLong(500))
      .matches(
        lettersOrNumbersWithExtraChars,
        lettersOrNumbersWithExtraCharsMessage
      )
      .notRequired()
      .nullable(),
  });
