import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { useMediaQuery, useTheme } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface IState {
  isOpen: boolean;
  closeModal: () => void;
}

export const SubmitDialog = (props: IState) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <div>
      <BootstrapDialog onClose={props.closeModal} open={props.isOpen}>
        <DialogTitle
          sx={{
            m: '0 auto',
            p: '50px',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          {Dictionary[DICTIONARY.STATUS_LOBBY].submitDialogTitle}
          <Typography sx={{ fontWeight: 'bold' }}>
            {Dictionary[DICTIONARY.STATUS_LOBBY].submitDialogSubTitle}
          </Typography>
          <IconButton
            aria-label='close'
            onClick={props.closeModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            X
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{ mb: '30px', p: isDesktop ? '0 60px 0 60px' : '0px' }}
          >
            {Dictionary[DICTIONARY.STATUS_LOBBY].submitDialogText}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ margin: '0px auto 30px auto' }}>
          <Button onClick={props.closeModal}>
            {Dictionary[DICTIONARY.STATUS_LOBBY].submitDialogButton}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
