import { Paper, useMediaQuery, useTheme } from '@mui/material';

export const FormPaper: React.FC<{}> = ({ children }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Paper
      sx={{
        boxShadow: '0px 3px 21px 0px #2D70D926',
        padding: isDesktop ? '60px 95px' : '38px 16px 48px 16px',
        maxWidth: '780px',
        mb: '64px',
        position: 'relative',
      }}
    >
      {children}
    </Paper>
  );
};
