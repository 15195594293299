import {IPersonalDetails, previousHMOSectionEmptyObject} from 'API/PersonalDetail';
import { FormSection, AddPaperCard } from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { IndexProvider} from 'Modules/CaseDetails/CaseContext';
import { useRef, useState } from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';
import { DeleteModal } from '../DeleteModal';
import { PreviousHospitalCard } from '../PreviousHospitalCard';
import {useAppSelector} from "Hooks";

export const FifthSection = () => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const chosenHospitalIndex = useRef<number>(0);

  const {getValues} = useFormContext<IPersonalDetails>();
  const hmos = useAppSelector(({generalContent: {generalContent: {hmOs}}}) => hmOs);
  const {previousHMOSection = []} = getValues();

  const preHmoName = previousHMOSection[chosenHospitalIndex.current] && hmos.find((hmo => hmo.id === previousHMOSection[chosenHospitalIndex.current].hmoRelationId))?.value;

  const { fields, append, remove } = useFieldArray({
    name: 'previousHMOSection',
  });

  const addHospitalHandler = () => {
    append({ ...previousHMOSectionEmptyObject });
  };

  const deleteHospital = (index: number) => {
    remove(index);
    setShowDeleteModal(false);
  };

  const onDeleteHandler = (index: number) => {
    setShowDeleteModal(true);
    chosenHospitalIndex.current = index;
  };

  const closeDeleteModalHandler = () => {
    setShowDeleteModal(false);
  };

  return (
    <>
      <FormSection disablePadding>
        {fields.map(({ id }, index) => (
          <IndexProvider index={index} key={index}>
            <PreviousHospitalCard
              key={id}
              deleteHospital={() => onDeleteHandler(index)}
            />
          </IndexProvider>
        ))}
        <AddPaperCard
          label={
            Dictionary[DICTIONARY.PERSONAL_DETAILS].fifthSectionAddNewHospital
          }
          add={addHospitalHandler}
        />
      </FormSection>
       {showDeleteModal && (
        <DeleteModal
          isVisible={showDeleteModal}
          closeModal={closeDeleteModalHandler}
          deleteHospital={() => deleteHospital(chosenHospitalIndex.current)}
          preHmoName={preHmoName || ''}
        />
      )}
    </>
  );
};
