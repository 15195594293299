import { api } from 'API';

const baseUrl = '/branch';

export interface IBranch {
  branchIdNumber: number;
  street: string;
  city: string;
  id: number;
  value: string;
  bankId: number;
}

export interface IBranchObj {
  [key: string]: IBranch[];
}

export const getBranches = async () => {
  const branchesData = await api.get<IBranchObj>(baseUrl);
  if (branchesData.data) {
    return branchesData.data;
  }
};

export const fetchBranches = async () => {
  const branchesData = await fetch(`${process.env.REACT_APP_DOMAIN}/branch`);
  const result = await branchesData.json();

  if (result) {
    return result;
  }
};
