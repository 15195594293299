import {
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  FormAutoSaveTypography,
  FormTitle,
  HomePageFormLink,
} from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { useEffect, useRef } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FirstSection } from './FirstSection';
import {
  getWaiverOfMedicalConfidentiality,
  IWaiverOfMedicalConfidentiality,
  putWaiverOfMedicalConfidentiality,
} from 'API';
import { Helmet } from 'react-helmet-async';
import { initFocus } from 'Utils';
import { useNavigate } from 'react-router-dom';
import { RoutesConfig } from 'Routes';
type Inputs = IWaiverOfMedicalConfidentiality;
export const WaiverOfMedicationConfidentiality = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const methods = useForm<Inputs>({
    defaultValues: {
      isConfirm: false,
    },
  });

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    navigate(RoutesConfig.STATUS_LOBBY.PATH);
  };

  // This flag is because when we use reset and it changes the inputs which causes to invoke 'putWaiverOfMedicalConfidentiality'
  // When it is true it means init hasn't finished therefor there is no need in calling 'putWaiverOfMedicalConfidentiality'
  const initFlag = useRef<boolean>(true);

  const initForm = async () => {
    initFlag.current = true;
    const waiverOfMedicationConfidentiality =
      await getWaiverOfMedicalConfidentiality();
    if (waiverOfMedicationConfidentiality) {
      methods.reset({ ...waiverOfMedicationConfidentiality });
      initFlag.current = false;
    }
  };

  useEffect(() => {
    initFocus();
    initForm();
  }, []);
  useEffect(() => {
    const subscription = methods.watch(async (data) => {
      if (initFlag.current) return;
      if (!methods.formState.errors) return;

      putWaiverOfMedicalConfidentiality(data);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [methods.watch]);

  const forceSubmit = async () => {
    const values = methods.getValues();
    await putWaiverOfMedicalConfidentiality(values);
  };

  return (
    <>
      <Helmet>
        <title>
          {Dictionary[DICTIONARY.HEADER].title +
            ' - ' +
            Dictionary[DICTIONARY.WAIVER_OF_MEDICAL_CONFIDENTIALITY].pageTitle}
        </title>
      </Helmet>
      <HomePageFormLink save={forceSubmit} />
      <Stack alignItems='center' component={'main'} role={'main'}>
        <FormTitle
          title={
            Dictionary[DICTIONARY.WAIVER_OF_MEDICAL_CONFIDENTIALITY].subTitle
          }
        />
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '16px!important',
            maxWidth: '630px',
            textAlign: 'center',
            lineHeight: '24px!important',
          }}
          variant={'h2'}
        >
          {Dictionary[DICTIONARY.WAIVER_OF_MEDICAL_CONFIDENTIALITY].subTitle2}
        </Typography>
        <Paper
          sx={{
            boxShadow: '0px 3px 21px 0px #2D70D926',
            padding: isDesktop ? '0 0 80px 0' : '38px 16px 48px 16px',
            maxWidth: '780px',
            mb: '64px',
            mt: '64px',
          }}
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <FirstSection />
              <FormAutoSaveTypography save={forceSubmit} />
            </form>
          </FormProvider>
        </Paper>
      </Stack>
    </>
  );
};
