import { IPersonalDetails } from 'API';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { useFormContext } from 'react-hook-form';
import { Typography } from '@mui/material';
import { FormSection, AddressTab, InfoText } from 'Components';
export const ThirdSection = () => {
  const baseName = 'residentialAddressSection.israeliAddressRelation';
  const {
    formState: { errors },
  } = useFormContext<IPersonalDetails>();

  const { residentialAddressSection } = errors;
  const israeliAddressRelationErrors =
    residentialAddressSection?.israeliAddressRelation;
  const address = {
    houseNumErrorMsg: israeliAddressRelationErrors?.apartmentNumber?.message,
    streetNameErrorMsg: israeliAddressRelationErrors?.street?.message,
    streetNumErrorMsg: israeliAddressRelationErrors?.homeNumHouse?.message,
    zipCodeErrorMsg: israeliAddressRelationErrors?.israeliZipCode?.message,
    cityName: `${baseName}.israeliTownId`,
    houseNum: `${baseName}.apartmentNumber`,
    streetName: `${baseName}.street`,
    streetNumName: `${baseName}.homeNumHouse`,
    zipCodeName: `${baseName}.israeliZipCode`,
  };

  return (
    <FormSection
      disableGutter
      grouped
      id={Dictionary[DICTIONARY.PERSONAL_DETAILS].thirdSectionTitle}
    >
      <Typography
        fontWeight={700}
        id={Dictionary[DICTIONARY.PERSONAL_DETAILS].thirdSectionTitle}
      >
        {Dictionary[DICTIONARY.PERSONAL_DETAILS].thirdSectionTitle}
      </Typography>
      <InfoText
        text={Dictionary[DICTIONARY.PERSONAL_DETAILS].thirdSectionInfoText}
      />
      <AddressTab {...address} />
    </FormSection>
  );
};
