import {useContext} from "react";
import {Typography, Grid} from '@mui/material';
import {useFormContext} from 'react-hook-form';
import {IndexContext} from "../../CaseContext";
import {ICaseDetailsInputs} from "API";
import {useAppSelector, useIsDesktop} from "Hooks";


export const SummaryCollapseBox = () => {

    const {getValues} = useFormContext<ICaseDetailsInputs>();
    const { phonePrefixes, witnessTypes } = useAppSelector(({generalContent: {generalContent: {witnessTypes, phonePrefixes}}}) => ({witnessTypes, phonePrefixes}));
    const { index, baseName = '' } = useContext(IndexContext);
    const {caseDetails = []} = getValues();
    const caseIndex = parseInt(baseName.split('.')[1]);

    const getWitness  = () => {
        if(caseDetails && caseDetails[caseIndex]) {
            if(caseDetails[caseIndex].witnesses) {
                if(caseDetails[caseIndex].witnesses![index]) {
                    return caseDetails[caseIndex].witnesses![index]
                }
            }
        }
    };

    const witness = getWitness();
    const witnessTypeName = witnessTypes.find((witnessType => witnessType.id.toString() == witness?.typeId));
    const witnessPhonePrefix = phonePrefixes.find((witnessPhonePrefix => witnessPhonePrefix.id.toString() == witness?.phonePrefixRelationId));

    const fullName = `${witness?.firstName} ${witness?.lastName}`;
    const prefixPhone = witnessPhonePrefix?.value || '';
    const phone = witness?.phone || '';


    return (
        <Grid container spacing={1} alignItems="center" sx={{ mt: 0, pl: useIsDesktop() ? '0' : '20px' }}>
            <Grid item sx={{pt:'0 !important'}}>
                <Typography>{fullName}</Typography>
            </Grid>
            <Grid item sx={{pt:'0 !important'}}>
                {witnessTypeName?.value &&
                <Typography> | {witnessTypeName.value}</Typography>
                }
            </Grid>
            <Grid item sx={{pt:'0 !important'}}>
                <Typography sx={{fontSize: '13px'}}>{prefixPhone}{witnessPhonePrefix && '-'}{phone}</Typography>
            </Grid>
        </Grid>
    );
};
