import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Button, Grid } from '@mui/material';
import { loginRequest } from 'B2C';
import { useNavigate } from 'react-router-dom';
import { RoutesConfig } from 'Routes';
interface IGridButton {
  rightButton: string;
  leftButton?: string;
  nextStep?: () => void;
  lastStep?: boolean;
}
export const GridButton = (props: IGridButton) => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const enterTheFormHandler = () => {
    if (isAuthenticated) {
      navigate(RoutesConfig.PRIVATE_LAYOUT.PATH);
    } else {
      instance.loginRedirect(loginRequest);
    }
  };

  const nextStepHandler = () => {
    if (props.nextStep) {
      props.nextStep();
    }
  };

  return (
    <Grid container justifyContent='center' spacing={2} mb={7} mt={5}>
      <Grid item>
        <Button
          onClick={enterTheFormHandler}
          variant={props.lastStep ? 'contained' : 'outlined'}
        >
          {props.rightButton}
        </Button>
      </Grid>
      {!props.lastStep && (
        <Grid item>
          <Button variant='contained' onClick={nextStepHandler}>
            {props.leftButton}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
