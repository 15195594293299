import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import {
  MedicalTreatment,
  Appointment,
  BankAccountDetails,
  Law,
  PersonalDetails,
  SocialSecurity,
} from 'Images';
import { RoutesConfig, RoutesConfigType } from 'Routes';
// MAKE SURE THAT THE NAME IS THE SAME AS THE VALUE

export type FORM_KEYS_TYPE = {
  readonly caseDetailsPartial: 'caseDetailsPartial';
  readonly employmentAndBankDetailsPartial: 'employmentAndBankDetailsPartial';
  readonly waiverOfMedicalConfidentialityPartial: 'waiverOfMedicalConfidentialityPartial';
  readonly representationPartial: 'representationPartial';
  readonly requestsAndEarlyAcquaintancePartial: 'requestsAndEarlyAcquaintancePartial';
  readonly personalDetailPartial: 'personalDetailPartial';
};
interface IFormConfig {
  id: keyof FORM_KEYS_TYPE;
  title: string;
  icon: string;
  path: RoutesConfigType;
  showProgressBar: boolean;
}
export const FORM_KEYS: FORM_KEYS_TYPE = {
  caseDetailsPartial: 'caseDetailsPartial',
  employmentAndBankDetailsPartial: 'employmentAndBankDetailsPartial',
  waiverOfMedicalConfidentialityPartial:
    'waiverOfMedicalConfidentialityPartial',
  representationPartial: 'representationPartial',
  requestsAndEarlyAcquaintancePartial: 'requestsAndEarlyAcquaintancePartial',
  personalDetailPartial: 'personalDetailPartial',
};
export const FormConfig: IFormConfig[] = [
  {
    id: FORM_KEYS.personalDetailPartial,
    title: Dictionary[DICTIONARY.PERSONAL_DETAILS].title,
    icon: PersonalDetails,
    path: 'PERSONAL_DETAILS',
    showProgressBar: true,
  },
  {
    id: FORM_KEYS.employmentAndBankDetailsPartial,
    title: Dictionary[DICTIONARY.EMPLOYMENT_AND_BANK_DETAILS].title,
    icon: BankAccountDetails,
    path: 'EMPLOYMENT_AND_BANK_DETAILS',
    showProgressBar: true,
  },

  {
    id: FORM_KEYS.requestsAndEarlyAcquaintancePartial,
    title: Dictionary[DICTIONARY.REQUEST_AND_EARLY_ACQUAINTANCE].title,
    icon: SocialSecurity,
    path: 'REQUEST_AND_EARLY_ACQUAINTANCE',
    showProgressBar: true,
  },
  {
    id: FORM_KEYS.representationPartial,
    title: Dictionary[DICTIONARY.REPRESENTATION].title,
    icon: Law,
    path: 'REPRESENTATION',
    showProgressBar: true,
  },
  {
    id: FORM_KEYS.caseDetailsPartial,
    title: Dictionary[DICTIONARY.CASE_DETAILS].title,
    icon: Appointment,
    path: 'CASE_DETAILS',
    showProgressBar: true,
  },

  {
    id: FORM_KEYS.waiverOfMedicalConfidentialityPartial,
    title: Dictionary[DICTIONARY.WAIVER_OF_MEDICAL_CONFIDENTIALITY].title,
    icon: MedicalTreatment,
    path: 'WAIVER_OF_MEDICAL_CONFIDENTIALITY',
    showProgressBar: true,
  },
];
