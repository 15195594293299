import { Tab, Tabs, Typography } from '@mui/material';
import { FormSection, TabPanel } from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { useDispatchApp } from 'Hooks';
import { useState } from 'react';
import { setIsAddressTab } from 'Store';
import { AddressTab, IAddressTab } from './AddressTab';
import { IPOBoxTab, POBox } from './POBox';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface IAddress {
  title: string;
  tabName?: string;
  addressTab: IAddressTab;
  POBoxTab: IPOBoxTab;
  onChangeCB?: (value: number) => void;
}

export const Address = (props: IAddress) => {
  const dispatch = useDispatchApp();

  const [tabValue, setTabValue] = useState(0);

  const tabOnChangeHandler = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    dispatch(setIsAddressTab(newValue === 0));
    setTabValue(newValue);
  };

  return (
    <>
      <FormSection disableGutter>
        <Typography sx={{ fontWeight: 'bold' }}>{props.title}</Typography>
        <Tabs
          value={tabValue}
          onChange={tabOnChangeHandler}
          aria-label='address-tabs'
        >
          <Tab
            label={
              Dictionary[DICTIONARY.PERSONAL_DETAILS].thirdSectionRightTabValue
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              Dictionary[DICTIONARY.PERSONAL_DETAILS].thirdSectionLeftTabValue
            }
            {...a11yProps(0)}
          />
        </Tabs>

        <TabPanel index={0} value={tabValue}>
          <AddressTab {...props.addressTab} />
        </TabPanel>
        <TabPanel index={1} value={tabValue}>
          <POBox {...props.POBoxTab} />
        </TabPanel>
      </FormSection>
    </>
  );
};
