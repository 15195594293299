import { Avatar, Paper, Typography } from '@mui/material';
import { Add } from 'Images';

interface IAddPaperCard {
  label: string;
  secondaryLabel?: string;
  add: () => void;
}
export const AddPaperCard = (props: IAddPaperCard) => {
  return (
    <Paper
      variant='outlined'
      sx={{
        backgroundColor: `linear-gradient(358.5deg, #F8F8F8 -32.79%, rgba(255, 255, 255, 0.765625) 79.18%, rgba(255, 255, 255, 0) 444.94%)`,
        border: '1px dashed #0000003D',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        minHeight: '82px',
        cursor: 'pointer',
        padding: '26px 32px',
        borderRadius: (theme) => `${theme.shape.borderRadius}px`,
      }}
      onClick={props.add}
      onKeyPress={(e) => {
          if (e.key === 'Enter') {
              props.add();
          }
      }}
      tabIndex={0}
      role={'button'}
    >
      <Avatar
        src={Add}
        alt={props.label}
        sx={{ width: '30px', height: '30px' }}
      />
      <div>
        <Typography color='primary'>{props.label}</Typography>
        {Boolean(props.secondaryLabel) && (
          <Typography>{props.secondaryLabel}</Typography>
        )}
      </div>
    </Paper>
  );
};
