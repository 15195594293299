import { Avatar, Button } from '@mui/material';
import { Dictionary } from 'Dictionary';
import { DICTIONARY, FileCategories } from 'Enum';
import { UploadFile } from 'Images';
import { FC, forwardRef } from 'react';
interface IUploadFileButton {
  clickHandler: () => void;
  children?: any;
  ariaLabel?: string;
  fileCategoryId?: FileCategories;
  caseDetailsId?: number;
  hospitalizationId?: number;
  dataRequired?: boolean;
}
export const UploadFileButton = forwardRef<
  HTMLButtonElement,
  IUploadFileButton
>((props, ref) => {
  return (
    <Button
      // hidden={true}
      data-file-category-id={props.fileCategoryId}
      data-case-details-id={props.caseDetailsId}
      data-hospitalization-id={props.hospitalizationId}
      data-required={props.dataRequired ? 'true' : 'false'}
      ref={ref}
      variant='outlined'
      sx={{ width: 'fit-content', marginBottom: 2 }}
      onClick={props.clickHandler}
      aria-label={
        props.ariaLabel
          ? props.ariaLabel
          : Dictionary[DICTIONARY.GENERAL].fileUploadLabel
      }
      endIcon={
        <Avatar
          alt=''
          src={UploadFile}
          sx={{ width: '18px', height: '18px' }}
        />
      }
    >
      {props.children}
    </Button>
  );
});
