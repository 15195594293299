import { api } from 'API';
const baseUrl = '/form';

export const submitForm = async () => {
  const submit = await api.post(baseUrl);
  if (submit.status === 200) {
    return true;
  }
  return false;
};

export const newFormForExistingUser = async () => {
  const res = await api.post(`${baseUrl}/new-form-for-existing-one`);
  if (res.status === 200) {
    return true;
  }
  return false;
};
export interface IForm {
  isSubmitted?: boolean | null;
  formId?: number | null;
  submittedDate?: string | null;
  isRecognized: boolean;
  isPhoneBelongsToUser?: boolean;
  canCreateNewForm?: boolean;
}
export const getForm = async () => {
  const form = await api.get<IForm>(baseUrl);
  if (form.data) {
    return form.data;
  }
};
