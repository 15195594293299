import { createContext, FC } from 'react';
import {CASE_TYPE_RELATION_ID} from "Enum";

interface IIndexContext {
  index: number;
  baseName?: string;
  diseaseId?: number;
  caseTypeName?: string;
  fieldId?: number;
}



export const diseaseId =  CASE_TYPE_RELATION_ID.DISEASE_ID ;
export const caseTypeFormName = 'caseTypeRelationId';
export const caseDetailsName = 'caseDetails';
export const index = 0;
export const IndexContext = createContext<IIndexContext>({
  index,
  diseaseId,
  caseTypeName: `${caseDetailsName}.${index}.${caseTypeFormName}`,
});

export const IndexProvider: FC<IIndexContext> = (props) => {
  return (
    <IndexContext.Provider
      value={{
        index: props.index,
        baseName: props.baseName || '',
        diseaseId: props.diseaseId || diseaseId, // Can be overwritten from props
        caseTypeName:
          props.caseTypeName ||
          `${caseDetailsName}.${props.index}.${caseTypeFormName}`, // Can be overwritten from props
        fieldId: props.fieldId,
      }}
    >
      {props.children}
    </IndexContext.Provider>
  );
};
