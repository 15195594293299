import { ControllerSelect, FormSection } from 'Components';
import { DICTIONARY } from 'Enum';
import { Dictionary } from 'Dictionary';
import { MenuItem, TextField } from '@mui/material';
import { useAppSelector } from 'Hooks';
import { useFormContext } from 'react-hook-form';
import { generateError, onlyNumbersValidation, textTooLong } from 'Regex';
import { validateNumbersOnly } from 'Utils/validateNumbersOnly';
export interface IPOBoxTab {
  cityName: string;
  poBoxName: string;
  zipCodeName: string;
  poBoxErrorMsg?: string;
  zipCodeErrorMsg?: string;
}
export const POBox = (props: IPOBoxTab) => {
  const { register } = useFormContext();
  const cities = useAppSelector(({ generalContent: { cities } }) => cities);
  const dictionary = Dictionary[DICTIONARY.PERSONAL_DETAILS];
  return (
    <FormSection disablePadding>
      <ControllerSelect
        name={props.cityName}
        label={
          Dictionary[DICTIONARY.PERSONAL_DETAILS].thirdSectionLeftTabFirstInput
        }
      >
        {cities.map(({ id, value }) => (
          <MenuItem key={id} value={id}>
            {value}
          </MenuItem>
        ))}
      </ControllerSelect>
      <TextField
        role={Boolean(props.poBoxErrorMsg) ? 'alert' : undefined}
        id={dictionary.thirdSectionLeftTabSecondInput}
        inputProps={{
          maxLength: '5',
        }}
        {...register(props.poBoxName)}
        error={Boolean(props.poBoxErrorMsg)}
        helperText={
          Boolean(props.poBoxErrorMsg) &&
          generateError(
            dictionary.thirdSectionLeftTabSecondInput,
            props.poBoxErrorMsg!
          )
        }
        label={dictionary.thirdSectionLeftTabSecondInput}
        onKeyDown={(event) => validateNumbersOnly(event)}
      />
      <TextField
        role={Boolean(props.zipCodeErrorMsg) ? 'alert' : undefined}
        id={dictionary.thirdSectionLeftTabThirdInput}
        inputProps={{
          maxLength: '7',
        }}
        {...register(props.zipCodeName)}
        error={Boolean(props.zipCodeErrorMsg)}
        helperText={
          Boolean(props.zipCodeErrorMsg) &&
          generateError(
            dictionary.thirdSectionLeftTabThirdInput,
            props.zipCodeErrorMsg!
          )
        }
        label={dictionary.thirdSectionLeftTabThirdInput}
        onKeyDown={(event) => validateNumbersOnly(event)}
      />
    </FormSection>
  );
};
