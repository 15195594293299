import { Avatar, Grid, Link, Typography } from '@mui/material';
import { WarningBlue } from 'Images';
interface IInfoText {
  text: string;
  url?: string;
  urlLabel?: string;
}
export const InfoTextNew = ({ text, url, urlLabel }: IInfoText) => {
  return (
    <Grid
      container
      columnGap={1}
      sx={{
        flexWrap: 'unset',
        bgcolor: '#F2F9FF',
        borderRadius: '16px',
        p: '16px 24px',
        maxWidth: '520px',
      }}
    >
      <Avatar
        src={WarningBlue}
        alt=''
        sx={{ width: '18px', height: '18px', top: '3px' }}
      />
      <Typography color='#4d4d4d' component={'p'} textAlign={'left'}>
        {text}
        {url && urlLabel && <Link href={url}>{urlLabel}</Link>}
      </Typography>
    </Grid>
  );
};
