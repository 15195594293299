import { TextField, Typography } from '@mui/material';
import { IEmploymentAndBankDetails } from 'API';
import { FormSection, ControllerAutoComplete } from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { useAppSelector } from 'Hooks';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { generateError } from 'Regex';
import { EmploymentAndBankDetailsSlice, store } from 'Store';
import { textFieldShrink } from 'Utils';
import { validateNumbersOnly } from 'Utils/validateNumbersOnly';
export const SecondSection = () => {
  const [banks, branches] = useAppSelector(
    ({ generalContent: { banks, branches } }) => [banks, branches]
  );

  const {
    register,
    getValues,
    formState: {
      errors: { bankAccountDetailsSection },
    },
  } = useFormContext<IEmploymentAndBankDetails>();

  const bankIdName = 'bankAccountDetailsSection.bankRelationId';
  const branchIdName = 'bankAccountDetailsSection.branchRelationId';

  const accountNumberName = 'bankAccountDetailsSection.accountNumber';

  const bankId = useWatch({
    name: bankIdName,
    defaultValue: undefined,
  }) as undefined | string;

  const branchId = useWatch({
    name: branchIdName,
    defaultValue: undefined,
  }) as undefined | string;

  useEffect(() => {
    store.dispatch(
      EmploymentAndBankDetailsSlice.actions.setBankNumber(parseInt(bankId!))
    );
  }, [bankId]);

  useEffect(() => {
    store.dispatch(
      EmploymentAndBankDetailsSlice.actions.setBranchNumber(parseInt(branchId!))
    );
  }, [branchId]);
  const formInfo = store.getState().generalContent.formInfo;

  const dictionary = Dictionary[DICTIONARY.EMPLOYMENT_AND_BANK_DETAILS];
  return (
    <FormSection grouped id={dictionary.secondSectionTitle}>
      <Typography
        variant='body1'
        sx={{ fontWeight: 'bold' }}
        id={dictionary.secondSectionTitle}
      >
        {dictionary.secondSectionTitle}
      </Typography>
      <Typography
        variant='subtitle1'
        sx={{ fontWeight: '400' }}
        component={'p'}
      >
        {dictionary.secondSectionSubTitle}
      </Typography>
      <ControllerAutoComplete
        options={banks}
        name={bankIdName}
        label={dictionary.secondSectionFirstInput}
      />
      <ControllerAutoComplete
        name='bankAccountDetailsSection.branchRelationId'
        label={dictionary.secondSectionSecondInput}
        options={
          bankId && branches[bankId]
            ? branches[bankId].map(({ id, value, branchIdNumber }) => ({
                id: id,
                value: `${value}, ${branchIdNumber}`,
              }))
            : []
        }
      />
      <TextField
        role={
          Boolean(bankAccountDetailsSection?.accountNumber)
            ? 'alert'
            : undefined
        }
        inputProps={{
          maxLength: '11',
        }}
        id={dictionary.secondSectionThirdInput}
        {...register(accountNumberName)}
        label={dictionary.secondSectionThirdInput}
        {...textFieldShrink(getValues([accountNumberName])[0])}
        error={Boolean(bankAccountDetailsSection?.accountNumber)}
        helperText={
          Boolean(bankAccountDetailsSection?.accountNumber?.message) &&
          generateError(
            dictionary.secondSectionThirdInput,
            bankAccountDetailsSection?.accountNumber?.message!
          )
        }
        onKeyDown={(event) => validateNumbersOnly(event)}
      />
    </FormSection>
  );
};
