import { api } from 'API';

const baseUrl = '/country';

export interface ICountry {
  id: number;
  value: string;
}

export const getCountries = async () => {
  const countriesData = await api.get<ICountry[]>(baseUrl);
  if (countriesData.data) {
    return countriesData.data;
  }
};
