import { Box, FormControl, FormHelperText, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { QuestionMarkToolTip } from 'Components/QuestionMarkToolTip';
import { useEffect } from 'react';
import { useController } from 'react-hook-form';
import { generateError } from 'Regex';
import { RadioButtonProvider, useRadioButton } from './RadioButtonContext';
import { InfoText } from 'Components';

interface StyledRadioWrapperProps {
  disabled?: boolean;
}

const RadioWrapper = styled('div')<StyledRadioWrapperProps>(
  ({ theme, disabled }) => ({
    position: 'relative',
    width: '100%',
    display: 'flex',
    input: {
      opacity: 0.01,
      zIndex: 100,
      position: 'absolute',
      top: 0,
      left: 0,
    },
    'input:checked + label': {
      color: theme.palette.common.white,
      fontWeight: 'bold',
      backgroundColor: disabled
        ? theme.palette.grey[300]
        : theme.palette.primary.main,
    },
    'input[aria-invalid="true"] + label': {
      borderColor: theme.palette.error.main,
    },
    label: {
      cursor: disabled ? 'default' : 'pointer',
      border: '1px solid #D1D6D9',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '48px',
      color: theme.palette.text.primary,
    },
  })
);

interface IRadioButton {
  label: string;
  value: string;
  name: string;
  index: number;
  tooltip?: string;
  infoText?: string;
  disabled?: boolean;
}

const RadioButton = (props: IRadioButton) => {
  const {
    field: { value, ref, onChange, ...rest },
    fieldState: { error },
  } = useController({
    name: props.name,
    defaultValue: null,
  });
  const { setError } = useRadioButton();

  useEffect(() => {
    if (error) {
      setError(true, error.message || '');
    } else {
      setError(false, '');
    }
  }, [error]);
  return (
    <>
      <input
        ref={ref}
        aria-invalid={error ? 'true' : 'false'}
        id={props.name + props.index}
        {...rest}
        value={props.value.toString()}
        onChange={(e) => {
          const eValue = e.currentTarget.value;
          if (eValue === 'true' || eValue === 'false') {
            onChange(eValue === 'true');
          } else {
            onChange(eValue);
          }
        }}
        type='radio'
        aria-label={props.label}
        checked={
          typeof value === 'boolean'
            ? value.toString() == props.value
            : value == props.value
        }
        disabled={props.disabled}
      />
      <label htmlFor={props.name + props.index}>{props.label}</label>
    </>
  );
};

type Options = {
  value: string;
  label: string;
};
interface IRadioButtons {
  options: Options[];
  name: string;
  id: string;
  toolTip?: string;
  infoText?: string;
  disabled?: boolean;
}

const RadioButtonGroup = (props: IRadioButtons) => {
  const { errorMsg, error } = useRadioButton();
  return (
    <FormControl error={error} fullWidth>
      <div style={{ position: 'relative' }}>
        <Typography color={error ? 'error' : 'text.primary'}>
          {props.id}
        </Typography>
        {props.toolTip && <QuestionMarkToolTip title={props.toolTip} />}
        {props.infoText && (
          <Box sx={{ mt: 1, mb: 1 }}>
            <InfoText text={props.infoText} />
          </Box>
        )}
      </div>
      <fieldset
        style={{
          position: 'relative',
          display: 'flex',
        }}
      >
        <legend>{props.id}</legend>
        <RadioWrapper disabled={props.disabled}>
          {props.options.map((option, index) => (
            <RadioButton
              key={index}
              {...option}
              name={props.name}
              index={index}
              disabled={props.disabled}
            />
          ))}
        </RadioWrapper>
      </fieldset>
      {Boolean(errorMsg) && (
        <FormHelperText role={error ? 'alert' : undefined}>
          {generateError(props.id, errorMsg)}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export const RadioButtons = (props: IRadioButtons) => {
  return (
    <RadioButtonProvider>
      <RadioButtonGroup {...props} />
    </RadioButtonProvider>
  );
};
