import { FileCategories } from 'Enum';
import { DeleteModal } from 'Components';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { deleteFile, getFile, IFile, postFile } from 'API';
import { useAppSelector, useDispatchApp } from 'Hooks';
import { addFile, removeFile } from 'Store';
import { Loader } from 'Components/Loader';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { FileCard } from './FileCard';
import { UploadFileButton } from './UploadFileButton';
import { FileRow } from './FileRow';
import { Divider, Typography } from '@mui/material';
import { checkFileSize, isFileValid } from 'Utils';
export interface IFileUpload {
  fileCategoryId: FileCategories;
  caseDetailsId?: number;
  hospitalizationId?: number;
  fileLabel: string;
  loading?: boolean;
  ariaLabel?: string;
  dataRequired?: boolean;
}

export const FileUpload = forwardRef<HTMLButtonElement, IFileUpload>(
  (props, ref) => {
    const dispatch = useDispatchApp();
    const dictionary = Dictionary[DICTIONARY.GENERAL];

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [showError, setShowError] = useState<boolean>(false);

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    const [currFileId, setCurrFileId] = useState<number | null>(null);
    const [currFileName, setCurrFileName] = useState<string>('');

    const checkExtension = (file: File) => {
      const allowsExtension = [
        'doc',
        'docx',
        'jpg',
        'png',
        'pdf',
        'tif',
        'tiff',
        'jpeg',
      ];
      const fileExt = file.name.split('.').pop();
      if (fileExt && allowsExtension.includes(fileExt.toLowerCase())) {
        return true;
      }
      return false;
    };

    const fileIds = useAppSelector(({ file: { files } }) => {
      const categoryFiles = files[props.fileCategoryId];
      if (props.caseDetailsId && props.hospitalizationId) {
        return categoryFiles?.filter(
          (file) =>
            file.caseDetailsRelationId === props.caseDetailsId &&
            file.hospitalizationDetailsRelationId === props.hospitalizationId
        );
      } else if (props.caseDetailsId) {
        return categoryFiles?.filter(
          (file) => file.caseDetailsRelationId === props.caseDetailsId
        );
      } else if (props.hospitalizationId) {
        return categoryFiles?.filter(
          (file) =>
            file.hospitalizationDetailsRelationId === props.hospitalizationId
        );
      } else {
        return files[props.fileCategoryId];
      }
    });
    const status = useAppSelector(({ file: { status } }) => status);

    const onChangeHandler = async (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const file = event.target.files;
      if (file !== null) {
        if (checkFileSize(file[0]) && checkExtension(file[0])) {
          if (!(await isFileValid(file[0]))) {
            setShowError(true);
            return;
          }
          setIsLoading(true);
          const fileWithoutExtension = file[0].name.split('.');
          const fileNTimestamp =
            fileWithoutExtension[0].replace(
              /[^a-zA-Z0-9\u05D0-\u05EA\- _]/g,
              ''
            ) +
            '-' +
            Date.now();
          const fileExt = file[0].name.split('.').pop();
          const fileName = fileNTimestamp + '.' + fileExt;

          await postFileHandler(file[0], fileName);
          setShowError(false);
          return;
        }
        setShowError(true);
      }
    };

    const fileInput = useRef<HTMLInputElement | null>(null);

    const postFileHandler = async (file: File, fileName: string) => {
      const form = new FormData();
      form.append('file', file, fileName);
      const fileData = await postFile(
        props.fileCategoryId,
        form,
        props.caseDetailsId,
        props.hospitalizationId
      );
      setIsLoading(false);
      if (fileData) {
        setShowError(false);
        dispatch(
          addFile({ file: fileData, fileCategory: props.fileCategoryId })
        );
      } else {
        setShowError(true);
      }
    };

    const deleteFileHandler = async (fileId: number, fileName: string) => {
      setCurrFileId(fileId);
      setCurrFileName(fileName);
      setShowDeleteModal(true);
    };

    const deleteModal = async (fileId: number) => {
      const fileData = await deleteFile(fileId);
      if (fileData) {
        dispatch(
          removeFile({
            fileCategory: props.fileCategoryId,
            fileId,
          })
        );
        if (fileInput.current) {
          fileInput.current.value = '';
        }
      }

      setShowDeleteModal(false);
    };

    const closeDeleteModalHandler = () => {
      setCurrFileId(null);
      setCurrFileName('');
      setShowDeleteModal(false);
    };

    const watchFile = async (fileId: number, userGivenName: string) => {
      if (fileId) {
        const fileData = await getFile(fileId, userGivenName);
        if (fileData) window.open(URL.createObjectURL(fileData), userGivenName);
      }
    };

    // if (status !== 'FINISHED_SETTING') {
    //   return <Loader />;
    // }
    const clickFileInput = () => {
      if (fileInput.current) {
        fileInput.current.click();
      }
    };
    return (
      <>
        <input
          ref={(e) => {
            fileInput.current = e;
          }}
          type='file'
          accept='.doc,.docx,.jpg,.png,.pdf,.tif,tiff,jpeg'
          hidden
          onChange={onChangeHandler}
        />
        {showDeleteModal && currFileId && (
          <DeleteModal
            isVisible={showDeleteModal}
            closeModal={closeDeleteModalHandler}
            deleteModal={() => deleteModal(currFileId)}
            fileName={currFileName}
          />
        )}
        {fileIds && fileIds.length ? (
          <>
            <FileCard clickHandler={clickFileInput} fileLabel={props.fileLabel}>
              <Divider />
              {fileIds.map((file, index) => (
                <>
                  <FileRow
                    key={file.id}
                    fileName={file.userGivenName}
                    watchFile={() => watchFile(file.id, file.userGivenName)}
                    deleteFile={() =>
                      deleteFileHandler(file.id, file.userGivenName)
                    }
                  />
                  {index !== fileIds.length - 1 ? <Divider /> : null}
                </>
              ))}
            </FileCard>
          </>
        ) : (
          <UploadFileButton
            fileCategoryId={props.fileCategoryId}
            caseDetailsId={props.caseDetailsId}
            hospitalizationId={props.hospitalizationId}
            dataRequired={props.dataRequired}
            clickHandler={clickFileInput}
            ref={ref}
            ariaLabel={props?.ariaLabel}
          >
            {dictionary.fileUploadLabel}
          </UploadFileButton>
        )}
        {showError ? (
          <Typography
            color='error'
            role={Boolean(showError) ? 'alert' : undefined}
          >
            {dictionary.fileErrorNew}
          </Typography>
        ) : null}
        {isLoading && <Loader />}
      </>
    );
  }
);
