import {Button, Grid, Typography,} from '@mui/material';
import {submitForm} from 'API';
import {FormSection} from 'Components';
import {Dictionary} from 'Dictionary';
import {DICTIONARY} from 'Enum';
import {useNavigate} from 'react-router-dom';
import {RoutesConfig} from 'Routes';

export const FirstSection = () => {
  const navigate = useNavigate();

  const onClickHandler = async () => {
    const formResult = await submitForm();
    if (formResult) {
      navigate(RoutesConfig.SUCCESS_PAGE.PATH);
    }
  };

  const onClickBackHandler = async () => {
      navigate(RoutesConfig.STATUS_LOBBY.PATH);

  };

  return (
    <FormSection>
        <Grid sx={{textAlign: 'center'}}>
            <Typography sx={{ mb: 3 }}>
                {Dictionary[DICTIONARY.VERIFY_DETAILS].firstSectionFirstParagraph}
            </Typography>

            <Typography sx={{ mb: 2, fontWeight: 'bold', fontSize: '18px' }}>
                {Dictionary[DICTIONARY.VERIFY_DETAILS].firstSectionFirstTitle}
            </Typography>

            <Typography sx={{ mb: 4, fontSize: '16px' }}>
                {Dictionary[DICTIONARY.VERIFY_DETAILS].firstSectionSecondParagraph}
            </Typography>

            <Grid sx={{ background: '#F2F9FF', borderRadius: 1, padding: 2, mb: 2, mt: 2}} >
                <Typography color='#39546A' component={'p'}>
                    {Dictionary[DICTIONARY.VERIFY_DETAILS].firstSectionCheckBoxLabel}
                </Typography>
            </Grid>
        </Grid>

        <Grid
            sx={{mt: 2}}
            container
            justifyContent='space-between'
            alignItems='center'
        >

            <Button
                variant='outlined'
                sx={{width: '45%', fontSize: '16px', fontWeight: '400'}}
                onClick={onClickBackHandler}
                aria-label={Dictionary[DICTIONARY.VERIFY_DETAILS].editRequest}
            >
                {Dictionary[DICTIONARY.VERIFY_DETAILS].editRequest}
            </Button>
            <Button
                sx={{width: '45%', fontSize: '16px', fontWeight: '400'}}
                variant='contained'
                onClick={onClickHandler}
                aria-label={Dictionary[DICTIONARY.VERIFY_DETAILS].buttonLabel}
            >
                {Dictionary[DICTIONARY.VERIFY_DETAILS].buttonLabel}
            </Button>

        </Grid>
    </FormSection>
  );
};
