import { MenuItem, Typography } from '@mui/material';
import { AddPaperCard, ControllerSelect, FormSection } from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { useAppSelector } from 'Hooks';
import { useContext, useState, useRef } from 'react';
import { WitnessCard } from '../WitnessCard';
import { DeleteModal } from './DeleteModal';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { ICaseDetailsInputs, witnessesEmptyObject } from 'API';
import { IndexContext, IndexProvider } from '../CaseContext';
export const SixthSection = () => {
  const areThereWitnessesOptions = useAppSelector(
    ({
      generalContent: {
        generalContent: { areThereWitnessesOptions },
      },
    }) => areThereWitnessesOptions
  );
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const { index } = useContext(IndexContext);
  const witnessIndex = useRef<number>(0);

  const fieldArrayBaseName = `caseDetails.${index}.witnesses`;

  const { remove, append, fields } = useFieldArray({
    name: `${fieldArrayBaseName}` as 'caseDetails.0.witnesses',
  });

  const deleteModal = (index: number) => {
    remove(index);
    setShowDeleteModal(false);
  };

  const onAddWitnessHandler = () => {
    append({ ...witnessesEmptyObject });
  };

  const onDeleteHandler = (index: number) => {
    setShowDeleteModal(true);
    witnessIndex.current = index;
  };

  const closeDeleteModalHandler = () => {
    setShowDeleteModal(false);
  };

  const baseName = `caseDetails.${index}`;

  const { getValues } = useFormContext<ICaseDetailsInputs>();
  const { caseDetails = [] } = getValues();
  const caseIndex = parseInt(baseName.split('.')[1]);

  const getWitness = () => {
    if (caseDetails && caseDetails[caseIndex]) {
      if (caseDetails[caseIndex].witnesses) {
        if (caseDetails[caseIndex].witnesses![index]) {
          return caseDetails[caseIndex].witnesses![index];
        }
      }
    }
  };

  const witness = getWitness();
  const fullName = `${witness?.firstName} ${witness?.lastName}`;

  const areThereWitnessesName = `${baseName}.areThereWitnessesOptionId`;

  const trueValue1 = 1;
  const trueValue2 = 2;

  const areThereWitnesses = useWatch({
    name: areThereWitnessesName,
    defaultValue: 0,
  }) as number;

  return (
    <>
      <FormSection
        grouped
        id={Dictionary[DICTIONARY.CASE_DETAILS].sixthSectionTitle}
      >
        <Typography
          sx={{ fontWeight: 'bold' }}
          id={Dictionary[DICTIONARY.CASE_DETAILS].sixthSectionTitle}
        >
          {Dictionary[DICTIONARY.CASE_DETAILS].sixthSectionTitle}
        </Typography>
        <Typography>
          {Dictionary[DICTIONARY.CASE_DETAILS].sixthSectionSubTitle}
        </Typography>
        <Typography variant={'subtitle1'}>
          {Dictionary[DICTIONARY.CASE_DETAILS].sixthSectionFirstInputLabel}
        </Typography>
        <Typography variant={'subtitle1'}>
          {Dictionary[DICTIONARY.CASE_DETAILS].sixthSectionFirstInputLabel2}
        </Typography>
        <ControllerSelect
          name={`${areThereWitnessesName}` as const}
          label={
            Dictionary[DICTIONARY.CASE_DETAILS]
              .sixthSectionFirstInputPlaceHolder
          }
        >
          {areThereWitnessesOptions.map(({ id, value }) => (
            <MenuItem key={id} value={id}>
              {value}
            </MenuItem>
          ))}
        </ControllerSelect>
      </FormSection>

      {(areThereWitnesses == trueValue1 || areThereWitnesses == trueValue2) && (
        <FormSection disablePadding>
          {fields.map((field, index) => (
            <IndexProvider index={index} baseName={fieldArrayBaseName}>
              <WitnessCard
                key={field.id}
                deleteWitness={() => onDeleteHandler(index)}
              />
            </IndexProvider>
          ))}
          <AddPaperCard
            label={
              Dictionary[DICTIONARY.CASE_DETAILS].sixthSectionAddNewWitness
            }
            add={onAddWitnessHandler}
          />
        </FormSection>
      )}
      {showDeleteModal && (
        <DeleteModal
          isVisible={showDeleteModal}
          closeModal={closeDeleteModalHandler}
          deleteModal={() => deleteModal(witnessIndex.current)}
          texts={{ name: fullName || '' }}
        />
      )}
    </>
  );
};
