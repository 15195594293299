import { Button, Grid, Stack, Typography } from '@mui/material';
import { ModuleCard, SubmitDialog } from 'Components';
import { Dictionary } from 'Dictionary';
import { FormConfig } from 'Constants';
import { AUTH_ISSUE, RoutesConfig, RoutesConfigType } from 'Routes';
import { DICTIONARY } from 'Enum';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getForm, getLobbyStatuses, LobbyStatusType } from 'API';
import { Helmet } from 'react-helmet-async';
import { useIsDesktop } from 'Hooks';

export const StatusLobby = () => {
  const isDesktop = useIsDesktop();

  const [submitDialog, setSubmitDialog] = useState<boolean>(false);
  const [isRecognized, setIsRecognized] = useState<boolean>(false);
  const [formStatus, setFormStatus] = useState<LobbyStatusType>({
    caseDetailsPartial: {
      areOptionalFieldsEmpty: false,
      requiredFieldsStatus: 0,
    },
    employmentAndBankDetailsPartial: {
      areOptionalFieldsEmpty: false,
      requiredFieldsStatus: 0,
    },
    personalDetailPartial: {
      areOptionalFieldsEmpty: false,
      requiredFieldsStatus: 0,
    },
    representationPartial: {
      areOptionalFieldsEmpty: false,
      requiredFieldsStatus: 0,
    },
    requestsAndEarlyAcquaintancePartial: {
      areOptionalFieldsEmpty: false,
      requiredFieldsStatus: 0,
    },
    waiverOfMedicalConfidentialityPartial: {
      areOptionalFieldsEmpty: false,
      requiredFieldsStatus: 0,
    },
  });

  const navigate = useNavigate();

  const statusLobbyInit = async () => {
    const form = await getForm();
    if (form) {
      setIsRecognized(form.isRecognized);
      if (form.isRecognized && !form.isPhoneBelongsToUser) {
        navigate(AUTH_ISSUE);
      }
      if (form.isSubmitted) {
        navigate('/form/success' + '/' + form.formId);
      }
    } else {
      return;
    }
    const statusLobbyData = await getLobbyStatuses();
    if (statusLobbyData) {
      setFormStatus(statusLobbyData);
    }
  };

  const isFormFinished = () => {
    let isAllFinished = true;
    const finishedForm = 100;
    for (const status in formStatus) {
      const key = status as keyof LobbyStatusType;
      if (Object.prototype.hasOwnProperty.call(formStatus, status)) {
        const element = formStatus[key];

        if (element.requiredFieldsStatus * 100 < finishedForm) {
          isAllFinished = false;
        }
      }
    }
    return isAllFinished;
  };

  const formSubmitHandler = async () => {
    if (!isFormFinished()) setSubmitDialog(true);
    else navigate(RoutesConfig.VERIFY_DETAILS.PATH);
  };

  const closeDialogHandler = () => {
    setSubmitDialog(false);
  };

  useEffect(() => {
    statusLobbyInit();
  }, []);

  const moduleCardButtonClick = (path: RoutesConfigType) => {
    navigate(RoutesConfig[path].PATH);
  };

  return (
    <>
      <Helmet>
        <title>{Dictionary[DICTIONARY.HEADER].title}</title>
      </Helmet>
      <Stack
        component={'main'}
        role={'main'}
        mt={isDesktop ? 11.5 : 4.25}
        alignItems='center'
        justifyContent='center'
      >
        <Typography mb={isDesktop ? 13 : 4} textAlign='center'>
          {Dictionary[DICTIONARY.STATUS_LOBBY].subTitle}
        </Typography>
        <Grid container gap={isDesktop ? 3 : 2} justifyContent='center'>
          {FormConfig.map(({ id, title, icon, path, showProgressBar }) => (
            <ModuleCard
              key={id}
              title={title}
              icon={icon}
              showProgressBar={showProgressBar}
              showComment={formStatus[id].areOptionalFieldsEmpty}
              progressBar={{
                value: formStatus[id].requiredFieldsStatus * 100,
              }}
              buttonClick={() => moduleCardButtonClick(path)}
            />
          ))}
        </Grid>
        <Grid
          sx={{
            mt: isDesktop ? 13.75 : 5,
            mb: isDesktop ? 13.75 : 5,
          }}
          container
          justifyContent='center'
          alignItems='center'
          flexDirection={isDesktop ? 'column' : 'row'}
        >
          <Button
            onClick={formSubmitHandler}
            variant='contained'
            sx={{
              opacity: isFormFinished() ? null : '80%',
              minWidth: !isDesktop ? ' 255px' : undefined,
            }}
          >
            {Dictionary[DICTIONARY.STATUS_LOBBY].submitFileButtonLabel}
          </Button>
        </Grid>
      </Stack>
      {submitDialog && (
        <SubmitDialog isOpen={submitDialog} closeModal={closeDialogHandler} />
      )}
    </>
  );
};
