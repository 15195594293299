import {
  Accordion,
  AccordionSummary,
  Avatar,
  Grid,
  IconButton,
  Paper,
} from '@mui/material';
import { TrashCanGrid } from 'Components';
import { Add, Shrink, TrashCan } from 'Images';
import React, { ReactNode, useEffect, useState } from 'react';
import { DICTIONARY } from '../../Enum';
import { Dictionary } from '../../Dictionary';
import {useIsDesktop} from "Hooks";

interface IPaperCard {
  remove: () => void;
  text?: ReactNode;
  isOpen: boolean;
}

export const PaperCard: React.FC<IPaperCard> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const isDesktop = useIsDesktop();

  const style = isOpen
    ? {
        minHeight: 0,
        height: 0,
        padding: '0 32px',
      }
    : {
        minHeight: '82px',
        height: '82px',
        padding: isDesktop ? '26px 32px' : '26px 15px',
      };

  const trashCan = isOpen
    ? { opacity: 0, width: 0, height: 0 }
    : { width: '20px', height: '20px', minHeight: '20px' };

  const buttonStyle = isOpen
    ? { opacity: 0, width: 0, height: 0 }
    : { width: '30px', height: '30px', minHeight: '30px' };


  return (
    <Accordion
      expanded={isOpen}
      disableGutters
      sx={{
        borderRadius: '6px',
        backgroundColor: '#F8F8F8',
        position: 'relative',
        border: 'none',
        boxShadow: isOpen ? 'none' : ' 0px 3px 21px rgba(45, 112, 217, 0.15)',
        '::before': {
          height: 0,
        },
      }}
    >
      {
        <AccordionSummary
          style={{
            ...style,
            transition: 'all',
            transitionDuration: '402ms',
          }}
          sx={{
            display: 'flex',
          }}
        >
          <Grid container alignItems='center' justifyContent='space-between'>
            <Grid item xs={1}>
              <IconButton
                onClick={() => {
                  setIsOpen(true);
                }}
                sx={{ padding: '0' }}
                disableRipple
              >
                <Paper
                  sx={{
                    ...buttonStyle,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: `0px 2px 10px 0px #2D70D91F`,
                    borderRadius: '6px',
                    cursor: 'pointer',
                    transition: 'all',
                    transitionDuration: '200ms',
                  }}
                >
                  <Avatar
                    src={Add}
                    alt={Dictionary[DICTIONARY.GENERAL].open}
                    sx={{
                      ...buttonStyle,
                      transition: 'height, width',
                      transitionDuration: '100ms',
                    }}
                  />
                </Paper>
              </IconButton>
            </Grid>
            <Grid item xs={10}>
              {!isOpen && props.text}
            </Grid>
            <Grid item xs={1}>
              <IconButton sx={{ padding: 0 }} onClick={props.remove}>
                <Avatar
                  src={TrashCan}
                  alt={Dictionary[DICTIONARY.GENERAL].delete}
                  sx={{
                    ...trashCan,
                    transition: 'all',
                    transitionDuration: '200ms',
                  }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </AccordionSummary>
      }
      <Paper
        variant='outlined'
        sx={{
          padding: isDesktop ? '32px 85px' : '20px 15px',
          borderRadius: '8px',
          backgroundColor: '#F8F8F8',
          position: 'relative',
          border: 'none',
        }}
      >
        <IconButton
          onClick={() => {
            setIsOpen(false);
          }}
          sx={{
            position: 'absolute',
            top: '26px',
            padding: '0',
            left: isDesktop ? '32px' : 'unset',
          }}
        >
          <Paper
            sx={{
              width: '30px',
              height: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: `0px 2px 10px 0px #2D70D91F`,
              borderRadius: '8px',
              cursor: 'pointer',
            }}
          >
            <Avatar
              alt={Dictionary[DICTIONARY.GENERAL].close}
              src={Shrink}
              sx={{ width: '12px', height: '1.5px' }}
            />
          </Paper>
        </IconButton>
        {props.children}
        <TrashCanGrid remove={props.remove} />
      </Paper>
    </Accordion>
  );
};
