import {useContext} from "react";
import {Typography} from '@mui/material';
import {useFormContext} from 'react-hook-form';
import {IndexContext} from "../../../CaseDetails/CaseContext";
import {IPersonalDetails} from "../../../../API";
import {useAppSelector, useIsDesktop} from "Hooks";
import {format} from "date-fns";


export const SummaryCollapseBox = () => {
    const isDesktop = useIsDesktop();

    const {getValues} = useFormContext<IPersonalDetails>();
    const hmos = useAppSelector(({generalContent: {generalContent: {hmOs}}}) => hmOs);
    const {index} = useContext(IndexContext);
    const {previousHMOSection = []} = getValues();

    const preHmoName = hmos.find((hmo => hmo.id === previousHMOSection[index].hmoRelationId))?.value;

    const preHmoEndDate = previousHMOSection[index].hospitalizationEndDate || '';
    const preHmoEndDateFormat = preHmoEndDate && format(new Date(preHmoEndDate), "yyyy" );

    return (<Typography sx={{ pl: isDesktop ? '0' : '20px' }}>{preHmoName} | {preHmoEndDateFormat}</Typography>)
}
