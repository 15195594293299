import { IRepresentation } from 'API';
import {
  textTooLong,
  onlyNumbers,
  onlyNumbersMessage,
  email,
  emailMessage,
  lettersOrNumbersNoSpecialCharsMessage,
  requiredMessage,
  phoneNumberMessage,
  emailWithEmpty,
  phoneNumberNotRequired,
  lettersOrNumbersNoSpecialChars,
} from 'Regex';
import * as yup from 'yup';

export const representationSchema: yup.SchemaOf<IRepresentation> = yup
  .object()
  .shape({
    associationSection: yup.object({
      associationName: yup
        .string()
        .max(20, textTooLong(20))
        .notRequired()
        .matches(
          lettersOrNumbersNoSpecialChars,
          lettersOrNumbersNoSpecialCharsMessage
        )
        .nullable(),
      representativeName: yup
        .string()
        .max(30, textTooLong(30))
        .notRequired()
        .matches(
          lettersOrNumbersNoSpecialChars,
          lettersOrNumbersNoSpecialCharsMessage
        )
        .nullable(),
      address: yup
        .string()
        .notRequired()
        .max(50, textTooLong(50))
        .matches(
          lettersOrNumbersNoSpecialChars,
          lettersOrNumbersNoSpecialCharsMessage
        )
        .nullable(),
      mobilePhonePrefixRelationId: yup.string().notRequired().nullable(),
      mobilePhone: yup
        .string()
        .max(7, textTooLong(7))
        .matches(phoneNumberNotRequired, onlyNumbersMessage)
        .nullable(),
      phonePrefixRelationId: yup.string().notRequired().nullable(),
      phone: yup
        .string()
        .max(7, textTooLong(7))
        .matches(phoneNumberNotRequired, onlyNumbersMessage)
        .nullable(),
      mail: yup
        .string()
        .max(50, textTooLong(50))
        .matches(emailWithEmpty, emailMessage)
        .notRequired()
        .nullable(),
    }),
    guardianSection: yup
      .object({
        firstName: yup
          .string()
          .max(20, textTooLong(20))
          .notRequired()
          .matches(
            lettersOrNumbersNoSpecialChars,
            lettersOrNumbersNoSpecialCharsMessage
          )
          .nullable(),
        lastName: yup
          .string()
          .max(30, textTooLong(30))
          .notRequired()
          .matches(
            lettersOrNumbersNoSpecialChars,
            lettersOrNumbersNoSpecialCharsMessage
          )
          .nullable(),
        idNumber: yup
          .string()
          .max(9, textTooLong(9))
          .notRequired()
          .matches(onlyNumbers, onlyNumbersMessage)
          .nullable(),
        address: yup
          .string()
          .notRequired()
          .max(50, textTooLong(50))
          .matches(
            lettersOrNumbersNoSpecialChars,
            lettersOrNumbersNoSpecialCharsMessage
          )
          .nullable(),
        phonePrefixRelationId: yup.string().notRequired().nullable(),
        phone: yup
          .string()
          .max(7, textTooLong(7))
          .matches(phoneNumberNotRequired, onlyNumbersMessage)
          .nullable(),
        mail: yup
          .string()
          .max(50, textTooLong(50))
          .matches(emailWithEmpty, emailMessage)
          .notRequired()
          .nullable(),
      })
      .notRequired()
      .nullable(),
    lawyerSection: yup
      .object({
        firstName: yup
          .string()
          .max(20, textTooLong(20))
          .notRequired()
          .matches(
            lettersOrNumbersNoSpecialChars,
            lettersOrNumbersNoSpecialCharsMessage
          )
          .nullable(),
        lastName: yup
          .string()
          .max(30, textTooLong(30))
          .notRequired()
          .matches(
            lettersOrNumbersNoSpecialChars,
            lettersOrNumbersNoSpecialCharsMessage
          )
          .nullable(),
        address: yup
          .string()
          .notRequired()
          .max(50, textTooLong(50))
          .matches(
            lettersOrNumbersNoSpecialChars,
            lettersOrNumbersNoSpecialCharsMessage
          )
          .nullable(),
      })
      .notRequired()
      .nullable(),
    isRepresentedByGuardian: yup.boolean().required(requiredMessage).nullable(),
    representationOptionRelationId: yup
      .string()
      .required(requiredMessage)
      .nullable(),
    isAssistedWithGoodHandsCenter: yup.boolean().notRequired().nullable(),
  });
