import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import ErrorBoundary from 'Components/ErrorBoundary';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BrowserRouter } from 'react-router-dom';
import { Router } from 'Routes';
import { HelmetProvider } from 'react-helmet-async';

interface IApp {
  instance: IPublicClientApplication;
}

function App(props: IApp) {
  // if (!document.getElementById('clarity')) {
  //   const script = document.createElement('script');
  //   script.setAttribute('id', 'clarity');
  //   script.setAttribute('type', 'text/javascript');
  //   script.innerHTML = `(function (c, l, a, r, i, t, y) {
  //   c[a] =
  //     c[a] ||
  //     function () {
  //       (c[a].q = c[a].q || []).push(arguments);
  //     };
  //   t = l.createElement(r);
  //   t.async = 1;
  //   t.src = 'https://www.clarity.ms/tag/' + i;
  //   y = l.getElementsByTagName(r)[0];
  //   y.parentNode.insertBefore(t, y);
  // })(window, document, 'clarity', 'script', '${process.env.REACT_APP_CLARITY}');`;
  //   document.head.append(script);
  // }
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
        >
          <MsalProvider instance={props.instance}>
            <HelmetProvider>
              <Router />
            </HelmetProvider>
          </MsalProvider>
        </GoogleReCaptchaProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
