import {
  Avatar,
  Button,
  Grid,
  Link,
  Modal,
  Paper,
  Typography,
} from '@mui/material';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { background, DoubleDocIcon } from 'Images';
import { useIsDesktop } from 'Hooks';
import { Header } from 'Components';
import { Helmet } from 'react-helmet-async';

export const DoubleDoc = () => {
  const isDesktop = useIsDesktop();

  const onClickHandler = () => {
    window.location.replace('https://shikum.mod.gov.il/Pages/personal.aspx');
  };

  return (
    <>
      <Helmet>
        <title>
          {Dictionary[DICTIONARY.HEADER].title +
            ' - ' +
            Dictionary[DICTIONARY.DOUBLE_DOC].title}
        </title>
      </Helmet>
      <Header />
      <Grid
        container
        sx={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundOrigin: 'border-box',
          height: '100vh',
        }}
      ></Grid>
      <Modal open={true}>
        <Paper
          sx={
            isDesktop
              ? {
                  boxShadow: '0px 3px 21px 0px #2D70D926',
                  padding: '65px 100px 70px 100px',
                  maxWidth: '850px',
                  mb: '64px',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  textAlign: 'center',
                  width: '80%',
                  maxHeight: '80vh',
                  overflow: 'visible',
                }
              : {
                  boxShadow: '0px 3px 21px 0px #2D70D926',
                  padding: '38px 16px 48px 16px',
                  maxWidth: '750px',
                  mb: '64px',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  textAlign: 'center',
                  width: '80%',
                  maxHeight: '80vh',
                  overflow: 'auto',
                }
          }
        >
          <Avatar
            src={DoubleDocIcon}
            sx={
              isDesktop
                ? {
                    width: '104px',
                    height: '104px',
                    margin: '0 auto',
                    mb: '30px',
                  }
                : {
                    width: '64px',
                    height: '64px',
                    margin: '0 auto',
                    mb: '30px',
                  }
            }
            alt=''
          />

          <Typography
            component='h3'
            variant={'h1'}
            sx={{
              fontWeight: 'bold',
              maxWidth: '330px',
              margin: '0 auto',
            }}
          >
            {Dictionary[DICTIONARY.DOUBLE_DOC].title}
          </Typography>

          <Typography
            sx={
              isDesktop
                ? { fontSize: '20px', mt: '20px' }
                : {
                    fontSize: '16px',
                    mt: '20px',
                    padding: '0 15px',
                  }
            }
          >
            {Dictionary[DICTIONARY.DOUBLE_DOC].subTitle1}
          </Typography>

          <Typography
            sx={
              isDesktop
                ? {
                    fontSize: '20px',
                    mb: 3,
                    padding: '0 50px',
                  }
                : { fontSize: '16px', padding: '0' }
            }
          >
            {Dictionary[DICTIONARY.DOUBLE_DOC].subTitle2}
          </Typography>
          <Link
            mt={2}
            href='https://shikum.mod.gov.il/BasicPrivileges/hacara/Pages/Request-.aspx'
            sx={{ fontSize: '16px' }}
          >
            {Dictionary[DICTIONARY.DOUBLE_DOC].link}
          </Link>
          <Typography
            sx={
              isDesktop
                ? { mt: '38px', fontWeight: 'bold', fontSize: '18px' }
                : {
                    mt: '35px',
                    fontWeight: 'bold',
                  }
            }
          >
            {Dictionary[DICTIONARY.DOUBLE_DOC].helpTitle}
          </Typography>

          <Typography
            sx={{
              mt: '5px',
              mb: '38px',
              fontSize: isDesktop ? '18px' : '16px',
              padding: isDesktop ? '0 150px' : '0',
            }}
          >
            {Dictionary[DICTIONARY.DOUBLE_DOC].helpInfo1}
          </Typography>

          <Button
            onClick={onClickHandler}
            sx={{
              mt: 2,
              fontSize: '18px',
              fontWeight: 400,
            }}
            variant='contained'
          >
            {Dictionary[DICTIONARY.DOUBLE_DOC].enterPersonalService}
          </Button>
        </Paper>
      </Modal>
    </>
  );
};
