import { api } from 'API';

const baseUrl = '/WaiverOfMedicalConfidentiality';

export interface IWaiverOfMedicalConfidentiality {
  isConfirm: boolean;
}

export const getWaiverOfMedicalConfidentiality = async () => {
  const waiverOfMedicalConfidentiality =
    await api.get<IWaiverOfMedicalConfidentiality>(baseUrl);
  if (waiverOfMedicalConfidentiality.data) {
    return waiverOfMedicalConfidentiality.data;
  }
};

export const putWaiverOfMedicalConfidentiality = async (
  data: Partial<IWaiverOfMedicalConfidentiality>
) => {
  const waiverOfMedicalConfidentiality = await api.put(baseUrl, data);
  if (waiverOfMedicalConfidentiality.status === 200) {
    return waiverOfMedicalConfidentiality.data;
  }
};
