import { Typography } from '@mui/material';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';

export const TopDescription = () => {
  return (
    <div style={{ maxWidth: '700px', margin: '0 auto 3rem auto' }}>
      <Typography>
        {Dictionary[DICTIONARY.CASE_DETAILS].topFirstDescription}
      </Typography>
      <br />
      <Typography>
        {Dictionary[DICTIONARY.CASE_DETAILS].topSecondDescription}
      </Typography>
      <br />
      <Typography sx={{ fontWeight: 'bold', mb: '10px' }}>
        {Dictionary[DICTIONARY.CASE_DETAILS].topThirdTitle}
      </Typography>
      <Typography>
        {Dictionary[DICTIONARY.CASE_DETAILS].topThirdDescription}
      </Typography>
      <Typography>
        {Dictionary[DICTIONARY.CASE_DETAILS].topThirdDescriptionLine2}
      </Typography>
      <Typography>
        {Dictionary[DICTIONARY.CASE_DETAILS].topThirdDescriptionLine3}
      </Typography>
    </div>
  );
};
