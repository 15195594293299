import { Container, Grid } from '@mui/material';
import { background } from 'Images';
import { Outlet } from 'react-router-dom';
import { Header, Footer } from 'Components';
export const LobbyLayout = () => {
  return (
    <>
      <Header />
      <Grid
        container
        sx={{
          overflowX: 'hidden',
          minHeight: 'calc(100vh - 72px - 92px)',
        }}
      >
        <Outlet />
      </Grid>
      <Footer />
    </>
  );
};
