import { TextField, Typography } from '@mui/material';
import { Dictionary } from 'Dictionary';
import { DICTIONARY, EmploymentAndBankDetails } from 'Enum';
import { CustomRadioGroup, FormSection, PhoneNumber } from 'Components';
import { useFormContext, useWatch } from 'react-hook-form';
import { textFieldShrink } from 'Utils';
import { IEmploymentAndBankDetails } from 'API';
import { generateError } from 'Regex';
import { useAppSelector } from 'Hooks';
import { validateLettersAndNumbersOnly } from 'Utils/validateLettersAndNumbersOnly';
import { validateLettersNumbersAndSpacialChars } from 'Utils/validateLettersNumbersAndSpacialChars';
export const FirstSection = () => {
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext<IEmploymentAndBankDetails>();

  const workNameFormName = 'workPlaceDetailsSection.workName';
  const workAddressFormName = 'workPlaceDetailsSection.workAddress';
  const managerNameFormName = 'workPlaceDetailsSection.managerName';
  const jobTitleFormName = 'workPlaceDetailsSection.jobTitle';
  const employmentStatusIdFormName =
    'workPlaceDetailsSection.employmentStatusId';

  const [workName, workAddress, managerName, jobTitle, employmentStatusId] =
    getValues([
      workNameFormName,
      workAddressFormName,
      managerNameFormName,
      jobTitleFormName,
      employmentStatusIdFormName,
    ]);

  const dictionary = Dictionary[DICTIONARY.EMPLOYMENT_AND_BANK_DETAILS];

  const [employmentStatuses] = useAppSelector(
    ({
      generalContent: {
        generalContent: { employmentStatuses },
      },
    }) => [employmentStatuses]
  );

  const employmentStatus = useWatch({
    name: employmentStatusIdFormName,
    defaultValue: 0,
  }) as number;

  const employmentJobDesc =
    employmentStatus == EmploymentAndBankDetails.haveJob
      ? dictionary.firstSectionFifthInput
      : dictionary.firstSectionFifthInputNoJob;

  return (
    <FormSection grouped id={dictionary.firstSectionTitle}>
      {employmentStatuses && (
        <CustomRadioGroup
          id={dictionary.employmentStatusesTitle}
          name={`${employmentStatusIdFormName}` as const}
          options={employmentStatuses.map(({ id, value }) => ({
            value: id.toString(),
            label: value,
          }))}
        />
      )}

      {employmentStatuses &&
        employmentStatus < EmploymentAndBankDetails.neverWorked && (
          <>
            <Typography
              sx={{ fontWeight: 'bold', mt: 2 }}
              variant='body1'
              id={dictionary.firstSectionTitle}
            >
              {employmentStatus == EmploymentAndBankDetails.unemployed
                ? dictionary.firstSectionTitle2
                : dictionary.firstSectionTitle}
            </Typography>
            <TextField
              role={
                Boolean(errors.workPlaceDetailsSection?.workName)
                  ? 'alert'
                  : undefined
              }
              inputProps={{
                maxLength: '50',
              }}
              id={dictionary.firstSectionFirstInput}
              {...register(workNameFormName)}
              label={dictionary.firstSectionFirstInput}
              {...textFieldShrink(workName)}
              error={Boolean(errors.workPlaceDetailsSection?.workName)}
              helperText={
                Boolean(errors.workPlaceDetailsSection?.workName?.message) &&
                generateError(
                  dictionary.firstSectionFirstInput,
                  errors.workPlaceDetailsSection?.workName?.message!
                )
              }
              onChange={(event: any) => validateLettersAndNumbersOnly(event)}
            />
            <TextField
              role={
                Boolean(errors.workPlaceDetailsSection?.workAddress)
                  ? 'alert'
                  : undefined
              }
              inputProps={{
                maxLength: '50',
              }}
              id={dictionary.firstSectionSecondInput}
              {...register(workAddressFormName)}
              label={dictionary.firstSectionSecondInput}
              {...textFieldShrink(workAddress)}
              error={Boolean(errors.workPlaceDetailsSection?.workAddress)}
              helperText={
                Boolean(errors.workPlaceDetailsSection?.workAddress?.message) &&
                generateError(
                  dictionary.firstSectionSecondInput,
                  errors.workPlaceDetailsSection?.workAddress?.message!
                )
              }
              onChange={(event: any) => validateLettersAndNumbersOnly(event)}
            />
            <TextField
              role={
                Boolean(errors.workPlaceDetailsSection?.managerName)
                  ? 'alert'
                  : undefined
              }
              inputProps={{
                maxLength: '50',
              }}
              id={dictionary.firstSectionThirdInput}
              {...register(managerNameFormName)}
              label={dictionary.firstSectionThirdInput}
              {...textFieldShrink(managerName)}
              error={Boolean(errors.workPlaceDetailsSection?.managerName)}
              helperText={
                Boolean(errors.workPlaceDetailsSection?.managerName?.message) &&
                generateError(
                  dictionary.firstSectionThirdInput,
                  errors.workPlaceDetailsSection?.managerName?.message!
                )
              }
              onChange={(event: any) => validateLettersAndNumbersOnly(event)}
            />
            <PhoneNumber
              phoneNumberError={Boolean(
                errors.workPlaceDetailsSection?.managerPhoneNum
              )}
              phoneNumberErrorMsg={
                errors.workPlaceDetailsSection?.managerPhoneNum?.message
              }
              phoneNumberFormName='workPlaceDetailsSection.managerPhoneNum'
              phoneNumberPrefixFormName='workPlaceDetailsSection.managerPhonePrefixRelationId'
              phoneNumberLabel={dictionary.firstSectionFourthInput}
            />
            <PhoneNumber
              phoneNumberError={Boolean(
                errors.workPlaceDetailsSection?.landlinePhoneNum
              )}
              phoneNumberErrorMsg={
                errors.workPlaceDetailsSection?.landlinePhoneNum?.message
              }
              phoneNumberFormName='workPlaceDetailsSection.landlinePhoneNum'
              phoneNumberPrefixFormName='workPlaceDetailsSection.landlinePrefixRelationId'
              phoneNumberLabel={dictionary.firstSectionTelephoneNumber}
            />
            <TextField
              role={
                Boolean(errors.workPlaceDetailsSection?.jobTitle)
                  ? 'alert'
                  : undefined
              }
              inputProps={{
                maxLength: '500',
              }}
              id={employmentJobDesc}
              {...register(jobTitleFormName)}
              label={employmentJobDesc}
              {...textFieldShrink(jobTitle)}
              multiline
              rows={4}
              error={Boolean(errors.workPlaceDetailsSection?.jobTitle)}
              helperText={
                Boolean(errors.workPlaceDetailsSection?.jobTitle?.message) &&
                generateError(
                  employmentJobDesc,
                  errors.workPlaceDetailsSection?.jobTitle?.message!
                )
              }
              placeholder={dictionary.firstSectionFifthInputPlaceholder}
              onChange={(event: any) =>
                validateLettersNumbersAndSpacialChars(event)
              }
            />
          </>
        )}
    </FormSection>
  );
};
