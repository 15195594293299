import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { REGEX_TYPES } from 'Enum/RegexTypes';
import {
  isPhoneNumber,
  isFullPhoneNumber,
  isValidEmail,
  isValidIsraeliID,
  noNumbersAndSymbols,
} from 'Regex';

const minNameLength = 2;

export const regexFormController = (
  inputRegexType: string | undefined,
  value: string,
  callback: Function = () => {}
) => {
  const dictionary = Dictionary[DICTIONARY.GENERAL].errors;
  if (inputRegexType !== REGEX_TYPES.EMAIL && value.length === 0) {
    callback(dictionary.requiredField);
    return false;
  }
  switch (inputRegexType) {
    case REGEX_TYPES.NAME: {
      if (noNumbersAndSymbols(value) || value.length < minNameLength) {
        callback(dictionary.invalidName);
        return false;
      }
      break;
    }
    case REGEX_TYPES.ID: {
      if (!isValidIsraeliID(value)) {
        callback(dictionary.invalidID);
        return false;
      }
      break;
    }
    case REGEX_TYPES.PHONE: {
      if (!isPhoneNumber(value)) {
        callback(dictionary.invalidPhone);
        return false;
      }
      break;
    }
    case REGEX_TYPES.FULL_PHONE: {
      if (!isFullPhoneNumber(value)) {
        callback(dictionary.invalidPhone);
        return false;
      }
      break;
    }
    case REGEX_TYPES.EMAIL: {
      if (value.length > 0 && !isValidEmail(value)) {
        callback(dictionary.invalidEmail);
        return false;
      }
      break;
    }
  }
  return true;
};
