import { Box, Button, Typography } from '@mui/material';
import { DynamicArrow } from 'Components';

interface IArrowButton {
  onClick?: () => void;
  text: string;
  arrowColor?: string;
  arrowPosition?: 'left' | 'right';
}
export const ArrowButton = ({
  onClick,
  text,
  arrowColor = '#000',
  arrowPosition = 'left',
}: IArrowButton) => {
  return (
    <Button onClick={onClick} sx={{ gap: '5px' }} variant='outlined'>
      {arrowPosition === 'right' && (
        <Box position={'relative'} top={'5px'}>
          <DynamicArrow color={arrowColor} rotate={-90} size={20} />
        </Box>
      )}
      <Typography>{text}</Typography>
      {arrowPosition === 'left' && (
        <Box position={'relative'} top={'5px'}>
          <DynamicArrow color={arrowColor} rotate={90} size={20} />
        </Box>
      )}
    </Button>
  );
};
