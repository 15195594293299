import { Stack, Typography } from '@mui/material';
import {
  FormAutoSaveTypography,
  FormPaper,
  FormTitle,
  HomePageFormLink,
  InfoPaper,
  InfoText,
} from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { FirstSection } from './FirstSection';
import { SecondSection } from './SecondSection';
import { ThirdSection } from './ThirdSection';
import { FourthSection } from './FourthSection';
import { FifthSection } from './FifthSection';
import { AddressForMailDelivery } from './AddressForMailDelivery';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useRef } from 'react';
import {
  getCities,
  getCountries,
  getPersonalDetail,
  putPersonalDetail,
} from 'API';
import { useDispatchApp, useIsDesktop } from 'Hooks';
import { CaseDetailsSlice, setCities, setCountries, store } from 'Store';
import { IPersonalDetails } from 'API/PersonalDetail';
import { debounce, files, initFocus } from 'Utils';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { RoutesConfig } from 'Routes';
import { yupResolver } from '@hookform/resolvers/yup';
import { personalDetailsSchema } from './Schema';
import { InfoTextNew } from 'Components/InfoTextNew';

type Inputs = IPersonalDetails;

type context = {};
export const PersonalDetails = () => {
  const methods = useForm<Inputs, context>({
    mode: 'onTouched',
    resolver: yupResolver(personalDetailsSchema),
    context: {},
    defaultValues: {
      contactInfoSection: {
        extraPhoneNumber: undefined,
        extraPhonePrefixRelationId: undefined,
        mail: undefined,
        phoneNumber: undefined,
        phonePrefixRelationId: undefined,
        hasNoMail: undefined,
      },
      hmoSection: {
        doctorsNameAndSpecialization: undefined,
        hmoRelationId: undefined,
      },

      personalInfoSection: {
        birthCountryId: undefined,
        birthDate: undefined,
        firstName: undefined,
        genderId: undefined,
        immigrationYear: undefined,
        lastName: undefined,
        maritalStatusId: undefined,
        israeliId: undefined,
      },
      previousHMOSection: [
        {
          doctorsNameAndSpecialization: undefined,
          hmoRelationId: undefined,
          hospitalizationEndDate: undefined,
        },
      ],
      residentialAddressSection: {
        isDifferentMailAddress: undefined,
        abroadAddressRelation: {
          abroadTown: undefined,
          countryId: undefined,
          homeNumHouse: undefined,
          po: undefined,
          street: undefined,
          townId: undefined,
          abroadZipCode: undefined,
          apartmentNumber: undefined,
        },
        israeliAddressRelation: {
          apartmentNumber: undefined,
          homeNumHouse: undefined,
          israeliTownId: undefined,
          po: undefined,
          street: undefined,
          townId: undefined,
          israeliZipCode: undefined,
        },
        isAbroadResidentialAddress: undefined,
      },
      mailBoxSection: {
        apartmentNumber: undefined,
        mailHouseNumber: undefined,
        po: undefined,
        street: undefined,
        townId: undefined,
        zipCode: undefined,
      },
    },
  });

  const dispatch = useDispatchApp();

  const navigate = useNavigate();
  const isDesktop = useIsDesktop();
  const formInfo = store.getState().generalContent.formInfo;

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await forceSubmit();
    const result = await files();
    if (result) {
      navigate(RoutesConfig.STATUS_LOBBY.PATH);
    }
  };

  const personalDetailsInit = async () => {
    const country = getCountries();
    const cities = getCities();
    const [countriesData, citiesData] = await Promise.all([country, cities]);

    if (citiesData) dispatch(setCities(citiesData));
    if (countriesData) dispatch(setCountries(countriesData));
  };
  useEffect(() => {
    initFocus();
    personalDetailsInit();
  }, []);

  const initFlag = useRef<boolean>(true);

  const initForm = async () => {
    initFlag.current = true;
    const personalDetails = await getPersonalDetail();
    if (personalDetails) {
      methods.reset({ ...personalDetails });
      initFlag.current = false;

      const birthDate = personalDetails?.personalInfoSection?.birthDate;
      store.dispatch(
        CaseDetailsSlice.actions.setGeneralObj({ birthDate: birthDate })
      );
    }
  };

  /* Creating a ref object that will be used to store the id of the timeout. */
  const id = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    initForm();
  }, []);
  useEffect(() => {
    const subscription = methods.watch((data) => {
      if (initFlag.current) return;
      // if (Object.keys(methods.formState.errors).length) return;
      let previousHMOSection = undefined;

      if (data.previousHMOSection)
        previousHMOSection = data.previousHMOSection.map((hmo) =>
          hmo ? hmo : {}
        );
      debounce(id, putPersonalDetail, {
        ...data,
        contactInfoSection: {
          ...data.contactInfoSection,
          extraPhonePrefixRelationId: data.contactInfoSection
            ?.extraPhonePrefixRelationId
            ? data.contactInfoSection?.extraPhonePrefixRelationId
            : undefined,
        },
        previousHMOSection,
      });
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [methods.watch]);

  const forceSubmit = async () => {
    const values = methods.getValues();
    await putPersonalDetail(values);
  };
  return (
    <>
      <Helmet>
        <title>
          {Dictionary[DICTIONARY.HEADER].title +
            ' - ' +
            Dictionary[DICTIONARY.PERSONAL_DETAILS].title}
        </title>
      </Helmet>
      <HomePageFormLink save={forceSubmit} />
      <Stack alignItems='center' component={'main'} role={'main'}>
        <FormTitle title={Dictionary[DICTIONARY.PERSONAL_DETAILS].title} />
        {formInfo.isRecognized && (
          <Stack
            alignItems='center'
            sx={{
              mb: isDesktop ? '28px' : '16px',
              maxWidth: '720px',
              textAlign: 'center',
            }}
          >
            <InfoTextNew
              text={Dictionary[DICTIONARY.PERSONAL_DETAILS].notRecognized}
              url={`${process.env.REACT_APP_PERSONAL_AREA_URL}/requests/update-personal-details/8/18`}
              urlLabel={
                Dictionary[DICTIONARY.PERSONAL_DETAILS].notRecognizedLink
              }
            />
          </Stack>
        )}

        <FormPaper>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <FirstSection />
              <SecondSection />
              <ThirdSection />
              <AddressForMailDelivery />
              <FourthSection />
              <FifthSection />
              <FormAutoSaveTypography save={forceSubmit} />
            </form>
          </FormProvider>
        </FormPaper>
      </Stack>
    </>
  );
};
