import axios from 'axios';

export interface IEmergencyForm {
  firstName?: string | null;
  lastName?: string | null;
  israeliId?: string | null;
  genderId?: number | null;
  fathersName?: string | null;
  maritalStatusId?: number | null;
  birthDate?: string | null;
  israeliTownId?: string | null;
  street?: string | null;
  homeNumHouse?: string | null;
  apartmentNumber?: string | null;
  israeliZipCode?: string | null;
  phonePrefixRelationId?: string | null;
  phoneNumber?: string | null;
  extraPhoneNumber?: string | null;
  extraPhonePrefixRelationId?: string | null;
  email?: string | null;
  serviceTypeId?: string | null;
  role?: string | null;
  injuryDate?: string | null;
  isHospitalization?: boolean | null;
  injuryDescription?: string | null;
  injuryLevelOptionId?: string;
  bankRelationId?: string | null;
  branchRelationId?: string | null;
  accountNumber?: string | null;
  familyContactFirstName?: string | null;
  familyContactLastName?: string | null;
  familyContactPhonePrefixRelationId?: string | null;
  familyContactPhoneNumber?: string | null;
  formFillerFirstName?: string | null;
  formFillerLastName?: string | null;
  formFillerPhonePrefixRelationId?: string | null;
  formFillerPhoneNumber?: string | null;
  isCanNotSign?: boolean | null;
  signerFullName?: string | null;
  signerId?: string | null;
  signerPhone?: string | null;
  signerRelation?: string | null;
  note?: string | null;
}

export interface IPostEmergencyFormResponse {
  formSubmitedDateUtc: Date;
  id: number;
}

export interface IEmergencyFormError {
  Message: string;
  TraceId: string;
}

export interface IEmergencyFormDTO extends IEmergencyForm {
  medicalDocuments: File[];
  generalFiles: File[];
}

export interface IGenerateOtpDTO {
  handicappedIsrId: string;
  formFillerIsrId: string;
  phone: string;
  emailAddress?: string;
  isSms?: boolean;
  password: string;
}

export interface VerifyOtpRequest {
  handicappedIsrId: string;
  formFillerIsrId: string;
  phone?: string;
  emailAddress?: string;
  otpCode: string;
}

export enum FileType {
  ExtraInformation = 1,
  HospitalizationDocument = 2,
}

const api = axios.create({
  baseURL: process.env.REACT_APP_DOMAIN,
  validateStatus: function (status) {
    return status >= 200 && status < 300;
  },
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    return error.response;
  }
);

export const createRequest = async (
  body: IEmergencyForm,
  formId: string,
  token: string
) => {
  try {
    const response = await api.post(
      `${process.env.REACT_APP_DOMAIN}/EmergencyForm/submit/${formId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const updateFormRequest = async (
  body: IEmergencyForm,
  formId: string,
  token: string
) => {
  try {
    const response = await api.put(
      `${process.env.REACT_APP_DOMAIN}/EmergencyForm/update/${formId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const postFile = async (
  formId: string,
  fileCategoryId: number,
  file: FormData,
  token: string
) => {
  const url = `${process.env.REACT_APP_DOMAIN}/EmergencyForm/${formId}/files?fileCategoryId=${fileCategoryId}`;
  const fileData = await api.post(url, file, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (fileData.data) {
    return fileData.data;
  }
};

export const deleteFile = async (
  formId: string,
  fieldId: number,
  token: string
) => {
  const url = `${process.env.REACT_APP_DOMAIN}/EmergencyForm/${formId}/files/${fieldId}`;
  const fileData = await api.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (fileData.status === 204) {
    return true;
  }
  return false;
};

export const generateOtpCode = async (body: IGenerateOtpDTO) => {
  const otpCode = await api.post('/EmgFormOtp/generate', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return otpCode;
};

export const otpCodeValidation = async (body: VerifyOtpRequest) => {
  const verifyOtpCode = await api.post('/EmgFormOtp/verify', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (verifyOtpCode.data) return verifyOtpCode;
  else return verifyOtpCode;
};

export const getFormDataById = async (id: string, token: string | null) => {
  try {
    const url = `${process.env.REACT_APP_DOMAIN}/EmergencyForm/${id}`;
    const response = await api.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
