import * as yup from 'yup';
import {
  lettersOrNumbersWithExtraChars,
  lettersOrNumbersNoSpecialChars,
  lettersOrNumbersNoSpecialCharsMessage,
  onlyNumbers,
  onlyNumbersMessage,
  textTooLong,
  requiredMessage,
  endBeforeStart,
  endService,
  injuryDate,
  bornDateAndInjury,
  phoneNumberMessage,
  lettersOrNumbersWithExtraCharsMessage,
} from 'Regex';
import { isBefore } from 'date-fns';
import { ICaseDetailsInputs } from 'API';
import { store } from 'Store';
import { CASE_TYPE_RELATION_ID } from 'Enum';

const extractIndexFromPath = (path: string) => {
  const charIndex = path.indexOf('[');
  return path.substring(charIndex + 1, charIndex + 2);
};

export const caseDetailsSchema: yup.SchemaOf<ICaseDetailsInputs> = yup
  .object()
  .shape({
    caseDetails: yup
      .array()
      .of(
        yup.object({
          isMentalIllnessCausedBySpecificEvent: yup
            .boolean()
            .test({
              name: 'isRequired',
              message: requiredMessage,
              test: (value, context) => {
                if (
                  store.getState().caseDetails.caseDetailsObj.caseType[
                    extractIndexFromPath(context.path)
                  ].type === CASE_TYPE_RELATION_ID.MENTAL_ID
                ) {
                  if (value === undefined || value === null) {
                    return false;
                  }
                }
                return true;
              },
            })
            .nullable(),
          areThereWitnessesOptionId: yup.string().notRequired().nullable(),
          caseTypeRelationId: yup.string().required(requiredMessage).nullable(),
          diseaseKindSection: yup
            .object({
              isDiagnosisExist: yup
                .boolean()
                .test({
                  name: 'isRequired',
                  message: requiredMessage,
                  test: (value, context) => {
                    if (
                      store.getState().caseDetails.caseDetailsObj.caseType[
                        extractIndexFromPath(context.path)
                      ].type == CASE_TYPE_RELATION_ID.DISEASE_ID ||
                      (store.getState().caseDetails.caseDetailsObj.caseType[
                        extractIndexFromPath(context.path)
                      ].type == CASE_TYPE_RELATION_ID.MENTAL_ID &&
                        store.getState().caseDetails.caseDetailsObj
                          .isMentalIllnessCausedBySpecificEvent[
                          extractIndexFromPath(context.path)
                        ].type === false)
                    ) {
                      if (value === null || value === undefined) {
                        return false;
                      }
                    }
                    return true;
                  },
                })
                .nullable(),
              diseaseDetailsDescription: yup
                .string()
                .max(500, textTooLong(500))
                .matches(
                  lettersOrNumbersWithExtraChars,
                  lettersOrNumbersWithExtraCharsMessage
                )
                .test({
                  name: 'isRequired',
                  message: requiredMessage,
                  test: (value, context) => {
                    if (
                      store.getState().caseDetails.caseDetailsObj.caseType[
                        extractIndexFromPath(context.path)
                      ].type == CASE_TYPE_RELATION_ID.DISEASE_ID ||
                      (store.getState().caseDetails.caseDetailsObj.caseType[
                        extractIndexFromPath(context.path)
                      ].type == CASE_TYPE_RELATION_ID.MENTAL_ID &&
                        !store.getState().caseDetails.caseDetailsObj
                          .isMentalIllnessCausedBySpecificEvent[
                          extractIndexFromPath(context.path)
                        ].type)
                    ) {
                      if (
                        value === null ||
                        value === undefined ||
                        value === ''
                      ) {
                        return false;
                      }
                    }
                    return true;
                  },
                })
                .notRequired()
                .nullable(),
              diseaseDiagnosisDate: yup
                .string()
                .test({
                  name: 'isRequired',
                  message: requiredMessage,
                  test: (value, context) => {
                    const isDiagnosisExist = context.parent.isDiagnosisExist;
                    if (
                      store.getState().caseDetails.caseDetailsObj.caseType[
                        extractIndexFromPath(context.path)
                      ].type != CASE_TYPE_RELATION_ID.DISEASE_ID
                    ) {
                      return true;
                    }
                    if (!isDiagnosisExist) {
                      return true;
                    }
                    if (
                      store.getState().caseDetails.caseDetailsObj.caseType[
                        extractIndexFromPath(context.path)
                      ].type == CASE_TYPE_RELATION_ID.DISEASE_ID &&
                      isDiagnosisExist
                    ) {
                      if (value) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                    return false;
                  },
                })
                .notRequired()
                .nullable(),
              howServiceCauseYourHealthCondition: yup
                .string()
                .max(500, textTooLong(500))
                .matches(
                  lettersOrNumbersWithExtraChars,
                  lettersOrNumbersWithExtraCharsMessage
                )
                .notRequired()
                .nullable(),
              isRelativesWithSameDisease: yup
                .boolean()
                .notRequired()
                .nullable(),
              relativesWithSameDisease: yup
                .string()
                .max(500, textTooLong(500))
                .matches(
                  lettersOrNumbersWithExtraChars,
                  lettersOrNumbersWithExtraCharsMessage
                )
                .notRequired()
                .nullable(),
            })
            .notRequired()
            .nullable(),
          hospitalizationDetailsSection: yup
            .array()
            .of(
              yup
                .object({
                  clinicDepartment: yup
                    .string()
                    .max(50, textTooLong(50))
                    .matches(
                      lettersOrNumbersWithExtraChars,
                      lettersOrNumbersWithExtraCharsMessage
                    )
                    .notRequired()
                    .nullable(),
                  doctorName: yup
                    .string()
                    .max(50, textTooLong(50))
                    .matches(
                      lettersOrNumbersNoSpecialChars,
                      lettersOrNumbersNoSpecialCharsMessage
                    )
                    .notRequired()
                    .nullable(),
                  hospitalization: yup.string().notRequired().nullable(),
                  hospitalizationEndDate: yup
                    .string()
                    .test({
                      name: 'isGray',
                      message: endBeforeStart,
                      test: (value, context) => {
                        if (
                          value &&
                          isBefore(
                            new Date(value?.valueOf()),
                            new Date(
                              context.parent.hospitalizationStartDate.valueOf()
                            )
                          )
                        ) {
                          return false;
                        }
                        return true;
                      },
                    })
                    .nullable(),
                  hospitalizationStartDate: yup
                    .string()
                    .notRequired()
                    .nullable(),
                  medicalInstitutionName: yup
                    .string()
                    .max(50, textTooLong(50))
                    .matches(
                      lettersOrNumbersNoSpecialChars,
                      lettersOrNumbersNoSpecialCharsMessage
                    )
                    .notRequired()
                    .nullable(),
                })
                .notRequired()
            )
            .notRequired(),
          injuryKindSection: yup
            .object({
              investigationHasBeen: yup
                .boolean()
                .test({
                  name: 'isRequired',
                  message: requiredMessage,
                  test: (value, context) => {
                    if (
                      store.getState().caseDetails.caseDetailsObj.caseType[
                        extractIndexFromPath(context.path)
                      ].type == CASE_TYPE_RELATION_ID.INJURY_ID ||
                      (store.getState().caseDetails.caseDetailsObj.caseType[
                        extractIndexFromPath(context.path)
                      ].type == CASE_TYPE_RELATION_ID.MENTAL_ID &&
                        store.getState().caseDetails.caseDetailsObj
                          .isMentalIllnessCausedBySpecificEvent[
                          extractIndexFromPath(context.path)
                        ].type)
                    ) {
                      if (value === null || value === undefined) {
                        return false;
                      }
                    }
                    return true;
                  },
                })
                .nullable(),
              investigatorOptionId: yup.string().notRequired().nullable(),
              date: yup
                .string()
                .test({
                  name: bornDateAndInjury,
                  message: bornDateAndInjury,
                  test: (value) => {
                    const userBirthDate =
                      store.getState().caseDetails.generalObj.birthDate;
                    if (
                      value &&
                      userBirthDate &&
                      isBefore(
                        new Date(value?.valueOf()),
                        new Date(userBirthDate.valueOf())
                      )
                    ) {
                      return false;
                    }
                    return true;
                  },
                })
                .test({
                  name: injuryDate,
                  message: injuryDate,
                  test: (value) => {
                    if (
                      value &&
                      isBefore(new Date(), new Date(value?.valueOf()))
                    ) {
                      return false;
                    }
                    return true;
                  },
                })
                .test({
                  name: 'isRequired',
                  message: requiredMessage,
                  test: (value, context) => {
                    if (
                      store.getState().caseDetails.caseDetailsObj.caseType[
                        extractIndexFromPath(context.path)
                      ].type == CASE_TYPE_RELATION_ID.INJURY_ID ||
                      (store.getState().caseDetails.caseDetailsObj.caseType[
                        extractIndexFromPath(context.path)
                      ].type == CASE_TYPE_RELATION_ID.MENTAL_ID &&
                        store.getState().caseDetails.caseDetailsObj
                          .isMentalIllnessCausedBySpecificEvent[
                          extractIndexFromPath(context.path)
                        ].type)
                    ) {
                      if (value === null || value === undefined) {
                        return false;
                      }
                    }
                    return true;
                  },
                })
                .nullable(),
              description: yup
                .string()
                .max(500, textTooLong(500))
                .matches(
                  lettersOrNumbersWithExtraChars,
                  lettersOrNumbersWithExtraCharsMessage
                )
                .test({
                  name: 'isRequired',
                  message: requiredMessage,
                  test: (value, context) => {
                    if (
                      store.getState().caseDetails.caseDetailsObj.caseType[
                        extractIndexFromPath(context.path)
                      ].type == CASE_TYPE_RELATION_ID.INJURY_ID ||
                      (store.getState().caseDetails.caseDetailsObj.caseType[
                        extractIndexFromPath(context.path)
                      ].type == CASE_TYPE_RELATION_ID.MENTAL_ID &&
                        store.getState().caseDetails.caseDetailsObj
                          .isMentalIllnessCausedBySpecificEvent[
                          extractIndexFromPath(context.path)
                        ].type)
                    ) {
                      if (
                        value === null ||
                        value === undefined ||
                        value === ''
                      ) {
                        return false;
                      }
                    }
                    return true;
                  },
                })
                .nullable(),
              reportAttachedSelectedOptionId: yup
                .string()
                .notRequired()
                .nullable(),
            })
            .notRequired(),
          isTrafficAccident: yup
            .boolean()
            .test({
              name: 'isRequired',
              message: requiredMessage,
              test: (value, context) => {
                if (
                  store.getState().caseDetails.caseDetailsObj.caseType[
                    extractIndexFromPath(context.path)
                  ].type == CASE_TYPE_RELATION_ID.INJURY_ID
                ) {
                  if (value === null || value === undefined) return false;
                }
                return true;
              },
            })
            .nullable(),
          militaryServiceSection: yup
            .object({
              serviceTypeId: yup.string().required(requiredMessage).nullable(),
              otherServiceType: yup
                .string()
                .max(50, textTooLong(50))
                .matches(
                  lettersOrNumbersWithExtraChars,
                  lettersOrNumbersWithExtraCharsMessage
                )
                .test({
                  name: 'isRequired',
                  message: requiredMessage,
                  test: (value, context) => {
                    if (context.parent.serviceTypeId == 12) {
                      if (!value) {
                        return false;
                      }
                    }
                    return true;
                  },
                })
                .nullable(),
              releaseDate: yup
                .string()
                .notRequired()
                .test({
                  name: endService,
                  message: endService,
                  test: (value) => {
                    if (
                      value &&
                      isBefore(new Date(), new Date(value?.valueOf()))
                    ) {
                      return false;
                    }
                    return true;
                  },
                })
                .nullable(),
              role: yup
                .string()
                .max(50, textTooLong(50))
                .matches(
                  lettersOrNumbersWithExtraChars,
                  lettersOrNumbersWithExtraCharsMessage
                )
                .required(requiredMessage)
                .nullable(),
              medicalProfile: yup
                .string()
                .max(2)
                .matches(onlyNumbers, onlyNumbersMessage)
                .nullable(),
              troopId: yup.string().notRequired().nullable(),
            })
            .notRequired(),
          trafficAccidentSection: yup
            .object({
              anotherVehicleInvolvedAccident: yup
                .boolean()
                .notRequired()
                .nullable(),
              insuranceCompany: yup
                .string()
                .max(50, textTooLong(50))
                .matches(
                  lettersOrNumbersWithExtraChars,
                  lettersOrNumbersWithExtraCharsMessage
                )
                .notRequired()
                .nullable(),
              isClaimForCompensationHasBeenSent: yup
                .boolean()
                .notRequired()
                .nullable(),
              isDriver: yup.boolean().notRequired().nullable(),
              isPedestrian: yup.boolean().notRequired().nullable(),
              vehicleDetailsSection: yup
                .object({
                  ownershipVehicleId: yup.string().notRequired().nullable(),
                  vehicleId: yup
                    .string()
                    .max(20, textTooLong(20))
                    .matches(onlyNumbers, onlyNumbersMessage)
                    .notRequired()
                    .nullable(),
                })
                .notRequired(),
            })
            .notRequired()
            .nullable(),
          wasMedicalInstitutionTreatment: yup
            .string()
            .required(requiredMessage)
            .nullable(),
          witnesses: yup
            .array()
            .of(
              yup
                .object({
                  civilAddress: yup
                    .string()
                    .max(100, textTooLong(100))
                    .matches(
                      lettersOrNumbersNoSpecialChars,
                      lettersOrNumbersNoSpecialCharsMessage
                    )
                    .notRequired()
                    .nullable(),
                  firstName: yup
                    .string()
                    .max(20, textTooLong(20))
                    .matches(
                      lettersOrNumbersNoSpecialChars,
                      lettersOrNumbersNoSpecialCharsMessage
                    )
                    .notRequired()
                    .nullable(),
                  idNumber: yup.string().notRequired().nullable(),
                  lastName: yup
                    .string()
                    .max(30, textTooLong(30))
                    .matches(
                      lettersOrNumbersNoSpecialChars,
                      lettersOrNumbersNoSpecialCharsMessage
                    )
                    .notRequired()
                    .nullable(),
                  militaryMail: yup
                    .string()
                    .max(9)
                    .matches(onlyNumbers, onlyNumbersMessage)
                    .notRequired()
                    .nullable(),
                  phone: yup
                    .string()
                    .test({
                      name: 'witnessPhoneNumber',
                      message: phoneNumberMessage,
                      test: (value) => {
                        if (value) {
                          if (value.length === 7) {
                            return true;
                          }
                          return false;
                        }
                        return true;
                      },
                    })
                    .matches(onlyNumbers, onlyNumbersMessage)
                    .notRequired()
                    .nullable(),
                  // phonePrefixRelationId: yup.string().notRequired(),
                  role: yup
                    .string()
                    .max(50, textTooLong(50))
                    .notRequired()
                    .nullable()
                    .matches(
                      lettersOrNumbersWithExtraChars,
                      lettersOrNumbersWithExtraCharsMessage
                    ),
                  // typeId: yup.string().notRequired(),
                  // otherWitnessesType: yup.string().notRequired(),
                  // isClassifiedRole: yup.boolean().notRequired().nullable(),
                })
                .notRequired()
                .nullable()
            )
            .notRequired(),
          id: yup.number().required(),
        })
      )
      .notRequired(),
  });
