import { FC } from 'react';
import { Paper } from '@mui/material';
import { useIsDesktop } from 'Hooks';
export const InfoPaper: FC<{}> = (props) => {
  const isDesktop = useIsDesktop();
  return (
    <Paper
      sx={{
        maxWidth: '780px',
        padding: isDesktop ? '36px 73px' : undefined,
        boxShadow: isDesktop
          ? '0px 3px 21px rgba(45, 112, 217, 0.15)'
          : undefined,
      }}
      elevation={0}
    >
      {props.children}
    </Paper>
  );
};
