import { Avatar, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { IFile } from 'API';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { File } from 'Images';
import { UploadFileButton } from '../UploadFileButton';
import { FC } from 'react';
export interface IFileCard {
  fileLabel: string;
  clickHandler: () => void;
}
export const FileCard: FC<IFileCard> = (props) => {
  const dictionary = Dictionary[DICTIONARY.GENERAL];
  return (
    <Paper
      sx={{
        width: '100%',
      }}
    >
      <Grid
        container
        spacing={1}
        alignItems='center'
        sx={{
          padding: '24px 16px',
        }}
      >
        <Grid container item xs={6} alignItems='center'>
          <Grid item xs={3}>
            <Avatar src={File} sx={{ width: '40px', height: '40px' }} alt='' />
          </Grid>
          <Grid item xs={9}>
            <Typography noWrap fontWeight={700}>
              {props.fileLabel}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <UploadFileButton clickHandler={props.clickHandler}>
            {dictionary.addAdditionalFile}
          </UploadFileButton>
        </Grid>
      </Grid>
      {props.children}
    </Paper>
  );
};
