import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fileNames, IFile } from 'API';
import { FileCategories } from 'Enum';

export type STATUS =
  | 'NOT_SET'
  | 'SETTING'
  | 'FINISHED_SETTING'
  | 'ERROR_SETTING';

interface InitState {
  files: fileNames;
  status: STATUS;
}

const initialState: InitState = {
  files: {},
  status: 'NOT_SET',
};

export const fileSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    setFiles: (state, action: PayloadAction<fileNames>) => {
      state.files = action.payload;
      state.status = 'FINISHED_SETTING';
    },
    addFile: (
      state,
      action: PayloadAction<{ fileCategory: FileCategories; file: IFile }>
    ) => {
      const files = state.files[action.payload.fileCategory];
      if (files) {
        files.push(action.payload.file);
      } else {
        state.files[action.payload.fileCategory] = [action.payload.file];
      }
    },
    removeFile: (
      state,
      action: PayloadAction<{
        fileCategory: FileCategories;
        fileId: number;
      }>
    ) => {
      if (action.payload.fileId) {
        state.files[action.payload.fileCategory] = state.files[
          action.payload.fileCategory
        ]?.filter((file) => file.id !== action.payload.fileId);
      } else {
        delete state.files[action.payload.fileCategory];
      }
    },
  },
});

export const { setFiles, addFile, removeFile } = fileSlice.actions;

export default fileSlice.reducer;
