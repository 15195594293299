import { useController } from 'react-hook-form';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';

interface ICustomCheckbox {
  name: string;
  label: string;
  disabled?: boolean;
}

export const CustomCheckbox = (props: ICustomCheckbox) => {
  const {
    field: { value, ...rest },
    fieldState: { error },
  } = useController({
    name: props.name,
    defaultValue: false,
  });

  const formError = Boolean(error);

  return (
    <FormControl error={formError}>
      <FormControlLabel
        sx={{ mb: 2, fontSize: '14px' }}
        control={
          <Checkbox
            aria-invalid={formError}
            checked={value}
            {...rest}
            disabled={props.disabled}
          />
        }
        label={props.label}
      />
      {formError && <FormHelperText>{error?.message || ''}</FormHelperText>}
    </FormControl>
  );
};
