import { Stack, Typography } from '@mui/material';
import { InfoPaper, PhoneNumberText } from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { useIsDesktop } from 'Hooks';
import { GridButton } from '../GridButtons';
import { Bullet } from './Bullet';
import { CustomAccordion } from './CustomAccordion';

interface ISecondTab {
  nextTab: () => void;
}

export const SecondTab = (props: ISecondTab) => {
  const dictionary = Dictionary[DICTIONARY.LOBBY];
  const isDesktop = useIsDesktop();

  const contactUsDictionary = Dictionary[DICTIONARY.CONTACT_US];
  return (
    <Stack alignItems='center'>
      <InfoPaper>
        <CustomAccordion title={dictionary.secondTabWeAreHereForYouTitle}>
          <Stack>
            <Typography textAlign={isDesktop ? 'start' : 'center'} paragraph>
              {dictionary.secondTabWeAreHereForYouFirstParagraph}
            </Typography>
            <Typography
              textAlign={isDesktop ? 'start' : 'center'}
              sx={{ fontWeight: 'bold' }}
            >
              {Dictionary[DICTIONARY.CONTACT_US].itemGoodHandsTitle}
            </Typography>
            <Typography
              textAlign={isDesktop ? 'start' : 'center'}
              sx={{ mb: '20px' }}
            >
              {Dictionary[DICTIONARY.CONTACT_US].itemGoodHands}
            </Typography>
            <Typography
              textAlign={isDesktop ? 'start' : 'center'}
              sx={{ fontWeight: 'bold' }}
            >
              {Dictionary[DICTIONARY.CONTACT_US].itemShikumTitle}
              <PhoneNumberText
                phoneNumber={contactUsDictionary.itemShikumPhoneNumber}
              />
              {contactUsDictionary.itemShikumHelper}
            </Typography>
            <Typography
              textAlign={isDesktop ? 'start' : 'center'}
              sx={{ mb: '20px' }}
            >
              {Dictionary[DICTIONARY.CONTACT_US].itemShikum}
            </Typography>
            <Typography
              textAlign={isDesktop ? 'start' : 'center'}
              sx={{ fontWeight: 'bold' }}
            >
              {dictionary.emotionalSupportTitle}
              <PhoneNumberText
                phoneNumber={dictionary.emotionalSupportPhoneNumber}
              />
            </Typography>
            <Typography
              textAlign={isDesktop ? 'start' : 'center'}
              sx={{ mb: '20px' }}
            >
              {dictionary.emotionalSupportFirstParagraph}
            </Typography>
            <Typography
              textAlign={isDesktop ? 'start' : 'center'}
              paragraph
              fontWeight={700}
            >
              {dictionary.secondTabWeAreHereForYouSecondParagraph}
            </Typography>
            <Bullet
              title={`${dictionary.secondTabWeAreHereForYouFirstBulletTitle}`}
              description={`${dictionary.secondTabWeAreHereForYouFirstBulletFirstParagraph}`}
            />
            <Bullet
              title={dictionary.secondTabWeAreHereForYouSecondBulletTitle}
              description={
                dictionary.secondTabWeAreHereForYouSecondBulletFirstParagraph
              }
            />
            <Bullet
              title={dictionary.secondTabWeAreHereForYouThirdBulletTitle}
              description={
                dictionary.secondTabWeAreHereForYouThirdBulletFirstParagraph
              }
            />
          </Stack>
        </CustomAccordion>
        <CustomAccordion title={dictionary.secondTabInjuryReportTitle}>
          <Stack>
            <Typography paragraph>
              {dictionary.secondTabInjuryReportFirstParagraph}
            </Typography>
          </Stack>
        </CustomAccordion>
        <CustomAccordion
          title={dictionary.secondTabMultipleVulnerabilitiesTitle}
        >
          <Stack>
            <Typography paragraph>
              {dictionary.secondTabMultipleVulnerabilitiesParagraph}
            </Typography>
          </Stack>
        </CustomAccordion>
        <CustomAccordion title={dictionary.secondTabAttachedFilesTitle}>
          <Stack>
            <Typography paragraph>
              {dictionary.secondTabAttachedFilesFirstParagraph}
              <Typography>{dictionary.instructions}</Typography>
              <Typography fontWeight={700}>
                {dictionary.secondTabAttachedFilesFirstParagraphBoldSection}
              </Typography>
            </Typography>

            <Typography>
              {dictionary.secondTabAttachedFilesSecondParagraph}
            </Typography>
          </Stack>
        </CustomAccordion>
        <CustomAccordion title={dictionary.secondTabYourDataIsSavedTitle}>
          <Stack>
            <Typography paragraph>
              {dictionary.secondTabYourDataIsSavedFirstParagraph}
            </Typography>
          </Stack>
        </CustomAccordion>
      </InfoPaper>
      <GridButton
        nextStep={props.nextTab}
        rightButton={dictionary.enterTheForm}
        leftButton={dictionary.thirdTabTitle}
      />
    </Stack>
  );
};
