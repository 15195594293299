import {Button, Modal, Paper, Typography, useMediaQuery, useTheme,} from '@mui/material';
import {Box} from '@mui/system';
import {Dictionary} from 'Dictionary';
import {DICTIONARY} from 'Enum';

export type Text = { date: string; injuryType: string };

interface IModal {
  isVisible: boolean;
  closeModal: () => void;
  deleteModal: () => void;
  texts: Text;
}

export const DeleteModal = (props: IModal) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Modal open={props.isVisible}>
      <Paper
        sx={{
          boxShadow: '0px 3px 21px 0px #2D70D926',
          padding: isDesktop ? '96px 100px 56px 100px' : '38px 16px 48px 16px',
          maxWidth: '550px',
          mb: '64px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          width: '80%',
        }}
      >
        <span
          style={{
            position: 'absolute',
            top: '20px',
            left: '20px',
            fontSize: '25px',
            cursor: 'pointer',
          }}
          onClick={props.closeModal}
        >
          X
        </span>
          <Typography>
              {Dictionary[DICTIONARY.PERSONAL_DETAILS].deleteModalFirstParagraph}
          </Typography>
          <Typography sx={{fontWeight: 'bold', mb: 2}}>
              {
                  Dictionary[DICTIONARY.PERSONAL_DETAILS]
                      .deleteModalCaseDetailsSecondLine
              }{' '}
              {props.texts.date && <span> מתאריך {props.texts.date}.</span>}
          </Typography>
          <Typography>
              {Dictionary[DICTIONARY.PERSONAL_DETAILS].deleteModalCaseDetailsLine3}
          </Typography>
          <Typography>
              {Dictionary[DICTIONARY.PERSONAL_DETAILS].deleteModalCaseDetailsLine4}
          </Typography>
          <Typography sx={{mt: '30px', fontWeight: 'bold'}}>
              {
                  Dictionary[DICTIONARY.PERSONAL_DETAILS]
                      .deleteModalCaseDetailsLastLine
              }
          </Typography>

        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', mt: '50px' }}
        >
          <Button variant={'outlined'} onClick={props.deleteModal}>
            {Dictionary[DICTIONARY.PERSONAL_DETAILS].deleteModalConfirmButton}
          </Button>
          <Button onClick={props.closeModal}>
            {Dictionary[DICTIONARY.PERSONAL_DETAILS].deleteModalCancelButton}
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};
