import { Avatar, Stack, Tab, Tabs, Typography, Container } from '@mui/material';
import { TabPanel } from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { useIsDesktop } from 'Hooks';
import { LobbyIcon } from 'Images';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { FirstTab } from './FirstTab';
import { SecondTab } from './SecondTab';
import { ThirdTab } from './ThirdTab';
export const Lobby = () => {
  const lobbyDictionary = Dictionary[DICTIONARY.LOBBY];

  const location = useLocation();

  const [searchParams] = useSearchParams();

  const [tabIndex, setTabIndex] = useState(0);

  const isDesktop = useIsDesktop();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    if (searchParams.get('tabIndex')) {
      setTabIndex(Number(searchParams.get('tabIndex')));
    }
  }, [location]);

  const nextStep = () => {
    setTabIndex(tabIndex + 1);
  };

  return (
    <Stack alignItems='center' component={'main'} role={'main'}>
      <Stack
        pt={isDesktop ? 12 : 6}
        alignItems='center'
        sx={{
          boxShadow: '0px 3px 21px rgba(45, 112, 217, 0.15)',
        }}
      >
        <Avatar
          src={LobbyIcon}
          alt=''
          sx={{
            width: '72.56px',
            height: '80px',
            mb: 1,
          }}
        />
        <Typography mb={1} fontWeight={700} variant='h1'>
          {lobbyDictionary.title}
        </Typography>
        <Typography
          sx={{ width: '25ch' }}
          mb={7}
          variant='h2'
          textAlign='center'
        >
          {lobbyDictionary.subTitle}
        </Typography>
        <Container maxWidth='md' sx={{ padding: '0' }}>
          <Tabs
            sx={{
              width: '100%',
              justifyContent: 'center',
            }}
            value={tabIndex}
            onChange={handleChange}
          >
            <Tab label={lobbyDictionary.firstTab} />
            <Tab label={lobbyDictionary.secondTab} />
            <Tab label={lobbyDictionary.thirdTab} />
          </Tabs>
        </Container>
      </Stack>
      <Container sx={{ mt: isDesktop ? 3 : 5 }} maxWidth='xl'>
        <TabPanel index={0} value={tabIndex}>
          <FirstTab nextTab={nextStep} />
        </TabPanel>
        <TabPanel index={1} value={tabIndex}>
          <SecondTab nextTab={nextStep} />
        </TabPanel>
        <TabPanel index={2} value={tabIndex}>
          <ThirdTab />
        </TabPanel>
      </Container>
    </Stack>
  );
};
