import { createContext, FC, useContext, useState } from 'react';
interface IRadioButtonContext {
  error: boolean;
  errorMsg: string;
  setError: (error: boolean, errorMsg: string) => void;
}
export const RadioButtonContext = createContext<IRadioButtonContext>({
  error: false,
  errorMsg: '',
  setError: () => {},
});

interface IRadioButtonProvider {}
export const RadioButtonProvider: FC<IRadioButtonProvider> = (props) => {
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const setErrorHandler = (error: boolean, errorMsg: string) => {
    setError(error);
    setErrorMsg(errorMsg);
  };
  return (
    <RadioButtonContext.Provider
      value={{
        error,
        errorMsg,
        setError: setErrorHandler,
      }}
    >
      {props.children}
    </RadioButtonContext.Provider>
  );
};

export const useRadioButton = () => {
  return useContext(RadioButtonContext);
};
