export enum FileCategories {
  //TrafficAccidentSection
  DrivingLicense = 10,
  VehicleInsurance = 11,
  PoliceReport = 19,

  //Represantation partial
  GuardianApprovalAttachment = 3,
  LawyerPowerOfAttorneyAttachment = 14,
  LawyerRepresentationAttachment = 1,
  AssociationRepresentationAttachment = 2,

  //SocialSecuritySection
  SocialSecurityApprovalDecision = 21,
  SocialSecurityProtocol = 12,

  //personalInfoSection
  IdNumberScan = 5,
  IdAttachmentScan = 7,

  //residintial address section
  // TODO add the file component there
  LeaseContractOrPropertyTax = 6,

  //BankAccountDetailsSection
  AccountManagementCertificate = 9,

  //InjuryKindSection
  InjuryReportFiLe = 13,
  InvestigationReportFile = 16,
  ExtraRelatedFile = 17,
  InjuryFromTheUnitOrCommandStaff = 20,
  AnotherDocumentFromServiceIndicatingInjury = 18,

  //HospitalizationDetailsSection
  ReleaseLetter = 4,

  MedicalConfidentialityWaiver = 8,
  FormConcludingDocument = 15,
}
