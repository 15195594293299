import { api } from 'API';

const baseUrl = '/city';

export interface ICity {
  id: number;
  value: string;
}

export const getCities = async () => {
  const citiesData = await api.get<ICity[]>(baseUrl);
  if (citiesData.data) {
    return citiesData.data;
  }
};

export const fetchCities = async () => {
  const citiesData = await fetch(`${process.env.REACT_APP_DOMAIN}/city`);
  const result = await citiesData.json();

  if (result) {
    return result;
  }
};
