import { Avatar, Grid, IconButton, Typography } from '@mui/material';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { TrashCan } from 'Images';
interface ITrashCanGrid {
  remove: () => void;
  alt?: string;
}
export const TrashCanGrid = (props: ITrashCanGrid) => {
  return (
    <Grid
      mt='30px'
      gap={1.25}
      container
      alignItems='center'
      onClick={props.remove}
    >
      <IconButton sx={{ padding: 0 }}>
        <Avatar
          alt={props.alt ? props.alt : ''}
          src={TrashCan}
          sx={{ width: '20px', height: '20px' }}
        />
      </IconButton>
      <Typography color='primary' sx={{ cursor: 'pointer' }}>
        {Dictionary[DICTIONARY.GENERAL].delete}
      </Typography>
    </Grid>
  );
};
