import { DatePicker, LocalizationProvider } from '@mui/lab';
import he from 'date-fns/locale/he';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import { useController } from 'react-hook-form';
import { setHours } from 'date-fns';
import { generateError, requiredMessage } from 'Regex';
import { CaseDetailsSlice, store } from '../../Store';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';

interface IControllerDatePicker {
  label?: string;
  name: string;
  viewYearOnly?: boolean;
  minDate?: Date;
  maxDate?: Date;
  required?: boolean;
  disabled?: boolean;
}
export const ControllerDatePicker = (innerProps: IControllerDatePicker) => {
  const {
    label,
    name,
    viewYearOnly = false,
    minDate = new Date('01/01/1900'),
    maxDate = new Date(),
    required = false,
    disabled,
  } = innerProps;

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    defaultValue: null,
    rules: {
      required: {
        value: required,
        message: requiredMessage,
      },
    },
  });

  let { ref, value, onChange, onBlur, ...other } = field;

  const onInputBlur = () => {
    if (
      (value &&
        value.toString() ===
          Dictionary[DICTIONARY.GENERAL].errors.invalidDate) ||
      (maxDate && value > maxDate) ||
      (minDate && value < minDate)
    )
      onChange(undefined);

    onBlur();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={he}>
      <DatePicker
        onChange={(date) => {
          if (date) onChange(setHours(new Date(date), 3));

          if (name == 'personalInfoSection.birthDate') {
            store.dispatch(
              CaseDetailsSlice.actions.setGeneralObj({ birthDate: date })
            );
          }
        }}
        mask={viewYearOnly ? '____' : '__/__/____'}
        inputFormat={viewYearOnly ? 'yyyy' : 'dd/MM/yyyy'}
        views={viewYearOnly ? ['year'] : undefined}
        inputRef={ref}
        {...other}
        label={label}
        value={value ? value : null}
        okText={Dictionary[DICTIONARY.GENERAL].select}
        cancelText={Dictionary[DICTIONARY.GENERAL].close}
        renderInput={(params) => (
          <TextField
            {...params}
            onBlur={onInputBlur}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            error={Boolean(error)}
            helperText={error?.message && generateError(label!, error?.message)}
            id={label}
            role={Boolean(error) ? 'alert' : undefined}
            sx={
              error?.type == 'isGray'
                ? {
                    '&  .MuiFormHelperText-root.MuiFormHelperText-filled.Mui-error':
                      {
                        backgroundColor: '#F8F8F8',
                        margin: 0,
                        padding: '3px 10px 0 10px',
                      },
                  }
                : {}
            }
          />
        )}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
};
