import { IGenerateOtpDTO } from 'API/EmergencyForm';
import { ReactNode, createContext, useState } from 'react';

const defaultErrors = {
  handicappedIsrId: null,
  formFillerIsrId: null,
  phone: null,
  emailAddress: null,
  accessToken: null,
  emergencyFormId: null,
  password: null,
};

export interface IUserOTPLoginDetails {
  handicappedIsrId?: string;
  formFillerIsrId?: string;
  phone?: string;
  emailAddress?: string;
  accessToken: string | null;
  emergencyFormId: string;
}

interface IErrors {
  handicappedIsrId: string | null;
  formFillerIsrId: string | null;
  phone: string | null;
  emailAddress: string | null;
  emergencyFormId: string | null;
  password: string | null;
}
interface IOTPLoginContext {
  step: number;
  setStep: Function;
  userDetails: IGenerateOtpDTO;
  setUserDetails: Function;
  loginDetails: IUserOTPLoginDetails;
  setLoginDetails: Function;
  isFormValidated: boolean;
  setIsFormValidation: Function;
  errors: IErrors;
  setErrors: (errors: IErrors) => void;
  uploadedFiles: EmgFormUploadedFileDto[];
  setUploadedFiles: (files: EmgFormUploadedFileDto[]) => void;
}

interface DownloadFileFromBlobResult {
  blobFileName: string;
  contentType: string;
  fileContentBase64: string;
}

interface EmgFormUploadedFileDto {
  id: number;
  fileCategoryRelationId: number;
  userGivenName: string;
}

export const OTPLoginContext = createContext<IOTPLoginContext>({
  step: 0,
  setStep: () => {},
  userDetails: {
    handicappedIsrId: '',
    formFillerIsrId: '',
    emailAddress: '',
    phone: '',
    password: '',
    isSms: true,
  },
  setUserDetails: () => {},
  isFormValidated: false,
  setIsFormValidation: () => {},
  errors: defaultErrors,
  setErrors: () => {},
  loginDetails: {
    accessToken: '',
    emergencyFormId: '',
  },
  setLoginDetails: () => {},
  uploadedFiles: [],
  setUploadedFiles: () => {},
});

type Props = { children: ReactNode };

export const OTPLoginContextProvider = ({ children }: Props) => {
  const [step, setStep] = useState<number>(0);
  const [isFormValidated, setIsFormValidation] = useState(false);
  const [userDetails, setUserDetails] = useState({
    handicappedIsrId: '',
    formFillerIsrId: '',
    emailAddress: '',
    phone: '',
    password: '',
    isSms: true,
  });
  const [loginDetails, setLoginDetails] = useState({
    accessToken: '',
    emergencyFormId: '',
  });
  const [uploadedFiles, setUploadedFiles] = useState<EmgFormUploadedFileDto[]>(
    []
  );

  const [errors, setErrors] = useState<IErrors>(defaultErrors);

  return (
    <OTPLoginContext.Provider
      value={{
        step,
        setStep,
        userDetails,
        setUserDetails,
        isFormValidated,
        setIsFormValidation,
        errors,
        setErrors,
        loginDetails,
        setLoginDetails,
        uploadedFiles,
        setUploadedFiles,
      }}
    >
      {children}
    </OTPLoginContext.Provider>
  );
};
