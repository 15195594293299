import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IGeneralContent,
  IBank,
  ICity,
  ICountry,
  IBranchObj,
  IForm,
} from 'API';
interface InitState {
  generalContent: IGeneralContent;
  banks: IBank[];
  branches: IBranchObj;
  cities: ICity[];
  countries: ICountry[];
  formInfo: IForm;
}

const initialState: InitState = {
  generalContent: {
    genders: [],
    caseTypes: [],
    hmOs: [],
    locationAddressCategories: [],
    maritalStatuses: [],
    militaryRanks: [],
    militaryTroops: [],
    ownershipVehicleTypes: [],
    phonePrefixes: [],
    serviceTypes: [],
    witnessTypes: [],
    requestReasonTypes: [],
    investigationDirectorOptions: [],
    reportAttachedOptions: [],
    areThereWitnessesOptions: [],
    employmentStatuses: [],
    representationOptions: [],
    injuryLevelOptions: [],
  },
  banks: [],
  branches: {},
  cities: [],
  countries: [],
  formInfo: {
    isRecognized: false,
    formId: undefined,
    isSubmitted: undefined,
    submittedDate: undefined,
    isPhoneBelongsToUser: true,
  },
};

export const GeneralContentSlice = createSlice({
  name: 'generalData',
  initialState,
  reducers: {
    setGeneralContent: (state, action: PayloadAction<IGeneralContent>) => {
      state.generalContent = action.payload;
    },
    setBanks: (state, action: PayloadAction<IBank[]>) => {
      state.banks = action.payload;
    },
    setBranches: (state, action: PayloadAction<IBranchObj>) => {
      state.branches = action.payload;
    },
    setCities: (state, action: PayloadAction<ICity[]>) => {
      state.cities = action.payload;
    },
    setCountries: (state, action: PayloadAction<ICountry[]>) => {
      state.countries = action.payload;
    },
    setFormInfo: (state, action: PayloadAction<IForm>) => {
      state.formInfo = action.payload;
    },
  },
});

export const {
  setBanks,
  setBranches,
  setCities,
  setCountries,
  setGeneralContent,
  setFormInfo,
} = GeneralContentSlice.actions;

export default GeneralContentSlice.reducer;
