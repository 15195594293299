import { Avatar, Divider, Grid, IconButton, Typography } from '@mui/material';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { TrashCan, WatchFile } from 'Images';

interface IFileActions {
  watchFile: () => void;
  deleteFile: () => void;
}

export const FileActions = (props: IFileActions) => {
  const dictionary = Dictionary[DICTIONARY.GENERAL];
  return (
    <>
      <Grid container xs={5} alignItems='center' onClick={props.watchFile}>
        <Grid item>
          <IconButton
            sx={{
              padding: '4px',
            }}
          >
            <Avatar
              src={WatchFile}
              alt={dictionary.watch}
              sx={{ width: '24px', height: '24px' }}
            />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography color='primary' noWrap sx={{ cursor: 'pointer' }}>
            {dictionary.watch}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent='center' item xs={2}>
        <Divider orientation='vertical' flexItem variant='middle' />
      </Grid>
      <Grid
        item
        container
        xs={5}
        alignItems='center'
        onClick={props.deleteFile}
      >
        <IconButton
          sx={{
            padding: '4px',
          }}
        >
          <Avatar
            src={TrashCan}
            sx={{ width: '24px', height: '24px' }}
            alt={dictionary.delete}
          />
        </IconButton>
        <Typography color='primary' sx={{ cursor: 'pointer' }}>
          {dictionary.delete}
        </Typography>
      </Grid>
    </>
  );
};
