import { AppBar, Divider, Grid, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';

export const Footer = () => {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  type footerItem = {
    title: string;
    href: string;
    target: '_self' | '_blank';
  };
  const footerItems: footerItem[] = [
    { title: 'חשוב לדעת', href: '/?tabIndex=1', target: '_self' },
    { title: 'על התהליך', href: '/?tabIndex=2', target: '_self' },
    {
      title: 'מדיניות הפרטיות',
      href: 'https://shikum.mod.gov.il/InformationService/Pages/privacy.aspx/',
      target: '_blank',
    },
    {
      title: 'מפת אתר',
      href: 'https://shikum.mod.gov.il/InformationService/Pages/Site_Map.aspx/',
      target: '_blank',
    },
    {
      title: 'הצהרת נגישות',
      href: 'https://shikum.mod.gov.il/InformationService/Pages/accessibility.aspx/',
      target: '_blank',
    },
  ];
  return (
    <AppBar
      component='footer'
      position='relative'
      color='secondary'
      sx={{
        top: 'auto',
        bottom: 0,
        minHeight: '72px',
        p: isDesktop ? '24px 0' : '14px 50px',
        overflowX: 'hidden',
        borderRadius: 0,
      }}
      role='navigation'
    >
      <Grid
        container
        gap={isDesktop ? 3 : 1}
        justifyContent='center'
        component={'ul'}
      >
        {footerItems.map((footerItem, footerItemIndex) => (
          <React.Fragment key={footerItem.title}>
            {footerItem.target === '_blank' ? (
              <li>
                <Link
                  href={footerItem.href}
                  target={footerItem.target}
                  color='common.white'
                  underline='none'
                  sx={{
                    width: 'auto',
                  }}
                >
                  {footerItem.title}
                </Link>
              </li>
            ) : (
              <li>
                <Link
                  component={RouterLink}
                  to={footerItem.href}
                  color='common.white'
                  underline='none'
                  sx={{
                    width: 'auto',
                  }}
                >
                  {footerItem.title}
                </Link>
              </li>
            )}
            {footerItemIndex !== footerItems.length - 1 ? (
              <Divider
                sx={{
                  borderWidth: '1px',
                  height: 'unset',
                  borderColor: (theme) => theme.palette.common.white,
                }}
                light
                orientation='vertical'
                aria-hidden='true'
              />
            ) : null}
          </React.Fragment>
        ))}
      </Grid>
    </AppBar>
  );
};
