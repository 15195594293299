import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import {
  IRequestAndEarlyAcquaintance,
  getRequestAndEarlyAcquaintance,
  putRequestAndEarlyAcquaintance,
} from 'API';
import {
  FormAutoSaveTypography,
  FormPaper,
  FormTitle,
  HomePageFormLink,
} from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { RoutesConfig } from 'Routes';
import { debounce, initFocus } from 'Utils';
import { FirstSection } from './FirstSection';
import { requestAndEarlyAcquaintanceSchema } from './Schema';
import { SecondSection } from './SecondSection';

type Inputs = IRequestAndEarlyAcquaintance;
export const RequestAndEarlyAcquaintance = () => {
  const methods = useForm<Inputs>({
    mode: 'onTouched',
    resolver: yupResolver(requestAndEarlyAcquaintanceSchema),
    defaultValues: {
      isRequestAllowanceWasSubmitted: undefined,
      ministryOfDefenceSection: {
        isThereRelativeInMinistryOfDefense: undefined,
        isMinistryOfDefenceEmployee: undefined,
        relativeDetail: undefined,
        workplace: undefined,
      },
      socialSecurityRequestSection: {
        requestReasonId: undefined,
        submittedRequestDate: undefined,
        isRequestProcessingEnded: undefined,
      },
    },
  });
  const forceSubmit = async () => {
    const values = methods.getValues();
    await putRequestAndEarlyAcquaintance(values);
  };
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await forceSubmit();
    navigate(RoutesConfig.STATUS_LOBBY.PATH);
  };

  // This flag is because when we use reset and it changes the inputs which causes to invoke 'putWaiverOfMedicalConfidentiality'
  // When it is true it means init hasn't finished therefor there is no need in calling 'putWaiverOfMedicalConfidentiality'
  const initFlag = useRef<boolean>(true);

  const initForm = async () => {
    initFlag.current = true;
    const requestAndEarlyAcquaintance = await getRequestAndEarlyAcquaintance();
    if (requestAndEarlyAcquaintance) {
      methods.reset({ ...requestAndEarlyAcquaintance });
      initFlag.current = false;
    }
  };
  useEffect(() => {
    initFocus();
    initForm();
  }, []);

  /* Creating a ref object that will be used to store the id of the timeout. */
  const id = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    const subscription = methods.watch(async (data) => {
      if (initFlag.current) return;
      if (!methods.formState.errors) return;
      debounce(id, putRequestAndEarlyAcquaintance, data);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [methods.watch]);

  return (
    <>
      <Helmet>
        <title>
          {Dictionary[DICTIONARY.HEADER].title +
            ' - ' +
            Dictionary[DICTIONARY.REQUEST_AND_EARLY_ACQUAINTANCE].title}
        </title>
      </Helmet>
      <HomePageFormLink save={forceSubmit} />
      <Stack alignItems='center' component={'main'} role={'main'}>
        <FormTitle
          title={Dictionary[DICTIONARY.REQUEST_AND_EARLY_ACQUAINTANCE].title}
        />
        <FormPaper>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <FirstSection />
              <SecondSection />
              <FormAutoSaveTypography save={forceSubmit} />
            </form>
          </FormProvider>
        </FormPaper>
      </Stack>
    </>
  );
};
