import { MutableRefObject } from 'react';
/**
 * debounce is a function that takes a function and a delay and returns a function that will call the
 * original function after the delay.
 * @param timerId - A MutableRefObject that will be used to store the timer ID.
 * @param fn - The function to be called.
 * @param {T} data - The data that will be passed to the function.
 * @param {number} [delay] - number
 */
export function debounce<T>(
  timerId: MutableRefObject<NodeJS.Timeout | undefined>,
  fn: (data: T) => Promise<any>,
  data: T,
  delay?: number
): void {
  if (timerId.current) clearTimeout(timerId.current);

  timerId.current = setTimeout(() => {
    fn(data);
  }, delay || 1000);
}
