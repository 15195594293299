import { Paper } from '@mui/material';
import { useIsDesktop } from 'Hooks';
import { FC } from 'react';
export const StepPaper: FC<{}> = (props) => {
  const isDesktop = useIsDesktop();
  return (
    <Paper
      sx={{
        boxShadow: '0px 3px 21px rgba(45, 112, 217, 0.15)',
        borderRadius: '16px',
        padding: isDesktop ? '24px' : '24px 16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {props.children}
    </Paper>
  );
};
