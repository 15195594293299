export enum DICTIONARY {
  STATUS_LOBBY,
  CASE_DETAILS,
  EMPLOYMENT_AND_BANK_DETAILS,
  PERSONAL_DETAILS,
  REPRESENTATION,
  REQUEST_AND_EARLY_ACQUAINTANCE,
  WAIVER_OF_MEDICAL_CONFIDENTIALITY,
  HEADER,
  GENERAL,
  VERIFY_DETAILS,
  SUCCESS_PAGE,
  STATUS_REQUEST_PAGE,
  LOBBY,
  CONTACT_US,
  NOT_FOUND,
  DOUBLE_DOC,
  AUTH_ISSUE,
  EMERGENCY_FORM,
  LOGIN,
}
