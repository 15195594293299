import { Stack} from '@mui/material';
import {useIsDesktop} from "Hooks";

interface IFormSection {
  disableGutter?: boolean;
  disablePadding?: boolean;
  id?: string;
  grouped?: boolean;
}

export const FormSection: React.FC<IFormSection> = (props) => {
  const { children, disableGutter, disablePadding = false } = props;

  const isDesktop = useIsDesktop();

  const setPadding = () => {
    const padding = '95px';
    const noPadding = '0';
    if (!isDesktop) return noPadding;

    if (disablePadding) return noPadding;
    else return padding;
  };

  return (
    <Stack
      id={props.grouped ? undefined : props.id}
      component='section'
      role={props.grouped ? 'group' : undefined}
      aria-labelledby={props.grouped ? props.id : undefined}
      gap={2}
      sx={{
        mb: disableGutter ? 0 : 5.25,
        padding: `0 ${setPadding()}}`,
      }}
    >
      {children}
    </Stack>
  );
};
