import { Grid, MenuItem, TextField } from '@mui/material';
import { ControllerSelect } from 'Components';
import { useAppSelector } from 'Hooks';
import { useFormContext } from 'react-hook-form';
import { generateError } from 'Regex';
import { textFieldShrink } from 'Utils';
import { validateNumbersOnly } from 'Utils/validateNumbersOnly';
import { Dictionary } from '../../Dictionary';
import { DICTIONARY } from '../../Enum';

interface IPhoneNumber {
  phoneNumberLabel: string;
  phoneNumberFormName: string;
  // TODO make phoneNumberError required if needed
  phoneNumberError?: boolean;
  phoneNumberErrorMsg?: string;
  phoneNumberPrefixFormName: string;
  disabled?: boolean;
}

export const PhoneNumber = (props: IPhoneNumber) => {
  const {
    phoneNumberLabel,
    phoneNumberFormName,
    phoneNumberPrefixFormName,
    phoneNumberError = false,
    phoneNumberErrorMsg = '',
    disabled,
  } = props;

  const phonePrefixes = useAppSelector(
    ({
      generalContent: {
        generalContent: { phonePrefixes },
      },
    }) => phonePrefixes
  );

  const { register, getValues } = useFormContext();

  return (
    <Grid
      container
      item
      columnSpacing={1.5}
      component='fieldset'
      sx={{ flexDirection: 'row-reverse' }}
    >
      <legend>{props.phoneNumberLabel}</legend>
      <Grid item xs={4}>
        <ControllerSelect
          // defaultValue='1'
          label={
            Dictionary[DICTIONARY.PERSONAL_DETAILS]
              .secondSectionFirstInputPrefix
          }
          name={phoneNumberPrefixFormName}
          disabled={disabled}
        >
          {phonePrefixes.map(({ id, value }) => (
            <MenuItem key={id} value={id}>
              {value}
            </MenuItem>
          ))}
        </ControllerSelect>
      </Grid>
      <Grid item xs={8}>
        <TextField
          type='tel'
          inputProps={{
            maxLength: '7',
          }}
          {...register(phoneNumberFormName)}
          label={phoneNumberLabel}
          {...textFieldShrink(getValues([phoneNumberFormName])[0])}
          error={phoneNumberError}
          helperText={
            phoneNumberError &&
            generateError(phoneNumberLabel!, phoneNumberErrorMsg)
          }
          role={Boolean(phoneNumberError) ? 'alert' : undefined}
          onKeyDown={(event) => validateNumbersOnly(event)}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};
