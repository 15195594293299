import { Stack } from '@mui/material';
import { FormPaper } from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { Helmet } from 'react-helmet-async';
import { FirstSection } from './FirstSection';

export const SuccessPage = () => {
  return (
    <>
      <Helmet>
          <title>
              {
                  Dictionary[DICTIONARY.HEADER].title +
                  ' - ' +
                  Dictionary[DICTIONARY.SUCCESS_PAGE].title
              }
          </title>
      </Helmet>
      <Stack
        alignItems={'center'}
        sx={{ mt: '100px' }}
        component={'main'}
        role={'main'}
      >
        <FormPaper>
          <FirstSection />
        </FormPaper>
      </Stack>
    </>
  );
};
