import { Avatar, Paper, Typography } from '@mui/material';
import { Vi } from 'Images';

interface IStatusRequestCard {
  isFinished?: boolean;
  title: string;
}
export const StatusRequestCard = (props: IStatusRequestCard) => {
  return (
    <Paper
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 4px 28px rgba(45, 112, 217, 0.15)',
        borderRadius: '24px',
        // background:
        //   'radial-gradient(100% 100% at 50% 0%, rgba(197, 246, 255, 0.79) 0%, rgba(255, 255, 255, 0.0001) 100%)',
        position: 'relative',
        width: '244px',
        height: '108px',
      }}
    >
      {props.isFinished && (
        <Avatar
          src={Vi}
          sx={{
            width: '20px',
            height: '20px',
            position: 'absolute',
            top: 0,
            right: '50%',
            transform: 'translate(50%, -50%)',
          }}
          alt=''
        />
      )}
      <Typography>{props.title}</Typography>
    </Paper>
  );
};
