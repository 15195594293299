import {
  Box,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ICaseDetailsInputs } from 'API';
import {
  ControllerDatePicker,
  ControllerSelect,
  CustomRadioGroup,
  FileUpload,
  FormSection,
  RadioButtons,
} from 'Components';
import { Dictionary } from 'Dictionary';
import { CASE_TYPE_RELATION_ID, DICTIONARY, FileCategories } from 'Enum';
import { useAppSelector, useDispatchApp } from 'Hooks';
import { useContext, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { generateError } from 'Regex';
import { IndexContext } from '../CaseContext';
import { Investigation } from '../Investigation';
import { textFieldShrink } from 'Utils';
import { setCaseType, setIsMentalIllnessCausedBySpecificEvent } from 'Store';
import { validateLettersNumbersAndSpacialChars } from 'Utils/validateLettersNumbersAndSpacialChars';
export const SecondSection = () => {
  const dictionary = Dictionary[DICTIONARY.CASE_DETAILS];

  const [caseTypes, reportAttachedOptions] = useAppSelector(
    ({
      generalContent: {
        generalContent: {
          caseTypes,
          reportAttachedOptions,
          investigationDirectorOptions,
        },
      },
    }) => [caseTypes, reportAttachedOptions, investigationDirectorOptions]
  );

  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext<ICaseDetailsInputs>();

  const {
    index,
    caseTypeName = '',
    diseaseId = CASE_TYPE_RELATION_ID.DISEASE_ID,
    fieldId,
  } = useContext(IndexContext);

  const diseaseKindErrors = errors.caseDetails
    ? errors.caseDetails[index]?.diseaseKindSection
    : undefined;

  const baseName = `caseDetails.${index}`;

  const dispatch = useDispatchApp();

  const isDiagnosisExistName = `${baseName}.diseaseKindSection.isDiagnosisExist`;
  const isRelativeWithSameDiseaseName = `${baseName}.diseaseKindSection.isRelativesWithSameDisease`;
  const isMentalIllnessCausedBySpecificEventName = `${baseName}.isMentalIllnessCausedBySpecificEvent`;
  const reportExistName = `${baseName}.injuryKindSection.reportAttachedSelectedOptionId`;

  const isRelativeWithSameDisease = useWatch({
    name: isRelativeWithSameDiseaseName,
    defaultValue: undefined,
  }) as boolean;

  const isDiagnosisExist = useWatch({
    name: isDiagnosisExistName,
    defaultValue: undefined,
  }) as boolean;

  const isMentalIllnessCausedBySpecificEvent = useWatch({
    name: isMentalIllnessCausedBySpecificEventName,
    defaultValue: undefined,
  }) as boolean;

  useEffect(() => {
    dispatch(
      setIsMentalIllnessCausedBySpecificEvent({
        id: index,
        type: isMentalIllnessCausedBySpecificEvent,
      })
    );
  }, [isMentalIllnessCausedBySpecificEvent]);

  const caseType = useWatch({
    name: caseTypeName,
    defaultValue: 0,
  }) as number;

  useEffect(() => {
    dispatch(setCaseType({ id: index, type: caseType }));
  }, [caseType]);

  const reportExist = useWatch({
    name: reportExistName,
    defaultValue: 0,
  }) as number;

  const releaseDate = useWatch({
    name: `${baseName}.militaryServiceSection.releaseDate`,
    defaultValue: undefined,
  });
  const injuryKindErrors = errors.caseDetails
    ? errors.caseDetails[index]?.injuryKindSection
    : undefined;

  const valuesInjuryKindSection = getValues(
    `caseDetails.${index}.injuryKindSection`
  );

  let isDisease = caseType == diseaseId;
  const isMental = caseType == CASE_TYPE_RELATION_ID.MENTAL_ID;

  if (isMental && !isMentalIllnessCausedBySpecificEvent) {
    isDisease = true;
  }

  const isRelativeWithSameDiseaseError = () => {
    if (isRelativeWithSameDisease) {
      if (diseaseKindErrors?.relativesWithSameDisease?.message) {
        return generateError(
          dictionary.secondSectionDiseaseDescriptionNoJustMe,
          diseaseKindErrors?.relativesWithSameDisease?.message!
        );
      }
    } else {
      if (diseaseKindErrors?.diseaseDetailsDescription?.message) {
        return generateError(
          dictionary.secondSectionDiseaseDescriptionNoJustMe,
          diseaseKindErrors?.diseaseDetailsDescription?.message!
        );
      }
    }
    return '';
  };

  return (
    <FormSection grouped id={dictionary.secondSectionTitle}>
      <span className={'sr-only'}>{dictionary.secondSectionTitle}</span>

      <Grid sx={{ mb: 2 }}>
        <CustomRadioGroup
          id={dictionary.secondSectionTitle}
          description={dictionary.secondSectionDescription}
          name={`${caseTypeName}` as const}
          options={caseTypes.map(({ id, value }) => ({
            value: id.toString(),
            label: value,
          }))}
          sx={{ mb: 2 }}
        />
        {isMental && (
          <Grid
            sx={{
              background: '#F2F9FF',
              borderRadius: 1,
              padding: 2,
              mb: 2,
              mt: 2,
            }}
          >
            <Typography
              color='#39546A'
              component={'p'}
              sx={{ fontWeight: 'bold', fontSize: '14px' }}
            >
              {Dictionary[DICTIONARY.CASE_DETAILS].secondSectionMentalBoxTitle}
            </Typography>
            <Typography
              color='#39546A'
              component={'p'}
              sx={{ fontSize: '14px' }}
            >
              {Dictionary[DICTIONARY.CASE_DETAILS].secondSectionMentalBoxInfo}
            </Typography>
          </Grid>
        )}
      </Grid>

      {isMental && (
        <RadioButtons
          toolTip={dictionary.tooltipMental}
          name={isMentalIllnessCausedBySpecificEventName}
          options={[
            {
              value: 'true',
              label:
                Dictionary[DICTIONARY.CASE_DETAILS].secondSectionMentalShort,
            },
            {
              value: 'false',
              label:
                Dictionary[DICTIONARY.CASE_DETAILS].secondSectionMentalLong,
            },
          ]}
          id={Dictionary[DICTIONARY.CASE_DETAILS].secondSectionMentalLabel}
        />
      )}
      {isDisease ? (
        <>
          <Box display='grid' gap={3} sx={{ mb: 2 }}>
            <RadioButtons
              name={isDiagnosisExistName}
              options={[
                {
                  value: 'true',
                  label:
                    Dictionary[DICTIONARY.CASE_DETAILS]
                      .secondSectionSecondToggleButtonRight,
                },
                {
                  value: 'false',
                  label:
                    Dictionary[DICTIONARY.CASE_DETAILS]
                      .secondSectionSecondToggleButtonLeft,
                },
              ]}
              id={
                Dictionary[DICTIONARY.CASE_DETAILS]
                  .secondSectionDiseaseIsDiagnosisExist
              }
            />
            {isDiagnosisExist && (
              <ControllerDatePicker
                name={`${baseName}.diseaseKindSection.diseaseDiagnosisDate`}
                label={
                  isMental
                    ? dictionary.secondSectionMentalDiseaseDiagnosisDate
                    : dictionary.secondSectionDiseaseDiagnosisDate
                }
              />
            )}
          </Box>
          <Typography
            sx={{ fontWeight: 'bold' }}
            id={Dictionary[DICTIONARY.CASE_DETAILS].diseaseSectionTitle}
          >
            {Dictionary[DICTIONARY.CASE_DETAILS].diseaseSectionTitle}
          </Typography>
          <Stack>
            <Typography sx={{ mt: 0 }}>
              {dictionary.fourthSectionInfo1}
            </Typography>
            <Typography sx={{ mt: 0 }}>
              {dictionary.fourthSectionInfo2}
            </Typography>
          </Stack>
          <TextField
            inputProps={{
              maxLength: '500',
            }}
            id={`${dictionary.diseaseSectionLabel1}-${index}`}
            error={Boolean(diseaseKindErrors?.diseaseDetailsDescription)}
            helperText={isRelativeWithSameDiseaseError()}
            label={
              isMental
                ? dictionary.diseaseMentalSectionLabel1
                : dictionary.diseaseSectionLabel1
            }
            {...register(
              `${baseName}.diseaseKindSection.diseaseDetailsDescription` as `caseDetails.0.diseaseKindSection.diseaseDetailsDescription`
            )}
            multiline
            rows={6}
            onChange={(event: any) =>
              validateLettersNumbersAndSpacialChars(event)
            }
          />
          <FileUpload
            caseDetailsId={fieldId}
            fileLabel={
              Dictionary[DICTIONARY.CASE_DETAILS]
                .fifthSectionSecondFileUploadText
            }
            fileCategoryId={
              FileCategories.AnotherDocumentFromServiceIndicatingInjury
            }
            ariaLabel={
              Dictionary[DICTIONARY.CASE_DETAILS]
                .fifthSectionSecondFileUploadLabel
            }
          />
          <TextField
            role={
              Boolean(diseaseKindErrors?.howServiceCauseYourHealthCondition)
                ? 'alert'
                : undefined
            }
            inputProps={{
              maxLength: '500',
            }}
            id={`${dictionary.secondSectionHowDidYourServiceCausedYourMedicalCondition}-${index}`}
            error={Boolean(
              diseaseKindErrors?.howServiceCauseYourHealthCondition
            )}
            helperText={
              Boolean(
                diseaseKindErrors?.howServiceCauseYourHealthCondition?.message
              ) &&
              generateError(
                dictionary.secondSectionHowDidYourServiceCausedYourMedicalCondition,
                diseaseKindErrors?.howServiceCauseYourHealthCondition?.message!
              )
            }
            label={
              isMental
                ? dictionary.secondSectionHowDidYourServiceCausedYourMentalMedicalCondition
                : dictionary.secondSectionHowDidYourServiceCausedYourMedicalCondition
            }
            {...register(
              `${baseName}.diseaseKindSection.howServiceCauseYourHealthCondition` as `caseDetails.0.diseaseKindSection.howServiceCauseYourHealthCondition`
            )}
            multiline
            rows={6}
            sx={{ mb: 4 }}
            onChange={(event: any) =>
              validateLettersNumbersAndSpacialChars(event)
            }
          />
        </>
      ) : (
        <>
          <ControllerDatePicker
            name={`${baseName}.injuryKindSection.date`}
            label={dictionary.fourthSectionFirstInput}
            maxDate={releaseDate ? new Date(releaseDate) : undefined}
          />
          <Stack>
            <Typography
              sx={{ fontWeight: 'bold', mt: 2, mb: '6px' }}
              id={dictionary.diseaseSectionTitle}
            >
              {dictionary.diseaseSectionTitle}
            </Typography>
            <Typography sx={{ mt: 0 }}>
              {dictionary.fourthSectionInfo1}
            </Typography>
            <Typography sx={{ mt: 0 }}>
              {dictionary.fourthSectionInfo2}
            </Typography>
          </Stack>
          <div style={{ position: 'relative' }}>
            <TextField
              role={
                Boolean(injuryKindErrors?.description) ? 'alert' : undefined
              }
              inputProps={{
                maxLength: '500',
              }}
              id={`${dictionary.fourthSectionSecondInput}-${index}`}
              error={Boolean(injuryKindErrors?.description)}
              helperText={
                Boolean(injuryKindErrors?.description?.message) &&
                generateError(
                  dictionary.fourthSectionSecondInput,
                  injuryKindErrors?.description?.message!
                )
              }
              {...register(
                `${baseName}.injuryKindSection.description` as `caseDetails.0.injuryKindSection.description`
              )}
              {...textFieldShrink(valuesInjuryKindSection?.description)}
              multiline
              rows={6}
              sx={{ width: '100%', mb: 2 }}
              label={dictionary.fourthSectionTitle}
              onChange={(event: any) =>
                validateLettersNumbersAndSpacialChars(event)
              }
            />
          </div>

          <FileUpload
            caseDetailsId={fieldId}
            fileLabel={
              Dictionary[DICTIONARY.CASE_DETAILS]
                .fifthSectionSecondFileUploadText
            }
            fileCategoryId={
              FileCategories.AnotherDocumentFromServiceIndicatingInjury
            }
            ariaLabel={
              Dictionary[DICTIONARY.CASE_DETAILS]
                .fifthSectionSecondFileUploadLabel
            }
          />
          <div style={{ position: 'relative', width: 'fit-content' }}>
            <Typography>{dictionary.secondSectionFirstInputLabel}</Typography>
          </div>
          <ControllerSelect
            name={
              `${baseName}.injuryKindSection.reportAttachedSelectedOptionId` as const
            }
            label={dictionary.secondSectionFirstInputPlaceholder}
          >
            {reportAttachedOptions.map(({ id, value }) => (
              <MenuItem key={id} value={id}>
                {value}
              </MenuItem>
            ))}
          </ControllerSelect>
        </>
      )}

      {reportExist == 1 && !isDisease && (
        <>
          <Typography variant='subtitle1' component={'p'}>
            {dictionary.secondSectionFirstFileUploadLabel}
          </Typography>
          <FileUpload
            caseDetailsId={fieldId}
            fileLabel={dictionary.secondSectionFirstFileUploadText}
            fileCategoryId={FileCategories.InjuryReportFiLe}
            ariaLabel={dictionary.secondSectionFirstFileUploadLabel}
          />
        </>
      )}
      {!isDisease && <Investigation />}
      {isDisease && (
        <>
          <Typography>
            {isMental
              ? dictionary.secondSectionIsThereAnyOtherFamilyMemberThatSuffersFromThatMentalDisease
              : dictionary.secondSectionIsThereAnyOtherFamilyMemberThatSuffersFromThatDisease}
          </Typography>
          <Grid sx={{ mb: 2 }}>
            <RadioButtons
              id={''}
              name={`${isRelativeWithSameDiseaseName}` as const}
              options={[
                {
                  label: dictionary.secondSectionThereIsAnotherPerson,
                  value: 'true',
                },
                {
                  label: dictionary.secondSectionNoItsOnlyMe,
                  value: 'false',
                },
              ]}
            />
          </Grid>

          {isRelativeWithSameDisease && (
            <TextField
              role={Boolean(isRelativeWithSameDisease) ? 'alert' : undefined}
              inputProps={{
                maxLength: '500',
              }}
              id={`${dictionary.secondSectionDiseaseDescriptionNoJustMe}-${index}`}
              error={Boolean(
                isRelativeWithSameDisease
                  ? diseaseKindErrors?.relativesWithSameDisease
                  : diseaseKindErrors?.diseaseDetailsDescription
              )}
              helperText={isRelativeWithSameDiseaseError()}
              label={
                isMental
                  ? dictionary.secondSectionMentalDiseaseDescriptionNoJustMe
                  : dictionary.secondSectionDiseaseDescriptionNoJustMe
              }
              {...register(
                isRelativeWithSameDisease
                  ? (`${baseName}.diseaseKindSection.relativesWithSameDisease` as `caseDetails.0.diseaseKindSection.relativesWithSameDisease`)
                  : (`${baseName}.diseaseKindSection.diseaseDetailsDescription` as `caseDetails.0.diseaseKindSection.diseaseDetailsDescription`)
              )}
              multiline
              rows={6}
              onChange={(event: any) =>
                validateLettersNumbersAndSpacialChars(event)
              }
            />
          )}
        </>
      )}
    </FormSection>
  );
};
