import { Typography } from '@mui/material';
import {
  AddPaperCard,
  CustomToggleGroup,
  FormSection,
  RadioButtons,
} from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { useContext } from 'react';
import { IndexContext, IndexProvider } from '../CaseContext';
import { MedicalFacilityCard } from '../MedicalFacilityCard';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import {
  deleteHospitalization,
  HospitalizationDetailsSection,
  hospitalizationDetailsSectionEmptyObject,
  ICaseDetails,
  ICaseDetailsInputs,
  postHospitalization,
} from 'API';
export const SeventhSection = () => {
  const { index } = useContext(IndexContext);

  const baseName = `caseDetails.${index}`;

  const wasMedicalInstitutionTreatmentName = `${baseName}.wasMedicalInstitutionTreatment`;

  const fieldArrayBaseName = `caseDetails.${index}.hospitalizationDetailsSection`;

  const { getValues } = useFormContext<ICaseDetailsInputs>();

  const { remove, append, fields } = useFieldArray<
    ICaseDetailsInputs,
    'caseDetails.0.hospitalizationDetailsSection',
    'rid'
  >({
    name: fieldArrayBaseName as 'caseDetails.0.hospitalizationDetailsSection',
    keyName: 'rid',
  });

  const wasMedicalInstitutionTreatment = useWatch({
    name: wasMedicalInstitutionTreatmentName,
    defaultValue: false,
  });

  const onAddHospitalizationHandler = async () => {
    const id = getValues(`caseDetails.${index}.id`);
    const hospitalizationDetailsSection = await postHospitalization(id);
    if (hospitalizationDetailsSection) {
      append({
        ...hospitalizationDetailsSectionEmptyObject,
        id: hospitalizationDetailsSection.id,
      });
    }
  };

  const deleteHospitalizationHandler = async (
    hospitalizationIndex: number,
    hospitalizationId: number
  ) => {
    const id = getValues(`caseDetails.${index}.id`);
    const deleteHospital = await deleteHospitalization(id, hospitalizationId);

    if (deleteHospital) {
      remove(hospitalizationIndex);
    }
  };

  return (
    <>
      <FormSection
        grouped
        id={Dictionary[DICTIONARY.CASE_DETAILS].seventhSectionTitle}
      >
        <Typography
          sx={{ fontWeight: 'bold' }}
          id={Dictionary[DICTIONARY.CASE_DETAILS].seventhSectionTitle}
        >
          {Dictionary[DICTIONARY.CASE_DETAILS].seventhSectionTitle}
        </Typography>
        <RadioButtons
          name={`${wasMedicalInstitutionTreatmentName}` as const}
          options={[
            {
              value: 'true',
              label:
                Dictionary[DICTIONARY.CASE_DETAILS]
                  .seventhSectionFirstToggleButtonRight,
            },
            {
              value: 'false',
              label:
                Dictionary[DICTIONARY.CASE_DETAILS]
                  .seventhSectionFirstToggleButtonLeft,
            },
          ]}
          id={
            Dictionary[DICTIONARY.CASE_DETAILS]
              .seventhSectionFirstToggleButtonLabel
          }
        />
      </FormSection>
      {wasMedicalInstitutionTreatment && (
        <FormSection disablePadding>
          {fields.map((field, index) => (
            <IndexProvider index={index} baseName={fieldArrayBaseName}>
              <MedicalFacilityCard
                key={field.id}
                hospitalizationId={field.id || 0}
                deleteFacility={() =>
                  deleteHospitalizationHandler(index, field.id || 0)
                }
              />
            </IndexProvider>
          ))}
          <AddPaperCard
            label={
              Dictionary[DICTIONARY.CASE_DETAILS].seventhSectionAddFacilityTitle
            }
            add={onAddHospitalizationHandler}
          />
        </FormSection>
      )}
    </>
  );
};
