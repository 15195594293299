import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import {
  Avatar,
  buttonClasses,
  createTheme,
  Palette,
  PaletteOptions,
  tabsClasses,
  inputLabelClasses,
} from '@mui/material';
import { PropsWithChildren } from 'react';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { alpha } from '@mui/material/styles';
import { SelectIcon } from 'Images';
import createPalette from '@mui/material/styles/createPalette';
import type {} from '@mui/lab/themeAugmentation';
import { buttonBaseClasses } from '@mui/material/ButtonBase';
export const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [rtlPlugin],
  prepend: true,
});
const defaultTheme = createTheme();

const typography:
  | TypographyOptions
  | ((palette: Palette) => TypographyOptions)
  | undefined = {
  fontFamily: "'Open Sans', sans-serif",
  h1: {
    fontStyle: 'normal',
    fontSize: '20px',
    lineHeight: '28px',
    [defaultTheme.breakpoints.up('sm')]: {
      fontSize: '28px',
      lineHeight: '40px',
    },
  },
  h2: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '24.5px',
    [defaultTheme.breakpoints.up('sm')]: {
      fontStyle: 'normal',
      fontSize: '20px',
      lineHeight: '32px',
    },
  },
  h3: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '16px',
    [defaultTheme.breakpoints.up('sm')]: {
      fontStyle: 'normal',
      fontSize: '18px',
      lineHeight: '32px',
    },
  },
  body1: {
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
  },
  subtitle1: {
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    [defaultTheme.breakpoints.up('sm')]: {
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
    },
  },
  subtitle2: {
    fontSize: '12px',
    lineHeight: '16px',
    [defaultTheme.breakpoints.up('sm')]: {
      fontStyle: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
};

const paletteOptions: PaletteOptions | undefined = {
  text: {
    primary: '#39556B',
    secondary: alpha('#39556B', 0.72),
  },
  primary: {
    main: '#1752AF',
  },
  secondary: {
    main: '#394B5A',
  },
  error: {
    main: '#d90000',
  },
  common: {
    white: '#fff',
  },
};

const palette = createPalette(paletteOptions);

const borderRadius = 8;

export const Theme = createTheme({
  direction: 'rtl',
  shape: {
    borderRadius: borderRadius,
  },
  breakpoints: {},
  palette: palette,
  components: {
    MuiPopover: {
      styleOverrides: {
        root: {
          maxHeight: '300px',
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: 'secondary',
        position: 'sticky',
      },
      styleOverrides: {
        root: {
          boxShadow: '0px 2px 17px rgba(57, 84, 106, 0.5)',
          minHeight: '92px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          [`& .${inputLabelClasses.root}`]: {
            color: '#60727e',
          },
          [`& .${inputLabelClasses.shrink}`]: {
            color: 'unset',
          },
          backgroundColor: palette.common.white,
        },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        orientation: 'portrait',
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          [`li&.${buttonBaseClasses.focusVisible}`]: {
            backgroundColor: '#F2F9FF',
          },
        },
      },
    },
    MuiMenuItem: {},
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          PaperProps: {
            sx: {
              borderRadius: '6px',
              boxShadow: '0px 3px 21px rgba(45, 112, 217, 0.15)',
            },
          },
        },
        IconComponent: () => (
          <Avatar
            sx={{
              userSelect: 'none',
              width: '1em',
              height: '1em',
              display: 'inline-block',
              fill: 'currentColor',
              flexShrink: '0',
              transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              fontSize: '1.5rem',
              position: 'absolute',
              right: '7px',
              top: 'calc(50% - .5em)',
              pointerEvents: 'none',
              color: 'rgba(0, 0, 0, 0.54)',
            }}
            variant='square'
            alt=''
            src={SelectIcon}
          />
        ),
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingRight: '22px',
          paddingLeft: '22px',
          paddingBottom: '16px',
          paddingTop: '16px',
          [defaultTheme.breakpoints.up('sm')]: {
            justifyContent: 'space-between',
            paddingRight: '90px',
            paddingLeft: '90px',
          },
        },
      },
    },
    MuiStack: {
      defaultProps: {
        width: '100%',
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          borderRadius: '32px',
          minWidth: '100px',
          minHeight: '30px',
          fontSize: '14px',
          [`&.${buttonClasses.outlined}`]: {
            backgroundColor: palette.common.white,
          },
          [defaultTheme.breakpoints.up('sm')]: {
            minWidth: '130px',
            minHeight: '40px',
            padding: '8px 24px',
          },
          '&.Mui-disabled': {
            backgroundColor: '#1752AF80',
            color: palette?.common?.white,
          },
        },
        contained: {
          fontWeight: 'bold',
        },
      },
    },
    MuiAvatar: {
      defaultProps: {
        variant: 'square',
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: '#EBEBEB;',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {},
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '24px',
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          position: 'relative',
          backgroundColor: palette.common.white,
        },
      },
    },
    MuiToggleButton: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: {
          width: '-webkit-fill-available',
          minHeight: '48px',
          color: palette.primary.main,
          '&.Mui-selected:hover': {
            color: palette.common?.white,
            backgroundColor: palette.primary.main,
          },
          '&.Mui-selected': {
            color: palette.common?.white,
            backgroundColor: palette.primary.main,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          [`& .${tabsClasses.flexContainer}`]: {
            justifyContent: 'center',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          flexGrow: 1,
          ['&.Mui-selected']: {
            fontWeight: 'bold',
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: 'top',
      },
    },
  },
  typography: typography,
});

export function RTL(props: PropsWithChildren<{}>) {
  return <CacheProvider value={cacheRtl}>{props.children}</CacheProvider>;
}
