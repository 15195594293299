import { Stack } from '@mui/material';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { FirstSection } from './FirstSection';
import { SecondSection } from './SecondSection';
import {
  FormAutoSaveTypography,
  FormPaper,
  FormTitle,
  HomePageFormLink,
} from 'Components';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useRef } from 'react';
import {
  getBanks,
  getBranches,
  getEmploymentAndBankDetails,
  IEmploymentAndBankDetails,
  putEmploymentAndBankDetails,
} from 'API';
import { debounce, files, initFocus } from 'Utils';
import { useDispatchApp, useIsDesktop } from 'Hooks';
import { setBanks, setBranches, store } from 'Store';
import { yupResolver } from '@hookform/resolvers/yup';
import { employmentAndBankDetailSchema } from './Schema';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { RoutesConfig } from 'Routes';
import { InfoTextNew } from 'Components/InfoTextNew';
type Inputs = IEmploymentAndBankDetails;

export const EmploymentAndBankDetails = () => {
  const methods = useForm<Inputs>({
    mode: 'onTouched',
    resolver: yupResolver(employmentAndBankDetailSchema),
    defaultValues: {
      bankAccountDetailsSection: {
        accountNumber: undefined,
        bankRelationId: undefined,
        branchRelationId: undefined,
      },
      workPlaceDetailsSection: {
        jobTitle: undefined,
        workAddress: undefined,
        workName: undefined,
        landlinePhoneNum: undefined,
        landlinePrefixRelationId: undefined,
        managerPhoneNum: undefined,
        managerPhonePrefixRelationId: undefined,
        managerName: undefined,
        employmentStatusId: undefined,
      },
    },
  });

  const dispatch = useDispatchApp();

  const navigate = useNavigate();
  const isDesktop = useIsDesktop();
  const formInfo = store.getState().generalContent.formInfo;
  const dictionary = Dictionary[DICTIONARY.EMPLOYMENT_AND_BANK_DETAILS];
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await forceSubmit();
    const result = await files();
    if (result) {
      navigate(RoutesConfig.STATUS_LOBBY.PATH);
    }
  };

  const initFlag = useRef<boolean>(true);

  const initForm = async () => {
    initFlag.current = true;
    const [banks, branches, employmentAndBankDetails] = await Promise.all([
      getBanks(),
      getBranches(),
      getEmploymentAndBankDetails(),
    ]);
    if (employmentAndBankDetails && banks && branches) {
      dispatch(setBanks(banks));
      dispatch(setBranches(branches));
      methods.reset({
        bankAccountDetailsSection:
          employmentAndBankDetails.bankAccountDetailsSection,
        workPlaceDetailsSection:
          employmentAndBankDetails.workPlaceDetailsSection,
      });
      initFlag.current = false;
    }
  };

  useEffect(() => {
    initFocus();
    initForm();
  }, [methods.reset]);
  /* Creating a ref object that will be used to store the id of the timeout. */
  const id = useRef<NodeJS.Timeout | undefined>(undefined);
  useEffect(() => {
    const subscription = methods.watch(async (data) => {
      if (initFlag.current) return;
      if (!methods.formState.errors) return;
      debounce(id, putEmploymentAndBankDetails, {
        ...data,

        bankAccountDetailsSection: {
          ...data.bankAccountDetailsSection,
          bankRelationId: data.bankAccountDetailsSection?.bankRelationId
            ? data.bankAccountDetailsSection?.bankRelationId
            : undefined,
          branchRelationId: data.bankAccountDetailsSection?.branchRelationId
            ? data.bankAccountDetailsSection?.branchRelationId
            : undefined,
        },
        workPlaceDetailsSection: {
          ...data.workPlaceDetailsSection,
          landlinePrefixRelationId: data.workPlaceDetailsSection
            ?.landlinePrefixRelationId
            ? data.workPlaceDetailsSection?.landlinePrefixRelationId
            : undefined,
          managerPhonePrefixRelationId: data.workPlaceDetailsSection
            ?.managerPhonePrefixRelationId
            ? data.workPlaceDetailsSection?.managerPhonePrefixRelationId
            : undefined,
        },
      });
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [methods.watch]);
  const forceSubmit = async () => {
    const values = methods.getValues();
    await putEmploymentAndBankDetails(values);
  };
  return (
    <>
      <Helmet>
        <title>
          {Dictionary[DICTIONARY.HEADER].title + ' - ' + dictionary.title}
        </title>
      </Helmet>
      <HomePageFormLink save={forceSubmit} />
      <Stack alignItems='center' component={'main'} role={'main'}>
        <FormTitle title={dictionary.title} />
        {formInfo.isRecognized && (
          <Stack
            alignItems='center'
            sx={{
              mb: isDesktop ? '28px' : '16px',
              maxWidth: '720px',
              textAlign: 'center',
            }}
          >
            <InfoTextNew
              text={dictionary.notRecognized}
              urlLabel={dictionary.notRecognizedLink}
              url={`${process.env.REACT_APP_PERSONAL_AREA_URL}/requests/update-personal-details/8/19`}
            />
          </Stack>
        )}
        <FormPaper>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <FirstSection />
              <SecondSection />
              <FormAutoSaveTypography save={forceSubmit} />
            </form>
          </FormProvider>
        </FormPaper>
      </Stack>
    </>
  );
};
