import { Avatar, Link, Stack, Typography, useTheme } from '@mui/material';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { useDictionary, useIsDesktop } from 'Hooks';
import { Validation } from './Validation';
import { CheckMark } from 'Images';
import React, { useState } from 'react';
import { accessibilityOnKeyDown } from 'Utils/accessibilityOnKeyDown';
import { ArrowButton } from '../../ArrowButton';

interface ICodeValidation {
  isValidated: boolean | null;
  isBlocked: boolean;
  setIsValidated: Function;
  validationHandler: Function;
  setValidation: Function;
  sendCodeAgain: Function;
  setIsBlocked: Function;
  isWrongCode: boolean;
  setIsWrongCode: Function;
}

const showSentAgainMsgTime = 5000;

export const CodeValidation = ({
  isValidated,
  isBlocked,
  setIsValidated,
  validationHandler,
  setValidation,
  sendCodeAgain,
  setIsBlocked,
  isWrongCode,
  setIsWrongCode,
}: ICodeValidation) => {
  const isDesktop = useIsDesktop();
  const dictionary = useDictionary(
    Dictionary,
    DICTIONARY.LOGIN
  ).phoneEmailValidation;
  const [sentCodeAgainMsg, setSentCodeAgainMsg] = useState<string | null>(null);
  const [isCodeSentAgain, setIsCodeSentAgain] = useState<boolean>(false);
  const sendCodeAgainHandler = () => {
    setIsCodeSentAgain(true);
    setSentCodeAgainMsg(dictionary.codeSentAgain);
    setIsValidated(null);
    setIsBlocked(false);
    setIsWrongCode(false);
    sendCodeAgain();
    setTimeout(() => {
      setIsCodeSentAgain(false);
      setSentCodeAgainMsg(null);
    }, showSentAgainMsgTime);
  };

  return (
    <>
      <Stack gap={'10px'} marginBottom={'10px'} alignItems={'center'}>
        <Typography variant={'h2'} fontWeight={'700'}>
          {dictionary.whatCode}
        </Typography>
        <Typography>{dictionary.codeTimer}</Typography>
      </Stack>

      {isValidated && !isWrongCode && (
        <Avatar
          alt=''
          src={CheckMark}
          sx={{ width: '120px', height: '100px' }}
        />
      )}
      <Validation
        isValidated={isValidated}
        setIsValidated={setIsValidated}
        validationHandler={validationHandler}
        isCodeSentAgain={isCodeSentAgain}
        isBlocked={isBlocked}
        isWrongCode={isWrongCode}
        setIsWrongCode={setIsWrongCode}
      />
      <Stack gap={isDesktop ? '64px' : '56px'} alignItems={'center'}>
        <Stack gap={'10px'} alignItems={'center'}>
          <Typography aria-live='assertive' fontSize={'18px'}>
            {dictionary.didntGetCode}{' '}
            <Link
              underline={'always'}
              onClick={() => sendCodeAgainHandler()}
              onKeyDown={(e: React.KeyboardEvent) => accessibilityOnKeyDown(e)}
              tabIndex={0}
            >
              {dictionary.sendAgain}
            </Link>
          </Typography>
        </Stack>
        {sentCodeAgainMsg && (
          <Typography
            sx={{
              mt: '-40px',
              mb: '24px',
              textAlign: 'center',
              fontSize: '20px',
              fontWeight: 600,
              fontFamily: 'inherit',
              letterSpacing: '1px',
              color: '#1552E8',
            }}
            role='alert'
            aria-live='assertive'
          >
            {sentCodeAgainMsg}
          </Typography>
        )}
        <Stack
          maxWidth={isDesktop ? '222px' : '208px'}
          height={isDesktop ? '56px' : '44px'}
        >
          <ArrowButton
            text={dictionary.backwards}
            arrowColor={'#1752AF'}
            arrowPosition={'right'}
            onClick={() => setValidation(false)}
          />
        </Stack>
      </Stack>
    </>
  );
};
