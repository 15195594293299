import { Stack } from '@mui/material';
import { FormPaper, FormTitle } from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { FirstSection } from './FirstSection';
export const VerifyDetails = () => {
  return (
    <Stack alignItems='center' component={'main'} role={'main'}>
      <FormTitle title={Dictionary[DICTIONARY.VERIFY_DETAILS].title} />
      <FormPaper>
        <FirstSection />
      </FormPaper>
    </Stack>
  );
};
