import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import { ICaseDetailsInputs } from 'API';
import { PhoneNumber, PaperCard, ControllerSelect } from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { useAppSelector, useIsDesktop } from 'Hooks';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { generateError } from 'Regex';
import { validateLettersAndNumbersOnly } from 'Utils/validateLettersAndNumbersOnly';
import { IndexContext } from '../CaseContext';
import { SummaryCollapseBox } from './SummaryCollapseBox';

interface IWitnessCard {
  deleteWitness: () => void;
}
export const WitnessCard = (props: IWitnessCard) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<ICaseDetailsInputs>();

  const witnessesType = useAppSelector(
    ({
      generalContent: {
        generalContent: { witnessTypes },
      },
    }) => witnessTypes
  );

  const { index, baseName = '' } = useContext(IndexContext);

  const isDesktop = useIsDesktop();

  const witnessErrorsArray = (() => {
    const caseDetailsIndex = (baseName.split('.')[1] as unknown as number) || 0;
    if (
      errors.caseDetails &&
      errors.caseDetails[caseDetailsIndex] &&
      errors.caseDetails[caseDetailsIndex].witnesses
    ) {
      return errors.caseDetails[caseDetailsIndex].witnesses;
    } else {
      return undefined;
    }
  })();

  const witnessError =
    witnessErrorsArray !== undefined ? witnessErrorsArray[index] : undefined;

  const witnessBaseName = `${baseName}.${index}`;

  const dictionary = Dictionary[DICTIONARY.CASE_DETAILS];

  return (
    <PaperCard
      remove={props.deleteWitness}
      text={<SummaryCollapseBox />}
      isOpen={true}
    >
      <Grid
        container
        gap={4}
        sx={{
          '& .MuiFormHelperText-root': { backgroundColor: '#F8F8F8', m: 0 },
        }}
      >
        <Typography
          color='primary'
          sx={{
            fontWeight: 'bold',
            width: '100%',
            paddingLeft: isDesktop ? '0' : '40px',
            pt: isDesktop ? '0' : '8px',
          }}
        >
          {dictionary.sixthSectionWitnessTitle}
        </Typography>
        <TextField
          role={Boolean(witnessError?.firstName) ? 'alert' : undefined}
          inputProps={{
            maxLength: '20',
          }}
          id={`${dictionary.sixthSectionWitnessFirstInput}-${index}`}
          error={Boolean(witnessError?.firstName)}
          helperText={
            Boolean(witnessError?.firstName?.message) &&
            generateError(
              dictionary.sixthSectionWitnessFirstInput,
              witnessError?.firstName?.message!
            )
          }
          {...register(
            `${witnessBaseName}.firstName` as `caseDetails.0.witnesses.0.firstName`
          )}
          label={dictionary.sixthSectionWitnessFirstInput}
          onChange={(event: any) => validateLettersAndNumbersOnly(event)}
        />
        <TextField
          role={Boolean(witnessError?.lastName) ? 'alert' : undefined}
          inputProps={{
            maxLength: '30',
          }}
          id={`${dictionary.sixthSectionWitnessSecondInput}-${index}`}
          error={Boolean(witnessError?.lastName)}
          helperText={
            Boolean(witnessError?.lastName?.message) &&
            generateError(
              dictionary.sixthSectionWitnessSecondInput,
              witnessError?.lastName?.message!
            )
          }
          {...register(
            `${witnessBaseName}.lastName` as `caseDetails.0.witnesses.0.lastName`
          )}
          fullWidth
          label={dictionary.sixthSectionWitnessSecondInput}
          onChange={(event: any) => validateLettersAndNumbersOnly(event)}
        />
        <ControllerSelect
          name={`${witnessBaseName}.typeId` as const}
          label={dictionary.sixthSectionWitnessThirdInput}
        >
          {witnessesType.map(({ value, id }) => (
            <MenuItem key={id} value={id}>
              {value}
            </MenuItem>
          ))}
        </ControllerSelect>
        <PhoneNumber
          phoneNumberError={Boolean(witnessError?.phone)}
          phoneNumberFormName={`${witnessBaseName}.phone` as const}
          phoneNumberPrefixFormName={`${witnessBaseName}.phonePrefixRelationId`}
          phoneNumberLabel={dictionary.sixthSectionWitnessFourthInput}
          phoneNumberErrorMsg={witnessError?.phone?.message}
        />
        <TextField
          role={Boolean(witnessError?.role) ? 'alert' : undefined}
          inputProps={{
            maxLength: '50',
          }}
          id={`${dictionary.sixthSectionWitnessFifthInput}-${index}`}
          error={Boolean(witnessError?.role)}
          helperText={
            Boolean(witnessError?.role?.message) &&
            generateError(
              dictionary.sixthSectionWitnessFifthInput,
              witnessError?.role?.message!
            )
          }
          {...register(
            `${witnessBaseName}.role` as `caseDetails.0.witnesses.0.role`
          )}
          label={dictionary.sixthSectionWitnessFifthInput}
          onChange={(event: any) => validateLettersAndNumbersOnly(event)}
        />
        <TextField
          role={Boolean(witnessError?.civilAddress) ? 'alert' : undefined}
          inputProps={{
            maxLength: '100',
          }}
          id={`${dictionary.sixthSectionWitnessCivilAddress}-${index}`}
          error={Boolean(witnessError?.civilAddress)}
          helperText={
            Boolean(witnessError?.civilAddress?.message) &&
            generateError(
              dictionary.sixthSectionWitnessCivilAddress,
              witnessError?.civilAddress?.message!
            )
          }
          {...register(
            `${witnessBaseName}.civilAddress` as `caseDetails.0.witnesses.0.civilAddress`
          )}
          label={dictionary.sixthSectionWitnessCivilAddress}
          onChange={(event: any) => validateLettersAndNumbersOnly(event)}
        />
      </Grid>
    </PaperCard>
  );
};
