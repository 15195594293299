import { api } from 'API';

const baseUrl = '/Representation';
export interface LawyerSection {
  address?: string | null;
  firstName?: string | null;
  lastName?: string | null;
}

export interface GuardianSection {
  idNumber?: string | null;
  address?: string | null;
  phonePrefixRelationId?: string | null;
  phone?: string | null;
  mail?: string | null;
  firstName?: string | null;
  lastName?: string | null;
}

export interface AssociationSection {
  associationName ?: string | null;
  representativeName ?: string | null;
  address ?: string | null;
  mobilePhonePrefixRelationId  ?: string | null;
  mobilePhone  ?: string | null;
  phonePrefixRelationId?: string | null;
  phone?: string | null;
  mail?: string | null;
}

export interface IRepresentation {
  isAssistedWithGoodHandsCenter?: boolean | null;
  representationOptionRelationId ?: string | null;
  lawyerSection?: LawyerSection | null;
  isRepresentedByGuardian?: boolean | null;
  guardianSection?: GuardianSection | null;
  associationSection?: AssociationSection | null;
}

export const getRepresentation = async () => {
  const representation = await api.get<IRepresentation>(baseUrl);
  if (representation.data) {
    return representation.data;
  }
};

export const putRepresentation = async (data: Partial<IRepresentation>) => {
  const representation = await api.put(baseUrl, data);
  if (representation.status === 200) {
    return representation.data;
  }
};
