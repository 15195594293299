import { TextField } from '@mui/material';
import { useController } from 'react-hook-form';
import { generateError } from 'Regex';

interface IControllerSelect {
  name: string;
  label?: string;
  defaultValue?: string;
  disabled?: boolean;
}

export const ControllerSelect: React.FC<IControllerSelect> = (props) => {
  const { name, label, children, defaultValue = null, disabled } = props;

  const {
    fieldState: { error },
    field: { value, ref, ...restField },
  } = useController({
    name,
    defaultValue,
  });

  return (
    <TextField
      error={Boolean(error)}
      select
      value={value ? value : ''}
      {...restField}
      inputRef={ref}
      label={label}
      fullWidth
      defaultValue={defaultValue}
      helperText={error?.message && generateError(label!, error?.message)}
      role={Boolean(error) ? 'alert' : undefined}
      disabled={disabled}
    >
      {children}
    </TextField>
  );
};
