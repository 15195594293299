import { OTPLoginContextProvider } from 'Context/OTPLoginContext';
import { EmergencyFormWrap } from './EmergencyFormWrap';

export const EmergencyForm = () => {
  return (
    <OTPLoginContextProvider>
      <EmergencyFormWrap />
    </OTPLoginContextProvider>
  );
};
