import {
  Avatar,
  Box,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { ContactUsHeader } from 'Images';

interface IModal {
  isVisible: boolean;
  closeModal: () => void;
}

export const ContactUs = (props: IModal) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Modal open={props.isVisible}>
      <Paper
        sx={
          isDesktop
            ? {
                '&:focus-visible': { outline: 'none' },
                boxShadow: '0px 3px 21px 0px #2D70D926',
                padding: isDesktop
                  ? '96px 100px 56px 100px'
                  : '38px 16px 48px 16px',
                maxWidth: '750px',
                mb: '64px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                width: '80%',
                maxHeight: '80vh',
                overflow: 'visible',
              }
            : {
                boxShadow: '0px 3px 21px 0px #2D70D926',
                padding: isDesktop
                  ? '96px 100px 56px 100px'
                  : '38px 16px 48px 16px',
                maxWidth: '750px',
                mb: '64px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                width: '80%',
                maxHeight: '80vh',
                overflow: 'auto',
              }
        }
      >
        <Box sx={{ height: '60vh', overflowY: 'auto' }}>
          <span
            style={{
              position: 'absolute',
              top: '20px',
              left: '20px',
              fontSize: '25px',
              cursor: 'pointer',
            }}
            onClick={props.closeModal}
          >
            X
          </span>
          {isDesktop && (
            <Avatar
              src={ContactUsHeader}
              sx={{
                width: '132px',
                height: '132px',
                position: 'absolute',
                top: '-50px',
                left: '0',
                right: '0',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              alt=''
            />
          )}

          <Typography variant={'h2'} sx={{ fontWeight: 'bold', mb: '15px' }}>
            {Dictionary[DICTIONARY.CONTACT_US].title}
          </Typography>

          <Typography
            sx={
              isDesktop
                ? { fontSize: '18px', mb: '30px' }
                : { fontSize: '16px', padding: '0 15px' }
            }
          >
            {Dictionary[DICTIONARY.CONTACT_US].firstSectionText}
          </Typography>

          <Typography sx={{ mt: '10px', mb: '5px', fontWeight: 'bold' }}>
            {Dictionary[DICTIONARY.CONTACT_US].itemGoodHandsTitle}
          </Typography>
          <Typography
            sx={
              isDesktop
                ? { mt: '5px', mb: '30px', padding: '0 80px' }
                : { mt: '5px', mb: '30px' }
            }
          >
            {Dictionary[DICTIONARY.CONTACT_US].itemGoodHands}
          </Typography>

          <Typography sx={{ mt: '10px', mb: '5px', fontWeight: 'bold' }}>
            {Dictionary[DICTIONARY.CONTACT_US].itemShikumTitle}{' '}
            {Dictionary[DICTIONARY.CONTACT_US].itemShikumPhoneNumber}{' '}
            {Dictionary[DICTIONARY.CONTACT_US].itemShikumHelper}
          </Typography>
          <Typography sx={{ mt: '5px', mb: '10px' }}>
            {Dictionary[DICTIONARY.CONTACT_US].itemShikum}
          </Typography>

          <Typography sx={{ mt: '30px', mb: '5px', fontWeight: 'bold' }}>
            {Dictionary[DICTIONARY.LOBBY].emotionalSupportTitle}{' '}
            {Dictionary[DICTIONARY.LOBBY].emotionalSupportPhoneNumber}
          </Typography>
          <Typography sx={{ mt: '5px', mb: '10px' }}>
            {Dictionary[DICTIONARY.LOBBY].emotionalSupportFirstParagraph}
          </Typography>

          <Typography sx={{ mt: '30px', mb: '5px', fontWeight: 'bold' }}>
            {Dictionary[DICTIONARY.CONTACT_US].secondSectionText}
          </Typography>

          <Typography sx={{ mt: '5px', mb: '10px' }}>
            {Dictionary[DICTIONARY.CONTACT_US].itemIDF}
          </Typography>

          <Typography sx={{ mt: '10px', mb: '10px' }}>
            {Dictionary[DICTIONARY.CONTACT_US].itemPolice}
          </Typography>

          <Typography sx={{ mt: '10px', mb: '10px' }}>
            {Dictionary[DICTIONARY.CONTACT_US].itemShabas}
          </Typography>
        </Box>
      </Paper>
    </Modal>
  );
};
