import { Avatar, Button, Link, Stack, Typography } from '@mui/material';
import { getForm, newFormForExistingUser } from 'API';
import { FormSection } from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { Vi } from 'Images';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useIsDesktop } from 'Hooks';
import { useNavigate } from 'react-router-dom';

interface IUser {
  id: string;
  email: string;
  formId: number;
  formSubmissionDate: string;
  canCreateNewForm: boolean;
}

export const FirstSection = () => {
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();
  const dictionary = Dictionary[DICTIONARY.SUCCESS_PAGE];
  const [userData, setUserData] = useState<IUser>({
    email: '',
    formId: 0,
    formSubmissionDate: new Date().toISOString(),
    id: '',
    canCreateNewForm: false,
  });

  const dateFormat = (date: Date) => {
    if (!date) return null;
    if (date.toString() !== 'Invalid date') return format(date, 'dd/MM/yy');
  };

  const init = async () => {
    const form = await getForm();
    if (form) {
      setUserData({
        formId: form.formId || 0,
        formSubmissionDate: form.submittedDate || new Date().toISOString(),
        email: '',
        id: '',
        canCreateNewForm: form.canCreateNewForm || false,
      });
    }
  };
  useEffect(() => {
    init();
  }, []);

  const fillAdditionalForm = async () => {
    const formResult = await newFormForExistingUser();
    if (formResult) {
      navigate('/form');
    }
  };

  return (
    <FormSection>
      <Stack
        alignItems='center'
        sx={{
          fontWeight: 'bold',
          mt: isDesktop ? '30px' : '40px',
          mb: '28px',
        }}
      >
        <Typography
          variant='h1'
          sx={{
            padding: isDesktop ? '0px' : '0px 50px 0 50px',
            textAlign: 'center',
            mb: '10px',
            fontWeight: 'bold',
          }}
        >
          {dictionary.title}
        </Typography>
      </Stack>
      <Avatar
        alt=''
        src={Vi}
        sx={{
          position: 'absolute',
          top: '-50px',
          right: '40%',
          height: isDesktop ? '120px' : '88px',
          width: isDesktop ? '120px' : '88px',
          border: '5px solid white',
          borderRadius: '50%',
        }}
      />
      <Typography textAlign='center' component={'h3'} sx={{ fontSize: '24px' }}>
        {dictionary.formNumber}
        <span>{userData?.formId}</span>
      </Typography>
      <Typography textAlign='center' component={'h3'} sx={{ fontSize: '24px' }}>
        {dictionary.formSubmissionDate}
        <span>
          {dateFormat(new Date(userData.formSubmissionDate) || new Date())}
        </span>
      </Typography>
      <Typography
        textAlign='center'
        sx={{ fontWeight: 'bold', mt: '40px' }}
        variant='h5'
      >
        {dictionary.secondaryTitle}
      </Typography>
      <Typography sx={{ mb: 1, textAlign: 'center' }}>
        {dictionary.firstParagraph}
      </Typography>
      <Typography sx={{ mb: 1, textAlign: 'center' }}>
        {dictionary.secondParagraph}
        <Link href={dictionary.secondParagraphLink} target='_blank'>
          {dictionary.secondParagraphTitle}
        </Link>
      </Typography>
      <Typography sx={{ mb: 1, textAlign: 'center' }}>
        {dictionary.thirdParagraph}
        <Link href={dictionary.thirdParagraphLink} target='_blank'>
          {dictionary.thirdParagraphTitle}
        </Link>
      </Typography>

      {userData.canCreateNewForm && (
        <Stack sx={{ alignItems: 'center', gap: 2, mt: 3 }}>
          <Typography>{dictionary.additionalRequest}</Typography>
          <Button
            variant='contained'
            sx={{ width: '213px' }}
            onClick={() => fillAdditionalForm()}
          >
            {dictionary.additionalRequestLabel}
          </Button>
        </Stack>
      )}
    </FormSection>
  );
};
