import {
  Autocomplete,
  AutocompleteInputChangeReason,
  Paper,
  TextField,
} from '@mui/material';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { FC, useEffect, useRef, useState } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { generateError } from 'Regex';
export interface Option {
  id: number;
  value: string;
}
interface IControllerAutoComplete extends UseControllerProps {
  label?: string;
  options: Option[];
  disabled?: boolean;
}

export const MyPaper: FC<{}> = (props) => {
  return <Paper sx={{ borderRadius: `0 0 6px 6px` }}>{props.children} </Paper>;
};

export const ControllerAutoComplete = (props: IControllerAutoComplete) => {
  const { label, options, disabled, ...controller } = props;
  const {
    fieldState: { error },
    field: { onChange, onBlur, ref, ...field },
  } = useController(controller);

  const [input, setInput] = useState<string>('');

  const inputHandler = (
    e: React.SyntheticEvent<Element, Event>,
    v: string,
    r: AutocompleteInputChangeReason
  ) => {
    switch (r) {
      case 'clear':
        break;
      case 'reset':
        break;
      case 'input':
        setInput(v);
        break;
      default:
        break;
    }
  };

  const reset = useRef<string>('');

  const findOptionLabel = (id: number) => {
    const option = options.find((option) => option.id == id);
    if (option) {
      setInput(option.value);
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (field.value && reset.current !== 'DONE' && options.length) {
      const option = findOptionLabel(field.value);
      if (option) {
        reset.current = 'DONE';
      }
    }
  }, [field.value, options]);

  return (
    <Autocomplete
      {...field}
      id={label}
      onChange={(e, v, r) => {
        if (v) {
          setInput(v?.value);
          onChange(v ? v.id : v);
        }
      }}
      PaperComponent={MyPaper}
      noOptionsText={Dictionary[DICTIONARY.GENERAL].noOptions}
      options={options}
      inputValue={input}
      onBlur={(e) => {
        if (field.value) {
          findOptionLabel(field.value);
        } else {
          setInput('');
        }
        onBlur();
      }}
      onInputChange={inputHandler}
      disableClearable
      getOptionLabel={(option: Option) => option.value || ''}
      renderInput={(params) => (
        <TextField
          role={Boolean(error) ? 'alert' : undefined}
          inputRef={ref}
          label={label}
          error={Boolean(error)}
          helperText={error?.message && generateError(label!, error.message)}
          {...params}
        />
      )}
      disabled={disabled}
    />
  );
};
