import { Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { useDictionary, useIsDesktop } from 'Hooks';
import { OTPCode } from './OTPCode';

interface IValidation {
  isValidated: boolean | null;
  setIsValidated: Function;
  validationHandler: Function;
  isCodeSentAgain: boolean;
  isBlocked: boolean;
  setIsWrongCode: Function;
  isWrongCode: boolean;
}

const validationCodeLength = 4;

export const Validation = ({
  validationHandler,
  isValidated,
  setIsValidated,
  isCodeSentAgain,
  isBlocked,
  isWrongCode,
  setIsWrongCode,
}: IValidation) => {
  const isDesktop = useIsDesktop();
  const dictionary = useDictionary(
    Dictionary,
    DICTIONARY.LOGIN
  ).phoneEmailValidation;
  const [code, setCode] = useState<(string | null)[]>(
    Array(validationCodeLength).fill(null)
  );

  useEffect(() => {
    if (isCodeSentAgain) setCode(Array(validationCodeLength).fill(null));
  }, [isCodeSentAgain]);

  useEffect(() => {
    if (code.every((value) => value !== null)) validationHandler(code.join(''));
  }, [code]);

  useEffect(() => {
    if (code.includes(null)) setIsValidated(null);
  }, [code, setIsValidated, setIsWrongCode]);

  return (
    <>
      <Stack
        direction='row-reverse'
        justifyContent='center'
        gap='15px'
        mb='26px'
      >
        <OTPCode
          isCodeSentAgain={isCodeSentAgain}
          setCode={setCode}
          isValidated={isValidated}
          isBlocked={isBlocked}
          validationHandler={validationHandler}
          isWrongCode={isWrongCode}
        />
      </Stack>
      {isValidated === false ||
        (isWrongCode && (
          <Typography
            sx={{
              mb: '16px',
              fontWeight: isDesktop ? 600 : 700,
              fontSize: isDesktop ? '18px' : '16px',
              fontFamily: 'inherit',
              lineHeight: isDesktop ? '22px' : '24px',
              color: '#A30F28',
            }}
            aria-live='assertive'
            role='alert'
          >
            {isBlocked ? dictionary.blocked : dictionary.wrongCode}
          </Typography>
        ))}
    </>
  );
};
