import axios, { AxiosError } from 'axios';
import { protectedResources } from 'B2C';
import { msalInstance } from 'index';
import { RoutesConfig } from 'Routes';
const HttpStatusCode = {
  UNAUTHORIZED: 401,
};

export const api = axios.create({
  baseURL: process.env.REACT_APP_DOMAIN,
});

const notAuthAction = () => {
  sessionStorage.clear();
  msalInstance.loginRedirect();
};

api.interceptors.request.use(
  async function (config) {
    const account = msalInstance.getAllAccounts()[0];
    const instance = await msalInstance.acquireTokenSilent({
      scopes: protectedResources.apiHello.scopes,
      account: account || {},
    });

    config.headers = {
      ...config.headers,
      Authorization: `${instance.tokenType} ${instance.accessToken}`,
    };
    return config;
  },
  function (error: AxiosError) {
    console.log(error);
    if (error.response?.status === HttpStatusCode.UNAUTHORIZED) {
      notAuthAction();
    }
    return error;
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: any) {
    if (
      error.response?.status === HttpStatusCode.UNAUTHORIZED ||
      error.correlationId
    ) {
      notAuthAction();
    }
    return error;
  }
);
