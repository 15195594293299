import { FormSection } from 'Components';
import { DICTIONARY } from 'Enum';
import { Dictionary } from 'Dictionary';
import { Grid, Link, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from 'Hooks';
import { textFieldShrink } from 'Utils';
import { generateError } from 'Regex';
import { ControllerAutoComplete } from 'Components/ControllerAutoComplete';
import { validateNumbersOnly } from 'Utils/validateNumbersOnly';
import { validateLettersAndNumbersOnly } from 'Utils/validateLettersAndNumbersOnly';
import { store } from 'Store';

export interface IAddressTab {
  cityName: string;
  streetName: string;
  streetNumName: string;
  houseNum: string;
  zipCodeName: string;
  streetNameErrorMsg?: string;
  streetNumErrorMsg?: string;
  houseNumErrorMsg?: string;
  zipCodeErrorMsg?: string;
}
export const AddressTab = (props: IAddressTab) => {
  const { register, getValues } = useFormContext();

  const cities = useAppSelector(({ generalContent: { cities } }) => cities);
  const formInfo = store.getState().generalContent.formInfo;

  const [streetName, streetNum, houseNum, zipCode] = getValues([
    props.streetName,
    props.streetNumName,
    props.houseNum,
    props.zipCodeName,
  ]);

  const dictionary = Dictionary[DICTIONARY.PERSONAL_DETAILS];

  return (
    <FormSection disablePadding>
      <ControllerAutoComplete
        name={props.cityName}
        label={dictionary.thirdSectionRightTabFirstInput}
        options={cities}
      />
      <Grid container spacing={1.5}>
        <Grid item xs={8}>
          <TextField
            role={Boolean(props.streetNameErrorMsg) ? 'alert' : undefined}
            id={dictionary.thirdSectionRightTabSecondInput}
            inputProps={{
              maxLength: '50',
            }}
            {...register(props.streetName)}
            label={dictionary.thirdSectionRightTabSecondInput}
            error={Boolean(props.streetNameErrorMsg)}
            helperText={
              Boolean(props.streetNameErrorMsg) &&
              generateError(
                dictionary.thirdSectionRightTabSecondInput,
                props.streetNameErrorMsg!
              )
            }
            {...textFieldShrink(streetName)}
            onChange={(event: any) => validateLettersAndNumbersOnly(event)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            role={Boolean(props.streetNumErrorMsg) ? 'alert' : undefined}
            id={dictionary.thirdSectionRightTabThirdInput}
            inputProps={{
              maxLength: '6',
            }}
            {...register(props.streetNumName)}
            error={Boolean(props.streetNumErrorMsg)}
            helperText={
              Boolean(props.streetNumErrorMsg) &&
              generateError(
                dictionary.thirdSectionRightTabThirdInput,
                props.streetNumErrorMsg!
              )
            }
            label={dictionary.thirdSectionRightTabThirdInput}
            {...textFieldShrink(streetNum)}
            onKeyDown={(event) => validateNumbersOnly(event)}
          />
        </Grid>
      </Grid>
      <TextField
        role={Boolean(props.houseNumErrorMsg) ? 'alert' : undefined}
        id={dictionary.thirdSectionRightTabFourthInput}
        inputProps={{
          maxLength: '6',
        }}
        {...register(props.houseNum)}
        error={Boolean(props.houseNumErrorMsg)}
        helperText={
          Boolean(props.houseNumErrorMsg) &&
          generateError(
            dictionary.thirdSectionRightTabFourthInput,
            props.houseNumErrorMsg!
          )
        }
        label={dictionary.thirdSectionRightTabFourthInput}
        {...textFieldShrink(houseNum)}
        onKeyDown={(event) => validateNumbersOnly(event)}
        disabled={formInfo.isRecognized}
      />
      <Grid gap={1} container>
        <TextField
          role={Boolean(props.zipCodeErrorMsg) ? 'alert' : undefined}
          id={dictionary.thirdSectionRightTabFifthInput}
          inputProps={{
            maxLength: '7',
          }}
          {...register(props.zipCodeName)}
          error={Boolean(props.zipCodeErrorMsg)}
          helperText={
            Boolean(props.zipCodeErrorMsg) &&
            generateError(
              dictionary.thirdSectionRightTabFifthInput,
              props.zipCodeErrorMsg!
            )
          }
          label={dictionary.thirdSectionRightTabFifthInput}
          {...textFieldShrink(zipCode)}
          onKeyDown={(event) => validateNumbersOnly(event)}
          disabled={formInfo.isRecognized}
        />
        <Link
          href='https://israelpost.co.il/%D7%A9%D7%99%D7%A8%D7%95%D7%AA%D7%99%D7%9D/%D7%90%D7%99%D7%AA%D7%95%D7%A8-%D7%9E%D7%99%D7%A7%D7%95%D7%93/'
          target='_blank'
          sx={{
            fontSize: '14px',
            width: 'auto',
          }}
        >
          {dictionary.thirdSectionRightTabFifthInfo}
        </Link>
      </Grid>
    </FormSection>
  );
};
