import { MenuItem, TextField, Typography } from '@mui/material';
import { ICaseDetailsInputs } from 'API';
import {
  ControllerSelect,
  FileUpload,
  FormSection,
  InfoText,
  RadioButtons,
} from 'Components';
import { Dictionary } from 'Dictionary';
import {
  CAR_ACCIDENT_TYPE,
  CASE_TYPE_RELATION_ID,
  DICTIONARY,
  FileCategories,
} from 'Enum';
import { useAppSelector } from 'Hooks';
import { useContext } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { generateError } from 'Regex';
import { validateLettersAndNumbersOnly } from 'Utils/validateLettersAndNumbersOnly';
import { validateNumbersOnly } from 'Utils/validateNumbersOnly';
import { IndexContext } from '../CaseContext';

export const ThirdSection = () => {
  const ownershipVehicleTypes = useAppSelector(
    ({
      generalContent: {
        generalContent: { ownershipVehicleTypes },
      },
    }) => ownershipVehicleTypes
  );

  const dictionary = Dictionary[DICTIONARY.CASE_DETAILS];

  const {
    register,
    formState: { errors },
  } = useFormContext<ICaseDetailsInputs>();

  const { index, fieldId } = useContext(IndexContext);

  const trafficAccidentErrors = errors.caseDetails
    ? errors.caseDetails[index]?.trafficAccidentSection
    : undefined;

  const baseName = `caseDetails.${index}`;

  const carOptionIdName = `${baseName}.trafficAccidentSection.vehicleDetailsSection.ownershipVehicleId`;

  const isClaimForCompensationHasBeenSentName = `${baseName}.trafficAccidentSection.isClaimForCompensationHasBeenSent`;

  const isDriverName = `${baseName}.trafficAccidentSection.isDriver`;

  const isDriver = useWatch({
    name: isDriverName,
    defaultValue: false,
  }) as boolean;

  const isClaimForCompensationHasBeenSent = useWatch({
    name: isClaimForCompensationHasBeenSentName,
    defaultValue: false,
  }) as boolean;

  const carOptionId = useWatch({
    name: carOptionIdName,
    defaultValue: '0',
  }) as string;

  const isCarAccident = useWatch({
    name: `${baseName}.isTrafficAccident`,
    defaultValue: false,
  }) as boolean;

  const isPedestrian = useWatch({
    name: `${baseName}.trafficAccidentSection.isPedestrian`,
    defaultValue: false,
  }) as boolean;

  const caseType = useWatch({
    name: `${baseName}.caseTypeRelationId`,
    defaultValue: 0,
  }) as number;

  let isDisease = caseType == CASE_TYPE_RELATION_ID.DISEASE_ID;
  const isMental = caseType == CASE_TYPE_RELATION_ID.MENTAL_ID;

  if (isMental) {
    isDisease = true;
  }

  return (
    <>
      {!isDisease && (
        <FormSection grouped id={dictionary.thirdSectionTitle}>
          <Typography
            sx={{ fontWeight: 'bold' }}
            id={dictionary.thirdSectionTitle}
          >
            {dictionary.thirdSectionTitle}
          </Typography>
          <RadioButtons
            name={`${baseName}.isTrafficAccident` as const}
            options={[
              {
                value: 'true',
                label: dictionary.thirdSectionFirstToggleButtonRight,
              },
              {
                value: 'false',
                label: dictionary.thirdSectionFirstToggleButtonLeft,
              },
            ]}
            id={dictionary.thirdSectionFirstToggleButtonLabel}
          />
          {isCarAccident && (
            <>
              <RadioButtons
                name={
                  `${baseName}.trafficAccidentSection.isPedestrian` as const
                }
                options={[
                  {
                    value: 'false',
                    label: dictionary.thirdSectionSecondToggleButtonRight,
                  },
                  {
                    value: 'true',
                    label: dictionary.thirdSectionSecondToggleButtonLeft,
                  },
                ]}
                id={dictionary.thirdSectionSecondToggleButtonLabel}
              />
              {!isPedestrian && (
                <>
                  <RadioButtons
                    name={`${isDriverName}` as const}
                    options={[
                      {
                        value: 'true',
                        label: dictionary.thirdSectionThirdToggleButtonRight,
                      },
                      {
                        value: 'false',
                        label: dictionary.thirdSectionThirdToggleButtonLeft,
                      },
                    ]}
                    id={dictionary.thirdSectionThirdToggleButtonLabel}
                  />
                  <ControllerSelect
                    name={`${carOptionIdName}` as const}
                    label={dictionary.thirdSectionFirstInputValue}
                  >
                    {ownershipVehicleTypes.map(({ value, id }) => (
                      <MenuItem key={id} value={id}>
                        {value}
                      </MenuItem>
                    ))}
                  </ControllerSelect>
                  {carOptionId == CAR_ACCIDENT_TYPE.MILITARY_CAR_ID && (
                    <InfoText
                      text={dictionary.thirdSectionMilitaryCardInfoText}
                    />
                  )}
                  <TextField
                    role={
                      Boolean(
                        trafficAccidentErrors?.vehicleDetailsSection?.vehicleId
                      )
                        ? 'alert'
                        : undefined
                    }
                    inputProps={{
                      maxLength: '20',
                    }}
                    id={`${dictionary.thirdSectionSecondInputValue}-${index}`}
                    error={Boolean(
                      trafficAccidentErrors?.vehicleDetailsSection?.vehicleId
                    )}
                    helperText={
                      Boolean(
                        trafficAccidentErrors?.vehicleDetailsSection?.vehicleId
                          ?.message
                      ) &&
                      generateError(
                        dictionary.thirdSectionSecondInputValue,
                        trafficAccidentErrors?.vehicleDetailsSection?.vehicleId
                          ?.message!
                      )
                    }
                    {...register(
                      `${baseName}.trafficAccidentSection.vehicleDetailsSection.vehicleId` as `caseDetails.0.trafficAccidentSection.vehicleDetailsSection.vehicleId`
                    )}
                    label={dictionary.thirdSectionSecondInputValue}
                    onKeyDown={(event) => validateNumbersOnly(event)}
                  />
                  <RadioButtons
                    name={
                      `${baseName}.trafficAccidentSection.anotherVehicleInvolvedAccident` as const
                    }
                    options={[
                      {
                        value: 'true',
                        label: dictionary.thirdSectionFourthToggleButtonRight,
                      },
                      {
                        value: 'false',
                        label: dictionary.thirdSectionFourthToggleButtonLeft,
                      },
                    ]}
                    id={dictionary.thirdSectionFourthToggleButtonLabel}
                  />
                  <RadioButtons
                    name={`${isClaimForCompensationHasBeenSentName}` as const}
                    options={[
                      {
                        value: 'true',
                        label: dictionary.thirdSectionFifthToggleButtonRight,
                      },
                      {
                        value: 'false',
                        label: dictionary.thirdSectionFifthToggleButtonLeft,
                      },
                    ]}
                    id={dictionary.thirdSectionFifthToggleButtonLabel}
                  />
                  {isClaimForCompensationHasBeenSent && (
                    <TextField
                      role={
                        Boolean(trafficAccidentErrors?.insuranceCompany)
                          ? 'alert'
                          : undefined
                      }
                      inputProps={{
                        maxLength: '50',
                      }}
                      id={`${dictionary.thirdSectionThirdInputValue}-${index}`}
                      error={Boolean(trafficAccidentErrors?.insuranceCompany)}
                      helperText={
                        Boolean(
                          trafficAccidentErrors?.insuranceCompany?.message
                        ) &&
                        generateError(
                          dictionary.thirdSectionThirdInputValue,
                          trafficAccidentErrors?.insuranceCompany?.message!
                        )
                      }
                      {...register(
                        `${baseName}.trafficAccidentSection.insuranceCompany` as `caseDetails.0.trafficAccidentSection.insuranceCompany`
                      )}
                      label={dictionary.thirdSectionThirdInputValue}
                      onChange={(event: any) =>
                        validateLettersAndNumbersOnly(event)
                      }
                    />
                  )}
                  {isDriver &&
                    carOptionId != CAR_ACCIDENT_TYPE.BICYCLE_OR_SCOOTER_ID && (
                      <>
                        <Typography sx={{ mt: 3 }} variant={'subtitle1'}>
                          {dictionary.thirdSectionFirstFileUploadLabel}{' '}
                          {carOptionId !=
                            CAR_ACCIDENT_TYPE.BICYCLE_OR_SCOOTER_ID &&
                            Dictionary[DICTIONARY.GENERAL].required}
                        </Typography>
                        <FileUpload
                          dataRequired={
                            carOptionId !=
                            CAR_ACCIDENT_TYPE.BICYCLE_OR_SCOOTER_ID
                          }
                          caseDetailsId={fieldId}
                          fileLabel={dictionary.thirdSectionFirstFileUploadText}
                          fileCategoryId={FileCategories.DrivingLicense}
                          ariaLabel={
                            dictionary.thirdSectionFirstFileUploadLabel
                          }
                        />
                      </>
                    )}
                  {carOptionId != CAR_ACCIDENT_TYPE.BICYCLE_OR_SCOOTER_ID && (
                    <>
                      <Typography sx={{ mt: 3 }} variant={'subtitle1'}>
                        {dictionary.thirdSectionSecondFileUploadLabel}{' '}
                        {carOptionId == CAR_ACCIDENT_TYPE.CIVIL_CAR_ID &&
                          Dictionary[DICTIONARY.GENERAL].required}
                      </Typography>
                      <FileUpload
                        dataRequired={
                          carOptionId == CAR_ACCIDENT_TYPE.CIVIL_CAR_ID
                        }
                        caseDetailsId={fieldId}
                        fileLabel={dictionary.thirdSectionSecondFileUploadText}
                        fileCategoryId={FileCategories.VehicleInsurance}
                        ariaLabel={dictionary.thirdSectionSecondFileUploadLabel}
                      />
                    </>
                  )}
                </>
              )}

              <Typography sx={{ mt: 3 }} variant={'subtitle1'}>
                {dictionary.thirdSectionThirdFileUploadLabel}
              </Typography>
              <Typography variant={'subtitle1'}>
                {dictionary.thirdSectionThirdFileUploadLabel2}
              </Typography>
              <FileUpload
                caseDetailsId={fieldId}
                fileLabel={dictionary.thirdSectionThirdFileUploadText}
                fileCategoryId={FileCategories.PoliceReport}
                ariaLabel={dictionary.thirdSectionThirdFileUploadLabel}
              />
            </>
          )}
        </FormSection>
      )}
    </>
  );
};
