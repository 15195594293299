import { api } from 'API';

const baseUrl = '/CaseDetails';

export interface MilitaryServiceSection {
  militaryID?: string | null;
  releaseDate?: string | null;
  serviceTypeId?: string | null;
  otherServiceType?: string | null;
  medicalProfile?: string | null;
  role?: string | null;
  rankId?: string | null;
  troopId?: string | null;
  unitAtTheTimeOfInjury?: string | null;
}

export interface InjuryKindSection {
  date?: string | null;
  description?: string | null;
  reportAttachedSelectedOptionId?: string | null;
  investigationHasBeen?: boolean | null;
  investigatorOptionId?: string | null;
}

export interface Witness {
  idNumber?: string | null;
  personalMilitaryID?: string | null;
  civilAddress?: string | null;
  militaryMail?: string | null;
  role?: string | null;
  phonePrefixRelationId?: string | null;
  phone?: string | null;
  typeId?: string | null;
  otherWitnessesType?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  isClassifiedRole?: boolean | null;
}

export interface VehicleDetailsSection {
  ownershipVehicleId?: string | null;
  vehicleId?: string | null;
}

export interface TrafficAccidentSection {
  isPedestrian?: boolean | null;
  isDriver?: boolean | null;
  vehicleDetailsSection?: VehicleDetailsSection;
  anotherVehicleInvolvedAccident?: boolean | null;
  isClaimForCompensationHasBeenSent?: boolean | null;
  insuranceCompany?: string;
}

export interface DiseaseKindSection {
  diseaseDiagnosisDate?: string;
  diseaseDetailsDescription?: string;
  howServiceCauseYourHealthCondition?: string;
  isRelativesWithSameDisease?: boolean | null;
  isDiagnosisExist?: boolean | null;
  relativesWithSameDisease?: string;
}

export interface HospitalizationDetailsSection {
  id?: number;
  medicalInstitutionName?: string;
  clinicDepartment?: string;
  hospitalization?: string;
  hospitalizationStartDate?: string;
  hospitalizationEndDate?: string;
  doctorName?: string;
  wasHospitalized?: boolean | null;
}

export interface ICaseDetails {
  id: number;
  militaryServiceSection?: MilitaryServiceSection;
  caseTypeRelationId?: string;
  injuryKindSection?: InjuryKindSection;
  diseaseKindSection?: DiseaseKindSection;
  isTrafficAccident?: boolean | null;
  trafficAccidentSection?: TrafficAccidentSection;
  areThereWitnessesOptionId?: string;
  witnesses?: Witness[];
  wasMedicalInstitutionTreatment?: string;
  hospitalizationDetailsSection?: HospitalizationDetailsSection[];
  isMentalIllnessCausedBySpecificEvent?: boolean | null;
}

export interface ICaseDetailsInputs {
  caseDetails: ICaseDetails[];
}
export const witnessesEmptyObject: Witness = {
  civilAddress: undefined,
  firstName: undefined,
  idNumber: undefined,
  lastName: undefined,
  militaryMail: undefined,
  otherWitnessesType: undefined,
  personalMilitaryID: undefined,
  phone: undefined,
  phonePrefixRelationId: undefined,
  role: undefined,
  typeId: undefined,
  isClassifiedRole: undefined,
};

export const hospitalizationDetailsSectionEmptyObject: HospitalizationDetailsSection =
  {
    doctorName: undefined,
    clinicDepartment: undefined,
    hospitalization: undefined,
    hospitalizationEndDate: undefined,
    hospitalizationStartDate: undefined,
    medicalInstitutionName: undefined,
    wasHospitalized: undefined,
  };

export const caseDetailsEmptyObject: Omit<ICaseDetails, 'id'> = {
  militaryServiceSection: {
    medicalProfile: undefined,
    militaryID: undefined,
    otherServiceType: undefined,
    rankId: undefined,
    releaseDate: undefined,
    role: undefined,
    serviceTypeId: undefined,
    troopId: undefined,
    unitAtTheTimeOfInjury: undefined,
  },
  caseTypeRelationId: undefined,
  injuryKindSection: {
    date: undefined,
    description: undefined,
    investigationHasBeen: undefined,
    investigatorOptionId: undefined,
    reportAttachedSelectedOptionId: undefined,
  },
  diseaseKindSection: {
    isDiagnosisExist: undefined,
    diseaseDetailsDescription: undefined,
    diseaseDiagnosisDate: undefined,
    howServiceCauseYourHealthCondition: undefined,
    isRelativesWithSameDisease: undefined,
    relativesWithSameDisease: undefined,
  },
  isTrafficAccident: undefined,
  trafficAccidentSection: {
    anotherVehicleInvolvedAccident: undefined,
    insuranceCompany: undefined,
    isClaimForCompensationHasBeenSent: undefined,
    isDriver: undefined,
    isPedestrian: undefined,
    vehicleDetailsSection: {
      ownershipVehicleId: undefined,
      vehicleId: undefined,
    },
  },
  areThereWitnessesOptionId: undefined,
  witnesses: [
    {
      ...witnessesEmptyObject,
    },
  ],
  wasMedicalInstitutionTreatment: undefined,
  hospitalizationDetailsSection: [
    {
      ...hospitalizationDetailsSectionEmptyObject,
    },
  ],
  isMentalIllnessCausedBySpecificEvent: undefined,
};

export const getCaseDetails = async () => {
  const caseDetails = await api.get<ICaseDetails[]>(baseUrl);
  if (caseDetails.data) {
    return caseDetails.data;
  }
};

export const putCaseDetails = async (data: Partial<ICaseDetails>[]) => {
  const caseDetails = await api.put(baseUrl, data);
  if (caseDetails.status === 200) {
    return;
  }
};

export const postCaseDetails = async (data: Partial<ICaseDetails>) => {
  const caseDetailsData = await api.post<ICaseDetails>(baseUrl, data);
  if (caseDetailsData.data) {
    return caseDetailsData.data;
  }
};

export const deleteCaseDetails = async (id: number) => {
  const caseDetailsData = await api.delete(baseUrl + '/' + id);
  if (caseDetailsData.status === 200) {
    return true;
  }
  return false;
};

export const postHospitalization = async (caseDetailsId: number) => {
  const hospitalization = await api.post<HospitalizationDetailsSection>(
    baseUrl + '/' + caseDetailsId + '/hospitalizationDetails',
    {}
  );
  if (hospitalization.data) {
    return hospitalization.data;
  }
};

export const deleteHospitalization = async (
  caseDetailsId: number,
  hospitalizationId: number
) => {
  const hospitalization = await api.delete(
    baseUrl +
      '/' +
      caseDetailsId +
      '/hospitalizationDetails/' +
      hospitalizationId
  );
  if (hospitalization.status === 200) {
    return true;
  }
  return false;
};
