export const bankAccountValidationErrorMessage = 'מספר חשבון בנק לא תקין';
export const bankAccountOwnershipErrorMessage = 'יש לסמן הצהרת בעלות (חובה)';

export const bankAccountValidation = (
  bankNumber?: number,
  branchNumber?: number,
  accountNumber?: number
) => {
  if (
    !bankNumber ||
    isNaN(bankNumber) ||
    !branchNumber ||
    isNaN(branchNumber) ||
    !accountNumber ||
    isNaN(accountNumber)
  )
    return false;

  // Input validation:
  if (bankNumber.constructor === String) {
    bankNumber = Number(bankNumber);
  }
  if (branchNumber.constructor === String) {
    branchNumber = Number(branchNumber);
  }
  if (accountNumber.constructor === String) {
    accountNumber = Number(accountNumber);
  }
  if (!isNonNegativeInteger(bankNumber)) {
    return false;
  }
  if (!isNonNegativeInteger(branchNumber)) {
    return false;
  }
  if (!isNonNegativeInteger(accountNumber)) {
    return false;
  }

  const bankNumberString = bankNumber.toString().padStart(3, '0');
  const branchNumberString = branchNumber
    .toString()
    .slice(bankNumber.toString().length);
  let combinedBranchNumber = Number(
    bankNumberString + branchNumberString.padStart(3, '0')
  );

  var SUPPORTED_BANKS = {
    YAHAV: 4, // Government workers
    POST: 9,
    LEUMI: 10,
    DISCOUNT: 11,
    HAPOALIM: 12,
    IGUD: 13,
    OTSAR_AHAYAL: 14,
    MERCANTILE: 17,
    MIZRAHI_TEFAHOT: 20,
    CITIBANK: 22,
    BEINLEUMI: 31,
    ARAVEI_ISRAELI: 34, // Merged with Leumi
    MASAD: 46,
    POALEI_AGUDAT_ISRAEL: 52, // merged with Beinleumi
    ONEZERO: 18,
  };

  if (bankNumber === SUPPORTED_BANKS.MIZRAHI_TEFAHOT) {
    if (combinedBranchNumber > 400) {
      combinedBranchNumber -= 400;
    }
  }

  const accountNumberLength = accountNumber.toString().length;
  var accountNumberDigits = numberDigitsToArr(accountNumber, 9);
  var branchNumberDigits = numberDigitsToArr(combinedBranchNumber, 3);

  // Account number validation
  var sum = 0;
  var remainder = 0;
  switch (bankNumber) {
    case SUPPORTED_BANKS.ONEZERO:
      const acc = accountNumber.toString();
      if (acc.length !== 9) {
        return false;
      }
      const testNumber = 97;
      const controlNumber = 98;
      const account = acc.padStart(9, '0');
      const lastTwoDigits = parseInt(account.slice(-2), 10);
      const accountTest = parseInt(`1${account.slice(0, 7)}`, 10);
      const controlRemainder = accountTest % testNumber;
      const result = controlNumber - controlRemainder === lastTwoDigits;
      return result;

    case SUPPORTED_BANKS.LEUMI:
    case SUPPORTED_BANKS.IGUD: {
      if (accountNumberLength < 2 || accountNumberLength > 8) return false;
    }
    case SUPPORTED_BANKS.ARAVEI_ISRAELI:
      sum = scalarProduct(
        accountNumberDigits?.slice(0, 8),
        [1, 10, 2, 3, 4, 5, 6, 7]
      );
      sum += scalarProduct(branchNumberDigits?.slice(0, 4), [8, 9, 10]);
      remainder = sum % 100;
      return arrIncludes([90, 72, 70, 60, 20], remainder);

    case SUPPORTED_BANKS.YAHAV:
    case SUPPORTED_BANKS.MIZRAHI_TEFAHOT:
    case SUPPORTED_BANKS.HAPOALIM:
      sum = scalarProduct(accountNumberDigits?.slice(0, 6), [1, 2, 3, 4, 5, 6]);
      sum += scalarProduct(branchNumberDigits?.slice(0, 4), [7, 8, 9]);
      remainder = sum % 11;

      switch (bankNumber) {
        case SUPPORTED_BANKS.YAHAV: {
          if (accountNumberLength < 4 || accountNumberLength > 7) return false;
          return arrIncludes([0, 2], remainder);
        }
        case SUPPORTED_BANKS.MIZRAHI_TEFAHOT: {
          if (accountNumberLength > 6) return false;
          return arrIncludes([0, 2, 4], remainder);
        }
        case SUPPORTED_BANKS.HAPOALIM: {
          if (accountNumberLength > 6 || accountNumberLength < 3) return false;
          return arrIncludes([0, 2, 4, 6], remainder);
        }
      }
      return false;

    case SUPPORTED_BANKS.DISCOUNT:
    case SUPPORTED_BANKS.MERCANTILE:
    case SUPPORTED_BANKS.BEINLEUMI:
    case SUPPORTED_BANKS.POALEI_AGUDAT_ISRAEL:
      sum = scalarProduct(
        accountNumberDigits?.slice(0, 9),
        [1, 2, 3, 4, 5, 6, 7, 8, 9]
      );
      remainder = sum % 11;

      switch (bankNumber) {
        case SUPPORTED_BANKS.DISCOUNT: {
          if (accountNumberLength > 9) return false;
        }
        case SUPPORTED_BANKS.MERCANTILE:
          return arrIncludes([0, 2, 4], remainder);

        case SUPPORTED_BANKS.BEINLEUMI: {
          if (accountNumberLength < 2 || accountNumberLength > 10) return false;
        }
        case SUPPORTED_BANKS.POALEI_AGUDAT_ISRAEL:
          if (arrIncludes([0, 6], remainder)) {
            return true;
          } else {
            sum = scalarProduct(
              accountNumberDigits?.slice(0, 6),
              [1, 2, 3, 4, 5, 6]
            );
            remainder = sum % 11;
            return arrIncludes([0, 6], remainder);
          }
      }
      return false;

    case SUPPORTED_BANKS.POST:
      sum = scalarProduct(
        accountNumberDigits?.slice(0, 9),
        [1, 2, 3, 4, 5, 6, 7, 8, 9]
      );
      remainder = sum % 10;
      return remainder === 0;

    case 54: // Jerusalem
      return true; // wtf?

    case SUPPORTED_BANKS.CITIBANK:
      if (accountNumberLength > 9) return false;
      sum = scalarProduct(
        accountNumberDigits?.slice(1, 9),
        [2, 3, 4, 5, 6, 7, 2, 3]
      );
      return 11 - (sum % 11) === accountNumberDigits[0];

    case SUPPORTED_BANKS.OTSAR_AHAYAL:
    case SUPPORTED_BANKS.MASAD:
      if (accountNumberLength < 2 || accountNumberLength > 9) return false;
      sum = scalarProduct(accountNumberDigits?.slice(0, 6), [1, 2, 3, 4, 5, 6]);
      sum += scalarProduct(branchNumberDigits?.slice(0, 4), [7, 8, 9]);
      remainder = sum % 11;

      if (remainder === 0) {
        return true;
      }

      if (bankNumber === SUPPORTED_BANKS.MASAD) {
        if (
          remainder === 2 &&
          arrIncludes(
            [
              154, 166, 178, 181, 183, 191, 192, 503, 505, 507, 515, 516, 527,
              539,
            ],
            parseInt(`${combinedBranchNumber}`.slice(2))
          )
        ) {
          return true;
        }

        sum = scalarProduct(
          accountNumberDigits?.slice(0, 9),
          [1, 2, 3, 4, 5, 6, 7, 8, 9]
        );
        remainder = sum % 11;

        if (remainder === 0) {
          return true;
        } else {
          sum = scalarProduct(
            accountNumberDigits?.slice(0, 6),
            [1, 2, 3, 4, 5, 6]
          );
          remainder = sum % 11;
          return remainder === 0;
        }
      }
      if (bankNumber === SUPPORTED_BANKS.OTSAR_AHAYAL) {
        if (
          arrIncludes([0, 2], remainder) &&
          arrIncludes(
            [385, 384, 365, 347, 363, 362, 361],
            parseInt(`${combinedBranchNumber}`.slice(2))
          )
        ) {
          return true;
        } else if (
          remainder === 4 &&
          arrIncludes(
            [363, 362, 361],
            parseInt(`${combinedBranchNumber}`.slice(2))
          )
        ) {
          return true;
        } else {
          sum = scalarProduct(
            accountNumberDigits?.slice(0, 9),
            [1, 2, 3, 4, 5, 6, 7, 8, 9]
          );
          remainder = sum % 11;
          if (remainder === 0) {
            return true;
          } else {
            sum = scalarProduct(
              accountNumberDigits?.slice(0, 6),
              [1, 2, 3, 4, 5, 6]
            );
            remainder = sum % 11;
            return remainder === 0;
          }
        }
      }
      return false;
  }

  return false;

  /**
   * Calculates scalar product of two arrays of the same length.
   * https://en.wikipedia.org/wiki/Dot_product
   * @param {Array} arr1
   * @param {Array} arr2
   */
  function scalarProduct(arr1: any, arr2: any) {
    var product = 0;
    for (var i = 0; i < arr1.length && i < arr2.length; ++i) {
      product += arr1[i] * arr2[i];
    }
    return product;
  }

  /**
   * Check if `val` is an element of `arr` using strict compare by reference
   * A bit like arr.includes(val), but made in-house for legacy browser support
   * @param {Array} arr
   * @param {*} val
   */
  function arrIncludes(arr: any, val: any) {
    if (arr) {
      for (var i = 0; i < arr.length; ++i) {
        if (arr[i] === val) {
          return true;
        }
      }
    }
    return false;
  }

  function numberDigitsToArr(num: any, length: any) {
    var digitsArray = [];
    for (var i = 0; i < length; ++i) {
      digitsArray.push(num % 10);
      num = Math.floor(num / 10);
    }
    return digitsArray;
  }

  function isNonNegativeInteger(num: number) {
    return (
      num.constructor === Number &&
      !isNaN(num) &&
      num >= 0 &&
      num === Math.floor(num)
    );
  }
};
