import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useIsDesktop } from 'Hooks';
import { CollapseExpandIcon, CollapseShrinkIcon } from 'Images';
import { FC, useState } from 'react';
interface ICustomAccordion {
  title: string;
}
export const CustomAccordion: FC<ICustomAccordion> = (props) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleChange =
    () => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded);
    };

  const isDesktop = useIsDesktop();
  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange()}
      elevation={0}
      sx={{
        '::before': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <AccordionSummary>
        <Grid container alignItems='center'>
          <Grid item xs={1}>
            <Avatar
              src={!expanded ? CollapseExpandIcon : CollapseShrinkIcon}
              sx={{
                width: '24px',
                height: '24px',
              }}
              alt={expanded ? 'expanded' : 'collapsed'}
            />
          </Grid>
          <Grid item xs={11}>
            <Typography variant='h2' fontWeight={700}>
              {props.title}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={11}>
            {props.children}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
