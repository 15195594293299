import { MenuItem, Typography } from '@mui/material';
import { ICaseDetailsInputs } from 'API';
import {
  ControllerSelect,
  CustomToggleGroup,
  FileUpload,
  RadioButtons,
} from 'Components';
import { Dictionary } from 'Dictionary';
import {CASE_TYPE_RELATION_ID, DICTIONARY, FileCategories} from 'Enum';
import { useAppSelector } from 'Hooks';
import { useContext } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { IndexContext } from '../CaseContext';

export const Investigation = () => {
  const [investigationDirectorOptions] = useAppSelector(
    ({
      generalContent: {
        generalContent: { investigationDirectorOptions },
      },
    }) => [investigationDirectorOptions]
  );
  const {
    formState: { errors },
  } = useFormContext<ICaseDetailsInputs>();
  const {
    index,
    caseTypeName = '',
    diseaseId = CASE_TYPE_RELATION_ID.DISEASE_ID,
    fieldId,
  } = useContext(IndexContext);
  const baseName = `caseDetails.${index}`;

  const caseType = useWatch({
    name: caseTypeName,
    defaultValue: 0,
  }) as number;

  const isDisease = caseType == diseaseId;

  const injuryInvestigationHasBeenName = `${baseName}.injuryKindSection.investigationHasBeen`;

  const injuryInvestigationHasBeen = useWatch({
    name: injuryInvestigationHasBeenName,
    defaultValue: false,
  }) as boolean;

  return isDisease ? (
    <></>
  ) : (
    <>
      <RadioButtons
        name={`${injuryInvestigationHasBeenName}` as const}
        options={[
          {
            value: 'true',
            label:
              Dictionary[DICTIONARY.CASE_DETAILS]
                .secondSectionSecondToggleButtonRight,
          },
          {
            value: 'false',
            label:
              Dictionary[DICTIONARY.CASE_DETAILS]
                .secondSectionSecondToggleButtonLeft,
          },
        ]}
        id={
          Dictionary[DICTIONARY.CASE_DETAILS]
            .secondSectionSecondToggleButtonLabel
        }
      />
      {injuryInvestigationHasBeen && (
        <>
          <ControllerSelect
            name={
              `${baseName}.injuryKindSection.investigatorOptionId` as `caseDetails.0.injuryKindSection.investigatorOptionId`
            }
            label={
              Dictionary[DICTIONARY.CASE_DETAILS]
                .secondSectionSecondInputPlaceholder
            }
          >
            {investigationDirectorOptions.map(({ id, value }) => (
              <MenuItem key={id} value={id}>
                {value}
              </MenuItem>
            ))}
          </ControllerSelect>
          <Typography>
            {
              Dictionary[DICTIONARY.CASE_DETAILS]
                .secondSectionSecondFileUploadLabel
            }
          </Typography>
          <FileUpload
            caseDetailsId={fieldId}
            fileLabel={
              Dictionary[DICTIONARY.CASE_DETAILS]
                .secondSectionSecondFileUploadText
            }
            fileCategoryId={FileCategories.InvestigationReportFile}
            ariaLabel={
              Dictionary[DICTIONARY.CASE_DETAILS]
                .secondSectionSecondFileUploadLabel
            }
          />
          <Typography>
            {
              Dictionary[DICTIONARY.CASE_DETAILS]
                .secondSectionThirdFileUploadLabel
            }
          </Typography>
          <FileUpload
            caseDetailsId={fieldId}
            fileLabel={
              Dictionary[DICTIONARY.CASE_DETAILS]
                .secondSectionThirdFileUploadText
            }
            fileCategoryId={FileCategories.ExtraRelatedFile}
            ariaLabel={
              Dictionary[DICTIONARY.CASE_DETAILS]
                .secondSectionThirdFileUploadLabel
            }
          />
        </>
      )}
    </>
  );
};
