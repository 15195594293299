import { api } from 'API';

const baseUrl = '/EmploymentAndBankDetails';

export interface BankAccountDetailsSection {
  bankRelationId?: string | null;
  branchRelationId?: string | null;
  accountNumber?: string | null;
}

export interface WorkPlaceDetailsSection {
  workName?: string | null;
  workAddress?: string | null;
  managerPhonePrefixRelationId?: string | null;
  managerPhoneNum?: string | null;
  landlinePrefixRelationId?: string | null;
  landlinePhoneNum?: string | null;
  jobTitle?: string | null;
  managerName?: string | null;
  employmentStatusId?: string | null;
}

export interface IEmploymentAndBankDetails {
  bankAccountDetailsSection?: BankAccountDetailsSection;
  workPlaceDetailsSection?: WorkPlaceDetailsSection;
}

export const getEmploymentAndBankDetails = async () => {
  const employmentAndBankDetails = await api.get<IEmploymentAndBankDetails>(
    baseUrl
  );
  if (employmentAndBankDetails.data) {
    return employmentAndBankDetails.data;
  }
};

export const putEmploymentAndBankDetails = async (
  data: Partial<IEmploymentAndBankDetails>
) => {
  const employmentAndBankDetails = await api.put(baseUrl, data);
  if (employmentAndBankDetails.status === 200) {
    return employmentAndBankDetails.data;
  }
};
