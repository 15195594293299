import { Grid, Typography } from '@mui/material';
import { FileActions } from '../FileActions';
interface IFileRow {
  fileName: string;
  deleteFile: () => void;
  watchFile: () => void;
}
export const FileRow = (props: IFileRow) => {
  const filename = props.fileName;

  const fileWithoutExtension = filename.split('.');
  const clearFile = fileWithoutExtension[0].slice(
    0,
    fileWithoutExtension[0].length - 14
  );
  const fileExt = filename.split('.').pop();
  const fileName = clearFile + '.' + fileExt;

  return (
    <Grid container>
      <Grid
        container
        alignItems='center'
        sx={{
          padding: '16px 16px',
        }}
      >
        <Grid item xs={6}>
          <Typography noWrap title={fileName}>
            {fileName}
          </Typography>
        </Grid>
        <Grid container item xs={6}>
          <FileActions
            deleteFile={props.deleteFile}
            watchFile={props.watchFile}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
