import { IRequestAndEarlyAcquaintance } from 'API';
import {
  textTooLong,
  lettersOrNumbersWithExtraChars,
  lettersOrNumbersNoSpecialCharsMessage,
  requiredMessage,
} from 'Regex';
import { store } from 'Store';
import * as yup from 'yup';

export const requestAndEarlyAcquaintanceSchema: yup.SchemaOf<IRequestAndEarlyAcquaintance> =
  yup.object().shape({
    isRequestAllowanceWasSubmitted: yup
      .boolean()
      .required(requiredMessage)
      .nullable(),
    socialSecurityRequestSection: yup
      .object({
        requestReasonId: yup.string().notRequired().nullable(),
        submittedRequestDate: yup.string().notRequired().nullable(),
        isRequestProcessingEnded: yup
          .boolean()
          .test({
            name: 'isRequired',
            message: requiredMessage,
            test: (value) => {
              if (
                store.getState().requestAndEarlyAcquaintance.generalObj
                  .isRequestAllowanceWasSubmitted
              ) {
                if (value === null || value === undefined) {
                  return false;
                }
              }
              return true;
            },
          })
          .nullable(),
      })
      .notRequired()
      .nullable(),
    ministryOfDefenceSection: yup.object({
      isMinistryOfDefenceEmployee: yup
        .boolean()
        .required(requiredMessage)
        .nullable(),
      isThereRelativeInMinistryOfDefense: yup
        .boolean()
        .required(requiredMessage)
        .nullable(),
      workplace: yup
        .string()
        .max(50, textTooLong(50))
        .notRequired()
        .nullable()
        .matches(
          lettersOrNumbersWithExtraChars,
          lettersOrNumbersNoSpecialCharsMessage
        )
        .test({
          name: 'isRequired',
          message: requiredMessage,
          test: (value, context) => {
            if (context.parent.isMinistryOfDefenceEmployee) {
              if (value) {
                return true;
              }
              return false;
            }
            return true;
          },
        }),
      relativeDetail: yup
        .string()
        .max(50, textTooLong(50))
        .notRequired()
        .nullable()
        .matches(
          lettersOrNumbersWithExtraChars,
          lettersOrNumbersNoSpecialCharsMessage
        )
        .test({
          name: 'isRequired',
          message: requiredMessage,
          test: (value, context) => {
            if (context.parent.isThereRelativeInMinistryOfDefense) {
              if (value) {
                return true;
              }
              return false;
            }
            return true;
          },
        }),
    }),
  });
