import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import { IPersonalDetails } from 'API';
import { Address, ControllerAutoComplete, FormSection } from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { useAppSelector, useDispatchApp } from 'Hooks';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { generateError } from 'Regex';
import { setIsDifferentMailAddress, store } from 'Store';
import { validateLettersAndNumbersOnly } from 'Utils/validateLettersAndNumbersOnly';
import { validateNumbersOnly } from 'Utils/validateNumbersOnly';
export const AddressForMailDelivery = () => {
  const {
    register,
    formState: {
      errors: { residentialAddressSection, mailBoxSection },
    },
  } = useFormContext<IPersonalDetails>();
  const formInfo = store.getState().generalContent.formInfo;

  const countries = useAppSelector(
    ({ generalContent: { countries } }) => countries
  );

  const isMailAddressDifferentLocationName =
    'residentialAddressSection.isDifferentMailAddress';

  const addressForMail: boolean = useWatch({
    name: isMailAddressDifferentLocationName,
    defaultValue: false,
  });

  const dispatch = useDispatchApp();

  useEffect(() => {
    dispatch(setIsDifferentMailAddress(addressForMail));
  }, [addressForMail]);

  const abroadAddressRelationName =
    'residentialAddressSection.abroadAddressRelation';

  const isAbroadResidentialAddressName =
    'residentialAddressSection.isAbroadResidentialAddress';

  const isAbroadResidentialAddress: boolean = useWatch({
    name: isAbroadResidentialAddressName,
    defaultValue: false,
  });

  const countriesWithoutIsrael = countries.filter(function (item) {
    return item.value !== 'ישראל';
  });

  const dictionary = Dictionary[DICTIONARY.PERSONAL_DETAILS];

  return (
    <>
      <FormSection>
        <FormControlLabel
          {...register(isMailAddressDifferentLocationName)}
          checked={addressForMail}
          control={<Checkbox />}
          label={dictionary.thirdSectionCheckboxLabel}
          disabled={formInfo.isRecognized}
        />
        <FormControlLabel
          {...register(isAbroadResidentialAddressName)}
          checked={isAbroadResidentialAddress}
          control={<Checkbox />}
          label={dictionary.thirdSectionMyResidentialLocationIsOutOfSeas}
          disabled={formInfo.isRecognized}
        />
      </FormSection>
      {isAbroadResidentialAddress && (
        <FormSection
          grouped
          id={dictionary.thirdSectionMyResidentialLocationIsOutOfSeas}
        >
          <span className={'sr-only'}>
            {dictionary.thirdSectionMyResidentialLocationIsOutOfSeas}
          </span>
          <ControllerAutoComplete
            label={dictionary.thirdSectionAbroadCountry}
            name={`${abroadAddressRelationName}.countryId`}
            options={countriesWithoutIsrael}
          />

          <Grid container spacing={1.5}>
            <Grid item xs={8}>
              <TextField
                role={
                  Boolean(
                    residentialAddressSection?.abroadAddressRelation?.street
                  )
                    ? 'alert'
                    : undefined
                }
                id={dictionary.thirdSectionAbroadStreet}
                inputProps={{
                  maxLength: '50',
                }}
                {...register(`${abroadAddressRelationName}.street`)}
                label={dictionary.thirdSectionAbroadStreet}
                error={Boolean(
                  residentialAddressSection?.abroadAddressRelation?.street
                )}
                helperText={
                  Boolean(
                    residentialAddressSection?.abroadAddressRelation?.street
                      ?.message
                  ) &&
                  generateError(
                    dictionary.thirdSectionAbroadStreet,
                    residentialAddressSection?.abroadAddressRelation?.street
                      ?.message!
                  )
                }
                onChange={(event: any) => validateLettersAndNumbersOnly(event)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                role={
                  Boolean(
                    residentialAddressSection?.abroadAddressRelation
                      ?.homeNumHouse
                  )
                    ? 'alert'
                    : undefined
                }
                id={dictionary.thirdSectionAbroadStreet}
                inputProps={{
                  maxLength: '50',
                }}
                {...register(`${abroadAddressRelationName}.homeNumHouse`)}
                label={dictionary.thirdSectionAbroadNumHouse}
                error={Boolean(
                  residentialAddressSection?.abroadAddressRelation?.homeNumHouse
                )}
                helperText={
                  Boolean(
                    residentialAddressSection?.abroadAddressRelation
                      ?.homeNumHouse?.message
                  ) &&
                  generateError(
                    dictionary.thirdSectionAbroadStreet,
                    residentialAddressSection?.abroadAddressRelation
                      ?.homeNumHouse?.message!
                  )
                }
                onKeyDown={(event) => validateNumbersOnly(event)}
              />
            </Grid>
          </Grid>

          <TextField
            role={
              Boolean(
                residentialAddressSection?.abroadAddressRelation?.abroadTown
              )
                ? 'alert'
                : undefined
            }
            id={dictionary.thirdSectionAbroadCity}
            inputProps={{
              maxLength: '50',
            }}
            {...register(`${abroadAddressRelationName}.abroadTown`)}
            label={dictionary.thirdSectionAbroadCity}
            error={Boolean(
              residentialAddressSection?.abroadAddressRelation?.abroadTown
            )}
            helperText={
              Boolean(
                residentialAddressSection?.abroadAddressRelation?.abroadTown
                  ?.message
              ) &&
              generateError(
                dictionary.thirdSectionAbroadCity,
                residentialAddressSection?.abroadAddressRelation?.abroadTown
                  ?.message!
              )
            }
            onChange={(event: any) => validateLettersAndNumbersOnly(event)}
          />
          <TextField
            role={
              Boolean(
                residentialAddressSection?.abroadAddressRelation?.abroadZipCode
              )
                ? 'alert'
                : undefined
            }
            id={dictionary.thirdSectionAbroadZipCode}
            inputProps={{
              maxLength: '7',
            }}
            {...register(`${abroadAddressRelationName}.abroadZipCode`)}
            label={dictionary.thirdSectionAbroadZipCode}
            error={Boolean(
              residentialAddressSection?.abroadAddressRelation?.abroadZipCode
            )}
            helperText={
              Boolean(
                residentialAddressSection?.abroadAddressRelation?.abroadZipCode
                  ?.message
              ) &&
              generateError(
                dictionary.thirdSectionAbroadZipCode,
                residentialAddressSection?.abroadAddressRelation?.abroadZipCode
                  ?.message!
              )
            }
            onKeyDown={(event) => validateNumbersOnly(event)}
          />
          <TextField
            role={
              Boolean(residentialAddressSection?.abroadAddressRelation?.po)
                ? 'alert'
                : undefined
            }
            id={dictionary.thirdSectionAbroadPOBox}
            inputProps={{
              maxLength: '5',
            }}
            {...register(`${abroadAddressRelationName}.po`)}
            label={dictionary.thirdSectionAbroadPOBox}
            error={Boolean(
              residentialAddressSection?.abroadAddressRelation?.po
            )}
            helperText={
              Boolean(
                residentialAddressSection?.abroadAddressRelation?.po?.message
              ) &&
              generateError(
                dictionary.thirdSectionAbroadPOBox,
                residentialAddressSection?.abroadAddressRelation?.po?.message!
              )
            }
            onKeyDown={(event) => validateNumbersOnly(event)}
          />
        </FormSection>
      )}

      {addressForMail && (
        <Address
          addressTab={{
            cityName: 'mailBoxSection.townId',
            houseNum: 'mailBoxSection.apartmentNumber',
            streetName: 'mailBoxSection.street',
            streetNumName: 'mailBoxSection.mailHouseNumber',
            zipCodeName: 'mailBoxSection.zipCode',
            houseNumErrorMsg: mailBoxSection?.apartmentNumber?.message,
            streetNameErrorMsg: mailBoxSection?.street?.message,
            streetNumErrorMsg: mailBoxSection?.mailHouseNumber?.message,
            zipCodeErrorMsg: mailBoxSection?.zipCode?.message,
          }}
          POBoxTab={{
            cityName: 'mailBoxSection.townId',
            poBoxName: 'mailBoxSection.po',
            zipCodeName: 'mailBoxSection.zipCode',
            poBoxErrorMsg: mailBoxSection?.po?.message,
            zipCodeErrorMsg: mailBoxSection?.zipCode?.message,
          }}
          title={dictionary.thirdSectionAddressForMailTitle}
        />
      )}
    </>
  );
};
