import jszip from 'jszip';
import { getFileIds } from './getFileIds';
import { fileName } from 'Regex';

export const MAX_FILE_SIZE_IN_MB = 20;
export const EMPTY_SIZE = 1000;

export const files = async () => {
  const files = document.querySelectorAll('button[data-required="true"]');
  if (files.length) {
    const btn = files[0] as HTMLButtonElement;

    const fileCategoryId = btn.getAttribute('data-file-category-id');

    const hospitalizationId = btn.getAttribute('data-hospitalization-id');

    const caseDetailsId = btn.getAttribute('data-case-details-id');

    if (fileCategoryId) {
      const fileIds = getFileIds(
        parseInt(fileCategoryId),
        hospitalizationId ? parseInt(hospitalizationId) : null,
        caseDetailsId ? parseInt(caseDetailsId) : null
      );
      if (!fileIds) {
        btn.style.color = '#d90000';
        btn.style.borderColor = '#d90000';
        await btn.focus();
        return false;
      }
    }
  }
  return true;
};

export const getCleanFileName = (name: string) => {
  const extensionIndex = name.lastIndexOf('.');
  const cleanName =
    name.slice(0, extensionIndex).replaceAll(fileName, '') +
    '.' +
    name.slice(extensionIndex).replaceAll(fileName, '');

  return cleanName;
};

export const checkFileSize = (file: File) => {
  if (file.size < EMPTY_SIZE) {
    return false;
  }
  const MB = file.size / 1024 / 1024;
  return MB < MAX_FILE_SIZE_IN_MB;
};

export const isFileValid = async (file: File) => {
  const fileExt = file.name.split('.').pop();
  if (fileExt === 'pdf') {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async function () {
        const files = new Blob([file], { type: 'application/pdf' });
        const text = await files.text();
        const isEncrypted =
          text.includes('Encrypt') ||
          text
            .substring(text.lastIndexOf('<<'), text.lastIndexOf('>>'))
            .includes('/Encrypt');
        if (isEncrypted) {
          resolve(false);
        } else {
          resolve(true);
        }
      };
    });
  } else if (fileExt === 'docx' || fileExt === 'doc') {
    return new Promise(async (resolve, reject) => {
      try {
        const zip = await jszip.loadAsync(file);
        const hasMacros = !!Object.keys(zip.files).find((f) =>
          f.includes('vbaProject')
        );
        resolve(!hasMacros);
      } catch (e) {
        resolve(false);
      }
    });
  }
  return true;
};
