import { api } from 'API';
import { FORM_KEYS_TYPE } from 'Constants';
const baseUrl = '/statusLobby';

export type LobbyStatusType = {
  [StatusKey in keyof FORM_KEYS_TYPE]: PersonalDetailPartial;
};

interface PersonalDetailPartial {
  areOptionalFieldsEmpty: boolean;
  requiredFieldsStatus: number;
}

export const getLobbyStatuses = async () => {
  const lobbyStatusData = await api.get<LobbyStatusType>(baseUrl);
  if (lobbyStatusData.data) {
    return lobbyStatusData.data;
  }
};
