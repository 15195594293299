import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface InitState {
  generalObj: {
    isRequestAllowanceWasSubmitted: boolean;
  };
}

const initialState: InitState = {
  generalObj: {
    isRequestAllowanceWasSubmitted: false,
  },
};

export const RequestAndEarlyAcquaintanceSlice = createSlice({
  name: 'requestAndEarlyAcquaintance',
  initialState,
  reducers: {
    setIsRequestAllowanceWasSubmitted: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.generalObj.isRequestAllowanceWasSubmitted = action.payload;
    },
  },
});

export const { setIsRequestAllowanceWasSubmitted } =
  RequestAndEarlyAcquaintanceSlice.actions;

export default RequestAndEarlyAcquaintanceSlice.reducer;
