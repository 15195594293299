import { Avatar, IconButton, Stack, Typography } from '@mui/material';
import { ControlledTextField } from 'Components/ControlledTextField';
import { OTPLoginContext } from 'Context/OTPLoginContext';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { useDictionary, useIsDesktop } from 'Hooks';
import { Eye } from 'Images';
import { useContext, useState } from 'react';

export const Form = () => {
  const { userDetails, setUserDetails, errors, setErrors } =
    useContext(OTPLoginContext);
  const [showPassword, setShowPassword] = useState(false);

  const isDesktop = useIsDesktop();
  const dictionary = useDictionary(Dictionary, DICTIONARY.LOGIN);

  return (
    <Stack
      direction='column'
      gap='16px'
      mb={isDesktop ? '40px' : '24px'}
      maxWidth={'400px'}
      m={'0 auto'}
    >
      <Typography
        component='h2'
        sx={{
          fontSize: '20px',
          textAlign: 'center',
          fontWeight: '700',
          mt: 3,
          mb: 2,
        }}
      >
        {dictionary.title}
      </Typography>
      <ControlledTextField
        label={dictionary.handicappedIsrId}
        ariaLabel={dictionary.handicappedIsrId}
        ariaRequired
        value={userDetails.handicappedIsrId}
        type='text'
        inputmode='numeric'
        setValue={(value: string) =>
          setUserDetails({ ...userDetails, handicappedIsrId: value.trim() })
        }
        showError
        inputRegexType='ID'
        error={errors.handicappedIsrId}
        setError={(error: string | null) =>
          setErrors({ ...errors, handicappedIsrId: error })
        }
      />
      <ControlledTextField
        label={dictionary.formFillerIsrId}
        ariaLabel={dictionary.formFillerIsrId}
        ariaRequired
        value={userDetails.formFillerIsrId}
        type='text'
        inputmode='numeric'
        setValue={(value: string) =>
          setUserDetails({ ...userDetails, formFillerIsrId: value.trim() })
        }
        showError
        inputRegexType='ID'
        error={errors.formFillerIsrId}
        setError={(error: string | null) =>
          setErrors({ ...errors, formFillerIsrId: error })
        }
      />
      <ControlledTextField
        label={dictionary.phone}
        ariaLabel={dictionary.phone}
        ariaRequired
        value={userDetails.phone}
        type='text'
        inputmode='numeric'
        setValue={(value: string) =>
          setUserDetails({ ...userDetails, phone: value.trim() })
        }
        showError
        inputRegexType='FULL_PHONE'
        error={errors.phone}
        setError={(error: string | null) =>
          setErrors({ ...errors, phone: error })
        }
        autocomplete='tel'
      />
      <ControlledTextField
        label={dictionary.password}
        ariaLabel={dictionary.password}
        ariaRequired
        value={userDetails.password}
        type={showPassword ? 'text' : 'password'}
        setValue={(value: string) =>
          setUserDetails({ ...userDetails, password: value.trim() })
        }
        showError
        inputRegexType='NAME'
        error={errors.password}
        setError={(error: string | null) =>
          setErrors({ ...errors, password: error })
        }
        // customInputProps={{
        //   endAdornment: (
        //     <IconButton onClick={() => setShowPassword(!showPassword)}>
        //       {showPassword ? (
        //         <Avatar
        //           alt={''}
        //           src={Eye}
        //           sx={{ width: '24px', height: '24px' }}
        //         />
        //       ) : (
        //         <Avatar
        //           alt={''}
        //           src={Eye}
        //           sx={{ width: '24px', height: '24px' }}
        //         />
        //       )}
        //     </IconButton>
        //   ),
        // }}
      />
    </Stack>
  );
};
