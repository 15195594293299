import { Grid, TextField, Typography } from '@mui/material';
import {
  CustomCheckbox,
  FileUpload,
  FormSection,
  InfoText,
  PhoneNumber,
  CustomRadioGroup,
} from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY, FileCategories, Representation } from 'Enum';
import { useWatch, useFormContext } from 'react-hook-form';
import { generateError } from 'Regex';
import { useAppSelector } from 'Hooks';
import { validateLettersAndNumbersOnly } from 'Utils/validateLettersAndNumbersOnly';

export const FirstSection = () => {
  const representationOptionRelationIdName = 'representationOptionRelationId';

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const [representationOptions] = useAppSelector(
    ({
      generalContent: {
        generalContent: { representationOptions },
      },
    }) => [representationOptions]
  );

  const representationOption = useWatch({
    name: representationOptionRelationIdName,
    defaultValue: 0,
  }) as number;

  const dictionary = Dictionary[DICTIONARY.REPRESENTATION];
  const generalDictionary = Dictionary[DICTIONARY.GENERAL];
  return (
    <FormSection grouped id={dictionary.firstSectionTitle}>
      <Grid
        sx={{
          background: '#F2F9FF',
          borderRadius: 1,
          padding: '16px 16px 0 16px',
          mb: 3,
        }}
      >
        <Grid container alignItems='center' columnGap={1}>
          <Typography
            variant='subtitle1'
            color='#39546A'
            sx={{ fontWeight: 'bold' }}
            component={'p'}
          >
            {dictionary.firstSectionInputTextHelperTitle}
          </Typography>
          <Typography variant='subtitle1' color='#39546A' component={'p'}>
            {dictionary.firstSectionInputTextHelperInfo}
          </Typography>
        </Grid>
        <CustomCheckbox
          name={'isAssistedWithGoodHandsCenter'}
          label={dictionary.firstSectionCheckboxLabel}
        />
      </Grid>

      {representationOptions && (
        <CustomRadioGroup
          id={dictionary.firstSectionTitle}
          name={`${representationOptionRelationIdName}` as const}
          options={representationOptions.map(({ id, value }) => ({
            value: id.toString(),
            label: value,
          }))}
          infoText={dictionary.firstSectionInputTextHelper}
        />
      )}
      {representationOption == Representation.isLawyer && (
        <>
          <Typography sx={{ marginTop: 3, fontWeight: 'bold' }}>
            {dictionary.firstSectionThirdInputTextHelper}
          </Typography>
          <TextField
            role={
              Boolean(errors.lawyerSection?.firstName) ? 'alert' : undefined
            }
            inputProps={{
              maxLength: '20',
            }}
            error={Boolean(errors.lawyerSection?.firstName)}
            helperText={
              Boolean(errors.lawyerSection?.firstName?.message) &&
              generateError(
                dictionary.firstSectionFirstInput,
                errors.lawyerSection?.firstName?.message!
              )
            }
            {...register('lawyerSection.firstName')}
            label={dictionary.firstSectionFirstInput}
            id={dictionary.firstSectionFirstInput}
            onChange={(event: any) => validateLettersAndNumbersOnly(event)}
          />
          <TextField
            role={Boolean(errors.lawyerSection?.lastName) ? 'alert' : undefined}
            inputProps={{
              maxLength: '30',
            }}
            error={Boolean(errors.lawyerSection?.lastName)}
            helperText={
              Boolean(errors.lawyerSection?.lastName?.message) &&
              generateError(
                dictionary.firstSectionSecondInput,
                errors.lawyerSection?.lastName?.message!
              )
            }
            {...register('lawyerSection.lastName')}
            label={dictionary.firstSectionSecondInput}
            id={dictionary.firstSectionSecondInput}
            onChange={(event: any) => validateLettersAndNumbersOnly(event)}
          />
          <TextField
            role={Boolean(errors.lawyerSection?.address) ? 'alert' : undefined}
            inputProps={{
              maxLength: '50',
            }}
            {...register('lawyerSection.address')}
            error={Boolean(errors.lawyerSection?.address)}
            helperText={
              Boolean(errors.lawyerSection?.address?.message) &&
              generateError(
                dictionary.firstSectionThirdInput,
                errors.lawyerSection?.address?.message!
              )
            }
            label={dictionary.firstSectionThirdInput}
            id={dictionary.firstSectionThirdInput}
            onChange={(event: any) => validateLettersAndNumbersOnly(event)}
          />
          <Typography sx={{ marginTop: 2 }}>
            {dictionary.firstSectionFileUploadLabel}
            <Typography color='error' component='span'>
              {generalDictionary.required}
            </Typography>
          </Typography>

          <FileUpload
            dataRequired
            fileLabel={dictionary.firstSectionFileUploadText}
            fileCategoryId={FileCategories.LawyerPowerOfAttorneyAttachment}
            ariaLabel={dictionary.firstSectionFileUploadLabel}
          />
          <Typography sx={{ marginTop: 2 }}>
            {dictionary.firstSectionSecondFileUploadLabel}
            <Typography color='error' component='span'>
              {generalDictionary.required}
            </Typography>
          </Typography>
          <FileUpload
            dataRequired
            fileLabel={dictionary.firstSectionSecondFileUploadText}
            fileCategoryId={FileCategories.LawyerRepresentationAttachment}
            ariaLabel={dictionary.firstSectionSecondFileUploadLabel}
          />
        </>
      )}

      {representationOption == Representation.isAssociation && (
        <>
          <Typography sx={{ marginTop: 3, fontWeight: 'bold' }}>
            {dictionary.firstSectionAssociationTitle}
          </Typography>
          <TextField
            role={
              Boolean(errors.associationSection?.associationName)
                ? 'alert'
                : undefined
            }
            inputProps={{
              maxLength: '100',
            }}
            error={Boolean(errors.associationSection?.associationName)}
            helperText={
              Boolean(errors.associationSection?.associationName?.message) &&
              generateError(
                dictionary.firstSectionAssociationName,
                errors.associationSection?.associationName?.message!
              )
            }
            {...register('associationSection.associationName')}
            label={dictionary.firstSectionAssociationName}
            id={dictionary.firstSectionAssociationName}
            onChange={(event: any) => validateLettersAndNumbersOnly(event)}
          />
          <TextField
            role={
              Boolean(errors.associationSection?.address) ? 'alert' : undefined
            }
            inputProps={{
              maxLength: '50',
            }}
            error={Boolean(errors.associationSection?.address)}
            helperText={
              Boolean(errors.associationSection?.address?.message) &&
              generateError(
                dictionary.firstSectionAssociationAddress,
                errors.associationSection?.address?.message!
              )
            }
            {...register('associationSection.address')}
            label={dictionary.firstSectionAssociationAddress}
            id={dictionary.firstSectionAssociationAddress}
            onChange={(event: any) => validateLettersAndNumbersOnly(event)}
          />
          <PhoneNumber
            phoneNumberErrorMsg={errors.associationSection?.phone?.message}
            phoneNumberError={Boolean(
              errors.associationSection?.phone?.message
            )}
            phoneNumberPrefixFormName='associationSection.phonePrefixRelationId'
            phoneNumberFormName='associationSection.phone'
            phoneNumberLabel={dictionary.firstSectionAssociationPhone}
          />
          <TextField
            role={
              Boolean(errors.associationSection?.representativeName)
                ? 'alert'
                : undefined
            }
            inputProps={{
              maxLength: '50',
            }}
            error={Boolean(errors.associationSection?.representativeName)}
            helperText={
              Boolean(errors.associationSection?.representativeName?.message) &&
              generateError(
                dictionary.firstSectionAssociationAgentName,
                errors.associationSection?.representativeName?.message!
              )
            }
            {...register('associationSection.representativeName')}
            label={dictionary.firstSectionAssociationAgentName}
            id={dictionary.firstSectionAssociationAgentName}
            onChange={(event: any) => validateLettersAndNumbersOnly(event)}
          />
          <PhoneNumber
            phoneNumberErrorMsg={
              errors.associationSection?.mobilePhone?.message
            }
            phoneNumberError={Boolean(
              errors.associationSection?.mobilePhone?.message
            )}
            phoneNumberPrefixFormName='associationSection.mobilePhonePrefixRelationId'
            phoneNumberFormName='associationSection.mobilePhone'
            phoneNumberLabel={dictionary.firstSectionAssociationAgentPhone}
          />
          <TextField
            role={
              Boolean(errors.associationSection?.mail) ? 'alert' : undefined
            }
            inputProps={{
              maxLength: '50',
            }}
            error={Boolean(errors.associationSection?.mail)}
            helperText={
              Boolean(errors.associationSection?.mail?.message) &&
              generateError(
                dictionary.firstSectionAssociationEmail,
                errors.associationSection?.mail?.message!
              )
            }
            {...register('associationSection.mail')}
            label={dictionary.firstSectionAssociationEmail}
            id={dictionary.firstSectionAssociationEmail}
          />

          <Typography>
            {Dictionary[DICTIONARY.REPRESENTATION].thirdSectionFileUploadLabel}
            <Typography color='error' component='span'>
              {Dictionary[DICTIONARY.GENERAL].required}
            </Typography>
          </Typography>
          <FileUpload
            dataRequired
            fileLabel={
              Dictionary[DICTIONARY.REPRESENTATION].thirdSectionFileUploadText
            }
            fileCategoryId={FileCategories.AssociationRepresentationAttachment}
            ariaLabel={
              Dictionary[DICTIONARY.REPRESENTATION].thirdSectionFileUploadLabel
            }
          />
        </>
      )}
    </FormSection>
  );
};
