import { Avatar, Grid, Typography } from '@mui/material';
import { Info } from 'Images';
interface IInfoText {
  text: string;
}
export const InfoText = (props: IInfoText) => {
  return (
    <Grid container columnGap={1} sx={{ flexWrap: 'unset' }}>
      <Avatar src={Info} alt='' sx={{ width: '18px', height: '18px' }} />
      <Typography variant='subtitle1' color='#4d4d4d' component={'p'}>
        {props.text}
      </Typography>
    </Grid>
  );
};
