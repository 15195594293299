import { MenuItem, TextField, Typography } from '@mui/material';
import { IPersonalDetails } from 'API';
import { ControllerSelect, FormSection } from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { useAppSelector } from 'Hooks';
import { useFormContext, useWatch } from 'react-hook-form';
import { generateError } from 'Regex';
import { textFieldShrink } from 'Utils';
import { validateLettersAndNumbersOnly } from 'Utils/validateLettersAndNumbersOnly';
export const FourthSection = () => {
  const {
    register,
    getValues,
    formState: {
      errors: { hmoSection },
    },
  } = useFormContext<IPersonalDetails>();
  const hmOs = useAppSelector(
    ({
      generalContent: {
        generalContent: { hmOs },
      },
    }) => hmOs
  );

  const hmoStillInMilitaryServiceId = 5;

  const hmoRelationIdName = 'hmoSection.hmoRelationId';

  const hmoRelationId = useWatch({
    name: hmoRelationIdName,
    defaultValue: 0,
  }) as number;

  const doctorsNameAndSpecializationName =
    'hmoSection.doctorsNameAndSpecialization';

  return (
    <FormSection
      grouped
      id={Dictionary[DICTIONARY.PERSONAL_DETAILS].fourthSectionTitle}
    >
      <Typography
        sx={{ fontWeight: 'bold' }}
        id={Dictionary[DICTIONARY.PERSONAL_DETAILS].fourthSectionTitle}
      >
        {Dictionary[DICTIONARY.PERSONAL_DETAILS].fourthSectionTitle}
      </Typography>
      <Typography>
        {Dictionary[DICTIONARY.PERSONAL_DETAILS].fourthSectionSubTitle}
      </Typography>
      <ControllerSelect
        name={hmoRelationIdName}
        label={Dictionary[DICTIONARY.PERSONAL_DETAILS].fourthSectionFirstInput}
      >
        {hmOs.map(({ value, id }) => (
          <MenuItem key={id} value={id}>
            {value}
          </MenuItem>
        ))}
      </ControllerSelect>
      {hmoRelationId != hmoStillInMilitaryServiceId && (
        <TextField
          role={
            Boolean(hmoSection?.doctorsNameAndSpecialization)
              ? 'alert'
              : undefined
          }
          id={Dictionary[DICTIONARY.PERSONAL_DETAILS].fourthSectionSecondInput}
          inputProps={{
            maxLength: '50',
          }}
          {...register(doctorsNameAndSpecializationName)}
          label={
            Dictionary[DICTIONARY.PERSONAL_DETAILS].fourthSectionSecondInput
          }
          {...textFieldShrink(getValues([doctorsNameAndSpecializationName])[0])}
          error={Boolean(hmoSection?.doctorsNameAndSpecialization)}
          helperText={Boolean(
            hmoSection?.doctorsNameAndSpecialization?.message &&
              generateError(
                Dictionary[DICTIONARY.PERSONAL_DETAILS]
                  .fourthSectionSecondInput,
                hmoSection?.doctorsNameAndSpecialization?.message!
              )
          )}
          onChange={(event: any) => validateLettersAndNumbersOnly(event)}
        />
      )}
    </FormSection>
  );
};
