import { Button, Divider, Stack, Typography } from '@mui/material';
import { Loader } from 'Components';
import { Dictionary } from 'Dictionary';
import { DICTIONARY } from 'Enum';
import { useIsDesktop } from 'Hooks';
import { Form } from './Form';
import { useContext, useState } from 'react';
import { OTPLoginContext } from 'Context/OTPLoginContext';
import { IGenerateOtpDTO, generateOtpCode } from 'API/EmergencyForm';
import { HTTP_STATUS_CODES } from 'Enum/HttpStatusCodes';
import { OTPCodeValidationWrapper } from './OTPCodeValidationWrapper';

interface ILogin {
  token: string;
  isLoginLoading: boolean;
  setIsLoggedIn: Function;
}

export const Login = ({ token, isLoginLoading, setIsLoggedIn }: ILogin) => {
  const { userDetails } = useContext(OTPLoginContext);
  const isDesktop = useIsDesktop();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const [showValidationOtp, setShowValidationOtp] = useState<boolean>(false);
  const [showInvalidUser, setShowInvalidUser] = useState<boolean>(false);
  const dictionary = Dictionary[DICTIONARY.EMERGENCY_FORM];

  const validationHandler = async () => {
    const requestBody: IGenerateOtpDTO = {
      formFillerIsrId: userDetails.formFillerIsrId,
      handicappedIsrId: userDetails.handicappedIsrId,
      phone: userDetails.phone,
      password: userDetails.password,
      isSms: true,
    };
    setIsLoading(true);
    const result = await generateOtpCode(requestBody);

    if (result.status === HTTP_STATUS_CODES.OK) setShowValidationOtp(true);
    if (result.request.status === HTTP_STATUS_CODES.BadRequest)
      setShowInvalidUser(true);
    setIsLoading(false);
  };

  const formValidation = () => {
    return false;
  };

  return (
    <Stack alignItems={'center'} pb={3}>
      <Typography
        variant='h1'
        fontWeight='bold'
        tabIndex={0}
        mb={2}
        textAlign={'center'}
        fontSize={'22px'}
        maxWidth={isDesktop ? undefined : '280px'}
      >
        {dictionary.subtitle}
      </Typography>
      <Typography variant='h2' fontWeight='bold' tabIndex={0} mb={2}>
        {dictionary.loginTitle}
      </Typography>
      <Typography
        component={'h2'}
        fontSize={'16px'}
        maxWidth={'570px'}
        textAlign={'center'}
        mb={4}
      >
        {dictionary.loginInfo}
      </Typography>
      <Stack
        maxWidth={'570px'}
        gap={'5px'}
        sx={{
          backgroundColor: '#fff',
          p: '32px 56px',
          borderRadius: '24px',
          boxShadow: '0px 3px 21px 0px rgba(45, 112, 217, 0.15)',
        }}
      >
        <Typography variant='h3' fontWeight='bold' lineHeight={'normal'}>
          {dictionary.info1Label}
        </Typography>
        <Typography mb={2}>{dictionary.info1}</Typography>

        <Typography variant='h3' fontWeight='bold' lineHeight={'normal'}>
          {dictionary.info2Label}
        </Typography>
        <Typography>{dictionary.info2}</Typography>

        <ul style={{ listStyleType: 'initial' }}>
          <li>{dictionary.li1}</li>
          <li>{dictionary.li2}</li>
          <li>{dictionary.li3}</li>
        </ul>
        <Divider sx={{ mb: 3 }} />
        {isLoading ? (
          <Loader />
        ) : showValidationOtp ? (
          <OTPCodeValidationWrapper
            setValidation={setShowValidationOtp}
            sendCodeAgain={validationHandler}
            setIsLoggedIn={setIsLoggedIn}
          />
        ) : (
          <>
            <form
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  validationHandler();
                }
              }}
            >
              <Form />
            </form>
            <Button
              disabled={formValidation()}
              variant='contained'
              color='primary'
              type='submit'
              sx={isDesktop ? { width: '220px', m: '32px auto' } : { mt: 3 }}
              onClick={validationHandler}
            >
              {dictionary.continue}
            </Button>
          </>
        )}
      </Stack>
    </Stack>
  );
};
